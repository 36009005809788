import React, { useState } from 'react';
import {
  EmotionAssetsBlock,
  EmotionBlock,
  EmotionBlockPurchaseBottom,
  EmotionBlockPurchaseItem,
  EmotionBlockPurchaseTop,
  EmotionBlockTitle,
  ModalBody, ModalButtons,
  ModalHeader,
  ModalHeaderInfo,
  ModalHeaderName,
  ModalHeaderPhoto,
  ModalTag,
  ModalTitle,
} from '../styles';
import Modal from '../index';
import modalHeaderPhoto from '../../../images/modalHeaderPhoto.png';
import CollectionValueIcon from '../../../images/CollectionValueIcon.svg';
import Button from '../../Button';
import SaveProfileIcon from '../../../images/SaveProfileIcon.svg';
import { useModalContext } from '../../../context/hooks';

const ConfirmCreationModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [loading, setLoading] = useState(false);

  const toggleCongratulationsModal = (data) => {
    const second = {
      ...modals.congratulationsModal,
      isOpen: !modals.congratulationsModal.isOpen,
      ...data,
    };
    updateModalState('congratulationsModal', second);
  };

  const toggleConfirmCreationModal = () => {
    updateModalState('confirmCreationModal', {
      isOpen: !modals.confirmCreationModal.isOpen,
    });
  };

  const delayCongratulationsSubmit = () => {
    const id = setTimeout(() => toggleCongratulationsModal({ isOpen: false }), 3000);

    toggleCongratulationsModal({ timeoutId: id, congratulationsText: 'You have created a token' }); // because of useReducer is async, we must do this
    toggleConfirmCreationModal();
  };

  const delaySubmit = () => {
    return new Promise((resolve => {
      setTimeout(() => resolve('Success'), 3000);
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    delaySubmit().then(console.log).then(() => {
      setLoading(false);
      delayCongratulationsSubmit();
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.confirmCreationModal.isOpen}
              setIsOpen={toggleConfirmCreationModal}
              modalName={'tokenModal'}
          >
              <ModalHeader>
                  <ModalHeaderPhoto>
                      <img src={modalHeaderPhoto} alt={''} />
                  </ModalHeaderPhoto>
                  <ModalHeaderInfo>
                      <ModalHeaderName>Token name</ModalHeaderName>
                      <ModalTitle>
                          Confirm creation
                          <ModalTag>Place</ModalTag>
                      </ModalTitle>
                  </ModalHeaderInfo>
              </ModalHeader>
              <ModalBody>
                  <EmotionBlock>
                      <EmotionBlockTitle>Purchase details</EmotionBlockTitle>
                      <EmotionAssetsBlock className={'purchase'}>
                          <EmotionBlockPurchaseTop>
                              <EmotionBlockPurchaseItem>
                                  <div className={'title'}>Your invest</div>
                                  <div className={'text'}><CollectionValueIcon />119.6547565 CCR</div>
                              </EmotionBlockPurchaseItem>
                              <EmotionBlockPurchaseItem className={'line'} />
                              <EmotionBlockPurchaseItem>
                                  <div className={'title'}>URL</div>
                                  <div className={'text'}>456dfgdrg33s346gs</div>
                              </EmotionBlockPurchaseItem>
                          </EmotionBlockPurchaseTop>
                          <EmotionBlockPurchaseBottom>
                              <div className={'title'}>Description</div>
                              <div className={'text'}>You will pass validation if 25 validators gave correct answer.
                                  If after 50 attempts the validators do not guess the name,
                                  then it is considered that this is not a famous person and refuses to add him ....
                              </div>
                          </EmotionBlockPurchaseBottom>
                      </EmotionAssetsBlock>
                  </EmotionBlock>
                  <ModalButtons className={'flex'}>
                      <Button
                          outlined
                          type={'button'}
                          onClick={toggleConfirmCreationModal}
                      >
                          Back
                      </Button>
                      <Button
                          primary
                          type={'submit'}
                          className={'loading'}
                          onClick={handleSubmit}
                      >
                          Confirm
                          {loading ? <SaveProfileIcon /> : null}
                      </Button>
                  </ModalButtons>
              </ModalBody>
          </Modal>
      </>
  );
};

export default ConfirmCreationModal;
