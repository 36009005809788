import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '48px'};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    
    &.closeThirdButton {
        margin-top: 16px;
    }
    
    &.cropButton {
        display: block;
        width: 90%;
        margin: 0 auto;
        
        &:last-child {
            color: ${props => props.theme.colors.primary};
            background-color: ${props => props.theme.colors.white};
            border: 2px solid ${props => props.theme.colors.mercury};
        }
    }
    
    &.loading {
        position: relative;
        
        & svg {
            position: absolute;
            top: 15px;
            right: 16px;    
            width: 18px;
            height: 18px;
            fill: ${props => props.theme.colors.white};
            -webkit-animation:spin 4s linear infinite;
            -moz-animation:spin 4s linear infinite;
            animation:spin 4s linear infinite;
        }
        
        &.edit {
            & svg {
                fill: ${props => props.theme.colors.primary};
            }
            
            &:hover {
                & svg {
                    fill: ${props => props.theme.colors.white};
                }
            }
        }
    }
    
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    
    &.topSeeAllButton {
        width: 120px;
        height: 40px;
        font-size: ${props => props.fontSize || props.theme.fontSize.f14};
        font-weight: 500;
        
        &:hover {
            color: ${props => props.color || props.theme.colors.white};
            background-color: ${props => props.color || props.theme.colors.clearBlue};
            border: none;
            box-shadow: ${props => props.color || props.theme.boxShadow.hoverButton};
        }
    }
    
    &.bottomSeeAllButton {
        display: none;
        width: 120px;
        height: 40px;
        margin: 16px auto 0;
        font-size: ${props => props.fontSize || props.theme.fontSize.f14};
    }
    
    &.filterButton {
        display: none;
    }

    &:active,
    &:focus {
        outline: none;
    }
    
    align-self: ${props => props.align || 'stretch'};

    ${props => props.primary && css`
        color: ${p => p.color || p.theme.colors.white};
        background: ${p => p.theme.colors.clearBlue};
        
        &:hover {
            box-shadow: ${p => p.theme.boxShadow.hoverButton};
        }
    `}
    ${props => props.outlined && css`
        color: ${props.theme.colors.primary};
        background: ${props.theme.colors.white};
        border: 2px solid ${p => p.theme.colors.mercury};
    `}
    ${props => props.delete && css`
        color: ${props.theme.colors.coralRed};
        background: transparent;
        border: 2px solid ${p => p.theme.colors.coralRed};
    `}
    ${props => props.disabled && css`
        color: ${props.theme.colors.cadetBlue};
        background: ${props.theme.colors.white};
        border: 2px solid ${p => p.theme.colors.mercury};
        
        &:hover {
            box-shadow: none;
        }
    `}
    ${props => props.decline && css`
        background: ${props.theme.colors.whiteSmoke};
    `}
    
    @media ${props => props.theme.device.lg} {
        &.filterButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 224px;
            height: 44px;
            font-weight: 500;
        
            & svg {
                margin-right: 12px;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.topSeeAllButton {
            display: none;
        }
    
        &.bottomSeeAllButton {
            display: block;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        &.filterButton {
            width: 100%;
            background: ${props => props.theme.colors.primary};
        }
    }
`;

export const DefaultButton = styled(StyledButton)`
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    border-radius: 0;
`;

export const CardButton = styled(StyledButton)`
    height: 40px;
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 500;
    line-height: 16px;
    border: none;
`;

export const CopyButton = styled(StyledButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 36px;
    height: 36px;
    background: transparent;
    border-top: 2px solid ${props => props.theme.colors.mercury};
    border-left: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 0;
    
    & .copyIcon {
        width: 24px;
        height: 24px;
        fill: ${props => props.theme.colors.clearBlue};
    }
`;

export const CopyInputButton = styled(StyledButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 50px;
    height: 52px;
    background: ${props => props.theme.colors.white};
    
    & svg {
        width: 24px;
        height: 24px;
        fill: ${props => props.theme.colors.clearBlue};
    }
`;

export const AddMaxButton = styled(StyledButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 2px;
    width: 52px;
    height: 42px;
    color: ${props => props.theme.colors.cadetBlue};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    background: transparent;
    
    &.disabled {
        color: ${props => props.theme.colors.mercury};
    }
    
    @media ${props => props.theme.device.xs} {
        width: 62px;
    }
`;

export const FormButton = styled(StyledButton)`
    display: block;
    width: 200px;
    margin: 26px 0 0 auto;
    
    &.login {
        width: 276px;
        margin: 0 auto 0 0;
    }
    
    &.loading {
        position: relative;
        
        & svg {
            position: absolute;
            right: 16px;    
            width: 18px;
            height: 18px;
            -webkit-animation:spin 4s linear infinite;
            -moz-animation:spin 4s linear infinite;
            animation:spin 4s linear infinite;
        }
    }
    
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    
    @media ${props => props.theme.device.lg} {
        &.login {
            width: 204px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
        margin-left: 0;
        
         &.login {
            width: 100%;
        }
    }
`;

export const TokenButton = styled(StyledButton)`
    width: auto;
    height: 28px;
    padding: 0 16px;
    font-size: ${props => props.theme.fontSize.f12};
    background: ${props => props.theme.colors.blueGradient};
    border-radius: 100px;
`;

export const TagButton = styled(StyledButton)`
    width: auto;
    height: 32px;
    padding: 0 16px;
    font-size: ${props => props.theme.fontSize.f14};
    background: ${props => props.theme.colors.blueGradient};
    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: 8px;
`;

export const SkipButton = styled(StyledButton)`
    position: absolute;
    top: 26px;
    right: 32px;
    width: 100px;
    height: 36px;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.f14};
    background: ${({ background }) => (background)};
    
    @media ${props => props.theme.device.md} {
        top: 16px;
        right: 20px;
        width: 62px;
    }
`;

