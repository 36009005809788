import styled from 'styled-components';

export const StyledDealsInvestments = styled.div`
    margin-top: 26px;
`;

export const Tabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 26px 0 16px;
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.md} {
         overflow-x: scroll;
         flex-wrap: nowrap;
         width: auto;
         margin: 26px 0 42px;
         white-space: nowrap;
         -ms-overflow-style: none;
         scrollbar-width: none;
        
         &::-webkit-scrollbar {
            display: none;
         }
    }
`;

export const RadioLabel = styled.label`
    display: block;
    height: 36px;
    margin: 0 32px 0 0;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
       margin-right: 0;
    }
    
    &.checked {
        position: relative;
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
        
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: ${props => props.theme.colors.clearBlue};
        }
    }
`;
