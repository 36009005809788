import React from 'react';
import { FrameWrapper } from './styles';
import FrameCard from './components/FrameCard';

const Frame = () => {
  return (
      <FrameWrapper>
          <FrameCard id={'horizontal'} />
      </FrameWrapper>
  );
};

export default Frame;
