import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Copyright, FooterInfo, StyledFooter } from './styles';
import Container from '../../components/Container';
import Logo from '../../components/Logo';
import MainMenu from '../../components/Nav/MainMenu';

const Footer = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  if (location.pathname === '/for-frame') {
    return null;
  }
  return (
      <StyledFooter {...props}>
          <Container justify={'space-between'} direction={'column'}>
              <FooterInfo>
                  <Logo />
                  <MainMenu open={open} setOpen={setOpen} className={'footer'} />
              </FooterInfo>
              <Copyright>Copyright © 2021 UI8 LLC. All rights reserved</Copyright>
          </Container>
      </StyledFooter>
  );
};

export default Footer;
