import React from 'react';
import {
  InfoBlock, InfoBlockTitle, StyledWarningBlock,
} from './styles';
import WarningIcon from '../../images/WarningIcon.svg';

const WarningBlock = ({
  className, image, title, content, 
}) => {
  return (
      <StyledWarningBlock className={className}>
          <InfoBlock className={'infoBlock'}>
              <InfoBlockTitle className={'infoBlockTitle'}>
                  {image
                    ? <WarningIcon />
                    : ''
                  }
                  {title}
              </InfoBlockTitle>
              {content}
          </InfoBlock>
      </StyledWarningBlock>
  );
};

export default WarningBlock;
