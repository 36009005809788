import styled from 'styled-components';

export const StyledMembersSlider = styled.div`
    box-shadow: ${props => props.theme.boxShadow.filter};

    & .swiper-slide {
        width: auto;
        list-style: none;
    }
`;
