import React from 'react';
import { RadioLabel } from '../../styles';

const Index = ({ checked, title, input }) => {
  return (
      <RadioLabel
          className={checked ? 'checked' : ''}
      >
          <input type='radio' {...input} />
          {title}
      </RadioLabel>
  );
};

export default Index;
