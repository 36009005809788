import styled from 'styled-components';

export const Typography1 = styled.div`
    h3 {
        font-size: ${props => props.theme.fontSize.f24};
        line-height: 32px;
    }
    
    p {
        margin-top: 30px;
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
    }
`;

export const Typography2 = styled.div`
    margin-top: 80px;
    
    h3 {
        font-size: ${props => props.theme.fontSize.f20};
    }
    
    p {
        margin-top: 30px;
        font-size: ${props => props.theme.fontSize.f14};
        line-height: 20px;
    }
`;

export const Typography3 = styled.div`
    margin-top: 80px;
    
    h3 {
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
    }
    
    p {
        margin-top: 30px;
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 16px;
    }
`;
