import styled from 'styled-components';

export const AssetsTableSelectContainer = styled.div`
    display: none;
    
    @media ${props => props.theme.device.md} {
        display: flex;
        align-items: center;
        height: 80px;
    }
    
    @media ${props => props.theme.device.sm} {
        height: auto;
        margin-bottom: 16px;
    }
`;

export const AssetsTableSelect = styled.div`  
    @media ${props => props.theme.device.md} {
        width: 260px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 272px;
    }
    
    @media ${props => props.theme.device.xs} {
        width: 225px;
    }
`;

export const AssetsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: 500;
        
        & button.sort {
            display: flex;
            align-items: center;
            color: ${props => props.theme.colors.secondary};
            font-size: ${props => props.theme.fontSize.f14};
            font-weight: 500;
                
            & svg {
                width: 24px;
                height: 24px;
                fill: ${props => props.theme.colors.secondary};
            }
        }
        
        & button.sort.active {
            & svg {
                transform: rotate(180deg);
            }
        }
        
    }
    
    td {
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
        
        a img {
            display: block;
        }
        
        & span {
            display: flex;
            align-items: center;
            font-weight: 500;
        
            &.token {
                display: flex;
                align-items: center;
        
                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                }
            }
        }
        
        &.buttons {
            padding-right: 0;
        
            & span {
                display: flex;
                justify-content: flex-end;
        
                & .modalButton {
                    width: 64px;
                    height: 36px;
                    font-size: ${props => props.theme.fontSize.f14}; 
                    font-weight: 500;
                
                    &:hover {
                        box-shadow: ${props => props.theme.boxShadow.hoverButton};
                    }
                
                    &:first-child {
                        margin-right: 12px;    
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        th {
            &:first-child {
                padding-right: 20px;
            }
        
            & button.sort {
                text-align: left;
            }
        }
    
        td {
            a img {
                width: 120px;
                height: 80px;
            }
            
            &.buttons {
                & span {
                    & .modalButton {
                        display: none;
                    }
                }
            }
            
            & span {
                 &.token {
                    img {
                        display: none;
                    }
                 }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
    
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            border-radius: 12px;
            
            a img {
                height: 52px;
            }
            
            &:nth-child(even) {
                background: ${props => props.theme.colors.white};
            }
            
            &:first-child {
                margin-bottom: 12px;
                padding: 0;
                
                &:before {
                    display: none;
                }
            }
            
            &:last-child {
                background: transparent;
            }
        
            &:before {
                content: attr(data-label);
                float: left;
                padding-right: 15px;
                color: ${props => props.theme.colors.secondary};
                font-weight: 600;
            }
            
            span {
                &.token {
                   
                }
            }
            
            &.priceHistory {
                height: 52px;
            }
            
            &.buttons {
                overflow: inherit;
                padding: 0;
                
                &:before {
                    padding-right: 0;
                }
                
                & span {
                    justify-content: center;
                    width: 100%;
                    
                    & .modalButton {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 138px;
                        width: 100%;
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px;
        }
    
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12}; 
            
            &:first-child {
                margin-bottom: 6px;
            }
        
            &.buttons {
                & span {
                    & .modalButton {
                        width: 120px;
                    }
                }
            }
        }
    }
`;

export const TableTabletPopup = styled.div`
    display: none;

    @media ${props => props.theme.device.lg} {
        display: block;
    }
    
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;
