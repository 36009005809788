import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  RadioLabel, ScheduleContainer, ScheduleTimeline, ScheduleTitle, ScheduleTop, ScheduleWrapper,
} from './styles';
import { baseTheme } from '../../styles/theme';
import { faker, mapToObject } from '../../utils/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const titleTooltip = () => {
  return '';
};

const lineOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: {
      grid: {
        color: `${baseTheme.colors.mercury}`,
        borderDash: [5, 5],
        zeroLineWidth: 0,
      },
      ticks: {
        color: `${baseTheme.colors.primary}`,
        font: {
          size: 12,
          family: "'Poppins', sans-serif",
        },
      },
    },
    yAxes: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      yAlign: 'bottom',
      zIndex: 1,
      callbacks: {
        title: titleTooltip,
      },
    },
  },
};

const timeline = [
  { id: 1, dealsAmount: 7, title: '1H' },
  { id: 2, dealsAmount: 10, title: '24H' },
  { id: 3, dealsAmount: 100, title: '1W' },
  { id: 4, dealsAmount: 500, title: '1M' },
  { id: 5, dealsAmount: 1000, title: '1Y' },
];

const Schedule = () => {
  const [selectedTab, setSelectedTab] = useState(timeline[0]);
  const [fakeDataCurrency, setFakeDataCurrency] = useState(faker(7));
  const [fakeDataLikesUp, setFakeDataLikesUp] = useState(faker(7));
  const [fakeDataLikesDown, setFakeDataLikesDown] = useState(faker(7));

  const data = {
    datasets: [
      {
        label: '$',
        lineTension: 0,
        background: 'none',
        borderColor: `${baseTheme.colors.clearBlue}`,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `${baseTheme.colors.primary}`,
        pointHoverBorderColor: `${baseTheme.colors.white}`,
        pointHoverBorderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 10,
        data: mapToObject(fakeDataCurrency),
        interaction: {
          intersect: false,
        },
      },
      {
        label: 'likesUp',
        lineTension: 0,
        background: 'none',
        borderColor: `${baseTheme.colors.mediumGreen}`,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `${baseTheme.colors.primary}`,
        pointHoverBorderColor: `${baseTheme.colors.white}`,
        pointHoverBorderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 10,
        data: mapToObject(fakeDataLikesUp),
        interaction: {
          intersect: false,
        },
      },
      {
        label: 'likesDown',
        lineTension: 0,
        background: 'none',
        borderColor: `${baseTheme.colors.coralRed}`,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `${baseTheme.colors.primary}`,
        pointHoverBorderColor: `${baseTheme.colors.white}`,
        pointHoverBorderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 10,
        data: mapToObject(fakeDataLikesDown),
        interaction: {
          intersect: false,
        },
      },
    ],
  };

  return (
       <ScheduleContainer>
         <ScheduleTop>
           <ScheduleTitle>+120 CCR in 24h</ScheduleTitle>
           <ScheduleTimeline>
             {timeline.map(time => {
               return (
                   <RadioLabel
                       key={time.id}
                       className={time.id === selectedTab.id ? 'checked' : ''}
                       onChange={() => {
                         setSelectedTab(time);
                         setFakeDataCurrency(faker(time.dealsAmount));
                         setFakeDataLikesUp(faker(time.dealsAmount));
                         setFakeDataLikesDown(faker(time.dealsAmount));
                       }}
                   >
                     {time.title}
                     <input type='radio' name="time" />
                   </RadioLabel>
               );
             })}
           </ScheduleTimeline>
         </ScheduleTop>
         <ScheduleWrapper>
           <Line data={data} options={lineOptions} />
         </ScheduleWrapper>
       </ScheduleContainer>
  );
};

export default Schedule;
