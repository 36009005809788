import styled from 'styled-components';
import SelectArrowIcon from '../../../../images/SelectArrowIcon.inline.svg';
import SelectCheckIcon from '../../../../images/SelectCheckIcon.inline.svg';

export const StyledSelect = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SelectBlock = styled.div`
    position: relative;
    width: 100%;
    height: 36px;
`;

export const DropDownHeader = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 32px 0 16px;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 36px;
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    background: ${props => props.theme.colors.white};
    border-radius: 8px;
    
    &:after {
        content: '';
        position: absolute;
        top: 6px;
        right: 8px;
        width: 24px;
        height: 24px;
        background: url('${SelectArrowIcon}') no-repeat center;
    }
`;

export const DropDownList = styled.ul`
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 16px;
    list-style: none;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.boxShadow.filter};
    border-radius: 8px;
    z-index: 2;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    padding: 6px 0;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
    
    &.active {
        position: relative;
        color: ${props => props.theme.colors.primary};
        
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 16px;
            height: 16px;
            background: url('${SelectCheckIcon}') no-repeat center;
        }
    }
`;

export const ResultBlock = styled.div`
    text-align: right;
    margin-top: 6px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    line-height: 16px;
    
    & span {
        margin-left: 8px;
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 500;
    }
    
    @media ${props => props.theme.device.sm} {
        text-align: left;
    }
`;

