import React, {
  useCallback, useEffect, useMemo, useState, 
} from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import { DefaultButton } from '../../../../components/Button/styles';
import CropCreateLeft from '../../../../images/CropCreateLeft.svg';
import CropCreateRight from '../../../../images/CropCreateRight.svg';
import CropDeleteIcon from '../../../../images/CropDeleteIcon.svg';
import {
  ControlBlock, CropContainer, PreviewCardImage,
} from './styles';
import cardImg from '../../../../images/frame_photo.png';

const CropEditorCreate = ({
  picture, setPicture, setCanvasInstance, setFiles,
}) => {
  const [editor, setEditor] = useState(null);

  const getCanvas = useCallback(() => {
    const canvas = editor?.getImageScaledToCanvas();
    if (canvas) {
      setCanvasInstance(canvas);
    }
  }, [editor, setCanvasInstance]);

  const rotateLeft = (e) => {
    e.preventDefault();
    setPicture({
      ...picture,
      rotate: picture.rotate - 90,
    });
  };

  const rotateRight = (e) => {
    e.preventDefault();
    setPicture({
      ...picture,
      rotate: picture.rotate + 90,
    });
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setPicture({
      ...picture,
      scale,
    });
  };

  const handlePositionChange = (position) => {
    setPicture({
      ...picture,
      position,
    });
  };

  useEffect(() => {
    getCanvas();
  }, [picture, getCanvas]);

  const backgroundImageStyles = useMemo(() => {
    if (picture?.image) {
      return {};
    }
    return {
      background: `url(${cardImg})`,
    };
  }, [picture?.image]);

  const setEditorRef = (ed) => (setEditor(ed));

  const handleDelete = () => {
    setFiles([]);
  };

  return (
      <>
        <CropContainer>
          <PreviewCardImage {...backgroundImageStyles}>
            <CropDeleteIcon className={'cropDelete'} onClick={handleDelete} />
            <ReactAvatarEditor
                ref={setEditorRef}
                scale={parseFloat(picture.scale)}
                width={picture.width}
                height={picture.height}
                position={picture.position}
                onPositionChange={handlePositionChange}
                rotate={parseFloat(picture.rotate)}
                border={picture.border}
                color={picture.color}
                image={picture.image}
                onLoadSuccess={getCanvas}
            />
          </PreviewCardImage>
          {picture?.image && <ControlBlock>
            <DefaultButton onClick={rotateLeft}><CropCreateLeft/></DefaultButton>
            <input
                name="scale"
                type="range"
                onChange={handleScale}
                min={picture.allowZoomOut ? '0.1' : '1'}
                max="2"
                step="0.01"
                defaultValue="1"
            />
            <DefaultButton onClick={rotateRight}><CropCreateRight/></DefaultButton>
          </ControlBlock>}
        </CropContainer>
      </>
  );
};

export default CropEditorCreate;
