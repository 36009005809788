import styled from 'styled-components';

export const Subtitle = styled.div`
    margin: 6px 0 32px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    
    & button {
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 840px;
    width: 100%;
    margin: 40px auto;
`;

export const Form = styled.form`
    padding: 32px;
    background: ${props => props.theme.colors.whiteSmoke};
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;

    @media ${props => props.theme.device.md} {
        padding: 24px 16px 16px;
    }
`;
