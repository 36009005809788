import React from 'react';
import { SearchInputWrapper, StyledCatalogSearch } from './styles';
import { SearchInput } from '../Forms/Input/styles';
import { DefaultButton } from '../Button/styles';
import SearchIcon from '../../images/SearchIcon.svg';

const CatalogSearch = () => {
  const [search, setSearch] = React.useState('');

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  return (
      <StyledCatalogSearch>
          <h3>Search</h3>
          <SearchInputWrapper>
              <DefaultButton><SearchIcon /></DefaultButton>
              <SearchInput
                  type="text"
                  placeholder="Enter token name"
                  value={search}
                  onChange={handleChangeSearch}
              />
          </SearchInputWrapper>
      </StyledCatalogSearch>
  );
};

export default CatalogSearch;
