import { format } from 'date-fns';

export const getPastDay = (daysBefore) => {
  const ms = new Date().getTime() - (daysBefore * 86400000);

  return format(ms, 'MMM, dd');
};

export const faker = (amount) => {
  return Array.from(Array(amount), (_, i) => ({
    value: Math.floor(Math.random() * 1000),
    date: getPastDay(i + 1),
  })).reverse();
};

export const mapToObject = (array) => {
  const obj = {};

  array.forEach((item) => obj[item.date] = item.value);

  return obj;
};
