import React, { useState, useMemo, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import Textarea from '../../components/Forms/Textarea';
import Label from '../../components/Forms/Label';
import FormGroup from '../../components/Forms/FormGroup';
import { DefaultButton, FormButton } from '../../components/Button/styles';
import { FormError, FormGroupClear, FormTextarea } from '../../components/Forms/FormGroup/styles';
import { AuthContext } from '../../context';
import Container from '../../components/Container';
import { Box, Form, Subtitle } from './styles';
import { useModalContext } from '../../context/hooks';

const correctPassword = '123';

const Login = () => {
  const { updateModalState } = useModalContext();
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [passwordError, setPasswordError] = useState('Cannot be empty');

  const showPasswordError = useMemo(() => {
    return passwordError && passwordDirty;
  }, [passwordError, passwordDirty]);

  const toggleOnboardingModal = () => {
    updateModalState('onboardingModal', {
      isOpen: true,
    });
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordError('Cannot be empty');
    } else {
      setPasswordError('');
    }
  };

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case 'password':
        setPasswordDirty(true);
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPasswordDirty(true);

    if (password === '') {
      return setPasswordError('Cannot be empty');
    }
    if (password !== correctPassword) {
      return setPasswordError('Invalid password');
    }
    setIsAuth(true);
  };

  if (isAuth) {
    return <Navigate to={'/launchpad'} />;
  }

  return (
        <Container>
            <Box>
                <h2>Log In your Account</h2>
                <Subtitle>Don’t have an account? <DefaultButton onClick={toggleOnboardingModal}>Create Account</DefaultButton></Subtitle>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="login">Enter your seed phrase</Label>
                        <FormTextarea>
                            <Textarea
                                value={password}
                                onChange={passwordHandler}
                                onBlur={blurHandler}
                                name="password"
                                id="login"
                                type={'password'}
                                placeholder={'Type your Password here to login'}
                                error={showPasswordError}
                                className={'password'}
                            />
                        </FormTextarea>
                        <FormGroupClear>{showPasswordError && <FormError>{passwordError}</FormError>}</FormGroupClear>
                    </FormGroup>
                    <FormButton
                        className={'login'}
                        primary
                        type={'submit'}
                        align={'center'}
                    >
                        Log In
                    </FormButton>
                </Form>
            </Box>
        </Container>
  );
};

export default Login;
