export const paginationMerge = (key) => {
  return {
    [key]: {
      keyArgs: false,
      merge(existing, incoming) {
        const prevEdges = existing?.edges || [];
        const incomingEdges = incoming?.edges || [];

        return  {
          ...incoming,
          edges: [...prevEdges, ...incomingEdges],
        };
      },
    },

  };
};
