import styled from 'styled-components';

export const StyledFooter = styled.div`
    flex-direction: column;
    border-top: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.lg} {
        border-top: none;
    }
`;

export const FooterInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Copyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 32px 0 26px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 16px;
    border-top: 1px solid ${props => props.theme.colors.mercury};
`;

