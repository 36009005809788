import React from 'react';
import { useNavigate } from 'react-router-dom';
import TokenCard from '../../../../components/Cards/TokenCard';
import Button from '../../../../components/Button';
import { members } from '../../../../utils/MOCK';
import {
  LeftSide, ListBottom, ListCard, ListTop, ListWrapper, MemberCardWrapper, RightSide,
} from '../../../Home/styles';

const membersTokensPopularity = members.slice(5, 10);

const New = () => {
  const navigate = useNavigate();

  const navigateToCatalogFilterPage = () => {
    navigate('/catalog-filter-page');
  };

  return (
      <ListWrapper className={'catalog'}>
          <ListTop className={'catalog'}>
              <LeftSide>New</LeftSide>
              <RightSide>
                  <Button outlined className={'topSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
              </RightSide>
          </ListTop>
          <ListBottom>
              <ListCard>
                  {membersTokensPopularity.map(item => (
                      <MemberCardWrapper key={item.id} className={'catalogWrapper'}>
                          <TokenCard {...item} />
                      </MemberCardWrapper>
                  ))}
              </ListCard>
              <Button outlined className={'bottomSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
          </ListBottom>
      </ListWrapper>
  );
};

export default New;
