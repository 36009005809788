import styled from 'styled-components';

export const FormColumnBlock = styled.div`
    display: flex;
    justify-content: space-between;
    
    @media ${props => props.theme.device.md} {
        justify-content: inherit;
        flex-direction: column;
    }
`;

export const StyledFormGroup = styled.div`
    display: block;
    clear: both;
    position: relative;
    
    &.warning {
        margin-top: 10px;
    }
    
    &.photoBlock {
        margin-bottom: 0;
    }
    
    &.yourInvest {
        padding: 16px 16px 0;
        background-color: ${props => props.theme.colors.mercury};
        border-radius: 8px;
        
        & label {
            float: left;
        }
    }
    
    &.select {
        margin-bottom: 0;
    }
    
    @media ${props => props.theme.device.lg} {
        &.yourInvest {
            & label {
                line-height: 32px;
            }
        }
    }
`;

export const FormGroupClear = styled.div`
    height: 24px;
    margin-top: 2px;
`;

export const GenerateLink = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 8px;
    
    & button {
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: normal;
        line-height: 24px;
    }
    
    @media ${props => props.theme.device.md} {
        & button {
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
    @media ${props => props.theme.device.xs} {
        & button {
            font-size: ${props => props.theme.fontSize.f10};
        }
    }
`;

export const LabelRight = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    line-height: 16px;
    
    & span {
        margin-left: 2px;
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 500;
        line-height: 16px;
        
        &.down {
            color: ${props => props.theme.colors.coralRed};
        }
        
        &.up {
            color: ${props => props.theme.colors.mediumGreen};
        }
    }
    
    &.influence {
        font-size: ${props => props.theme.fontSize.f14};
        
        & span {
            font-size: ${props => props.theme.fontSize.f14};
        }
    }
    
     &.disabled {
        color: ${props => props.theme.colors.cadetBlue};
        
         & span {
            color: ${props => props.theme.colors.cadetBlue};
        }
    }
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
        align-items: flex-end;
    }
`;

export const MinInvest = styled.div`
    float: right;
    margin-bottom: 8px;
    color: ${props => props.theme.colors.cadetBlue};
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 500;
    line-height: 24px;
    
    @media ${props => props.theme.device.lg} {
        text-align: right;
        max-width: 102px;
        height: 32px;
        line-height: 16px;
    }
`;

export const FormInput = styled.div`
    position: relative;
    clear: both;
    width: 100%;
    height: 56px;
    
    &.tokenModal {
        height: 42px;
    
        & input {
            width: 100%;
            height: 100%;
            padding: 16px 90px 16px 16px;
            font-size: ${props => props.theme.fontSize.f16};
            line-height: 24px;
            border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
            border-radius: 8px;
            -webkit-user-select: initial;
            -khtml-user-select: initial;
            -moz-user-select: initial;
            -ms-user-select: initial;
            user-select: initial;
            
            &::placeholder {
                color: ${props => props.theme.colors.cadetBlue};
            }
            
            &:disabled {
                background: ${props => props.theme.colors.milkWhite};
        
                &::placeholder {
                    color: ${props => props.theme.colors.secondary};
                }
            }
            
            &:focus {
                border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.clearBlue)};
                outline: none;
            }
        }
        
        &.column {
            width: 226px;
            
            & input {
                &::placeholder {
                    color: ${props => props.theme.colors.primary};
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.tokenModal {
            &.column {
                width: 100%;
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        &.tokenModal {
            input {
                padding-right: 80px;
            }
        }
    }
`;

export const CCRBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 0;
    width: 65px;
    height: 52px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    border-left: 2px solid ${props => props.theme.colors.mercury};
`;

export const FormCheckbox = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
`;

export const FormTextarea = styled.div`
    position: relative;
    width: 100%;
    height: 80px;
    
    &.share {
        height: auto;
    }
    
    &.description {
        height: 110px;
    }
    
    @media ${props => props.theme.device.md} {
        height: auto;
    
        &.generatePassword {
            height: auto;
        }
    }
`;

export const FormError = styled.div`
    color: ${props => props.theme.colors.coralRed};
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 1;
    
    &.green {
        color: ${props => props.theme.colors.mediumGreen};
    }
`;

