import React, {
  useCallback, useEffect, useRef, useState, 
} from 'react';
import {
  LeftSide, ListBottom, ListTop, ListWrapper, MemberCardWrapper, RightSide, SelectContainer, SelectContainerTitle,
} from '../../../pages/Home/styles';
import Select from '../../Select';
import Button from '../../Button';
import { CatalogListCard } from '../styles';
import TokenCard from '../../Cards/TokenCard';
import { useGetTopsQuery } from '../../../hooks/quaries';
import { members } from '../../../utils/MOCK';
import TokenCardSkeleton from '../../Cards/TokenCardSkeleton';

const options = ['Amount', 'Creation date', 'Popularity', 'Most liked'];
const member = members[0];

const Catalog = () => {
  const listRef = useRef(null);
  const isLoadingMore = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const { data, fetchMore } = useGetTopsQuery({
    tags: [],
    shift: 0,
  });

  const scrollHandler = useCallback(async () => {
    const { bottom } = listRef.current.getBoundingClientRect();
    const { innerHeight } = window;
    const rangeToEnd = bottom - innerHeight;

    if (rangeToEnd < 200 && !isLoadingMore.current && data?.tops?.pageInfo?.shift) {
      isLoadingMore.current = true;

      await fetchMore({
        variables: {
          tags: [],
          shift: data?.tops?.pageInfo?.shift,
        },
      });

      isLoadingMore.current = false;
    }
  }, [data, fetchMore]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
      <ListWrapper className={'catalog'}>
          <ListTop className={'catalog'}>
              <LeftSide>Tokens of Popularity</LeftSide>
              <RightSide>
                  <SelectContainer>
                      <SelectContainerTitle>Sort by</SelectContainerTitle>
                      <Select results={data?.tops?.pageInfo?.total} options={options} resultBlock />
                  </SelectContainer>
              </RightSide>
          </ListTop>
          <ListBottom ref={listRef}>
              <CatalogListCard >
                  {isLoading ? Array.from({ length: 8 }, (_, i) => (
                          <MemberCardWrapper key={i} className={'catalogListWrapper'}>
                              <TokenCardSkeleton horizontalProps />
                          </MemberCardWrapper>
                  ))
                    : data?.tops?.edges?.map(item => (
                          <MemberCardWrapper key={item.id} className={'catalogListWrapper'}>
                              <TokenCard {...member } horizontalProps />
                          </MemberCardWrapper>
                    ))
                  }
              </CatalogListCard>
              <Button outlined className={'bottomSeeAllButton'}>See all</Button>
          </ListBottom>
      </ListWrapper>
  );
};

export default Catalog;
// updateQuery: (prev, { fetchMoreResult }) => {
//     return {
//         tops:{
//             pageInfo: fetchMoreResult.tops.pageInfo,
//             edges: [...prev.tops.edges, ...fetchMoreResult.tops.edges],
//         },
//     };
// },
