import React from 'react';
import { StyledTooltip } from './styles';
import InfoIcon from '../../images/TooltipIcon.svg';

const Tooltip = ({ tooltip }) => {
  return (
      <StyledTooltip>
          <InfoIcon />
          <span className={'tooltipText'}>{tooltip}</span>
      </StyledTooltip>
  );
};

export default Tooltip;
