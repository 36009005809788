import React, { useState, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { StyledSubMenuItem } from '../styles';

const SubMenuItem = memo(({ title, destination }) => {
  const [liRef, setLiRef] = useState(null);

  const fixPosition = () => {
    if (liRef) {
      liRef.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return (
        <StyledSubMenuItem ref={setLiRef}>
            <NavLink
                className={
                    linkState => {
                      if (linkState?.isActive) {
                        fixPosition();
                      }
                      return `nav-link${linkState?.isActive ? ' active' : ''}`;
                    }
                }
                to={destination}
            >
                {title}
            </NavLink>
        </StyledSubMenuItem>
  );
});

export default SubMenuItem;
