import styled from 'styled-components';

export const ConfirmationWrapper = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;
    padding: 26px;
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;
    
    &.bottom {
        justify-content: inherit;
    }
    
    @media ${props => props.theme.device.lg} {
        justify-content: inherit;
        
        &.bottom {
            flex-direction: column;
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
    }
`;

export const ConfirmationTopItem = styled.div`
    display: flex;
    align-items: center;
    
    &:first-child {
        margin-right: 30px;
        padding-right: 30px;
    }
    
    &:last-child {
        overflow: hidden;
    }
    
    & .title {
        display: flex;
        margin-right: 6px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        text-transform: uppercase;
    }
    
    & .text {
        overflow: hidden;
        display: flex;
        align-items: center;
        
        & span {
            min-width: 70px;
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
        }
        
        & a {
            overflow: hidden;
            margin-left: 6px;
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
            text-decoration: underline;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        align-items: stretch;
        
        &:first-child {
            border-right: 1px solid ${props => props.theme.colors.mercury};
        }
        
        & .title {
            margin-right: 0;
        }
    }
    
    @media ${props => props.theme.device.md} {
        &:first-child {
            margin: 0 0 16px;
            padding-right: 0;
            border-right: none;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & .title,
        & .text span,
        & .text a {
            margin-left: 0;
            font-size: ${props => props.theme.fontSize.f14};
            line-height: 16px;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        & .text {
            flex-direction: column;
            align-items: stretch;
        
            & a {
                margin-top: 6px;
            }
        }
    }
`;

export const ConfirmationBottomInner = styled.div`
    display: flex;
    align-items: center;
    
    &:first-child {
        margin-right: 30px;
        padding-right: 30px;
        border-right: 1px solid ${props => props.theme.colors.mercury};
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        justify-content: space-between;
        
        &:first-child {
            margin-right: 0;
            padding: 0 0 16px 0;
            border-right: none;
            border-bottom: 1px solid ${props => props.theme.colors.mercury};
        }
        
        &:last-child {
            padding-top: 16px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        justify-content: inherit;
        align-items: flex-start;
    }
`;

export const ConfirmationBottomItem = styled.div`
    display: flex;
    flex-direction: column;
    
    & .title {
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
    }

    & .text {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 8px;
        font-size: ${props => props.theme.fontSize.f20};
        font-weight: 600;
        line-height: 32px;
        
        & svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
        }
    }
    
    &.center {
        align-items: center;
        margin: 0 16px;
        
        & .title {
            font-size: ${props => props.theme.fontSize.f14};
            font-weight: 400;
        }
        
        & .text {
            & svg {
                margin-right: 0;
                transform: rotate(90deg);
            }
        } 
    }
    
    &.right {
        &.rightMargin {
            margin-right: 20px;
        }
        
        & .text {
            color: ${props => props.theme.colors.secondary};
        }
    }
    
    @media ${props => props.theme.device.lg} {
        & .text {
            margin-top: 6px;
        }
    
        &.right {
            & .text {
                 font-size: ${props => props.theme.fontSize.f16};
                font-weight: 500;
                line-height: 24px;
            }
            
            &.rightMargin {
                margin-right: 0;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        & .text {
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
        }
    
        &:first-child {
            margin-bottom: 16px;
        }
    
        &.center {
            display: none;
        }
    }
`;

export const ConfirmationButtons = styled.div`
    display: flex;
    position: absolute;
    bottom: 26px;
    right: 26px;
    
    & button {
        width: 260px;
        
        &:first-child {
            margin-right: 36px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        position: unset;
        width: 100%;
        margin-top: 26px;
        
        & button {
            margin: 0 auto 0 0;
            
            &:first-child {
                order: 1;
                margin: 16px auto 0 0;
            }
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            width: 100%;
        }
    }
`;
