import styled from 'styled-components';

export const ReferralInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
`;

export const ReferralTop = styled.div`
    display: flex;
    
    h3 {
        margin-bottom: 16px;
    }
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
    }
`;

export const ReferralProgram = styled.div`
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid ${props => props.theme.colors.cadetBlue};
    
    @media ${props => props.theme.device.lg} {
        margin: 0 0 16px 0;
        padding: 0 0 16px 0;
        border-right: none;
        border-bottom: 1px solid ${props => props.theme.colors.cadetBlue};
    }
`;

export const ReferralInformationItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
    
    &:last-child {
        margin-bottom: 0;
    }

    & .title {
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
    }
    
    & .text {
        margin-left: 6px;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        word-break: break-all;
    }
`;

export const Description = styled.div`
    max-width: 858px;
    width: 100%;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    
    @media ${props => props.theme.device.lg} {
        max-width: 100%;
    }
`;

export const ReferralBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 536px;
    margin-top: 16px;
    
    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        margin: 0 auto;
        font-weight: 500;
            
        & svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            fill: ${props => props.theme.colors.white};
        }
        
        &:hover {
            box-shadow: ${props => props.theme.boxShadow.hoverButton};
        }
        
        &:first-child {
            margin-right: 16px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        flex-direction: column;
        
        & button {
            width: 312px;
            margin: 0;
            
            &:hover {
                box-shadow: none;
            }
            
            &:first-child {
                margin: 0 0 16px;
            }
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            width: 100%;
        }
    }
`;
