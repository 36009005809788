import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubMenu from '../../components/SubMenu';
import { PageContainer } from '../../components/Container/styles';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import {
  AssetsTop, AssetsTopLeft, AssetsTopRightEmpty, ProfileInfo, 
} from '../MyAssets/styles';
import accountDefaultImage from '../../images/accountDefaultImage.png';
import EditProfileIcon from '../../images/EditProfileIcon.svg';
import { DefaultButton } from '../../components/Button/styles';
import ButtonShareIcon from '../../images/ButtonShareIcon.svg';
import { useModalContext } from '../../context/hooks';

const navigationBreadcrumbs = [
  { title: 'My Assets',  destination: '/my-assets-empty' },
];

const MyAssetsEmpty = () => {
  const { updateModalState } = useModalContext();
  const navigate = useNavigate();

  const toggleShareModal = () => {
    updateModalState('shareModal', {
      isOpen: true,
    });
  };

  const navigateEditProfile = () => {
    navigate('/edit-profile');
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <SubMenu title='My Assets' />
          <PageContainer direction={'column'}>
              <AssetsTop>
                  <AssetsTopLeft>
                      <ProfileInfo>
                          <div className={'left'}>
                              <img src={accountDefaultImage} alt={''} />
                              <Link to={'#'} className={'link'}>@megaNFTsEller</Link>
                          </div>
                          <DefaultButton className={'shareButton'} onClick={toggleShareModal}><ButtonShareIcon /></DefaultButton>
                      </ProfileInfo>
                      <Button className={'editButton'} onClick={navigateEditProfile}><EditProfileIcon /><span>Edit profile</span></Button>
                  </AssetsTopLeft>
                  <AssetsTopRightEmpty>
                      <div className={'description'}>Your collection is blanc right now start investing, get you first NFT Token</div>
                      <Button primary>Invest</Button>
                  </AssetsTopRightEmpty>
              </AssetsTop>
          </PageContainer>
      </Container>
  );
};

export default MyAssetsEmpty;
