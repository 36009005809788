import React, { useState } from 'react';
import {
  AfterTokenCurrency, StyledTokenProfileCard, TokenButtons, TokenCurrency, TokenFavorites,
  TokenInformation, TokenName, TokenPhoto, TokenVote, TokenVoteDown, TokenVoteInner,
  TokenVoteNumberMinus, TokenVoteNumberPlus, TokenVoteRange, TokenVoteUp,
} from './styles';
import MemberCardPhoto6 from '../../../../images/memberCards/member_card_6.png';
import { TokenButton } from '../../../../components/Button/styles';
import BookmarkBlackIcon from '../../../../images/BookmarkBlackIcon.svg';
import BookmarkBlueFillIcon from '../../../../images/BookmarkBlueFillIcon.svg';
import Tooltip from '../../../../components/Tooltip';
import CollectionValueIcon from '../../../../images/CollectionValueIcon.svg';

const voteDown = '13.20';
const voteUp = '86.80';

const TokenProfileCard = () => {
  const [show, setShow] = useState(true);

  const handleBookmarkClick = () => {
    setShow(prev => !prev);
  };

  return (
      <StyledTokenProfileCard>
          <TokenPhoto><img src={MemberCardPhoto6} alt={''} /></TokenPhoto>
          <TokenInformation>
              <TokenFavorites onClick={handleBookmarkClick}>{show ? <BookmarkBlackIcon /> : <BookmarkBlueFillIcon />}</TokenFavorites>
              <TokenName><h3>Token Cap</h3></TokenName>
              <TokenCurrency><CollectionValueIcon className={'currencyIcon'} />999,000,000 CCR
                  <Tooltip tooltip='Lorem ipsum Votes value: 1000' />
              </TokenCurrency>
              <AfterTokenCurrency>($999,925)</AfterTokenCurrency>
          </TokenInformation>
          <TokenVote>
              <TokenVoteInner>
                  <TokenVoteNumberMinus>{voteDown}%</TokenVoteNumberMinus>
                  <TokenVoteRange>
                      <TokenVoteDown voteDown={voteDown} />
                      <TokenVoteUp voteUp={voteUp} />
                  </TokenVoteRange>
                  <TokenVoteNumberPlus>{voteUp}%</TokenVoteNumberPlus>
              </TokenVoteInner>
          </TokenVote>
          <TokenButtons>
              <TokenButton>Art</TokenButton>
              <TokenButton>Photo</TokenButton>
              <TokenButton>Portrait</TokenButton>
              <TokenButton>Art</TokenButton>
              <TokenButton>Photo</TokenButton>
              <TokenButton>Portrait</TokenButton>
              <TokenButton>Art</TokenButton>
              <TokenButton>Photo</TokenButton>
              <TokenButton>Portrait</TokenButton>
          </TokenButtons>
      </StyledTokenProfileCard>
  );
};

export default TokenProfileCard;
