import {
  ApolloClient, ApolloLink, InMemoryCache, createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { paginationMerge } from '../utils/paginationMerge';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (process.env.NODE_ENV !== 'production') {
    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        console.log('graphQLError: ', graphQLError);
      }
    } else {
      console.log('networkError: ', networkError);
    }
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...paginationMerge('tops'),
          ...paginationMerge('deals'),
          ...paginationMerge('users'),
        },
      },
    },
  }),
});

