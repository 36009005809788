import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DealsInvestments from './components/DealsInvestments';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import {
  InfoWrapper, TokenDescriptionItem, TokenInfoBlock, TokenInfoBlockCenter, TokenInfoBlockLeft, TokenInfoBlockLeftTitle,
  TokenInfoBlockRight, TokenInfoButtons, TokenInfoContent, TokenProfileCardContainer, TokenRank, TokenRecommended,
  TokenScheduleContainer, TokenTitle, TokenTitleInfo, TokenTooltip, TokenWrapper, VoteBlockIcon, VoteWrapper,
} from './styles';
import RankIcon from '../../images/RankIcon.svg';
import TokenProfileCard from './components/TokenProfileCard';
import Schedule from '../../components/Schedule';
import LinkIcon from '../../images/LinkIcon.svg';
import Button from '../../components/Button';
import CatalogFullSizeList from '../../components/CardLists/CatalogFullSizeListCard';
import VoteFillIcon from '../../images/LikeIcon.svg';
import VoteIcon from '../../images/LikeOutlineIcon.svg';
import InfoIcon from '../../images/TooltipIcon.svg';
import { useModalContext } from '../../context/hooks';
import { smartTrim } from '../../utils/smartTrim';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'Tokens',  destination: '/tokens' },
  { title: 'Aaron Rodgers',  destination: '/tokens/token-page' },
];

const TokenPage = () => {
  const { updateModalState } = useModalContext();
  const titleLink = '0xd1540f5555556dA8bfeE';

  const [likeStatus, setLikeStatus] = useState();

  const handleIconClick = (status) => () => {
    setLikeStatus(status);
  };

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: true,
      likeStatus,
    });
  };

  const toggleSellModal = () => {
    updateModalState('sellModal', {
      isOpen: true,
    });
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} buttonBlock />
          <PageContainer>
              <TokenTitle>
                  <TokenRank className={'green'}><RankIcon />#24563</TokenRank>
                  <TokenTitleInfo>
                      <h2>Aaron Rodgers</h2>
                      <span>{smartTrim(titleLink, 16)}</span>
                  </TokenTitleInfo>
              </TokenTitle>
              <TokenWrapper>
                  <TokenProfileCardContainer>
                      <TokenProfileCard />
                  </TokenProfileCardContainer>
                  <TokenScheduleContainer>
                      <Schedule />
                  </TokenScheduleContainer>
              </TokenWrapper>
              <TokenWrapper>
                  <TokenInfoBlock className={'left'}>
                      <TokenInfoBlockLeft>
                          <TokenInfoBlockLeftTitle>
                              <h2>Invest and vote</h2>
                              <TokenTooltip /* className={'empty'} */>
                                  <InfoIcon />
                                  <div className={'tokenTooltipContent'}>
                                      <div className={'tokenTooltipTitle'}>You will pass.</div>
                                      <div className={'tokenTooltipText'}><span>Votes value:</span>1000</div>
                                  </div>
                              </TokenTooltip>
                          </TokenInfoBlockLeftTitle>
                          <TokenInfoContent>
                              <TokenDescriptionItem>
                                  <div className={'title'}>Your Shares:</div>
                                  <div className={'content'}>10.54</div>
                              </TokenDescriptionItem>
                              <TokenDescriptionItem>
                                  <div className={'title'}>Shares value:</div>
                                  <div className={'content income'}>
                                      8.634600 CCR
                                      <span>+ 5,443 CCR</span>
                                  </div>
                              </TokenDescriptionItem>
                              <TokenDescriptionItem>
                                  <div className={'title'}>Staking:</div>
                                  <div className={'content'}>
                                      8.634600 CCR
                                      <span>60% APR</span>
                                  </div>
                              </TokenDescriptionItem>
                          </TokenInfoContent>
                          <TokenInfoButtons /* className={'empty'} */>
                              <Button primary onClick={toggleInvestModal}>Buy Shares</Button>
                              <Button outlined onClick={toggleSellModal}>Sell Shares</Button>
                          </TokenInfoButtons>
                      </TokenInfoBlockLeft>
                      <TokenInfoBlockCenter />
                      <TokenInfoBlockRight>
                          <VoteWrapper>
                              <VoteBlockIcon className={likeStatus === 'down' ? 'down active' : 'down'} onClick={handleIconClick('down')}>
                                  {likeStatus === 'down' ? <VoteFillIcon className={'down'} /> : <VoteIcon /> }
                              </VoteBlockIcon>
                              <VoteBlockIcon className={likeStatus === 'up' ? 'up active' : 'up'} onClick={handleIconClick('up')}>
                                  {likeStatus === 'up' ? <VoteFillIcon className={'up'} /> : <VoteIcon />}</VoteBlockIcon>
                          </VoteWrapper>
                          <InfoWrapper /* className={'empty'} */>
                              <li>Votes:&nbsp;<span>12.435643</span></li>
                              <li>Influence:&nbsp;<span className={'up'}>1.57%</span></li>
                          </InfoWrapper>
                      </TokenInfoBlockRight>
                  </TokenInfoBlock>
                  <TokenInfoBlock className={'description'}>
                      <h2>Description</h2>
                      <TokenInfoContent className={'description'}>
                          You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered
                          that this is not a famous person and refuses to add him. 25.00 CCR that paid for validation is withheld in benefit of the Cript NFT.
                          You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered
                          that this is not a famous person and refuses to add him. 25.00 CCR that paid for validation is withheld in benefit of the Cript NFT.
                          You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered
                          that this is not a famous person and refuses to add him. 25.00 CCR that paid for validation is withheld in benefit of the Cript NFT.
                          You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered
                          that this is not a famous person and refuses to add him. 25.00 CCR that paid for validation is withheld in benefit of the Cript NFT.
                      </TokenInfoContent>
                      <Link className={'tokenInfoLink'} to={'#'}><LinkIcon />wokipedia.org</Link>
                  </TokenInfoBlock>
              </TokenWrapper>
              <DealsInvestments />
              <TokenRecommended>
                  <CatalogFullSizeList catalogTitle='Recommended' />
              </TokenRecommended>
          </PageContainer>
      </Container>
  );
};

export default TokenPage;
