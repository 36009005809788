import React from 'react';
import Button from '../../Button';
import { members } from '../../../utils/MOCK';
import TokenCardTimer from '../../Cards/TokenCardTimer';
import {
  LeftSide, ListBottom, ListTop, ListWrapper, MemberCardWrapper, 
} from '../../../pages/Home/styles';
import { CatalogFullSizeListCard } from '../styles';
import TokenCard from '../../Cards/TokenCard';

const catalogFullSizeList = members.slice(10, 20);

const CatalogFullSizeList = ({ tokenCardTimer, catalogTitle }) => {
  return (
      <ListWrapper className={'catalog'}>
          <ListTop className={'catalog'}>
              <LeftSide>{catalogTitle}</LeftSide>
          </ListTop>
          <ListBottom>
              <CatalogFullSizeListCard>
                  {catalogFullSizeList.map(item => (
                      <MemberCardWrapper key={item.id} className={'catalogListWrapper'}>
                          {tokenCardTimer
                            ? <TokenCardTimer {...item} timerProps />
                            : <TokenCard {...item} horizontalProps />
                          }
                      </MemberCardWrapper>
                  ))}
              </CatalogFullSizeListCard>
              {!tokenCardTimer && <Button outlined className={'bottomSeeAllButton'}>See all</Button>}
          </ListBottom>
      </ListWrapper>
  );
};

export default CatalogFullSizeList;
