import React from 'react';
import { DefaultButton } from '../../../../components/Button/styles';
import SearchIcon from '../../../../images/SearchIcon.svg';
import { SearchInput } from '../../../../components/Forms/Input/styles';
import {
  InvestorsTable, Overlay, SearchResults, SearchResultsTitle, SearchWrapper,
} from '../../styles';
import { investors } from '../../../../utils/MOCK';
import RankIcon from '../../../../images/RankIcon.svg';

const InvestorsSearch = () => {
  const [search, setSearch] = React.useState('');

  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  const overlayClick = (callback) => () => {
    setSearch('');
    callback?.();
  };

  return (
      <>
          <Overlay search={search} onClick={overlayClick()} />
          <SearchWrapper>
              <DefaultButton><SearchIcon /></DefaultButton>
              <SearchInput
                  type="text"
                  placeholder="Enter the name token, investors"
                  value={search}
                  onChange={handleChangeSearch}
              />
              <SearchResults search={search}>
                  <SearchResultsTitle>Search Result</SearchResultsTitle>
                  <InvestorsTable className={'results'}>
                      <tbody>
                      {investors.filter((i) => {
                        return i.investor.name.includes(search);
                      }).map(row => (
                          <tr key={row.id}>
                              <td>
                                  <span className={`position ${row.position === 1 ? 'orange' : row.position === 2 ? 'green' : row.position === 3 ? 'blue' : ''}`}>
                                      <RankIcon/>#{row.position}
                                  </span>
                              </td>
                              <td className={'name'}>
                                  <img src={row.investor?.image} alt={row.investor?.name}/>
                                  {row.investor?.name}
                              </td>
                              <td className={'invested'} data-label='Total invest'>
                                  <div>
                                      {row.invested?.amount} {row.invested?.currency}
                                      <span>({row.invested?.currencyDesc}{row.invested?.amountDesc})</span>
                                  </div>
                              </td>
                              <td data-label='Tokens #'>{row.tokens}<span>Tokens</span></td>
                          </tr>
                      ))}
                      </tbody>
                  </InvestorsTable>
              </SearchResults>
          </SearchWrapper>
      </>
  );
};

export default InvestorsSearch;
