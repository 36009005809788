import styled from 'styled-components';

export const ValidationEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 234px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
`;
