import styled from 'styled-components';

export const TokenTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 26px;
    
    @media ${props => props.theme.device.md} {
        margin-bottom: 16px;
    }
`;

export const TokenTitleInfo = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    h2 {
        margin-right: 8px;
    }

    span {
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
    }
`;

export const TokenRank = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 85px;
    margin-right: 8px;
    padding: 0 8px;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 600;
    line-height: 32px;
    background-color: ${props => props.theme.colors.secondary};
    border-radius: 8px;
    
    &.green {
        background-color: ${props => props.theme.colors.mediumGreen};
    }
    
    &.orange {
        background-color: ${props => props.theme.colors.sunshade};
    }
    
    &.blue {
        background: ${props => props.theme.colors.clearBlue};
    }
    
    & svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
`;

export const TokenWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        justify-content: normal;
        margin-bottom: 0;
    }
`;

export const TokenProfileCardContainer = styled.div`
    width: 680px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-bottom: 26px;
    }
    
     @media ${props => props.theme.device.md} {
        margin-bottom: 16px;
    }
`;

export const TokenScheduleContainer = styled.div`
    width: 494px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-bottom: 26px;
    }
`;

export const TokenInfoBlock = styled.div`
    display: flex;
    justify-content: space-between;
    width: 582px;
    padding: 26px;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    &.description {
        flex-direction: column;
        
        h2 {
            margin-bottom: 16px;
        }
    }
    
    &.left {
        justify-content: flex-start;
    }
    
    a.tokenInfoLink {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
        & svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-bottom: 26px;
        
        &.left {
            justify-content: space-between;
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.left {
            flex-direction: column;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 16px;
    }
`;

export const TokenInfoBlockLeft = styled.div`
    padding-right: 24px;
    
    @media ${props => props.theme.device.lg} {
        width: 60%;
        padding-right: 0;
    }

    @media ${props => props.theme.device.md} {
        width: auto;
        padding-bottom: 16px;
        border-bottom: 1px solid ${props => props.theme.colors.cadetBlue};
    }
`;

export const TokenInfoBlockLeftTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const TokenTooltip = styled.div`
    display: none;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    
    &.empty {
        display: block;
    }
    
    & .tokenTooltipContent {
        visibility: hidden;
        position: absolute;
        top: 25px;
        left: 0;
        width: 190px;
        padding: 16px;
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: normal;
        line-height: 20px;
        background-color: ${props => props.theme.colors.white};
        border-radius: 16px;
        box-shadow: ${props => props.theme.boxShadow.tooltip};
        z-index: 2;
        
        & .tokenTooltipText {
            margin-top: 16px;
            font-size: ${props => props.theme.fontSize.f16};
            
            & span {
                display: block;
                color: ${props => props.theme.colors.secondary};
                font-size: ${props => props.theme.fontSize.f14};
            }
        }
    }
    
    &:hover {
        & .tokenTooltipContent {
            visibility: visible;
        }
    }
    
    
    @media ${props => props.theme.device.lg} {   
        &.empty {
            display: none;
        }
    }
`;

export const TokenInfoBlockCenter = styled.div`
    width: 1px;
    background-color: ${props => props.theme.colors.cadetBlue};
    
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;

export const TokenInfoBlockRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 24px;
    
    @media ${props => props.theme.device.lg} {
        align-items: center;
        width: 30%;
        padding-left: 0;
    }
    
    @media ${props => props.theme.device.md} {
        align-items: inherit;
        width: auto;
        padding-top: 16px;
    }
`;

export const VoteWrapper = styled.div`
    display: flex;
    
    @media ${props => props.theme.device.md} {
        justify-content: space-between;
    }
`;

export const VoteBlockIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 48px;
    background: ${props => props.theme.colors.blueGradient};
    border-radius: 8px;
    cursor: pointer;
    
    & svg {
        width: 19px;
        height: 20px;
    }
    
    &.down {
        margin-right: 16px;
        
        &.active {
            background: ${props => props.theme.colors.coralRed};
        }
        
        & svg {
            stroke: ${props => props.theme.colors.primary};
            transform: rotate(180deg);
            
            &.down {
                fill: ${props => props.theme.colors.white};
                stroke: none;
            }
        }
    }
    
    &.up {
        &.active {
            background: ${props => props.theme.colors.mediumGreen};
        }
    
        & svg {
            stroke: ${props => props.theme.colors.primary};
            
            &.up {
                fill: ${props => props.theme.colors.white};
                stroke: none;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 148px;
    }
`;

export const InfoWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    list-style: none;
    
    &.empty {
        display: none;
    }
    
    & li {
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 16px;
        
        & span {
            font-size: ${props => props.theme.fontSize.f12};
            font-weight: 500;
            line-height: 16px;
            
            &.down {
                color: ${props => props.theme.colors.coralRed};
            }
            
            &.up {
                color: ${props => props.theme.colors.mediumGreen};
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        min-width: 132px;
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: row;
        justify-content: space-between;
        min-width: auto;
    }
`;

export const TokenInfoContent = styled.div`
    display: flex;
    line-height: 20px;
    
    &.description {
        overflow-y: scroll;
        flex-direction: column;
        height: 100px;
        padding-right: 18px;
        
        &::-webkit-scrollbar {
            width: 6px;
            background: ${props => props.theme.colors.whiteSmoke};
        }
    
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 0;
            border-radius: 0;
            background: ${props => props.theme.colors.cadetBlue};
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 0;
    }
    
    @media ${props => props.theme.device.xs} {
        flex-direction: column;
    }
`;

export const TokenDescriptionItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    
    &:last-child {
        margin-right: 0;
    }
    
    & .title {
        margin-bottom: 8px;
        color: ${props => props.theme.colors.secondary};
        font-weight: 500;
        line-height: 16px;
    }
    
    & .content {
        display: flex;
        flex-direction: column;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
        & span {
            font-size: ${props => props.theme.fontSize.f12};
            font-weight: 500;
            line-height: 16px;
        }
    }
    
    & .content.income {    
        & span {
            color: ${props => props.theme.colors.mediumGreen};
        }
    }
    
    & .content.outcome {
        flex-direction: column;
            
        & span {
            color: ${props => props.theme.colors.coralRed};
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 16px;
    }
    
    @media ${props => props.theme.device.xs} {
        margin-right: 0;
    }
`;

export const TokenInfoButtons = styled.div`
    display: flex;
    margin-top: 16px;
    
    & button {
        width: 166px;
        height: 40px;
        font-size: ${props => props.theme.fontSize.f14};
        
        &:first-child {
            margin-right: 16px;
        }
    }
    
    &.empty {
        & button {
            &:first-child {
                width: 275px;
                margin-right: 0;
            }
        
            &:last-child {
                display: none;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 16px;
    }
    
    @media ${props => props.theme.device.xs} {
        flex-direction: column;
        
        & button {
            width: 100%;
        
            &:first-child {
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
        
        &.empty {
            button {
                width: 100%;
            }
        }
    }
`;

export const TokenRecommended = styled.div`
    margin-top: 60px;

    h2 {
        margin-bottom: 36px;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-top: 44px;
        
        h2 {
            margin-bottom: 26px;
        }
    }
`;

