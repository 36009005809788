import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import mainBanner from '../../../../images/MainBanner.inline.svg';
import Button from '../../../../components/Button';
import { Buttons, Description, MainBannerWrapper } from './styles';
import Container from '../../../../components/Container';
import MainSlider from '../MainSlider';
import { useModalContext } from '../../../../context/hooks';

const MainBanner = () => {
  const { updateModalState } = useModalContext();
  const navigate = useNavigate();

  const toggleOnboardingModal = () => {
    updateModalState('onboardingModal', {
      isOpen: true,
    });
  };

  const navigateWhitePaper = useCallback(() => {
    navigate('/white-paper');
  }, [navigate]);

  return (
      <Container direction={'column'}>
          <MainBannerWrapper background={mainBanner}>
              <Description>
                  <h1>Decentralized<br /> Popularity Exchange</h1>
                  Create, buy and sell unique NFT tokens.
                  <Buttons>
                      <Button primary onClick={toggleOnboardingModal}>Create Account</Button>
                      <Button outlined onClick={navigateWhitePaper}>White paper</Button>
                  </Buttons>
              </Description>
              <MainSlider />
          </MainBannerWrapper>
      </Container>
  );
};

export default MainBanner;
