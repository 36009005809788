import styled from 'styled-components';

export const AssetsListTop = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        margin-bottom: 16px;
    }
`;

export const FirstSelect = styled.div`
    width: 260px;
    
    @media ${props => props.theme.device.sm} {
        width: 272px;
        margin-bottom: 16px;
    }
    
    @media ${props => props.theme.device.xs} {
        width: 225px;
    }
`;

export const SecondSelect = styled.div`
    width: 100px;
    margin-left: 26px;
    
    @media ${props => props.theme.device.md} {
        margin-left: 16px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 120px;
        margin-left: 0;
    }
`;
