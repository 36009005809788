import styled from 'styled-components';

export const DropContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    height: 300px;
    margin-top: 16px;
    border: 1px dashed ${props => props.theme.colors.cadetBlue};
    border-radius: 16px;
    
    & button {
        width: 200px;
        height: 40px;
        margin: 0 auto;
        font-size: ${props => props.theme.fontSize.f14};
    }
    
    @media ${props => props.theme.device.md} {
        width: auto;
        height: auto;
        border: none;
        border-radius: 0;
        
        & button {
            width: 310px;
            margin: 0 auto 0 0;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            width: 100%;
            margin: 0 auto;
        }
    }
`;

export const DropDescription = styled.div`
    margin-bottom: 12px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
     
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;
