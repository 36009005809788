import styled from 'styled-components';

export const StyledRecentDeals = styled.div`
    & .more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 40px;
        margin: 26px auto 0;
        font-size: ${props => props.theme.fontSize.f14};
        
        & svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }
`;

export const RecentDealsTable = styled.table`
    width: 100%;
    position: relative;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th, td {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
    }
    
    th {
        color: ${props => props.theme.colors.secondary};
    }
    
    td {
        & a.hashLink {
            font-weight: 500;
            text-decoration: underline;
        }
    
        & span {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-weight: 500;
            
            & svg {
                width: 20px;
                height: 20px;
            }
            
            &.investor {
                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                }
            }
    
            &.dealsOutcome {
                color: ${props => props.theme.colors.sunshade};
            }
        
            &.dealsIncome {
                color: ${props => props.theme.colors.mediumGreen};
            }
            
            & .dealsLink {
                margin-left: 2px;
                font-weight: 500;
            }
            
            &.popularityOutcome {
                min-width: 140px;
                color: ${props => props.theme.colors.coralRed};
                
                & svg {
                    fill: ${props => props.theme.colors.coralRed};
                }
            }
            
            &.popularityIncome {
                min-width: 140px;
                color: ${props => props.theme.colors.mediumGreen};
                
                & svg {
                    fill: ${props => props.theme.colors.mediumGreen};
                    transform: rotate(180deg);
                }
            }
            
            &.popularityPrice {
                 display: block;
                 line-height: 16px;
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        td {
            & span {
                &.investor {
                    img {
                        display: none;
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
        
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
            
             &:last-child {
                margin-bottom: 0;
            }
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            border-radius: 12px;
            
            &.popularity {
                height: 50px;
            }
            
            &:nth-child(even) {
                background: ${props => props.theme.colors.white};
            }
            
            & span {
                &.investor {
                    img {
                        display: block;
                        width: 32px;
                        height: 32px;
                    }
                }
                
                &.popularityOutcome,
                &.popularityIncome {
                    min-width: auto;
                }
                
                &.popularityPrice {
                    text-align: right;
                }
            }
        }
        
        td:before {
            content: attr(data-label);
            flex-grow: 1;
            color: ${props => props.theme.colors.secondary};
            font-weight: 600;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px; 
        }
        
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;
