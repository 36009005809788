import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AssetsTable, AssetsTableSelect, AssetsTableSelectContainer, TableTabletPopup,
} from './styles';
import { DefaultButton } from '../../../../components/Button/styles';
import TableSortArrow from '../../../../images/TableSortArrow.svg';
import Button from '../../../../components/Button';
import TablePopup from '../../../../components/TablePopup';
import tokenImage1 from '../../../../images/tableImages/table_img_1.png';
import priceHistoryImage1 from '../../../../images/assetsSchedules/assets_schedule_1.png';
import tokenImage2 from '../../../../images/tableImages/table_img_2.png';
import priceHistoryImage2 from '../../../../images/assetsSchedules/assets_schedule_2.png';
import Select from '../../../../components/Select';
import { useModalContext } from '../../../../context/hooks';

const options = ['Asset value', 'Shares number', 'Share price'];

const createData = (tokenImage, tokenName, assetValue, shares, sharePrice, priceHistoryImage) => {
  return {
    id : `${tokenName}-${Math.random()}`, tokenImage, tokenName, assetValue, shares, sharePrice, priceHistoryImage,
  };
};

const rows = [
  createData(tokenImage1, 'Token', '999,999,754 CCR', '1', '999,999,754 CCR', priceHistoryImage1),
  createData(tokenImage2, 'Token', '999,999,754 CCR', '23', '999,999,754 CCR', priceHistoryImage2),
  createData(tokenImage1, 'Token', '999,999,754 CCR', '456', '999,999,754 CCR', priceHistoryImage1),
  createData(tokenImage2, 'Token', '999,999,754 CCR', '7891', '999,999,754 CCR', priceHistoryImage2),
];

const AssetsTableBlock = () => {
  const { updateModalState } = useModalContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handlerMobileWith = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handlerMobileWith);

    return () => window.removeEventListener('resize', handlerMobileWith);
  }, []);

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: true,
    });
  };

  const toggleSellModal = () => {
    updateModalState('sellModal', {
      isOpen: true,
    });
  };

  return (
       <>
           <AssetsTableSelectContainer>
               <AssetsTableSelect>
                   <Select options={options} />
               </AssetsTableSelect>
           </AssetsTableSelectContainer>
           <AssetsTable>
               <thead>
               <tr>
                   <th>Token name</th>
                   <th>
                       <DefaultButton className={'sort active'}>
                           Asset value
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
                   <th>
                       <DefaultButton className={'sort'}>
                           Shares
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
                   <th>
                       <DefaultButton className={'sort'}>
                           Share price
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
                   <th>Price history</th>
               </tr>
               </thead>
               <tbody>
               {rows.map(row => (
                   <tr key={row.id}>
                       <td>
                           <span className={'token'}>
                               <img src={row.tokenImage} alt={row.tokenName} />
                               {row.tokenName}
                           </span>
                       </td>
                       <td data-label='Asset value'><span>{row.assetValue}</span></td>
                       <td data-label='Shares'><span>{row.shares}</span></td>
                       <td data-label='Share price'><span>{row.sharePrice}</span></td>
                       <td data-label='Price history' className={'priceHistory'}>
                           <Link to={'#'}>
                               <img src={row.priceHistoryImage} alt={row.tokenName} />
                           </Link>
                       </td>
                       <td className={'buttons'}>
                           <span>
                               <Button primary onClick={toggleInvestModal} className={'modalButton'}>Buy</Button>
                               {isMobile
                                 ? <Button outlined onClick={toggleSellModal} className={'modalButton'}>Sell</Button>
                                 : <Button decline onClick={toggleSellModal} className={'modalButton'}>Sell</Button>
                               }
                               <TableTabletPopup>
                                   <TablePopup />
                               </TableTabletPopup>
                           </span>
                       </td>
                   </tr>
               ))}
               </tbody>
           </AssetsTable>
       </>
  );
};

export default AssetsTableBlock;
