import React from 'react';
import { useDropzone } from 'react-dropzone';
import { DropButtonContainer } from './styles';
import Button from '../Button';

const DropzoneButton = ({
  setFiles, toggleCropModal, toggleEditModal,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
      toggleCropModal();
      toggleEditModal();
    },
  });

  return (
        <>
            <DropButtonContainer {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Button primary type={'button'} onClick={open}>Choose Photo from Gallery</Button>
            </DropButtonContainer>
        </>
  );
};

export default DropzoneButton;
