import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalButtons, ModalDescription, ModalTitle } from '../styles';
import Button from '../../Button';
import Modal from '../index';
import { useModalContext } from '../../../context/hooks';

const CompletedValidatorModal = () => {
  const { modals, updateModalState } = useModalContext();
  const navigate = useNavigate();

  const toggleCompletedValidatorModal = () => {
    updateModalState('completedValidatorModal', {
      isOpen: !modals.completedValidatorModal.isOpen,
    });
  };

  const handleMainPage = () => {
    toggleCompletedValidatorModal();
    navigate('/');
  };

  return (
      <>
          <Modal
              isOpen={modals.completedValidatorModal.isOpen}
              setIsOpen={toggleCompletedValidatorModal}
          >
              <ModalTitle>Completed!</ModalTitle>
              <ModalDescription>
                  Payment confirmed, now you can proceed to validation.
              </ModalDescription>
              <ModalButtons>
                  <Button
                      onClick={toggleCompletedValidatorModal}
                      primary
                  >
                      Validation
                  </Button>
                  <Button
                      outlined
                      onClick={handleMainPage}
                  >
                      Main Page
                  </Button>
              </ModalButtons>
          </Modal>
      </>
  );
};

export default CompletedValidatorModal;
