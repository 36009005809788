import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import Container from '../../components/Container';
import {
  EditTokenTop, FormBottomFields, FormBottomRight, FormDateBlock,
  FormInfoBlock, FormMainFields, FormOptionsBlock, FormPhotoBlock, FormTabs, RightEditToken,
} from './styles';
import DropzoneCreate from '../../components/DropzoneCreate';
import Input from '../../components/Forms/Input';
import {
  FormError, FormGroupClear, FormInput, FormTextarea, 
} from '../../components/Forms/FormGroup/styles';
import Textarea from '../../components/Forms/Textarea';
import Label from '../../components/Forms/Label';
import FormGroup from '../../components/Forms/FormGroup';
import Button from '../../components/Button';
import Radio from './components/Radio';
import CropEditorCreate from './components/CropEditorCreate';
import Navigation from '../../components/Navigation';
import { PageContainer } from '../../components/Container/styles';
import { InputBlockInner, InputBlockInnerLeft, InputBlockInnerRight } from '../../components/Forms/Input/styles';
import accountDefaultImage from '../../images/accountDefaultImage.png';
import FormSelect from './components/FormSelect';
import { useModalContext } from '../../context/hooks';
import SaveProfileIcon from '../../images/SaveProfileIcon.svg';
import { DropzoneCreateWrapper } from '../CreateToken/styles';

const required = value => (value ? undefined : 'Required field');
const options = ['01 Feb 12:00', '02 Feb 12:00', '03 Feb 12:00', '04 Feb 12:00'];

const tabs = [
  { id: 1, title: 'Person' },
  { id: 2, title: 'Event' },
  { id: 3, title: 'Place' },
  { id: 4, title: 'Item' },
  { id: 5, title: 'Idea' },
  { id: 6, title: 'Brand' },
  { id: 7, title: 'Website' },
  { id: 8, title: 'Culture' },
];

const navigationBreadcrumbs = [
  { title: 'Edit Token',  destination: '/edit-token' },
];

const EditToken = () => {
  const { updateModalState } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [canvasInstance, setCanvasInstance] = useState(null);

  const [picture, setPicture] = useState({
    image: files[0]?.preview,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    color: [35, 38, 47, 0.6],
    scale: 1,
    rotate: 0,
    width: 300,
    height: 300,
    border: null,
    borderRadius: 16,
  });

  useEffect(() => {
    setPicture((prev) => {
      return {
        ...prev, image: files[0]?.preview,
      };
    });
  }, [setPicture, files]);

  // eslint-disable-next-line no-promise-executor-return
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve) {
      // We create an image to receive the Data URI
      const img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

        const dataURI = canvas.toDataURL();

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;
    });
  }

  const toggleSentForValidation = () => {
    updateModalState('sentForValidation', {
      isOpen: true,
    });
  };

  const showResults = async (values, form) => {
    setLoading(true);

    await sleep(3000);

    toggleSentForValidation();
    setFiles([]);
    setLoading(false);

    const res = await resizedataURL(canvasInstance.toDataURL(), 200, 200);
    console.log(res);

    await form.restart();
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <Form
                  onSubmit={showResults}
                  initialValues={{
                    dropzone: undefined, radio: 'Person', name: '', url: '', description: '', date: options[0],
                  }}
              >
                  {({
                    values, handleSubmit,
                  }) => {
                    return <form onSubmit={handleSubmit}>
                        <FormMainFields>
                            <FormInfoBlock>
                                <EditTokenTop>
                                    <h2>Edit Token</h2>
                                    <RightEditToken>
                                        <div className={'button-prev'} />
                                        1/7
                                        <div className={'button-next'} />
                                    </RightEditToken>
                                </EditTokenTop>

                                <FormTabs>
                                    {tabs.map(tab => {
                                      return (
                                            <Field key={tab.id} name="radio" type="radio" value={tab.title}
                                                   component={({ input }) => {
                                                     return (
                                                           <Radio
                                                               checked={tab.title === values.radio}
                                                               title={tab.title}
                                                               input={input}
                                                           />
                                                     );
                                                   }}
                                            />
                                      );
                                    })}
                                </FormTabs>
                                <Field name='name' validate={required}>
                                    {({ input, meta }) => (
                                        <FormGroup>
                                            <Label>{values.radio} name *</Label>
                                            <FormInput>
                                                <Input
                                                    {...input}
                                                    placeholder={`${values.radio} name`}
                                                    error={meta.error && meta.touched}
                                                />
                                            </FormInput>
                                            <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                        </FormGroup>
                                    )}
                                </Field>
                                <Field name='url'>
                                    {({ input }) => (
                                        <FormGroup>
                                            <Label>URL</Label>
                                            <FormInput>
                                                <Input
                                                    {...input}
                                                    placeholder='Example: 23h2h23f2j3fh3'
                                                />
                                            </FormInput>
                                            <FormGroupClear />
                                        </FormGroup>
                                    )}
                                </Field>
                                <Field name='description' validate={required}>
                                    {({ input, meta }) => (
                                        <FormGroup>
                                            <Label>Description *</Label>
                                            <FormTextarea className={'description'}>
                                                <Textarea
                                                    {...input}
                                                    placeholder='Description'
                                                    error={meta.error && meta.touched}
                                                />
                                            </FormTextarea>
                                            <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                        </FormGroup>
                                    )}
                                </Field>
                                <FormGroup>
                                    <FormInput>
                                        <InputBlockInner>
                                            <InputBlockInnerLeft>
                                                <img src={accountDefaultImage} alt={''} />
                                                Bryan Hicks
                                            </InputBlockInnerLeft>
                                            <InputBlockInnerRight>
                                                <div className={'invested'}>Invested:</div>
                                                <div className={'currency'}>555 CCR</div>
                                            </InputBlockInnerRight>
                                        </InputBlockInner>
                                    </FormInput>
                                </FormGroup>
                            </FormInfoBlock>
                            <FormOptionsBlock>
                                <FormPhotoBlock className={picture.image ? 'picture' : null}>
                                    <Label>Image *</Label>
                                    <Field name='dropzone' validate={required}>
                                        {({ input, meta }) => (
                                            <FormGroup className={'photoBlock'}>
                                              <DropzoneCreateWrapper className={picture.image ? 'disabled' : null}>
                                                <DropzoneCreate input={input} files={files} setFiles={setFiles} />
                                                {meta.error && meta.touched && <FormGroupClear><FormError>{meta.error}</FormError></FormGroupClear>}
                                              </DropzoneCreateWrapper>
                                              {picture.image
                                                  && <CropEditorCreate setCanvasInstance={setCanvasInstance} picture={picture} setPicture={setPicture} setFiles={setFiles} />
                                              }
                                            </FormGroup>
                                        )}
                                    </Field>
                                </FormPhotoBlock>
                                <FormDateBlock>
                                    <Field name='date' validate={required}>
                                        {({ input, meta }) => (
                                            <FormGroup className={'select'}>
                                                <FormSelect
                                                    {...input}
                                                    options={options}
                                                    error={meta.error && meta.touched}
                                                    selectedOption={values.date}
                                                />
                                                <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                            </FormGroup>
                                        )}
                                    </Field>
                                </FormDateBlock>
                            </FormOptionsBlock>
                        </FormMainFields>
                        <FormBottomFields className={'editPage'}>
                            <Button delete type='submit' className={'decline'}>Decline</Button>
                            <FormBottomRight>
                                <Button
                                    decline
                                    type='submit'
                                    className={'loading edit'}
                                >
                                  Save token
                                  {loading ? <SaveProfileIcon /> : null}
                                </Button>
                                <Button
                                    decline
                                    type='submit'
                                    className={'loading edit'}
                                >
                                  Approve
                                  {loading ? <SaveProfileIcon /> : null}
                                </Button>
                            </FormBottomRight>
                        </FormBottomFields>
                      </form>;
                  }}
              </Form>
          </PageContainer>
      </Container>
  );
};

export default EditToken;
