import React, { useEffect, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import Button from '../Button';
import { DefaultButton } from '../Button/styles';
import CropLeft from '../../images/CropLeft.svg';
import CropRight from '../../images/CropRight.svg';
import { ControlBlock, CropContainer } from './styles';
import { ModalButtons } from '../Modal/styles';

const CropEditor = ({ setAccountImage, imageToCrop, toggleCropModal }) => {
  const [editor, setEditor] = useState(null);
  const [picture, setPicture] = useState({
    image: imageToCrop.preview,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    color: [35, 38, 47, 0.6],
    scale: 2,
    rotate: 0,
    borderRadius: 100,
    preview: null,
    width: 188,
    height: 188,
    border: [126, 56],
  });

  const setWidthAvatar = ((edit) => {
    if (edit) {
      setPicture(prev => {
        const pictureDiameter = edit.canvas.clientWidth * 0.45;

        return (
          {
            ...prev,
            width: pictureDiameter,
            height: pictureDiameter,
            border: [(edit.canvas.clientWidth - pictureDiameter) / 2, (edit.canvas.clientHeight - pictureDiameter) / 2],
          }
        );
      });
    }
  });

  const rotateLeft = (e) => {
    e.preventDefault();
    setPicture({
      ...picture,
      rotate: picture.rotate - 90,
    });
  };

  const rotateRight = (e) => {
    e.preventDefault();
    setPicture({
      ...picture,
      rotate: picture.rotate + 90,
    });
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setPicture({
      ...picture,
      scale,
    });
  };

  const handlePositionChange = (position) => {
    setPicture({
      ...picture,
      position,
    });
  };

  const onClickSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      toggleCropModal();
      setAccountImage(canvasScaled.toDataURL());
    }
  };

  const setEditorRef = (ed) => (setEditor(ed));

  useEffect(() => {
    setWidthAvatar(editor);
  }, [editor]);

  return (
      <CropContainer>
        <div>
          <ReactAvatarEditor
              ref={setEditorRef}
              scale={parseFloat(picture.scale)}
              width={picture.width}
              height={picture.height}
              position={picture.position}
              onPositionChange={handlePositionChange}
              rotate={parseFloat(picture.rotate)}
              border={picture.border}
              color={picture.color}
              borderRadius={picture.width / (100 / picture.borderRadius)}
              image={picture.image}
          />
        </div>
        <ControlBlock>
          <DefaultButton onClick={rotateLeft}><CropLeft /></DefaultButton>
          <input
              name="scale"
              type="range"
              onChange={handleScale}
              min={picture.allowZoomOut ? '0.1' : '1'}
              max="2"
              step="0.01"
              defaultValue="1"
          />
          <DefaultButton onClick={rotateRight}><CropRight /></DefaultButton>
        </ControlBlock>
        <ModalButtons>
          <Button primary className={'cropButton'} onClick={onClickSave}>Confirm</Button>
          <Button outlined className={'cropButton'} onClick={toggleCropModal}>Cancel</Button>
        </ModalButtons>
      </CropContainer>
  );
};

export default CropEditor;
