import styled from 'styled-components';

export const HistoryTable = styled.table`
    width: 100%;
    position: relative;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th, td {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        font-size: ${props => props.theme.fontSize.f14}; 
        font-weight: 500;
    }
    
    th {
        color: ${props => props.theme.colors.secondary};
        
        & button.sort {
            display: flex;
            align-items: center;
            color: ${props => props.theme.colors.secondary};
            font-size: ${props => props.theme.fontSize.f14};
            font-weight: 500;
            line-height: 24px;
                
            & svg {
                width: 24px;
                height: 24px;
                fill: ${props => props.theme.colors.secondary};
            }
        }
        
        & button.sort.active {
            & svg {
                transform: rotate(180deg);
            }
        }
    }
    
    td {
        & span {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-weight: 500;
            
            & svg {
                width: 20px;
                height: 20px;
            }
            
            &.operationType {
                & svg {
                    display: block;
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                }
                
                & .operationTypeText {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-width: 120px;
                    color: ${props => props.theme.colors.secondary};
                }
            }
            
            &.user {
                & img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                }
                
                .userName {
                    max-width: 120px;
                }
            }
            
            &.dealsOutcome {
                color: ${props => props.theme.colors.sunshade};
            }
        
            &.dealsIncome {
                color: ${props => props.theme.colors.mediumGreen};
            }
            
            & .dealsLink {
                font-weight: 500;
            }
            
            &.priceOutcome {
                min-width: 145px;
                color: ${props => props.theme.colors.coralRed};
                
                & svg {
                    fill: ${props => props.theme.colors.coralRed};
                }
            }
            
            &.priceIncome {
                min-width: 145px;
                color: ${props => props.theme.colors.mediumGreen};
                
                & svg {
                    fill: ${props => props.theme.colors.mediumGreen};
                    transform: rotate(180deg);
                }
            }
            
            &.priceCurrency {
                 display: block;
                 font-size: ${props => props.theme.fontSize.f14};
                 font-weight: 500;
                 line-height: 20px;
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        th {
            padding-right: 0;
            
            &:nth-child(2) {
                min-width: 160px;
            }
            
            & button.sort {
                text-align: left;
            }
        }
    
        td {
            & span {
                &.operationType {
                    & svg {
                        display: none;
                    }
                }
                
                &.user {
                    & img {
                        display: none;
                    }
                }
            }
            
            &:last-child {
                padding-right: 0;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
        
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
            
             &:last-child {
                margin-bottom: 0;
            }
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            order: 2;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            border-radius: 12px;
            
            &:nth-child(even) {
                background: ${props => props.theme.colors.white};
            }
            
            &:first-child {
                order: 1;
                background: ${props => props.theme.colors.white};
                z-index: 1;
            }
            
            &:nth-child(2) {
                order: 0;
                margin-bottom: 12px;
                padding: 0;
                background: transparent;
                
                &:before {
                    display: none;
                }
            }
            
            &:last-child {
                height: 50px;
                padding: 0 8px;
            }
            
            & span {
                &.operationType {
                    & svg {
                        display: block;
                        width: 36px;
                        height: 36px;
                    }
                }
                
                &.user {
                    & .userName {
                        max-width: inherit;
                    }
                }
                
                &.priceOutcome,
                &.priceIncome {
                    min-width: auto;
                }
                
                &.priceCurrency {
                    text-align: right;
                }
            }
        }
        
        td:before {
            content: attr(data-label);
            flex-grow: 1;
            color: ${props => props.theme.colors.secondary};
            font-weight: 500;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px; 
        }
        
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12};
            
            &:nth-child(2) {
                margin-bottom: 6px;
            }
            
            &:last-child {
                padding: 0 6px;
            }
        }
    }
`;

