import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './layout';
import Global from './styles/global';
import { baseTheme } from './styles/theme';
import 'react-loading-skeleton/dist/skeleton.css';

const App = () => {
  return (
        <ThemeProvider theme={baseTheme}>
            <Global />
            <Layout />
        </ThemeProvider>
  );
};

export default App;
