import styled from 'styled-components';

export const MainBannerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 540px;
    margin: 40px auto;
    background-image: url('${({ background }) => (background || 'initial')}');
    background-repeat: no-repeat;
    background-size: 100%;
    
    @media ${props => props.theme.device.lg} {
        height: auto;
        background-size: contain;
        background-position: center;
    }
    
    @media ${props => props.theme.device.md} {
        background: none;
    }
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f24};
    line-height: 32px;
    
    h1 {
        margin-bottom: 32px;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 48%;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
        h1 {
            margin-bottom: 16px;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 32px;
    
    & button {
        width: 200px;
        height: 60px;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
    }
    
    & button:last-child {
        margin-left: 26px;
        border: 1px solid ${props => props.theme.colors.white};
        
        &:hover {
            box-shadow: ${p => p.theme.boxShadow.hoverButton};
        }
    }
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        
        & button {
            width: 260px;
            height: 48px;
        }
        
        & button:last-child {
            margin: 16px 0 0 0;
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 16px;
    
        & button {
            width: 343px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            width: 100%;
        }
    }
`;
