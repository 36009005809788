import styled from 'styled-components';

export const FormWrapper = styled.form`
    margin-bottom: 44px;
`;

export const FormMainFields = styled.div`
    display: flex;
    
    @media ${props => props.theme.device.lg} {
        justify-content: space-between;
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
    }
`;

export const FormInfoBlock = styled.div`
    width: 768px;
    margin-bottom: 40px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        margin-bottom: 16px;
    }
`;

export const FormTabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;    
    
    @media ${props => props.theme.device.md} {
        overflow-x: scroll;
        flex-wrap: nowrap;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const RadioLabel = styled.label`
    margin: 0 12px 12px 0;
    padding: 6px 12px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
    }
`;

export const FormPhotoBlock = styled.div`
    width: 332px;
    height: 390px;
    margin-left: 36px;
    padding: 26px 16px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter}; 
    
    &.picture {
        height: 450px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding: 26px 16px 16px 16px;
        
        &.picture {
            height: auto;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        width: 100%;
    }
`;

export const DropzoneCreateWrapper = styled.div`
    &.disabled {
        display: none;
    }
`;

export const FormBottomFields = styled.div`
    width: 768px;
    
    & button.createToken {
        width: 260px;
        margin-top: 24px;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        padding-top: 26px;
        
        & button.createToken {
            width: 344px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button.createToken {
            width: 100%;
        }
    }
`;
