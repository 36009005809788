import styled from 'styled-components';

export const StyledCatalogFilter = styled.div`
    margin-bottom: 16px;
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 0;
        padding: 26px;
    }
`;

export const CatalogFilterTop = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 0;
    }
`;

export const FilterWrapper = styled.div`
    margin-top: 16px;
`;

export const FilterWrapperFavourite = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    
    & button {
        align-self: center;
        width: 24px;
        height: 21px;
        margin-left: auto;
        
        & svg {
            width: 16px;
            height: 21px;
        }
    }
`;

export const FilterTopTabs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FilterClear = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const FilterTabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
`;

export const RadioLabelFilter = styled.label`
    display: flex;
    align-items: center;
    height: 26px;
    margin: 0 12px 12px 0;
    padding: 0 16px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    & span {
        display: flex;
        
        svg {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            fill: ${props => props.theme.colors.secondary};
        }
        
        &.dislike {
            & svg {
                transform: rotate(180deg);
            }
        }   
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
        
        & svg {
            fill: ${props => props.theme.colors.white};
        }
    }
`;
