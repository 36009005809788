import styled from 'styled-components';

export const InvestorsWrapper = styled.div`
    margin-bottom: 44px;
    
    h2 {
        text-align: center;
    }
    
    @media ${props => props.theme.device.md} {
        margin-bottom: 26px;
        
        h2 {
            text-align: left;
        }
    }
`;

export const SearchWrapper = styled.div`
    position: relative;
    width: 768px;
    height: 44px;
    margin: 26px auto;
    
    & button {
        position: absolute;
        top: 0;
        left: 0;
        width: 44px;
        height: 44px;
        z-index: 1;
        
        & svg {
            width: 14px;
            height: 14px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        width: 678px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin: 16px auto 26px;
    }
`;

export const Overlay = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 5;
    transform: ${({ search }) => (search ? 'translateX(0)' : 'translateX(100%)')};
`;

export const SearchResults = styled.div`
    overflow: hidden;
    display: ${({ search }) => (search ? 'block' : 'none')};
    position: absolute;
    width: 768px;
    padding: 16px;
    border-radius: 8px;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.boxShadow.filter};
    z-index: 2;
    
    @media ${props => props.theme.device.lg} {
        width: 678px;
    }
    
    @media ${props => props.theme.device.md} {
         width: 100%;
    }
    
    @media ${props => props.theme.device.xs} {
         padding: 6px;
    }
`;

export const SearchResultsTitle = styled.div`
    margin: 6px 0;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const Tabs = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 26px;
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.md} {
        overflow-x: scroll;
        flex-wrap: nowrap;
        width: auto;
        margin-bottom: 16px;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const RadioLabel = styled.label`
    display: block;
    height: 36px;
    margin: 0 32px 0 0;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
       margin-right: 0;
    }
    
    &.checked {
        position: relative;
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
        
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: ${props => props.theme.colors.clearBlue};
        }
    }
`;

export const TabsResult = styled.div`
    overflow-y: scroll;
    width: 784px;
    height: 532px;
    margin: 0 auto;
    
    &::-webkit-scrollbar {
        width: 6px;
        background: ${props => props.theme.colors.whiteSmoke};
    }
    
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0;
        border-radius: 0;
        background: ${props => props.theme.colors.cadetBlue};
    }
    
    @media ${props => props.theme.device.lg} {
        width: 678px;
    }
    
    @media ${props => props.theme.device.md} {
        overflow-y: auto;
        width: 100%;
        height: auto;
    }
`;

export const InvestorsTable = styled.table`
    width: 768px;
    border-collapse: collapse;
    
    &.results {
        width: 100%;
        
        td {
            height: 56px;
            
            &:last-child {
                & span {
                    color: ${props => props.theme.colors.secondary};
                    margin-left: 6px;
                }
            }
        }
    }
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th {
        text-align: left;
        height: 46px;
        padding-right: 20px;
        color: ${props => props.theme.colors.secondary};
        font-weight: 500;
    }
    
    td {
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
        
        a img {
            display: block;
        }
        
        &.name {
            display: flex;
            align-items: center;
        
            img {
                display: block;
                width: 32px;
                height: 32px;
                margin-right: 12px;
            }
        }
        
        &.invested {
            & div {
                font-weight: 500;
            }
            
            & span {
                margin-left: 20px;
                color: ${props => props.theme.colors.secondary};
                font-weight: 500;
            }
        }
        
        & span.position {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 52px;
            max-width: 72px;
            padding: 0 8px;
            color: ${props => props.theme.colors.white};
            font-size: ${props => props.theme.fontSize.f12}; 
            line-height: 24px;
            background: ${props => props.theme.colors.secondary};
            border-radius: 8px;
            
            & svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
            
            &.orange {
                background: ${props => props.theme.colors.sunshade};
            }
            
            &.green {
                background: ${props => props.theme.colors.mediumGreen};
            }
            
            &.blue {
                background: ${props => props.theme.colors.clearBlue};
            }
        }
        
        &.follow {
            padding: 0;
        
            & button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 119px;
                height: 36px;
                margin-left: auto;
                background: ${props => props.theme.colors.clearBlue};
                border-radius: 8px;
                
                & svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 6px;
                }
                
                & span {
                    color: ${props => props.theme.colors.white};
                    font-size: ${props => props.theme.fontSize.f14}; 
                    font-weight: 500;
                    line-height: 16px;
                }
                
                &:hover {
                    box-shadow: ${props => props.theme.boxShadow.hoverButton};
                }
            }
            
            & button.followed {
                background: ${props => props.theme.colors.blueGradient};
                
                & span {
                    color: ${props => props.theme.colors.clearBlue};
                }
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        width: 662px;
    
        th, td {
            a img {
                width: 120px;
                height: 80px;
            }
            
            &.follow {
                & button {
                    width: 36px;
                    text-indent: -99999px;
                    
                    & svg {
                        margin-right: 0;
                    }
                    
                    & span {
                        display: none;
                    }
                    
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        
        &.results {
            td {
                height: 44px;
                
                &:last-child {
                   font-size: ${props => props.theme.fontSize.f14};
                   
                    & span {
                        display: none;
                    }
                }
            }
        }
    
        thead tr {
            display: none;
        }
    
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
            
            &:last-child {
                 margin-bottom: 0;
            }
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding: 0 16px;
            color: ${props => props.theme.colors.primary};
            font-size: ${props => props.theme.fontSize.f14}; 
            border-radius: 12px;
            
            a img {
                height: 52px;
            }
        
            &:before {
                content: attr(data-label);
                float: left;
                padding-right: 15px;
                color: ${props => props.theme.colors.secondary};
                font-weight: 500;
            }
            
            &:nth-child(odd) {
                background: ${props => props.theme.colors.white};
            }
            
            &.follow {
                height: auto;
                background: transparent;
                
                &:before {
                    display: none;
                }
                
                & button {
                    width: 310px;
                    margin: 0 auto;
                    text-indent: 0;
                    
                    & svg {
                        margin-right: 6px;
                    }
                    
                    & span {
                        display: flex;
                    }
                }
                & button.followed {
                    background: ${props => props.theme.colors.white};
                    
                    & span {
                        color: ${props => props.theme.colors.clearBlue};
                    }
                }
            }
            
            &:nth-child(1) {
                margin-bottom: -44px;
                padding: 0;
                background: transparent;
                z-index: 1;
            }
            
            &.name {
                overflow: inherit;
                justify-content: stretch;
                margin-bottom: 12px;
                padding: 0;
                
                &:before {
                    padding-right: 0;
                }
                
                & img {
                    margin-right: 6px;
                }
            }
            
            &.invested {
                & span {
                    margin-left: 0;
                }
            }
            
            & span.position {
                float: right;
            }
        }
    }
    
    @media ${props => props.theme.device.sm} {
        &.results {
            td {
                 &.invested {
                    display: block;
                    text-align: left;
                    height: auto;
                    padding: 10px 16px;
                    line-height: 20px;
                
                    &:before {
                        display: block;
                        float: none;
                    }
                }
                
                &:last-child {
                    padding: 0;
                }
            }
        }
    
        td {
            &.follow {
                & button {
                    width: 100%;
                }
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        &.results {
            td {
                padding: 0 6px;
                
                &.invested {
                    padding: 6px;
                }
            }
        }
    
        tr {
            padding: 6px;
        }
    
        td {
            padding: 0 6px;
            
            &:before {
                font-size: ${props => props.theme.fontSize.f12}; 
            }
            
            &.name {
                margin-bottom: 6px;
            }
        }
    }
`;
