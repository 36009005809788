import styled from 'styled-components';

export const ValidationSelect = styled.div`
    align-self: self-end;
    width: 160px;
    
    @media ${props => props.theme.device.md} {
        width: 320px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
    }
`;

export const ListValidationCard = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
`;
