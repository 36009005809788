import React from 'react';
import SortArrow from '../../images/SortArrow.svg';
import NewProfileIcon from '../../images/NewProfileIcon.svg';
import {
  Description, Line, Name, Photo, ProfileInner, 
} from './styles';

const ProfileCard = ({
  image, name, isNew, amount, percent, status, date, collectionItem,
}) => {
  return (
      <ProfileInner className={collectionItem ? 'collectionItem' : ''}>
          <Photo className={'photo'}>
              <img src={image} alt={name} />
          </Photo>
          <div>
              {isNew
                ? <Name className={'name new'}><NewProfileIcon />{name}</Name>
                : <Name className={'name'}>{name}</Name>
              }
              <Description className={'description'}>
                  <span className={status === 'income' ? 'income' : 'outcome'}>
                    {amount} {percent}<SortArrow />
                  </span>in {date}
              </Description>
          </div>
          <Line className={'line'} />
      </ProfileInner>
  );
};

export default ProfileCard;
