import React, { useState } from 'react';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { RecentDealsTable, StyledRecentDeals } from './styles';
import RecentDealsIcon from '../../../../images/recent_deals_icon.png';
import Button from '../../../../components/Button';
import SortArrow from '../../../../images/SortArrow.svg';
import RefreshIcon from '../../../../images/RefreshIcon.svg';
import { useGetDealsQuery } from '../../../../hooks/quaries';

const createData = (date, investor, deals, popularity, hash) => {
  return {
    id: `${investor}-${Math.random()}`, date, investor, deals, popularity, hash,
  };
};

const row = createData(
  format(new Date(), 'dd MMM HH:MM'),
  { image: RecentDealsIcon, investorName: 'Bryan Hicks' },
  {
    amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
  },
  {
    amount: '100,000,000 ', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '124,546,234',
  },
  { name: '0xd1...A8bE', link: '#' }
);

const RecentDeals = () => {
  const { id } = useParams();

  const {
    data, fetchMore, networkStatus,
  } = useGetDealsQuery({ token: id, shift: 0 });

  const [showLoadMore, setShowLoadMore] = useState(true);

  const handleLoadMore = async () => {
    const res = await fetchMore({
      variables: {
        shift: data?.deals?.pageInfo?.shift,
        token: id,
      },
    });

    if (res?.data?.deals?.pageInfo?.shift === 0) {
      setShowLoadMore(false);
    }
  };

  return (
        <StyledRecentDeals>
            <RecentDealsTable>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Investor</th>
                    <th>Deals</th>
                    <th>Popularity</th>
                    <th>TX hash</th>
                </tr>
                </thead>
                <tbody>
                {data?.deals?.edges?.map(edge => (
                    <tr key={edge.id}>
                        <td data-label='Date'><span>{row.date}</span></td>
                        <td data-label='Investor'>
                           <span className={'investor'}>
                               <img src={row.investor.image} alt={row.investor.investorName}/>
                               {row.investor.investorName}
                           </span>
                        </td>
                        <td data-label='Deals'>
                           <span className={row.deals.status === 'outcome' ? 'dealsOutcome' : 'dealsIncome'}>
                               {row.deals.amount} {row.deals.currency}
                               <Link to={row.deals.linkDestination} className={'dealsLink'}>{row.deals.linkName}</Link>
                           </span>
                        </td>
                        <td data-label='Popularity' className={'popularity'}>
                            <div>
                               <span
                                   className={row.popularity.status === 'outcome' ? 'popularityOutcome' : 'popularityIncome'}>
                                   {row.popularity.amount} {row.popularity.currency} <SortArrow/>
                               </span>
                                <span className={'popularityPrice'}>
                                   {row.popularity.defaultCurrency}{row.popularity.defaultAmount}
                               </span>
                            </div>
                        </td>
                        <td data-label='TX hash'><Link to={row.hash.link} className={'hashLink'}>{row.hash.name}</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </RecentDealsTable>
            {
                showLoadMore && (
                    <Button
                        disabled={networkStatus === 3}
                        onClick={handleLoadMore}
                        outlined
                        className={classNames('more', { loading: networkStatus === 3 })}
                    >
                        <RefreshIcon/>
                        Load More
                    </Button>
                )
            }
        </StyledRecentDeals>
  );
};

export default RecentDeals;
