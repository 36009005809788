import styled from 'styled-components';

export const StyledValidation = styled.div`
    margin-bottom: 44px;
    
    & button.becomeValidator {
        width: 260px;
        margin-bottom: 36px;
    }
    
    @media ${props => props.theme.device.xs} {
        & button.becomeValidator {
            width: 100%;
        }
    }
`;

export const ValidationDescription = styled.div`
    max-width: 432px;
    width: 100%;
    margin: 26px 0 36px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;

    & span {
        color: ${props => props.theme.colors.primary};
        font-weight: 500;
    }
`;
