import React, { useState } from 'react';
import { OnboardingModalBottom, OnboardingModalRadioLabel, OnboardingModalTabs } from '../styles';
import Modal from '../index';
import Button from '../../Button';
import OnboardingModalItem from './OnboardingModalItem';
import { baseTheme as theme } from '../../../styles/theme';
import OnboardImageFirst from '../../../images/icons/OnboardImageFirst';
import OnboardImageSecond from '../../../images/icons/OnboardImageSecond';
import OnboardImageThird from '../../../images/icons/OnboardImageThird';
import OnboardImageFourth from '../../../images/icons/OnboardImageFourth';
import { useModalContext } from '../../../context/hooks';

const tabs = {
  1: {
    id: 1,
    backgroundColor: theme.colors.hawkesBlue,
    buttonColor: theme.colors.pastelBlue,
    circleColor: theme.colors.jordyBlue,
    image: OnboardImageFirst,
    title: <><strong>Buy CCR</strong><br />
      and <strong>earn</strong> on their <strong>growth</strong></>,
    text: 'In the field above you will get your “12 word Password” in specific order.\n'
          + 'This information is private and must be kept secure.\n'
          + 'This is the only way to access your wallet.',
    buttonTitle: 'Next',
  },
  2: {
    id: 2,
    backgroundColor: theme.colors.cosmos,
    buttonColor: theme.colors.sweetPink,
    circleColor: theme.colors.geraldine,
    image: OnboardImageSecond,
    title: <><strong>Invest</strong> in popularity <strong>tokens</strong> and <strong>participate</strong> in staking</>,
    text: 'In the field above you will get your “12 word Password” in specific order.\n'
          + 'This information is private and must be kept secure.\n'
          + 'This is the only way to access your wallet.',
    buttonTitle: 'Next',
  },
  3: {
    id: 3,
    backgroundColor: theme.colors.antiqueWhite,
    buttonColor: theme.colors.chardonnay,
    circleColor: theme.colors.sand,
    image: OnboardImageThird,
    title: <><strong>Express</strong> your <strong>attitude</strong> to the objects of <strong>popularity (thumbs up / thumbs down)</strong> and <strong>influence the rating</strong></>,
    text: 'In the field above you will get your “12 word Password” in specific order.\n'
          + 'This information is private and must be kept secure.\n'
          + 'This is the only way to access your wallet.',
    buttonTitle: 'Next',
  },
  4: {
    id: 4,
    backgroundColor: theme.colors.edgeWater,
    buttonColor: theme.colors.vistaBlue,
    circleColor: theme.colors.hintOfGreen,
    image: OnboardImageFourth,
    title: <><strong>Share links</strong> and get a <strong>bonus</strong> from the <strong>referral program</strong></>,
    text: 'In the field above you will get your “12 word Password” in specific order.\n'
          + 'This information is private and must be kept secure.\n'
          + 'This is the only way to access your wallet.',
    buttonTitle: 'Get Started',
  },
};

const OnboardingModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [selectedTab, setSelectedTab] = useState(tabs[1]);

  const toggleCreateNewWalletModal = () => {
    updateModalState('createNewWalletModal', {
      isOpen: true,
    });
  };

  const toggleOnboardingModal = () => {
    updateModalState('onboardingModal', {
      isOpen: !modals.onboardingModal.isOpen,
    });
  };

  const toggleModalAndReset = () => {
    toggleOnboardingModal();
    setSelectedTab(tabs[1]);
  };

  const handleButtonClick = () => {
    if (selectedTab.id === 4) {
      toggleModalAndReset();
      toggleCreateNewWalletModal();
    } else {
      setSelectedTab(tabs[selectedTab.id + 1]);
    }
  };

  return (
        <>
          <Modal
              isOpen={modals.onboardingModal.isOpen}
              setIsOpen={toggleModalAndReset}
              modalName={'onboardingModalSmall'}
          >
            <OnboardingModalItem
                toggleCreateNewWalletModal={toggleCreateNewWalletModal}
                toggleModalAndReset={toggleModalAndReset}
                image={tabs[selectedTab.id].image}
                backgroundColor={tabs[selectedTab.id].backgroundColor}
                buttonColor={tabs[selectedTab.id].buttonColor}
                circleColor={tabs[selectedTab.id].circleColor}
                title={tabs[selectedTab.id].title}
                text={tabs[selectedTab.id].text}
            />
            <OnboardingModalBottom>
              <OnboardingModalTabs>
                {Object.values(tabs).map(tab => {
                  return (
                      <OnboardingModalRadioLabel
                          key={tab.id}
                          className={tab.id === selectedTab.id ? 'checked' : ''}
                          onChange={() => {
                            setSelectedTab(tab);
                          }}
                      >
                        <input type='radio' name="tab" />
                      </OnboardingModalRadioLabel>
                  );
                })}
              </OnboardingModalTabs>
              <Button primary onClick={handleButtonClick} clasName={selectedTab.id === 4 ? 'getStarted' : ''}>
                {tabs[selectedTab.id].buttonTitle}
              </Button>
            </OnboardingModalBottom>
          </Modal>
        </>
  );
};

export default OnboardingModal;
