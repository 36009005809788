import React, { useState } from 'react';
import { RadioLabel, StyledDealsInvestments, Tabs } from './styles';
import RecentDeals from '../RecentDeals';
import Investors from '../Investors';

const tokenPageTabs = [
  { id: 1, title: 'Investors' },
  { id: 2, title: 'Recent Deals' },
];

const DealsInvestments = () => {
  const [selectedTab, setSelectedTab] = useState(tokenPageTabs[0]);

  return (
       <StyledDealsInvestments>
           <h2>Deals and Investments</h2>
           <Tabs>
               {tokenPageTabs.map(tab => {
                 return (
                       <RadioLabel
                           key={tab.id}
                           className={tab.id === selectedTab.id ? 'checked' : ''}
                           onChange={() => {
                             setSelectedTab(tab);
                           }}
                       >{tab.title}
                           <input type='radio' name="tab" />
                       </RadioLabel>
                 );
               })}
           </Tabs>
           {{
             1: <Investors />,
             2: <RecentDeals />,
           }[selectedTab.id]}
       </StyledDealsInvestments>
  );
};

export default DealsInvestments;
