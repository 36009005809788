import styled from 'styled-components';

export const CropContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 358px;
    background: ${props => props.theme.colors.white};
    border: none;
    z-index: 1;
    
    canvas {
        border-radius: 16px;
    }
    
    @media ${props => props.theme.device.md} {
        position: inherit;
        margin-bottom: 12px;
    }
    
    @media ${props => props.theme.device.xs} {
        width: 100%;
        
        canvas {
            width: 100% !important;
        }
    }
`;

export const PreviewCardImage = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background-color: ${props => props.theme.colors.mercury};
    background: ${({ background }) => (background || 'initial')} no-repeat center;
    border-radius: 16px;
    
    & .cropDelete {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 24px;
    }
`;

export const ControlBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    & button {
        width: 35px;
        height: 35px;
        margin-right: 5px;
        
        &:last-child {
            margin-right: 0;
            margin-left: 5px;
        }
    }
    
    input {
        width: 100%;
        height: 3px;
    }
    
    @media ${props => props.theme.device.md} {
        max-width: 324px;
        width: 100%;
    }
`;
