import React, { useCallback, useState } from 'react';
import { StyledModals } from './styles';
import Container from '../../components/Container';
import Button from '../../components/Button';
import SuccessCheckIcon from '../../images/SuccessCheckIcon.svg';
import ErrorCheckIcon from '../../images/ErrorCheckIcon.svg';
import PopupMessage from '../../components/PopupMessage';
import { PopupMessageTitle } from '../../components/PopupMessage/styles';

const Modals = () => {
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const toggleSuccessMessage = useCallback(() => setOpenSuccessMessage(prev => !prev), [setOpenSuccessMessage]);
  const toggleErrorMessage = useCallback(() => setOpenErrorMessage(prev => !prev), [setOpenErrorMessage]);

  const [successTimeoutId, setSuccessTimeoutId] = useState();
  const [errorTimeoutId, setErrorTimeoutId] = useState();

  const delaySuccessSubmit = () => {
    toggleSuccessMessage();
    const id = setTimeout(toggleSuccessMessage, 3000);
    setSuccessTimeoutId(id);
  };

  const delayErrorSubmit = () => {
    toggleErrorMessage();
    const id = setTimeout(toggleErrorMessage, 3000);
    setErrorTimeoutId(id);
  };

  return (
       <StyledModals>
           <Container direction={'column'} align={'center'}>
               {/* First Modal */}
               <div className={'modalWindow'}>
                   <div className={'modalTitle'}>First Modal</div>
                   <div className={'modalDescription'}>
                       In order to buy a share of tokens,
                       please login or register
                   </div>
                   <div className={'modalButtons'}>
                       <Button
                           primary
                           onClick={delaySuccessSubmit}
                       >
                           Primary
                       </Button>
                       <Button
                           outlined
                           onClick={delayErrorSubmit}
                       >
                           Cancel
                       </Button>
                   </div>
               </div>

               {/* Second Modal */}
               <div className={'modalWindow'}>
                   <div className={'modalTitle'}>Second Modal</div>
                   <div className={'modalDescription'}>
                       In order to buy a share of tokens,
                       please login or register
                   </div>
                   <div className={'modalButtons'}>
                       <div className={'modalButtonsBlock'}>
                           <Button primary>Primary</Button>
                           <Button outlined>Cancel</Button>
                       </div>
                       <Button disabled>Disabled</Button>
                   </div>
               </div>

               {/* Third Modal */}
               <div className={'modalWindow'}>
                   <div className={'modalTitle'}>NFT Token has been successfully sent for validation!</div>
                   <div className={'modalDescription'}>
                       The token you created has been successfully sent for validation. Expect a notification of the result.
                   </div>
                   <div className={'modalButtons'}>
                       <Button primary>Primary</Button>
                       <Button outlined>Cancel</Button>
                       <Button disabled>Disabled</Button>
                   </div>
               </div>

               {/* Fourth Modal */}
               <div className={'modalWindow'}>
                   <div className={'modalTitle'}>Fourth Modal</div>
                   <div className={'modalDescription'}>
                       In order to buy a share of tokens,
                       please login or register
                   </div>
                   <div className={'modalButtons'}>
                       <Button primary>Primary</Button>
                   </div>
               </div>

               {/* Fifth Modal */}
               <div className={'modalWindow'}>
                   <div className={'modalTitle'}>Fifth Modal</div>
                   <div className={'modalButtons'}>
                       <Button primary>Primary</Button>
                   </div>
               </div>

               {/* Successful action */}
               <PopupMessage
                   isOpen={openSuccessMessage}
                   setIsOpen={setOpenSuccessMessage}
                   modalName={'message'}
                   timeoutId={successTimeoutId}
               >
                   <PopupMessageTitle className={'successMessage'}><SuccessCheckIcon />Successful action</PopupMessageTitle>
               </PopupMessage>

               {/* Error action */}
               <PopupMessage
                   isOpen={openErrorMessage}
                   setIsOpen={setOpenErrorMessage}
                   modalName={'message'}
                   timeoutId={errorTimeoutId}
               >
                   <PopupMessageTitle className={'errorMessage'}><ErrorCheckIcon />Error message</PopupMessageTitle>
               </PopupMessage>
           </Container>
       </StyledModals>
  );
};

export default Modals;
