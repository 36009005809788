import React, { useCallback, useMemo, useState } from 'react';
import Modal from '../index';
import {
  EnterForm, ModalBody, ModalClose, ModalHeader, ModalTitle,
} from '../styles';
import CloseButtonIcon from '../../../images/CloseButtonIcon.svg';
import FormGroup from '../../Forms/FormGroup';
import Label from '../../Forms/Label';
import {
  FormError, FormGroupClear, FormTextarea, GenerateLink, 
} from '../../Forms/FormGroup/styles';
import { CopyButton, DefaultButton, FormButton } from '../../Button/styles';
import {
  PasswordNumber, PasswordString, PasswordWord, TextareaBlock, 
} from '../../Forms/Textarea/styles';
import CopyIcon from '../../../images/CopyIcon.svg';
import Textarea from '../../Forms/Textarea';
import WarningBlock from '../../WarningBlock';
import { useModalContext } from '../../../context/hooks';

const textareaArray = ['region'];

const CreateNewWalletModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [password] = useState(textareaArray.join(' '));
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('Cannot be empty');
  const [loading] = useState(false);

  const showConfirmPasswordError = useMemo(() => {
    return confirmPasswordError && confirmPasswordDirty;
  }, [confirmPasswordError, confirmPasswordDirty]);

  const toggleProfileModal = () => {
    updateModalState('profileModal', {
      isOpen: true,
    });
  };

  const toggleCreateNewWalletModal = () => {
    updateModalState('createNewWalletModal', {
      isOpen: !modals.createNewWalletModal.isOpen,
    });
  };

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    if (!e.target.value) {
      setConfirmPasswordError('Cannot be empty');
    } else {
      setConfirmPasswordError('');
    }
  };

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case 'confirmPassword':
        setConfirmPasswordDirty(true);
        break;
    }
  };

  const toggleModalAndReset = () => {
    toggleCreateNewWalletModal();
    setConfirmPassword('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmPasswordDirty(true);

    if (password !== confirmPassword) {
      return setConfirmPasswordError('Incorrect Password Confirmation');
    }
    toggleModalAndReset();
    toggleProfileModal();
  };

  const copyToClipboard = useCallback((pas) => () => {
    navigator.clipboard.writeText(pas).then();
  }, []);

  return (
      <>
          <Modal
              isOpen={modals.createNewWalletModal.isOpen}
              setIsOpen={toggleModalAndReset}
              modalName={'onboardingLarge'}
          >
              <ModalHeader className={'onboardingLarge'}>
                  <ModalTitle className={'onboardingLarge'}>Create New Wallet</ModalTitle>
                  <ModalClose className={'createNewWalletModal'}>
                      <CloseButtonIcon onClick={toggleModalAndReset} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody className={'onboardingLarge'}>
                  <EnterForm onSubmit={handleSubmit}>
                      <FormGroup>
                          <Label htmlFor="password">Your Password</Label>
                          <GenerateLink>
                              <DefaultButton>Generate New Password</DefaultButton>
                          </GenerateLink>
                          <FormTextarea className={'generatePassword'}>
                              <TextareaBlock
                                  value={password}
                                  name="password"
                                  id="password"
                              >
                                  {password.split(' ').map((item, i) => {
                                    return (
                                          <PasswordString key={item}>
                                              <PasswordNumber>{i + 1}</PasswordNumber>
                                              <PasswordWord>{item}</PasswordWord>
                                          </PasswordString>
                                    );
                                  })}
                              </TextareaBlock>
                              <CopyButton type={'button'} onClick={copyToClipboard(password)}><CopyIcon className={'copyIcon'} /></CopyButton>
                          </FormTextarea>
                          <FormGroupClear />
                      </FormGroup>
                      <FormGroup>
                          <Label htmlFor="confirmPassword">Confirm your Password</Label>
                          <FormTextarea>
                              <Textarea
                                  value={confirmPassword}
                                  onChange={confirmPasswordHandler}
                                  onBlur={blurHandler}
                                  placeholder={'Type your Password here to login'}
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  type={'password'}
                                  error={showConfirmPasswordError}
                              />
                          </FormTextarea>
                          <FormGroupClear>{showConfirmPasswordError && <FormError>{confirmPasswordError}</FormError>}</FormGroupClear>
                      </FormGroup>
                      <FormGroup className={'warning'}>
                          <WarningBlock
                              className={'alert'}
                              image
                              title='You must save your Password'
                              content='In the field above you will get your “12 word Password” in specific order.
                              This information is private and must be kept secure.  This is the only way to access your wallet.
                              If lost, recovery is not possible because we never store your Password on our servers. '
                          />
                      </FormGroup>
                      <FormButton
                          disabled={loading}
                          primary
                          type={'submit'}
                          align={'center'}
                      >
                          Create wallet
                      </FormButton>
                  </EnterForm>
              </ModalBody>
          </Modal>
      </>
  );
};

export default CreateNewWalletModal;
