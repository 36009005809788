import styled from 'styled-components';

export const StyledModals = styled.div`
    .modalWindow {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 520px;
        margin: 20px 0;
        padding: 24px 40px;
        background: ${props => props.theme.colors.white};
        border: 2px solid ${props => props.theme.colors.mercury};
        border-radius: 16px;
        z-index: 3;
    }
    
    .modalTitle {
        font-size: ${props => props.theme.fontSize.f24};
        font-weight: 600;
        line-height: 32px;
    }
    
    .modalDescription {
        margin-top: 16px;
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
    }
    
    .modalButtons {
        width: 100%;
        margin-top: 32px;
        
        & button {
            margin-top: 16px;
            font-weight: 500;
        }
    
        & button:first-child {
            margin-top: 0;
        }
    
        & button[disabled] {
            color: ${props => props.theme.colors.primary};
            background: ${props => props.theme.colors.whiteSmoke};
            border: none;
            cursor: none;
        }
    }
    
    .modalButtonsBlock {
        display: flex;
        
        & button {
            margin-top: 0;
        }
        
        & button:first-child {
            margin-right: 16px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        .modalWindow {
            width: 90%;
            padding: 40px 24px 24px;
        }
    }
`;
