import React, { useReducer } from 'react';

export const ModalContext = React.createContext({
  modals: {

  },
  updateModalState: () => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MODAL_STATE': return {
      ...state,
      [action.payload.modalName]: action.payload.modalData,
    };
    default: return state;
  }
};

const initialValues = {
  investModal: { isOpen: false },
  confirmInvestModal: { isOpen: false },
  sellModal: { isOpen: false },
  confirmSellModal: { isOpen: false },
  buyCCRModal: { isOpen: false },
  confirmationActionModal: { isOpen: false },
  shareModal: { isOpen: false },
  sentForValidation: { isOpen: false },
  almostThereModal: { isOpen: false },
  confirmValidatorModal: { isOpen: false },
  insufficientFundsModal: { isOpen: false },
  completedValidatorModal: { isOpen: false },
  buySharesModal: { isOpen: false },
  confirmCreationModal: { isOpen: false },
  onboardingModal: { isOpen: false },
  createNewWalletModal: { isOpen: false },
  profileModal: { isOpen: false },
  onboardingSuccessModal: { isOpen: false },
  congratulationsModal: { isOpen: false, congratulationsText: '', timeoutId: null },
  congratulationsBuyCCRModal: { isOpen: false },
};

export const ModalProvider = ({ children }) => {
  const [modals, dispatch] = useReducer(reducer, initialValues);

  const updateModalState = (modalName, modalData) => {
    dispatch({ 
      type: 'UPDATE_MODAL_STATE',
      payload: { modalName, modalData },
    });
  };

  return (
      <ModalContext.Provider value={{ modals, updateModalState }}>
          {children}
      </ModalContext.Provider>
  );
};
