import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledHeader, HeaderButtons, HeaderInfo } from './styles';
import Logo from '../../components/Logo';
import Burger from '../../components/Nav/Burger';
import Container from '../../components/Container';
import { DefaultButton } from '../../components/Button/styles';
import HeaderCounts from '../../components/HeaderCounts';
import HeaderSettings from '../../components/HeaderSettings';
import { AuthContext } from '../../context';
import Button from '../../components/Button';
import { useModalContext } from '../../context/hooks';

const Header = () => {
  const { updateModalState } = useModalContext();
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogIn = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const toggleOnboardingModal = () => {
    updateModalState('onboardingModal', {
      isOpen: true,
    });
  };

  if (location.pathname === '/for-frame') {
    return null;
  }
  return (
      <StyledHeader>
          <Container align={'center'} justify={'space-between'}>
              <Logo headerLogo />
              <Burger isAuth={isAuth} />
              <HeaderInfo isAuth={isAuth}>
                  <HeaderCounts />
                  <HeaderSettings />
              </HeaderInfo>
              <HeaderButtons isAuth={isAuth}>
                  <DefaultButton onClick={handleLogIn}>Log In</DefaultButton>
                  <Button primary onClick={toggleOnboardingModal} className={'primary'}>Sign Up</Button>
              </HeaderButtons>
          </Container>
      </StyledHeader >
  );
};

export default Header;
