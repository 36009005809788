import React, { useEffect, useMemo, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import NumberFormat from 'react-number-format';
import {
  EmotionBlock, EmotionBlockTop, EmotionBlockTopInfo, EmotionTopDesc, EmotionTopDescWrapper, ModalBody, ModalButtons,
  ModalForm, ModalFormAddFunds, ModalFormYouHave, ModalHeader, ModalHeaderInfo, ModalHeaderName, ModalHeaderPhoto,
  ModalRangeInner, ModalRangeOutput, ModalRangeThumb, ModalRangeWrapper, ModalTitle, ModalVote, ModalVoteDown, ModalVoteNumber,
  ModalVoteRange, ModalVoteUp, Shareholders, ShareholdersInner, VoteBlock, VoteBlockIcon,
} from '../styles';
import {
  FormError, FormGroupClear, FormInput, LabelRight,
} from '../../Forms/FormGroup/styles';
import Button from '../../Button';
import Modal from '../index';
import { AddMaxButton, DefaultButton } from '../../Button/styles';
import { baseTheme } from '../../../styles/theme';
import Label from '../../Forms/Label';
import VoteIcon from '../../../images/LikeOutlineIcon.svg';
import VoteFillIcon from '../../../images/LikeIcon.svg';
import FormGroup from '../../Forms/FormGroup';
import { InvestLabel } from '../../Forms/Label/styles';
import modalHeaderPhoto from '../../../images/modalHeaderPhoto.png';
import { useModalContext } from '../../../context/hooks';

const STEP = 1;
const MIN = 0;
const BALANCE = 300;

const investmentCoefficient = 0.03;

const likesPercent = 30;
const dislikesPercent = 70;

const InvestModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [investError, setInvestError] = useState('');

  const [range, setRange] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const [likeStatus, setLikeStatus] = useState();

  const { likeStatus: investStatus } = modals.investModal;

  useEffect(() => {
    setLikeStatus(investStatus);
  }, [investStatus]);

  useEffect(() => {
    if (isDirty) {
      if (!range[0]) {
        setInvestError('Cannot be empty');
      } else if (range[0] > BALANCE) {
        setInvestError('Insufficient funds');
      } else {
        setInvestError('');
      }
    }
  }, [isDirty, range]);

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  const toggleConfirmInvestModal = () => {
    updateModalState('confirmInvestModal', {
      isOpen: true,
    });
  };

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: !modals.investModal.isOpen,
    });
  };

  const toggleModalAndReset = () => {
    setRange([]);
    setLikeStatus(undefined);
    setInvestError('');
    setIsDirty(false);
    toggleInvestModal();
  };

  const showInvestError = useMemo(() => {
    return investError;
  }, [investError]);

  const checkRangeValue = (r) => {
    return r[0] ? r : [0];
  };

  const investHandlerInput = (data) => {
    !isDirty && setIsDirty(true);
    setRange([data.floatValue]);
  };

  const investHandlerSetRange = (value) => {
    !isDirty && setIsDirty(true);
    setRange(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleModalAndReset();
    toggleConfirmInvestModal();
  };

  const handleIconClick = (status) => () => {
    setLikeStatus(status);
  };

  const yourInfluence = useMemo(() => {
    return ((range[0] || 0) * investmentCoefficient).toFixed(2);
  }, [range]);

  const [likes, dislikes] = useMemo(() => {
    if (likeStatus === 'down') {
      return [likesPercent - yourInfluence, dislikesPercent + Number(yourInfluence)];
    }
    if (likeStatus === 'up') {
      return [likesPercent + Number(yourInfluence), dislikesPercent - yourInfluence];
    }
    return [likesPercent, dislikesPercent];
  }, [likeStatus, yourInfluence]);

  const [percentLikes, percentDislikes] = useMemo(() => {
    if (likes === 0 && dislikes === 0) {
      return [50, 50];
    }
    const sum = likes + dislikes;
    const pLikes = (likes / sum) * 100;
    const dLikes = (dislikes / sum) * 100;
    return [pLikes, dLikes];
  }, [likes, dislikes]);

  return (
      <>
          <Modal
              isOpen={modals.investModal.isOpen}
              setIsOpen={toggleModalAndReset}
              modalName={'tokenModalLarge'}
          >
              <ModalHeader>
                  <ModalHeaderPhoto>
                      <img src={modalHeaderPhoto} alt={''} />
                  </ModalHeaderPhoto>
                  <ModalHeaderInfo>
                      <ModalHeaderName>Token name</ModalHeaderName>
                      <ModalTitle>Invest & Vote</ModalTitle>
                  </ModalHeaderInfo>
              </ModalHeader>
              <ModalBody>
                  <ModalForm onSubmit={handleSubmit}>
                      <EmotionBlock>
                          <InvestLabel>Vote rating <LabelRight className={'influence'}>Your influence: <span className={likeStatus}>{yourInfluence}%</span></LabelRight></InvestLabel>
                          <ModalVote>
                              <ModalVoteNumber className={'minus'}>{dislikes.toFixed(2)}%</ModalVoteNumber>
                              <ModalVoteRange>
                                  <ModalVoteDown width={percentDislikes} />
                                  <ModalVoteUp width={percentLikes} />
                              </ModalVoteRange>
                              <ModalVoteNumber className={'plus'}>{likes.toFixed(2)}%</ModalVoteNumber>
                          </ModalVote>
                      </EmotionBlock>
                      <EmotionBlock className={'gray'}>
                          <EmotionBlockTop>
                              <EmotionBlockTopInfo>
                                  <Label>Make your choice</Label>
                                  <VoteBlock>
                                      <VoteBlockIcon className={likeStatus === 'down' ? 'down active' : 'down'} onClick={handleIconClick('down')}>
                                          {likeStatus === 'down' ? <VoteFillIcon className={'down'} /> : <VoteIcon /> }
                                      </VoteBlockIcon>
                                      <VoteBlockIcon className={likeStatus === 'up' ? 'up active' : 'up'} onClick={handleIconClick('up')}>
                                          {likeStatus === 'up' ? <VoteFillIcon className={'up'} /> : <VoteIcon />}
                                      </VoteBlockIcon>
                                  </VoteBlock>
                              </EmotionBlockTopInfo>
                              <EmotionBlockTopInfo>
                                  <Label>Votes</Label>
                                  <EmotionTopDescWrapper>
                                      <EmotionTopDesc>You own: <span>12.346544</span></EmotionTopDesc>
                                      <EmotionTopDesc>To be purchased: <span>0</span></EmotionTopDesc>
                                      <EmotionTopDesc>Total: <span>0</span></EmotionTopDesc>
                                  </EmotionTopDescWrapper>
                              </EmotionBlockTopInfo>
                          </EmotionBlockTop>
                          <FormGroup>
                              <InvestLabel className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>
                                  Your invest CCR
                                  <LabelRight className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>You get:
                                      <span>20 shares</span>
                                  </LabelRight>
                              </InvestLabel>
                              <FormInput className={'tokenModal'}>
                                  <NumberFormat
                                      value={range[0]}
                                      onValueChange={investHandlerInput}
                                      allowedDecimalSeparators={[',', '.']}
                                      decimalSeparator="."
                                      isNumericString
                                      name="invest"
                                      id="invest"
                                      placeholder={'min: 0.0001 CCR'}
                                      error={showInvestError}
                                      disabled={likeStatus !== 'down' && likeStatus !== 'up'}
                                  />
                                  <AddMaxButton type={'button'} className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>Max</AddMaxButton>
                              </FormInput>
                              {!investError && <ModalFormYouHave className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>You have:<span>{BALANCE} CCR</span></ModalFormYouHave>}
                              <ModalFormAddFunds className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>
                                  <DefaultButton type={'button'} onClick={toggleBuyCCRModal}>+Add funds</DefaultButton>
                              </ModalFormAddFunds>
                              <FormGroupClear>{showInvestError && <FormError>{investError}</FormError>}</FormGroupClear>
                          </FormGroup>
                          <Range
                              values={checkRangeValue(range)}
                              step={STEP}
                              min={MIN}
                              max={BALANCE}
                              onChange={investHandlerSetRange}
                              disabled={likeStatus !== 'down' && likeStatus !== 'up'}
                              renderTrack={({ props, children }) => (
                                  <ModalRangeWrapper
                                      onMouseDown={props.onMouseDown}
                                      onTouchStart={props.onTouchStart}
                                  >
                                      <ModalRangeInner
                                          ref={props.ref}
                                          style={{
                                            background: getTrackBackground({
                                              values: checkRangeValue(range),
                                              colors: [`${baseTheme.colors.clearBlue}`, likeStatus !== 'down' && likeStatus !== 'up' ? `${baseTheme.colors.mercury}` : `${baseTheme.colors.secondary}`],
                                              min: MIN,
                                              max: BALANCE,
                                            }),
                                          }}
                                      >
                                          {children}
                                      </ModalRangeInner>
                                  </ModalRangeWrapper>
                              )}
                              renderThumb={({ props }) => (
                                  <ModalRangeThumb
                                      className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}
                                      {...props}
                                  >
                                      <ModalRangeOutput
                                          id="output"
                                          className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}
                                      >
                                          {(((range[0] || 0) / BALANCE) * 100).toFixed(0)}%
                                      </ModalRangeOutput>
                                  </ModalRangeThumb>
                              )}
                          />
                          <Shareholders>
                              <ShareholdersInner className={likeStatus !== 'down' && likeStatus !== 'up' ? 'disabled' : ''}>
                                  Shareholders fee (18.3%)
                                  <span>119.6547565 CCR</span>
                              </ShareholdersInner>
                          </Shareholders>
                      </EmotionBlock>
                      <ModalButtons className={'flex'}>
                          <Button
                              outlined
                              type={'button'}
                              onClick={toggleModalAndReset}
                          >
                              Cancel
                          </Button>
                          <Button
                              primary
                              type={'submit'}
                              disabled={likeStatus !== 'down' && likeStatus !== 'up'}
                          >
                              Next
                          </Button>
                      </ModalButtons>
                  </ModalForm>
              </ModalBody>
          </Modal>
      </>
  );
};

export default InvestModal;
