import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AssetsScheduleContainer, AssetsTop, AssetsTopLeft, AssetsTopRight, ListSwitcher, MyAssetsWrapper,
  ProfileInfo, RadioLabel, TableSwitcher, Tabs,
} from './styles';
import Button from '../../components/Button';
import Navigation from '../../components/Navigation';
import Container from '../../components/Container';
import { PageContainer } from '../../components/Container/styles';
import accountDefaultImage from '../../images/accountDefaultImage.png';
import CollectionValueIcon from '../../images/CollectionValueIcon.svg';
import RankIcon from '../../images/RankIcon.svg';
import AssetsList from './components/AssetsList';
import AssetsTableBlock from './components/AssetsTableBlock';
import Schedule from '../../components/Schedule';
import { DefaultButton } from '../../components/Button/styles';
import ButtonShareIcon from '../../images/ButtonShareIcon.svg';
import EditProfileIcon from '../../images/EditProfileIcon.svg';
import { AssetsItem } from '../MyAssets/styles';
import DownloadIcon from '../../images/DownloadIcon.svg';
import UpdateIcon from '../../images/UpdateIcon.svg';
import ReloadIcon from '../../images/ReloadIcon.svg';
import CopyButtonIcon from '../../images/CopyButtonIcon.svg';
import { smartTrim } from '../../utils/smartTrim';
import { useModalContext } from '../../context/hooks';

const myAssetsTabs = [
  { id: 1 },
  { id: 2 },
];

const navigationBreadcrumbs = [
  { title: 'User Profile',  destination: '/user-profile' },
];

const textareaArray = ['0xd1540f111116dA8bfeE'];

const UserProfile = () => {
  const { updateModalState } = useModalContext();
  const [walletAddressLink] = useState(textareaArray.join(' '));
  const [selectedTab, setSelectedTab] = useState(myAssetsTabs[0]);
  const navigate = useNavigate();

  const toggleShareModal = () => {
    updateModalState('shareModal', {
      isOpen: true,
    });
  };

  const navigateEditProfile = () => {
    navigate('/edit-profile');
  };

  const copyToClipboard = useCallback((wal) => () => {
    navigator.clipboard.writeText(wal).then();
  }, []);

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <AssetsTop>
                  <AssetsTopLeft>
                      <ProfileInfo>
                          <div className={'left'}>
                              <img src={accountDefaultImage} alt={''} />
                              <Link to={'#'} className={'link'}>@megaNFTsEllert1gh34</Link>
                          </div>
                          <DefaultButton className={'shareButton'} onClick={toggleShareModal}><ButtonShareIcon /></DefaultButton>
                      </ProfileInfo>
                      <Button className={'editButton'} onClick={navigateEditProfile}><EditProfileIcon /><span>Edit profile</span></Button>
                  </AssetsTopLeft>
                  <AssetsTopRight>
                      <div className={'left'}>
                          <AssetsItem>
                              <div className={'title'}>Wallet balance:</div>
                              <div className={'content'}>
                                  <CollectionValueIcon className={'preContent'} /> 22.000000 CCR ($999.999)
                                  <DefaultButton className={'download'}><DownloadIcon /></DefaultButton>
                                  <DefaultButton className={'update'}><UpdateIcon /></DefaultButton>
                                  <DefaultButton className={'reload'}><ReloadIcon /></DefaultButton>
                              </div>
                          </AssetsItem>
                          <AssetsItem>
                              <div className={'title'}>Wallet address:</div>
                              <div className={'content'}>
                                  <Link to={'#'} onClick={copyToClipboard(walletAddressLink)}>
                                      {smartTrim(walletAddressLink, 16)}
                                      <CopyButtonIcon className={'copy'} />
                                  </Link>
                              </div>
                          </AssetsItem>
                      </div>
                      <div className={'right'}>
                          <AssetsItem>
                              <div className={'title'}>Locked in tokens:</div>
                              <div className={'content'}>
                                  <CollectionValueIcon className={'preContent'} /> 11.000000 CCR ($157.435)
                                  <div className={'rank orange'}><RankIcon />#1</div>
                              </div>
                          </AssetsItem>
                          <AssetsItem>
                              <div className={'title'}>Staking:</div>
                              <div className={'content'}>
                                  <CollectionValueIcon className={'preContent'} /> 0.000231 CCR ($0.35)
                              </div>
                          </AssetsItem>
                      </div>
                  </AssetsTopRight>
              </AssetsTop>
              <AssetsScheduleContainer>
                  <Schedule />
              </AssetsScheduleContainer>
              <MyAssetsWrapper>
                  <Tabs>
                      <RadioLabel
                          onChange={() => {
                            setSelectedTab(myAssetsTabs[0]);
                          }}
                      >
                          <ListSwitcher className={myAssetsTabs[0] === selectedTab ? 'checked' : ''} />
                          <input type='radio' name="tab" />
                      </RadioLabel>
                      <RadioLabel
                          onChange={() => {
                            setSelectedTab(myAssetsTabs[1]);
                          }}
                      >
                          <TableSwitcher className={myAssetsTabs[1] === selectedTab ? 'checked' : ''} />
                          <input type='radio' name="tab" />
                      </RadioLabel>
                  </Tabs>
                  {{
                    1: <AssetsList />,
                    2: <AssetsTableBlock />,
                  }[selectedTab.id]}
              </MyAssetsWrapper>
          </PageContainer>
      </Container>
  );
};

export default UserProfile;
