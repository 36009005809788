import React, { useState, useCallback } from 'react';
import { Overlay, StyledBurger } from './styles';
import MainMenu from '../MainMenu';
import BurgerIcon from '../../../images/BurgerIcon.svg';

const Burger = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(prev => !prev), [setOpen]);

  return (
        <>
            <Overlay open={open} onClick={toggleOpen} />
            <StyledBurger onClick={toggleOpen}>
                <BurgerIcon />
            </StyledBurger>
            <MainMenu open={open} setOpen={setOpen} className={'header'} />
        </>
  );
};

export default Burger;
