import styled from 'styled-components';

export const StyledTokens = styled.div`
    background: ${props => props.theme.colors.whiteSmoke};
`;

export const CatalogWrapper = styled.div`
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    margin-right: -16px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        align-items: inherit;
        width: 100%;
        margin-right: 0;
    }
`;

export const CatalogFilters = styled.div`
    width: 320px;
    margin-right: 32px;
    padding: 26px;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: ${props => props.theme.colors.drop};
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-right: 0;
        padding: 0;
        background: transparent;
    }
`;

export const CatalogFilterBlock = styled.div`
    @media ${props => props.theme.device.lg} {
        overflow-y: scroll;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: ${({ open }) => (open ? '320px' : '0')};
        background: ${props => props.theme.colors.white};
        box-shadow: ${props => props.theme.boxShadow.filter};
        z-index: 3;
        transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    }
    
    @media ${props => props.theme.device.sm} {
        width: ${({ open }) => (open ? '100%' : '0')};
    }
`;

export const FilterCloseButton = styled.div`
    display: none;

    @media ${props => props.theme.device.lg} {
        display: block;
        position: absolute;
        top: 26px;
        right: 26px;
        width: 24px;
        height: 24px;
    }
`;

export const CatalogList = styled.div`
    width: 848px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-top: 32px;
    }
`;

export const OverlayFilter = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
`;
