import React from 'react';
import Button from '../../Button';
import { members } from '../../../utils/MOCK';
import TokenCardTimer from '../../Cards/TokenCardTimer';
import {
  LeftSide, ListBottom, ListTop, ListWrapper, MemberCardWrapper, RightSide,
} from '../../../pages/Home/styles';
import { CatalogFullSizeListCard, StyledRecentlyLaunched } from '../styles';
import TokenCard from '../../Cards/TokenCard';

const recentlyLaunched = members.slice(0, 5);

const RecentlyLaunched = ({ tokenCardTimer }) => {
  return (
      <StyledRecentlyLaunched>
          <ListWrapper className={'catalog'}>
              <ListTop className={'catalog'}>
                  <LeftSide>Recently launched</LeftSide>
                  <RightSide>
                      <Button outlined className={'topSeeAllButton'}>See all</Button>
                  </RightSide>
              </ListTop>
              <ListBottom>
                  <CatalogFullSizeListCard>
                      {recentlyLaunched.map(item => (
                          <MemberCardWrapper key={item.id} className={'catalogListWrapper'}>
                              {tokenCardTimer
                                ? <TokenCardTimer {...item} />
                                : <TokenCard {...item} horizontalProps />
                              }
                          </MemberCardWrapper>
                      ))}
                  </CatalogFullSizeListCard>
                  <Button outlined className={'bottomSeeAllButton'}>See all</Button>
              </ListBottom>
          </ListWrapper>
      </StyledRecentlyLaunched>
  );
};

export default RecentlyLaunched;
