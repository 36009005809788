import styled from 'styled-components';

export const ScheduleContainer = styled.div`
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
`;

export const ScheduleTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    
    @media ${props => props.theme.device.xs} {
        padding: 0 8px;
    }
`;

export const ScheduleTitle = styled.div`
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const ScheduleTimeline = styled.div`
    display: flex;
    align-items: center;
    
    @media ${props => props.theme.device.md} {
        justify-content: center;
    }
`;

export const RadioLabel = styled.label`
    display: block;
    padding: 5px;
    line-height: 16px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        font-weight: 600;
        background-color: ${props => props.theme.colors.clearBlue};
        border-radius: 6px;
    }
    
    @media ${props => props.theme.device.sm} {
        padding: 3px;
    }
`;

export const ScheduleWrapper = styled.div`
    height: 186px;
`;

