import React from 'react';
import { ModalButtons, ModalDescription, ModalTitle } from '../styles';
import Button from '../../Button';
import Modal from '../index';
import { useModalContext } from '../../../context/hooks';

const ConfirmValidatorModal = () => {
  const { modals, updateModalState } = useModalContext();

  const toggleConfirmValidatorModal = () => {
    updateModalState('confirmValidatorModal', {
      isOpen: !modals.confirmValidatorModal.isOpen,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.confirmValidatorModal.isOpen}
              setIsOpen={toggleConfirmValidatorModal}
          >
              <ModalTitle>Confirmation of the action</ModalTitle>
              <ModalDescription>
                  You are going to become a validator of the Popex NFT platform for <span>25.00 CCR ($25.00).</span>
              </ModalDescription>
              <ModalButtons>
                  <Button
                      onClick={toggleConfirmValidatorModal}
                      primary
                  >
                      Confirm
                  </Button>
                  <Button
                      outlined
                      onClick={toggleConfirmValidatorModal}
                  >
                      Cancel
                  </Button>
              </ModalButtons>
          </Modal>
      </>
  );
};

export default ConfirmValidatorModal;
