import React from 'react';
import { AssetsListTop, FirstSelect, SecondSelect } from './styles';
import Select from '../../../../components/Select';
import CatalogFullSizeList from '../../../../components/CardLists/CatalogFullSizeListCard';

const firstOptions = ['Asset value', 'Shares number', 'Share price'];
const secondOptions = ['1h', '6h', '12h', '24h'];

const AssetsList = () => {
  return (
       <>
           <AssetsListTop>
               <FirstSelect>
                   <Select options={firstOptions} />
               </FirstSelect>
               <SecondSelect>
                   <Select options={secondOptions} />
               </SecondSelect>
           </AssetsListTop>
           <CatalogFullSizeList />
       </>
  );
};

export default AssetsList;
