import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTop } from './styles';
import TokenCard from '../../../../components/Cards/TokenCard';
import { members } from '../../../../utils/MOCK';
import Button from '../../../../components/Button';
import {
  LeftSide, ListBottom, ListCard, ListTop, ListWrapper, MemberCardWrapper, RadioLabel, RightSide,
} from '../../styles';
import Container from '../../../../components/Container';

const membersTopFirst = members.slice(10, 15);
const membersTopSecond = members.slice(15, 20);

const titles = [
  { id: 1, title: 'Top' },
  { id: 2, title: 'Great Offer' },
];

const Top = () => {
  const [selectedTab, setSelectedTab] = useState(titles[0]);
  const navigate = useNavigate();

  const navigateToCatalogFilterPage = () => {
    navigate('/catalog-filter-page');
  };

  return (
       <StyledTop>
           <Container direction={'column'}>
               <ListWrapper className={'top'}>
                   <ListTop>
                       <LeftSide>
                           {titles.map(item => {
                             return (
                                   <RadioLabel
                                       key={item.id}
                                       className={item.id === selectedTab.id ? 'checked' : ''}
                                       onChange={() => {
                                         setSelectedTab(item);
                                       }}
                                   >
                                       {item.title}
                                       <input type='radio' name="tab" />
                                   </RadioLabel>
                             );
                           })}
                       </LeftSide>
                       <RightSide>
                           <Button outlined className={'topSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                       </RightSide>
                   </ListTop>

                   <ListBottom>
                       {{
                         1: <ListBottom>
                               <ListCard>
                                   {membersTopFirst.map(item => (
                                       <MemberCardWrapper key={item.id}>
                                           <TokenCard {...item} />
                                       </MemberCardWrapper>
                                   ))}
                               </ListCard>
                           </ListBottom>,
                         2: <ListBottom>
                               <ListCard>
                                   {membersTopSecond.map(item => (
                                       <MemberCardWrapper key={item.id}>
                                           <TokenCard {...item} />
                                       </MemberCardWrapper>
                                   ))}
                               </ListCard>
                           </ListBottom>,
                       }[selectedTab.id]}
                       <Button outlined className={'bottomSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                   </ListBottom>
               </ListWrapper>
           </Container>
       </StyledTop>
  );
};

export default Top;
