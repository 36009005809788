import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px 46px 16px 16px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
    border-radius: 8px;
    resize: none;
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
    
     &:before, &:after {
      -webkit-user-select: initial;
      -khtml-user-select: initial;
      -moz-user-select: initial;
      -ms-user-select: initial;
      user-select: initial;
    }
    
    &.password {
        -webkit-text-security: disc;
    }
    
    &:focus {
        border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.clearBlue)};
        outline: none;
    }
    &::placeholder {
        color: ${props => props.theme.colors.secondary};
    }
`;

export const TextareaBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px 46px 16px 16px;
    background: ${props => props.theme.colors.white};
    border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
    border-radius: 8px;
    word-break: break-all;
    
    &.shareTextarea {
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
    }
`;

export const PasswordString = styled.span`
    margin-right: 5px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
`;

export const PasswordNumber = styled.span`
    margin-right: 5px;
    color: ${props => props.theme.colors.cadetBlue};
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
`;

export const PasswordWord = styled.span`
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
`;

