import React from 'react';
import { Link } from 'react-router-dom';
import { LeftSide, ListTop, ListWrapper } from '../../styles';
import {
  ListServices, ServiceItem, ServiceItemImage, ServiceItemTitle, ServiceItemTop, StyledServices,
} from './styles';
import ServiceItem1 from '../../../../images/services/ServiceItem1.svg';
import ServiceItem2 from '../../../../images/services/ServiceItem2.svg';
import ServiceItem3 from '../../../../images/services/ServiceItem3.svg';
import ServiceItem4 from '../../../../images/services/ServiceItem4.svg';
import ReadMoreArrowIcon from '../../../../images/ReadMoreArrowIcon.svg';
import Container from '../../../../components/Container';

const createData = (image, title, description) => {
  return {
    id : `${title}-${Math.random()}`, image, title, description,
  };
};

const services = [
  createData(<ServiceItem1 />, 'Set up your wallet', 'Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner.'),
  createData(<ServiceItem2 />, 'Set up your wallet', 'Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner.'),
  createData(<ServiceItem3 />, 'Set up your wallet', 'Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner.'),
  createData(<ServiceItem4 />, 'Set up your wallet', 'Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner.'),
];

const Services = () => {
  return (
       <StyledServices>
           <Container direction={'column'}>
               <ListWrapper className={'services'}>
                   <ListTop><LeftSide>Create and sell your NFTs</LeftSide></ListTop>
                   <ListServices>
                       {services.map(item => (
                           <ServiceItem key={item.id}>
                               <ServiceItemTop>
                                   <ServiceItemImage>
                                       {item.image}
                                   </ServiceItemImage>
                                   <ServiceItemTitle>
                                       {item.title}
                                   </ServiceItemTitle>
                               </ServiceItemTop>
                               <div>
                                   {item.description}
                               </div>
                               <Link to={'#'} className={'readMore'}>Read More<ReadMoreArrowIcon /></Link>
                           </ServiceItem>
                       ))}
                   </ListServices>
               </ListWrapper>
           </Container>
       </StyledServices>
  );
};

export default Services;
