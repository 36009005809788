import React from 'react';
import { investors } from '../../../../utils/MOCK';
import { InvestorsTable } from '../../styles';
import Table from '../Table';

const ByAssetValue = () => {
  return (
      <InvestorsTable>
          <thead>
          <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Total invest</th>
              <th>Tokens #</th>
          </tr>
          </thead>
          <tbody>
          {investors.map(row => {
            return (
                  <Table key={row.id} {...row} />
            );
          })}
          </tbody>
      </InvestorsTable>
  );
};

export default ByAssetValue;
