import styled from 'styled-components';

export const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;

export const Main = styled.div`   
    @media ${props => props.theme.device.lg} {
        padding-top: ${({ locationContent }) => (locationContent ? '0' : '80px')};
    }
`;
