import React from 'react';
import { StyledTextarea } from './styles';

const Textarea = (props) => {
  return (
        <StyledTextarea {...props} />
  );
};

export default Textarea;
