import React, { useEffect, useMemo, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import NumberFormat from 'react-number-format';
import {
  EmotionAssetsBlock, EmotionAssetsItem, EmotionAssetsItemInfo, EmotionAssetsLine, EmotionBlock, EmotionBlockTitle,
  ModalBody, ModalButtons, ModalForm, ModalHeader, ModalHeaderInfo, ModalHeaderName, ModalHeaderPhoto,
  ModalRangeInner, ModalRangeOutput, ModalRangeThumb, ModalRangeWrapper, ModalTitle,
} from '../styles';
import {
  FormColumnBlock, FormError, FormGroupClear, FormInput, 
} from '../../Forms/FormGroup/styles';
import Button from '../../Button';
import Modal from '../index';
import { baseTheme } from '../../../styles/theme';
import modalHeaderPhoto from '../../../images/modalHeaderPhoto.png';
import BusinessIcon from '../../../images/BusinessIcon.svg';
import CollectionValueIcon from '../../../images/CollectionValueIcon.svg';
import VoteFillIcon from '../../../images/LikeIcon.svg';
import { AddMaxButton } from '../../Button/styles';
import FormGroup from '../../Forms/FormGroup';
import { SellLabel } from '../../Forms/Label/styles';
import { useModalContext } from '../../../context/hooks';

const STEP = 1;
const MIN = 0;
const BALANCE = 300;

const sharePrice = 12.5;

const SellModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [sellError, setSellError] = useState('');

  const [range, setRange] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty) {
      if (!range[0]) {
        setSellError('Cannot be empty');
      } else if (range[0] > BALANCE) {
        setSellError('Insufficient funds');
      } else {
        setSellError('');
      }
    }
  }, [isDirty, range]);

  const toggleConfirmSellModal = () => {
    updateModalState('confirmSellModal', {
      isOpen: true,
    });
  };

  const toggleSellModal = () => {
    updateModalState('sellModal', {
      isOpen: !modals.sellModal.isOpen,
    });
  };

  const toggleModalAndReset = () => {
    setRange([]);
    setSellError('');
    setIsDirty(false);
    toggleSellModal();
  };

  const showSellError = useMemo(() => {
    return sellError;
  }, [sellError]);

  const checkRangeValue = (r) => {
    return r[0] ? r : [0];
  };

  const sellHandlerFirstInput = (data) => {
    !isDirty && setIsDirty(true);
    setRange([data.floatValue]);
  };

  const sellHandlerSecondInput = (data) => {
    !isDirty && setIsDirty(true);
    setRange([data.floatValue / sharePrice]);
  };

  const sellHandlerSetRange = (value) => {
    !isDirty && setIsDirty(true);
    setRange(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleModalAndReset();
    toggleConfirmSellModal();
  };

  return (
      <>
        <Modal
            isOpen={modals.sellModal.isOpen}
            setIsOpen={toggleModalAndReset}
            modalName={'tokenModalLarge'}
        >
            <ModalHeader>
                <ModalHeaderPhoto>
                    <img src={modalHeaderPhoto} alt={''} />
                </ModalHeaderPhoto>
                <ModalHeaderInfo>
                    <ModalHeaderName>Token name</ModalHeaderName>
                    <ModalTitle>Sell shares</ModalTitle>
                </ModalHeaderInfo>
            </ModalHeader>
            <ModalBody>
                <ModalForm onSubmit={handleSubmit}>
                    <EmotionBlock>
                        <EmotionBlockTitle>Your assets</EmotionBlockTitle>
                        <EmotionAssetsBlock>
                            <EmotionAssetsItem>
                                <BusinessIcon />
                                <EmotionAssetsItemInfo>
                                    <div className={'title'}>Shares:</div>
                                    <div className={'value'}>999.999999</div>
                                </EmotionAssetsItemInfo>
                            </EmotionAssetsItem>
                            <EmotionAssetsLine />
                            <EmotionAssetsItem>
                                <CollectionValueIcon />
                                <EmotionAssetsItemInfo>
                                    <div className={'title'}>Shares value:</div>
                                    <div className={'value'}>999.999999 CCR</div>
                                    <div className={'currency'}>$2.17</div>
                                </EmotionAssetsItemInfo>
                            </EmotionAssetsItem>
                            <EmotionAssetsLine />
                            <EmotionAssetsItem>
                                <VoteFillIcon className={'down'} />
                                <EmotionAssetsItemInfo>
                                    <div className={'title'}>Votes:</div>
                                    <div className={'value'}>120.034563</div>
                                </EmotionAssetsItemInfo>
                            </EmotionAssetsItem>
                        </EmotionAssetsBlock>
                    </EmotionBlock>
                    <EmotionBlock>
                        <EmotionBlockTitle>You sell</EmotionBlockTitle>
                        <FormColumnBlock>
                            <FormGroup>
                                <SellLabel htmlFor="nickname">Shares</SellLabel>
                                <FormInput className={'tokenModal column'}>
                                    <NumberFormat
                                        value={range[0]}
                                        onValueChange={sellHandlerFirstInput}
                                        allowedDecimalSeparators={[',', '.']}
                                        decimalSeparator="."
                                        isNumericString
                                        name="sell"
                                        id="sell"
                                        placeholder={'0'}
                                        error={showSellError}
                                    />
                                    <AddMaxButton type={'button'}>Max</AddMaxButton>
                                </FormInput>
                                <FormGroupClear>{showSellError && <FormError>{sellError}</FormError>}</FormGroupClear>
                            </FormGroup>
                            <FormGroup>
                                <SellLabel htmlFor="nickname">Shares value</SellLabel>
                                <FormInput className={'tokenModal column'}>
                                    <NumberFormat
                                        value={range[0] * sharePrice}
                                        onValueChange={sellHandlerSecondInput}
                                        allowedDecimalSeparators={[',', '.']}
                                        decimalSeparator="."
                                        isNumericString
                                        name="sell1"
                                        id="sell1"
                                        placeholder={'0'}
                                        error={showSellError}
                                    />
                                    <AddMaxButton type={'button'}>Max</AddMaxButton>
                                </FormInput>
                                <FormGroupClear>{showSellError && <FormError>{sellError}</FormError>}</FormGroupClear>
                            </FormGroup>
                        </FormColumnBlock>
                        <Range
                            values={checkRangeValue(range)}
                            step={STEP}
                            min={MIN}
                            max={BALANCE}
                            onChange={sellHandlerSetRange}
                            renderTrack={({ props, children }) => (
                                <ModalRangeWrapper
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                >
                                    <ModalRangeInner
                                        ref={props.ref}
                                        style={{
                                          background: getTrackBackground({
                                            values: checkRangeValue(range),
                                            colors: [`${baseTheme.colors.clearBlue}`, `${baseTheme.colors.mercury}`],
                                            min: MIN,
                                            max: BALANCE,
                                          }),
                                        }}
                                    >
                                        {children}
                                    </ModalRangeInner>
                                </ModalRangeWrapper>
                            )}
                            renderThumb={({ props }) => (
                                <ModalRangeThumb
                                    {...props}
                                >
                                    <ModalRangeOutput id="output">
                                        {(((range[0] || 0) / BALANCE) * 100).toFixed(0)}%
                                    </ModalRangeOutput>
                                </ModalRangeThumb>
                            )}
                        />
                    </EmotionBlock>
                    <EmotionBlock className={'warning'}>
                        Token and the price of 1 share of the token may rise in price. Therefore,
                        the sale price is fixed at the moment when the user clicks on the Sell button
                    </EmotionBlock>
                    <ModalButtons className={'flex'}>
                        <Button
                            outlined
                            type={'button'}
                            onClick={toggleModalAndReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            primary
                            type={'submit'}
                            disabled={!range[0]}
                        >
                            Next
                        </Button>
                    </ModalButtons>
                </ModalForm>
            </ModalBody>
        </Modal>
      </>
  );
};

export default SellModal;
