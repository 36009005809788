import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  RadioLabel, StyledTokensPopularity, TopFilters,
} from './styles';
import TokenCard from '../../../../components/Cards/TokenCard';
import Button from '../../../../components/Button';
import { members } from '../../../../utils/MOCK';
import {
  LeftSide, ListBottom, ListCard, ListTop, ListWrapper, MemberCardWrapper, RightSide,
} from '../../styles';
import Container from '../../../../components/Container';

const membersTokensPopularity = members.slice(5, 10);

const filters = [
  { id: 1, title: 'All' },
  { id: 2, title: 'Actors' },
  { id: 3, title: 'Bloggers' },
  { id: 4, title: 'Rappers' },
  { id: 5, title: 'Businessmens' },
  { id: 6, title: 'Singers' },
  { id: 7, title: 'Football players' },
  { id: 8, title: '...' },
];

const TokensPopularity = () => {
  const [selectedTab, setSelectedTab] = useState(filters[0]);
  const navigate = useNavigate();

  const navigateToCatalogFilterPage = () => {
    navigate('/catalog-filter-page');
  };

  return (
       <StyledTokensPopularity>
           <Container direction={'column'} className={'tokensPopularity'}>
               <ListWrapper className={'tokensPopularity'}>
                   <ListTop>
                       <LeftSide>Tokens of Popularity</LeftSide>
                       <RightSide>
                           <Button outlined className={'topSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                       </RightSide>
                   </ListTop>
                   <TopFilters>
                       {filters.map(tab => {
                         return (
                               <RadioLabel
                                   key={tab.id}
                                   className={tab.id === selectedTab.id ? 'checked' : ''}
                                   onChange={() => {
                                     setSelectedTab(tab);
                                   }}
                               >{tab.title}
                                   <input type='radio' name="tab" />
                               </RadioLabel>
                         );
                       })}
                   </TopFilters>
                   <ListBottom>
                       <ListCard>
                           {membersTokensPopularity.map(item => (
                               <MemberCardWrapper key={item.id}>
                                   <TokenCard {...item} />
                               </MemberCardWrapper>
                           ))}
                       </ListCard>
                       <Button outlined className={'bottomSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                   </ListBottom>
               </ListWrapper>
           </Container>
       </StyledTokensPopularity>
  );
};

export default TokensPopularity;
