import React from 'react';
import { ModalButtons, ModalDescription, ModalTitle } from '../styles';
import Button from '../../Button';
import Modal from '../index';
import { useModalContext } from '../../../context/hooks';

const AlmostThereModal = () => {
  const { modals, updateModalState } = useModalContext();

  const toggleAlmostThereModal = () => {
    updateModalState('almostThereModal', {
      isOpen: !modals.almostThereModal.isOpen,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.almostThereModal.isOpen}
              setIsOpen={toggleAlmostThereModal}
          >
              <ModalTitle>You are almost there!</ModalTitle>
              <ModalDescription>
                  In order to buy this NFT Token, you need to create
                  an account.
              </ModalDescription>
              <ModalButtons>
                  <Button
                      onClick={toggleAlmostThereModal}
                      primary
                  >
                      Create Account</Button>
              </ModalButtons>
          </Modal>
      </>
  );
};

export default AlmostThereModal;
