import styled from 'styled-components';

export const StyledNewTokensLaunches = styled.div`
    position: relative;
    padding-bottom: 80px;
    z-index: 1;
    
    @media ${props => props.theme.device.md} {
        padding-bottom: 44px;
    }
`;
