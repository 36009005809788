import React from 'react';
import {
  StyledSubMenu, SubMenuButton, SubMenuList, SubMenuTitle, 
} from './styles';
import Button from '../Button';
import SubMenuItem from './SubMenuItem';
import { useModalContext } from '../../context/hooks';

const listSubMenu = [
  { title: 'My Assets',  destination: '/my-assets' },
  { title: 'History',  destination: '/history' },
  { title: 'Referrals',  destination: '/referrals' },
  { title: 'Staking',  destination: '/staking' },
  { title: 'Validation',  destination: '/token-validation' },
  { title: 'Create Token',  destination: '/create-token' },
];

const SubMenu = ({ title }) => {
  const { updateModalState } = useModalContext();

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  return (
      <StyledSubMenu>
          <SubMenuTitle>{title}</SubMenuTitle>
          <SubMenuList>
              {listSubMenu.map((item) => {
                return (
                    <SubMenuItem key={item.title} {...item} />
                );
              })}
          </SubMenuList>
          <SubMenuButton>
              <Button primary onClick={toggleBuyCCRModal}>Buy CCR</Button>
          </SubMenuButton>
      </StyledSubMenu>
  );
};

export default SubMenu;
