import styled from 'styled-components';

export const RadioLabel = styled.label`
    margin-right: 14px;
    padding: 6px 12px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
       margin-right: 0;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
    }
`;

export const CatalogListCard = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: unset;
    gap: 16px 16px; 
    justify-items: center;
    
    @media ${props => props.theme.device.sm} {
        display: block;
    }
`;

export const CatalogFullSizeListCard = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: unset;
    gap: 50px 50px; 
    justify-items: center;
    
    @media ${props => props.theme.device.lg} {
        gap: 16px 16px;
    }
    
    @media ${props => props.theme.device.sm} {
        display: block;
    }
`;

export const StyledRecentlyLaunched = styled.div`
    margin-top: 44px;
    
    @media ${props => props.theme.device.lg} {
        margin-top: 26px;
    }
`;
