import styled from 'styled-components';

export const PageWrapper = styled.div`
    display: grid;
    grid-template-areas: 'sidebar-tabs content' 'sidebar-buttons content';
    grid-template-columns: 300px auto;
    width: 100%;
    margin-top: 26px;
    
    h3 {
        display: flex;
        align-items: center;
    }
    
    @media ${props => props.theme.device.lg} {
        display: block;
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 16px;
    
        h3 {
            margin-bottom: 16px;
        }
    }
`;

export const Sidebar = styled.div`
    grid-area: sidebar-tabs;
    width: 260px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-right: 0;
    }
`;

export const SidebarTab = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    
    @media ${props => props.theme.device.lg} {
         overflow-x: scroll;
         flex-wrap: nowrap;
         white-space: nowrap;
         -ms-overflow-style: none;
         scrollbar-width: none;
        
         &::-webkit-scrollbar {
            display: none;
         }
    }
    
    @media ${props => props.theme.device.md} {
         margin-top: 0;
    }
`;

export const RadioLabel = styled.label`
    margin: 0 6px 12px 0;
    padding: 6px 12px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:nth-child(4) {
        margin-right: 0;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
    }
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 0;
        
        &:nth-child(4) {
             margin-right: 6px;
        }
    }
`;

export const SidebarButtons = styled.div`
    grid-area: sidebar-buttons;
    width: 260px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-top: 24px;
    }
`;

export const SidebarButtonsInner = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: row;
        align-items: center;
    }
    
    @media ${props => props.theme.device.md} {
         flex-direction: column;
         margin-top: 0;
    }
`;

export const Content = styled.div`
    grid-area: content;
    width: 100%;
    padding-left: 40px;
    border-left: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.lg} {
        overflow: hidden;
        padding-left: 0;
        border-left: none;
    }
`;

export const FormStep = styled.div`
    padding: 24px;
    background-color: ${props => props.theme.colors.whiteSmoke};
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;
    
    &:last-child {
        margin-top: 32px;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-top: 24px;
        
        &:last-child {
            margin-top: 24px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        padding: 16px;
    }
`;

export const FormBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
    }
`;

export const LeftSide = styled.div`
    align-self: flex-end;
    width: 100%;
    margin-right: 20px;
    
    @media ${props => props.theme.device.md} {
        align-self: inherit;
        margin-right: 0;
    }
    
    @media ${props => props.theme.device.xs} {
        label {
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;

export const RightSide = styled.div`
     width: 87px;
     height: 87px;
     
     & svg {
        width: 87px;
        height: 87px;
     }
     
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;

export const SecondStepInput = styled.div`
    width: 305px;
    margin-top: 24px;
    
    @media ${props => props.theme.device.lg} {
        width: 45%;
        margin-top: 16px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin-top: 0;
    }
    
     @media ${props => props.theme.device.xs} {
        label {
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;

export const CenterIcon = styled.div`
    width: 24px;
    height: 24px;
    margin-top: 24px;
    
    & svg {
        transform: rotate(90deg);
    }
    
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;

export const FormInfo = styled.div`
    margin-right: 24px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: normal;
    line-height: 16px;
     
    & span {
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.primary};
        line-height: 20px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin: 0 0 16px 0;
    }
`;

export const FormButtonBlock = styled.div`
    width: 260px;
    
    @media ${props => props.theme.device.lg} {
        width: 160px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 247px;
        margin-right: auto;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
    }
`;
