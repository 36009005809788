import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import Container from '../../components/Container';
import {
  DropzoneCreateWrapper,
  FormBottomFields, FormInfoBlock, FormMainFields, FormPhotoBlock, FormTabs, FormWrapper,
} from './styles';
import DropzoneCreate from '../../components/DropzoneCreate';
import { CheckboxLabel } from '../../components/Forms/Label/styles';
import Input from '../../components/Forms/Input';
import {
  CCRBlock, FormCheckbox, FormError, FormGroupClear, FormInput, FormTextarea, MinInvest,
} from '../../components/Forms/FormGroup/styles';
import Textarea from '../../components/Forms/Textarea';
import Label from '../../components/Forms/Label';
import FormGroup from '../../components/Forms/FormGroup';
import { CheckboxInput } from '../../components/Forms/Input/styles';
import Button from '../../components/Button';
import Radio from './components/Radio';
import CropEditorCreate from './components/CropEditorCreate';
import Navigation from '../../components/Navigation';
import SubMenu from '../../components/SubMenu';
import { PageContainer } from '../../components/Container/styles';
import { useModalContext } from '../../context/hooks';

const required = value => (value ? undefined : 'Required field');

const tabs = [
  { id: 1, title: 'Person' },
  { id: 2, title: 'Event' },
  { id: 3, title: 'Place' },
  { id: 4, title: 'Item' },
  { id: 5, title: 'Idea' },
  { id: 6, title: 'Brand' },
  { id: 7, title: 'Website' },
  { id: 8, title: 'Culture' },
];

const navigationBreadcrumbs = [
  { title: 'Create Token',  destination: '/create-token' },
];

const CreateToken = () => {
  const { updateModalState } = useModalContext();
  const [files, setFiles] = useState([]);
  const [canvasInstance, setCanvasInstance] = useState(null);

  const [picture, setPicture] = useState({
    image: files[0]?.preview,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    color: [35, 38, 47, 0.6],
    scale: 1,
    rotate: 0,
    width: 300,
    height: 300,
    border: null,
    borderRadius: 16,
  });

  useEffect(() => {
    setPicture((prev) => {
      return {
        ...prev, image: files[0]?.preview,
      };
    });
  }, [setPicture, files]);

  // eslint-disable-next-line no-promise-executor-return
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(async function (resolve) {
      // We create an image to receive the Data URI
      const img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

        const dataURI = canvas.toDataURL();

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;
    });
  }

  const toggleConfirmCreationModal = () => {
    updateModalState('confirmCreationModal', {
      isOpen: true,
    });
  };

  const showResults = async (values, form) => {
    await sleep(500);

    toggleConfirmCreationModal();

    const res = await resizedataURL(canvasInstance.toDataURL(), 200, 200);
    console.log(res);

    setFiles([]);
    await form.restart();
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
            <SubMenu title='Create Token' />
              <Form
                  onSubmit={showResults}
                  initialValues={{
                    dropzone: undefined, radio: 'Person', name: '', url: '', description: '', yourInvest: '', adoptAgreement: false,
                  }}
              >
                  {({
                    handleSubmit, values, submitting, form, valid,
                  }) => {
                    return <FormWrapper onSubmit={event => {
                      const promise = handleSubmit(event);
                      promise.then(() => {
                        form.restart();
                      });
                      return promise;
                    }}>
                          <FormMainFields>
                              <FormInfoBlock>
                                  <FormTabs>
                                      {tabs.map(tab => {
                                        return (
                                              <Field key={tab.id} name="radio" type="radio" value={tab.title}
                                                     component={({ input }) => {
                                                       return (
                                                             <Radio
                                                                 checked={tab.title === values.radio}
                                                                 title={tab.title}
                                                                 input={input}
                                                             />
                                                       );
                                                     }}
                                              />
                                        );
                                      })}
                                  </FormTabs>
                                  <Field name='name' validate={required}>
                                      {({ input, meta }) => (
                                          <FormGroup>
                                              <Label>{values.radio} name *</Label>
                                              <FormInput>
                                                  <Input
                                                      {...input}
                                                      placeholder={`${values.radio} name`}
                                                      error={meta.error && meta.touched}
                                                  />
                                              </FormInput>
                                              <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                          </FormGroup>
                                      )}
                                  </Field>
                                  <Field name='url'>
                                      {({ input }) => (
                                          <FormGroup>
                                              <Label>URL</Label>
                                              <FormInput>
                                                  <Input
                                                      {...input}
                                                      placeholder='Example: 23h2h23f2j3fh3'
                                                  />
                                              </FormInput>
                                              <FormGroupClear />
                                          </FormGroup>
                                      )}
                                  </Field>
                                  <Field name='description' validate={required}>
                                      {({ input, meta }) => (
                                          <FormGroup>
                                              <Label>Description *</Label>
                                              <FormTextarea className={'description'}>
                                                  <Textarea
                                                      {...input}
                                                      placeholder='Description'
                                                      error={meta.error && meta.touched}
                                                  />
                                              </FormTextarea>
                                              <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                          </FormGroup>
                                      )}
                                  </Field>
                                  <Field name='yourInvest' validate={required}>
                                      {({ input, meta }) => (
                                          <FormGroup className={'yourInvest'}>
                                              <Label>Your invest *</Label>
                                              <MinInvest>Min invest: 100 POPX ($10)</MinInvest>
                                              <FormInput>
                                                  <Input
                                                      type="number"
                                                      {...input}
                                                      placeholder='Your invest'
                                                      error={meta.error && meta.touched}
                                                  />
                                                  <CCRBlock>CCR</CCRBlock>
                                              </FormInput>
                                              <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                          </FormGroup>
                                      )}
                                  </Field>
                              </FormInfoBlock>
                              <FormPhotoBlock className={picture.image ? 'picture' : null}>
                                  <Label>Image *</Label>
                                  <Field name='dropzone' validate={required}>
                                      {({ input, meta }) => (
                                          <FormGroup className={'photoBlock'}>
                                            <DropzoneCreateWrapper className={picture.image ? 'disabled' : null}>
                                              <DropzoneCreate input={input} files={files} setFiles={setFiles} />
                                              {meta.error && meta.touched && <FormGroupClear><FormError>{meta.error}</FormError></FormGroupClear>}
                                            </DropzoneCreateWrapper>
                                            {picture.image
                                                && <CropEditorCreate setCanvasInstance={setCanvasInstance} picture={picture} setPicture={setPicture} setFiles={setFiles} />
                                            }
                                          </FormGroup>
                                      )}
                                  </Field>
                              </FormPhotoBlock>
                          </FormMainFields>
                          <FormBottomFields>
                              <Field name='adoptAgreement' type="checkbox" validate={required}>
                                  {({ input, meta }) => (
                                      <FormGroup>
                                          <FormCheckbox>
                                              <CheckboxInput
                                                  id='adoptAgreement'
                                                  {...input}
                                              />
                                              <CheckboxLabel htmlFor="adoptAgreement">
                                                  Adopt the &nbsp;<Link to={'#'}>agreement</Link>
                                              </CheckboxLabel>
                                          </FormCheckbox>
                                          <FormGroupClear>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</FormGroupClear>
                                      </FormGroup>
                                  )}
                              </Field>
                              <Button primary type='submit' className={'createToken'} disabled={submitting || !valid}>Create token</Button>
                          </FormBottomFields>
                      </FormWrapper>;
                  }}
              </Form>
          </PageContainer>
      </Container>
  );
};

export default CreateToken;
