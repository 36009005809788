import styled from 'styled-components';

export const Menu = styled.div`
    overflow-y: auto;
    display: ${({ openProfileMenu }) => (openProfileMenu ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 90px;
    right: 0;
    width: 327px;
    max-height: calc(100vh - 90px);
    padding: 24px 24px 0;
    background-color: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.drop};
    z-index: 6;
    
    @media ${props => props.theme.device.xs} {
        width: 300px;
    }
`;

export const MenuTop = styled.div`
    display: flex;
    align-items: center;
    
    & svg {
        width: 48px;
        height: 48px;
    }
`;

export const MenuDesc = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    
    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 126px;
        height: 32px;
        font-size: ${props => props.theme.fontSize.f14};
        
        &:last-child {
            display: none;
        }
    }
    
    @media ${props => props.theme.device.md} {
        & button {
            &:last-child {
                display: flex;
                font-weight: 600;
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        & button {
            width: 120px;
        }
    }
`;

export const MenuImage = styled.div`
    position: relative;
    width: 48px;
    height: 48px;
    
    & img {
        width: 100%;
        height: 100%;
        border: 2px solid ${props => props.theme.colors.mercury};
        border-radius: 100px;
    }
    
    button {
        position: absolute;
        right: -7px;
        bottom: 0;
        width: 20px;
        height: 20px;
        
        & svg {
            width: 100%;
            height: 100%;
        }
    }
`;

export const MenuDescription = styled.div`
    margin-left: 16px;
`;

export const MenuName = styled.div`
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const List = styled.ul`
    margin: 16px 0 8px;
    list-style: none;
    
    li {
        line-height: 52px;
    
        .nav-link {
            display: flex;
            align-items: center;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            border-top: 1px solid ${props => props.theme.colors.mercury};
        
            & svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                fill: ${props => props.theme.colors.secondary};
            }
        }
    }
    
    li:last-child {
        .nav-link {
            & svg {
                fill: ${props => props.theme.colors.coralRed};
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 0;
    }
`;

export const Overlay = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 5;
    transform: ${({ openProfileMenu }) => (openProfileMenu ? 'translateX(0)' : 'translateX(100%)')};
`;
