import React, { useCallback } from 'react';
import { ShareLabel } from '../../../../Forms/Label/styles';
import { FormInput } from '../../../../Forms/FormGroup/styles';
import { CopyInputButton } from '../../../../Button/styles';
import CopyIcon from '../../../../../images/CopyIcon.svg';
import { InputBlock } from '../../../../Forms/Input/styles';

const InputCopyBlock = ({ link }) => {
  const copyToClipboard = useCallback((l) => () => {
    navigator.clipboard.writeText(l).then();
  }, []);

  return (
        <>
            <ShareLabel classNme={'share'} htmlFor="eth-address">Link</ShareLabel>
            <FormInput>
                <InputBlock
                    name="ethAddress"
                    id="ethAddress"
                    className={'shareInput'}
                >
                    {link}
                </InputBlock>
                <CopyInputButton type={'button'} onClick={copyToClipboard(link)}><CopyIcon /></CopyInputButton>
            </FormInput>
        </>
  );
};

export default InputCopyBlock;
