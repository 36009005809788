import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationItem, NavigationList, StyledNavigation } from './styles';
import NavigationArrowIcon from '../../images/NavigationArrowIcon.svg';
import HomeNavigationIcon from '../../images/HomeNavigationIcon.svg';
import ButtonShareIcon from '../../images/ButtonShareIcon.svg';
import { DefaultButton } from '../Button/styles';
import { useModalContext } from '../../context/hooks';

const Navigation = ({ buttonBlock, navigationBreadcrumbs }) => {
  const { updateModalState } = useModalContext();

  const toggleShareModal = () => {
    updateModalState('shareModal', {
      isOpen: true,
    });
  };

  return (
      <StyledNavigation>
          <NavigationList>
              <NavigationItem className={'home'}><Link to="/"><HomeNavigationIcon /></Link></NavigationItem>
              {navigationBreadcrumbs.map((item, index) => {
                if (index === navigationBreadcrumbs.length - 1) {
                  return (
                      <NavigationItem key={item.title}><span>{item.title}</span></NavigationItem>
                  );
                }
                return (
                    <NavigationItem key={item.title}><Link to={item.destination}>{item.title}<NavigationArrowIcon /></Link></NavigationItem>
                );
              })}
          </NavigationList>
          {buttonBlock
            ? <DefaultButton buttonBlock={buttonBlock} onClick={toggleShareModal}><ButtonShareIcon />Share</DefaultButton>
            : ''
          }
      </StyledNavigation>
  );
};

export default Navigation;
