import React from 'react';
import { format } from 'date-fns';
import SubMenu from '../../components/SubMenu';
import Navigation from '../../components/Navigation';
import Container from '../../components/Container';
import { PageContainer } from '../../components/Container/styles';
import tokenImage1 from '../../images/tableImages/table_img_1.png';
import tokenImage2 from '../../images/tableImages/table_img_2.png';
import { StakingTable } from './styles';

const createData = (date, user, invested, percentage, bonusCrypto) => {
  return {
    id : `${user}-${Math.random()}`, date, user, invested, percentage, bonusCrypto,
  };
};

const rows = [
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenImage1, userName: 'Reagent cript' },
    { amount: '999,000,999', currency: 'CCR' },
    { amount: '5' },
    { amount: '2.453467', status: 'income' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenImage2, userName: 'Flower' },
    { amount: '999', currency: 'CCR' },
    { amount: '7' },
    { amount: '4.453467', status: 'income' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenImage1, userName: 'Token' },
    { amount: '999,999', currency: 'CCR' },
    { amount: '4' },
    { amount: '0' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenImage2, userName: 'Abstract' },
    { amount: '999,000', currency: 'CCR' },
    { amount: '9' },
    { amount: '5.453467', status: 'outcome' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenImage1, userName: 'Token' },
    { amount: '999', currency: 'CCR' },
    { amount: '12' },
    { amount: '5.453467', status: 'outcome' }
  ),
];

const navigationBreadcrumbs = [
  { title: 'Staking',  destination: '/staking' },
];

const Staking = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <SubMenu title='Staking' />
              <StakingTable>
                  <thead>
                  <tr>
                      <th>Date / Time Entered</th>
                      <th>Token</th>
                      <th>Invested</th>
                      <th>Percentage</th>
                      <th>Bonus crypto</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rows.map(row => (
                      <tr key={row.id}>
                          <td>
                              <span>{row.date}</span>
                          </td>
                          <td data-label='Token'>
                              <span className={'token'}>
                                  <img src={row.user.image} alt={row.user.userName} />
                                  <span className={'tokenText'}>
                                      {row.user.userName}
                                  </span>
                              </span>
                          </td>
                          <td data-label='Invested'>
                              <span>{row.invested.amount} {row.invested.currency}</span>
                          </td>
                          <td data-label='Percentage'>
                              <span>{row.percentage.amount}%</span>
                          </td>
                          <td data-label='Bonus crypto'>
                              <span className={row.bonusCrypto.status === 'outcome' ? 'bonusCryptoOutcome' : row.bonusCrypto.status === 'income' ? 'bonusCryptoIncome' : ''}>
                                   {row.bonusCrypto.amount}
                              </span>
                          </td>
                      </tr>
                  ))}
                  </tbody>
              </StakingTable>
          </PageContainer>
      </Container>
  );
};

export default Staking;
