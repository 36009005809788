import MemberCardPhoto1 from '../images/memberCards/member_card_1.png';
import MemberCardPhoto2 from '../images/memberCards/member_card_2.png';
import MemberCardPhoto3 from '../images/memberCards/member_card_3.png';
import MemberCardPhoto4 from '../images/memberCards/member_card_4.png';
import MemberCardPhoto5 from '../images/memberCards/member_card_5.png';
import MemberCardPhoto6 from '../images/memberCards/member_card_6.png';
import MemberCardPhoto7 from '../images/memberCards/member_card_7.png';
import MemberCardPhoto8 from '../images/memberCards/member_card_8.png';
import MemberCardPhoto9 from '../images/memberCards/member_card_9.png';
import MemberCardPhoto10 from '../images/memberCards/member_card_10.png';
import MemberCardPhoto11 from '../images/memberCards/member_card_11.png';
import MemberCardPhoto12 from '../images/memberCards/member_card_12.png';
import MemberCardPhoto13 from '../images/memberCards/member_card_13.png';
import MemberCardPhoto14 from '../images/memberCards/member_card_14.png';
import MemberCardPhoto15 from '../images/memberCards/member_card_15.png';
import MemberCardPhoto16 from '../images/memberCards/member_card_16.png';
import MemberCardPhoto17 from '../images/memberCards/member_card_17.png';
import MemberCardPhoto18 from '../images/memberCards/member_card_18.png';
import MemberCardPhoto19 from '../images/memberCards/member_card_19.png';
import MemberCardPhoto20 from '../images/memberCards/member_card_20.png';
import MemberCardPhoto21 from '../images/memberCards/member_card_21.png';
import MemberCardPhoto22 from '../images/memberCards/member_card_22.png';
import Img1 from '../images/membersBlock/members_block_1.png';
import Img2 from '../images/membersBlock/members_block_2.png';
import Img3 from '../images/membersBlock/members_block_3.png';
import Img4 from '../images/membersBlock/members_block_4.png';
import Img5 from '../images/membersBlock/members_block_5.png';
import Img6 from '../images/membersBlock/members_block_6.png';
import Img7 from '../images/membersBlock/members_block_7.png';
import accountDefaultImage from '../images/accountDefaultImage.png';
import investorImage1 from '../images/investors/investor_1.png';
import investorImage2 from '../images/investors/investor_2.png';
import investorImage3 from '../images/investors/investor_3.png';
import investorImage4 from '../images/investors/investor_4.png';
import investorImage5 from '../images/investors/investor_5.png';
import investorImage6 from '../images/investors/investor_6.png';
import investorImage7 from '../images/investors/investor_7.png';

const createData = (args) => {
  return {
    id : `${args.name}-${Math.random()}`, ...args,
  };
};

export const investors = [
  createData({
    position: 1,
    investor: {
      image: accountDefaultImage,
      name: 'Bryan Hicks',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 2,
    investor: {
      image: investorImage1,
      name: 'Aaron_Rodgers',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 3,
    investor: {
      image: investorImage2,
      name: 'Karl Lambert',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 4,
    investor: {
      image: investorImage3,
      name: 'Don Gibson',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 5,
    investor: {
      image: investorImage4,
      name: 'Gregory Martin',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 6,
    investor: {
      image: investorImage5,
      name: 'Alfred Gray',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 7,
    investor: {
      image: investorImage6,
      name: 'Franklin Spencer',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
  createData({
    position: 8,
    investor: {
      image: investorImage7,
      name: 'Franklin Shalby',
    },
    invested: {
      amount: '999,999,754',
      currency: 'CCR',
      currencyDesc: '$',
      amountDesc: '125',
    },
    tokens: 100,
  }),
];

export const validations = [
  createData({
    image: MemberCardPhoto1,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: accountDefaultImage,
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
  createData({
    image: MemberCardPhoto2,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs4fg36',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: '',
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
  createData({
    image: MemberCardPhoto3,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: accountDefaultImage,
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
  createData({
    image: MemberCardPhoto4,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs4fg36',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: '',
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
  createData({
    image: MemberCardPhoto5,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: accountDefaultImage,
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
  createData({
    image: MemberCardPhoto6,
    creationDate: '22.09.2022',
    nameToken: 'Aaron_Rodgers',
    url: 'sef89seg98sef89gs',
    tags: ['Art', 'Photo', 'Portrait'],
    author: {
      photo: '',
      name: 'Bryan Hicks',
    },
    description: 'You will pass validation if 25 validators gave correct answer. If after 50 attempts the validators do not guess the name, then it is considered\n'
        + 'that this is not a famous person and refuses to add him.',
    investmentAmount: {
      amount: '442,256',
      currency: 'CCR',
    },
  }),
];

export const members = [
  createData({
    image: MemberCardPhoto1,
    rank: 1,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '100',
      up: '0',
    },
    name: 'Art in this area blink and top world',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '48h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto2,
    rank: 2,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '0',
      up: '100',
    },
    name: 'Art',
    currencyFirst:  {
      currency: '$',
      amount: '323,618,999',
    },
    currencySecond: {
      amount: '34,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-123,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto3,
    rank: 3,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '40',
      up: '60',
    },
    name: 'Benjamin Lucas',
    currencyFirst:  {
      currency: '$',
      amount: '223,618,999',
    },
    currencySecond: {
      amount: '54,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+323,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto4,
    rank: 4,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '50',
      up: '50',
    },
    name: 'Art',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '24h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto5,
    rank: 5,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '60',
      up: '40',
    },
    name: 'Abstract forms',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+423,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto6,
    rank: 6,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '100',
      up: '0',
    },
    name: 'Brett Parker',
    currencyFirst:  {
      currency: '$',
      amount: '323,618,999',
    },
    currencySecond: {
      amount: '34,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-223,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto7,
    rank: 7,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '0',
      up: '100',
    },
    name: 'Gregory Brady',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '48h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto8,
    rank: 8,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '40',
      up: '60',
    },
    name: 'Fuel on the floor',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-423,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto9,
    rank: 9,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '50',
      up: '50',
    },
    name: 'Some strange',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+423,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto10,
    rank: 10,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '60',
      up: '40',
    },
    name: 'Flowers really old flowers',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '24h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto11,
    rank: 11,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '100',
      up: '0',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '223,618,999',
    },
    currencySecond: {
      amount: '54,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+323,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto12,
    rank: 12,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '0',
      up: '100',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-423,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto13,
    rank: 13,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '40',
      up: '60',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '48h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto14,
    rank: 14,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '50',
      up: '50',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-423,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto15,
    rank: 15,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '40',
      up: '60',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '223,618,999',
    },
    currencySecond: {
      amount: '54,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+323,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto16,
    rank: 16,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '100',
      up: '0',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '24h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto17,
    rank: 17,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '0',
      up: '100',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '223,618,999',
    },
    currencySecond: {
      amount: '54,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+323,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto18,
    rank: 18,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '40',
      up: '60',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-423,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto19,
    rank: 19,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '50',
      up: '50',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '48h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto20,
    rank: 20,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '60',
      up: '40',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '423,618,999',
    },
    currencySecond: {
      amount: '64,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '-423,000',
      currency: 'CCR',
      date: '24h',
      status: 'outcome',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto21,
    rank: 21,
    isTopTag: true,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '100',
      up: '0',
    },
    name: 'Benjamin Jensen',
    currencyFirst:  {
      currency: '$',
      amount: '223,618,999',
    },
    currencySecond: {
      amount: '54,457,754',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '+323,000',
      currency: 'CCR',
      date: '48h',
      status: 'income',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
  createData({
    image: MemberCardPhoto22,
    rank: 22,
    isTopTag: false,
    tags: ['Art', 'Human', '...'],
    vote: {
      down: '0',
      up: '100',
    },
    name: 'Laura Integro',
    currencyFirst:  {
      currency: '$',
      amount: '0',
    },
    currencySecond: {
      amount: '0',
      currency: 'CCR',
    },
    statusInfo: {
      amount: '0',
      currency: 'CCR',
      date: '24h',
    },
    timer: {
      hours: '03',
      minutes: '15',
      seconds: '43',
    },
  }),
];

export const profiles = [
  createData({
    image: Img1,
    name: 'Leonardo DiCaprio',
    isNew: true,
    amount: '+$423,618,999',
    percent: '32%',
    status: 'income',
    date: '24hours',
  }),
  createData({
    image: Img2,
    name: 'Karl Lambert',
    isNew: false,
    amount: '-$423,618,999',
    percent: '32%',
    status: 'outcome',
    date: '48hours',
  }),
  createData({
    image: Img3,
    name: 'Don Gibson',
    isNew: false,
    amount: '+$423,618,999',
    percent: '32%',
    status: 'income',
    date: '24hours',
  }),
  createData({
    image: Img4,
    name: 'Gregory Martin',
    isNew: false,
    amount: '-$423,618,999',
    percent: '32%',
    status: 'outcome',
    date: '48hours',
  }),
  createData({
    image: Img5,
    name: 'Alfred Gray',
    isNew: false,
    amount: '+$423,618,999',
    percent: '32%',
    status: 'income',
    date: '24hours',
  }),
  createData({
    image: Img6,
    name: 'Franklin Spencer',
    isNew: false,
    amount: '-$423,618,999',
    percent: '32%',
    status: 'outcome',
    date: '48hours',
  }),
  createData({
    image: Img7,
    name: 'Franklin Shalby',
    isNew: false,
    amount: '+$423,618,999',
    percent: '32%',
    status: 'income',
    date: '24hours',
  }),
];
