import React from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import SubMenu from '../../components/SubMenu';
import { PageContainer } from '../../components/Container/styles';
import Select from '../../components/Select';
import { ListValidationCard, ValidationSelect } from './styles';
import ValidationCard from './components/ValidationCard';
import { validations } from '../../utils/MOCK';

const validationOptions = validations.slice(0, 6);
const options = ['Date', 'Name', 'Also'];

const navigationBreadcrumbs = [
  { title: 'Validation',  destination: '/token-validation' },
];

const TokenValidation = () => {
  return (
      <>
          <Container direction={'column'}>
              <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
              <PageContainer>
                  <SubMenu title='Validation' />
                  <ValidationSelect>
                      <Select options={options} />
                  </ValidationSelect>
                  <ListValidationCard>
                      {validationOptions.map(item => (
                          <ValidationCard key={item.id} {...item} />
                      ))}
                  </ListValidationCard>
              </PageContainer>
          </Container>
      </>
  );
};

export default TokenValidation;
