import React, { useCallback, useState } from 'react';
import { ShareLabel } from '../../../../Forms/Label/styles';
import { FormTextarea } from '../../../../Forms/FormGroup/styles';
import { CopyButton } from '../../../../Button/styles';
import CopyIcon from '../../../../../images/CopyIcon.svg';
import { TextareaBlock } from '../../../../Forms/Textarea/styles';
import Button from '../../../../Button';
import { ShareOptions, ShareOptionsItem, ShareOptionsList } from '../../../styles';
import SharesFirstOption from '../../../../../images/shareIcons/SharesFirstOption.svg';
import SharesSecondOption from '../../../../../images/shareIcons/SharesSecondOption.svg';
import { useModalContext } from '../../../../../context/hooks';

const tabs = [
  {
    id: 1, image: <SharesFirstOption />, title: 'Vertical',
  },
  {
    id: 2, image: <SharesSecondOption />, title: 'Horizontal',
  },
];

const EmbedCopyBlock = ({ embed }) => {
  const { updateModalState } = useModalContext();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const toggleShareModal = () => {
    updateModalState('shareModal', {
      isOpen: false,
    });
  };

  const copyToClipboard = useCallback((em) => () => {
    navigator.clipboard.writeText(em).then();
  }, []);

  return (
        <>
            <ShareLabel classNme={'share'} htmlFor="eth-address">Embed</ShareLabel>
            <FormTextarea className={'share'}>
                <TextareaBlock
                    name="ethAddress"
                    id="ethAddress"
                    value={embed}
                    className={'shareTextarea'}
                >
                    {embed}
                </TextareaBlock>
                <CopyButton type={'button'} onClick={copyToClipboard(embed)}><CopyIcon className={'copyIcon'} /></CopyButton>
            </FormTextarea>
            <ShareOptions>
                <ShareLabel classNme={'share'} htmlFor="eth-address">Choose a view</ShareLabel>
                <ShareOptionsList>
                    {tabs.map(tab => {
                      return (
                            <ShareOptionsItem
                                key={tab.id}
                                className={tab.id === selectedTab.id ? 'checked' : ''}
                                onChange={() => {
                                  setSelectedTab(tab);
                                }}
                            >
                                <label className={'title'}>
                                    <input type='radio' name="tab" />
                                    <div className={'checkPointer'} />
                                    {tab.title}
                                </label>
                                <div className={'image'}>{tab.image}</div>
                            </ShareOptionsItem>
                      );
                    })}
                </ShareOptionsList>
            </ShareOptions>
            <Button
                primary
                onClick={toggleShareModal}
                className={'copyCode'}
            >
                Copy code
            </Button>
        </>
  );
};

export default EmbedCopyBlock;
