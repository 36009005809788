import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    color: ${props => props.theme.colors.primary};
    font-family: "Poppins", sans-serif;
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: normal;
    box-sizing: border-box;
    user-select: none;
  }
  html,
  body {
    height: 100%;
  }
  html {
    width: 100vw;
    overflow-x: hidden;
  }
  button, svg {
    -webkit-tap-highlight-color: transparent;
  }
  a {
    color: ${props => props.theme.colors.clearBlue};
    text-decoration: none;
  }
  h1 {
    font-size: ${props => props.theme.fontSize.f48};
    font-weight: 600;
    line-height: 62px;
    
    @media ${props => props.theme.device.md} {
        font-size: ${props => props.theme.fontSize.f32};
        line-height: 42px;
    }
  }
  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 32px;
  }
  h3 {
    font-size: ${props => props.theme.fontSize.f20};
    font-weight: 600;
    line-height: 32px;
  }
  h4 {
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 32px;
  }
`;

export default Global;
