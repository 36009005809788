import styled from 'styled-components';
import listSwitcherImage from '../../images/ListSwitcher.inline.svg';
import listSwitcherActiveImage from '../../images/ListSwitcherActive.inline.svg';
import tableSwitcherImage from '../../images/TableSwitcher.inline.svg';
import tableSwitcherActiveImage from '../../images/TableSwitcherActive.inline.svg';

export const AssetsTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
    }
    
    @media ${props => props.theme.device.md} {
        margin-bottom: 16px;
    }
`;

export const AssetsTopLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 334px;
    height: 169px;
    margin-right: 26px;
    padding: 26px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    & .editButton {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 52px;
        
        & svg {
            margin-right: 6px;
        }
        
        & span {
            font-weight: 500;
            line-height: 16px;
        }
        
        &:hover {
            box-shadow: ${p => p.theme.boxShadow.hoverButton};
        }
    }
    
    @media ${props => props.theme.device.lg} {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 16px;
        
        & .editButton {
            flex-shrink: 0;
            width: 228px;
        
            &:hover {
                box-shadow: none;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        padding: 16px;
    
        & .editButton {
            width: 42px;
            height: 42px;
            
            & svg {
                margin-right: 0;
            }
            
            & span {
                display: none;
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 8px;
    }
`;

export const ProfileInfo = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    & .left {
        overflow: hidden;
        display: flex;
        align-items: center;
    
        & img {
            width: 46px;
            height: 46px;
            margin-right: 5px;
        }
    
        & .link {
            overflow: hidden;
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
            text-decoration: underline;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    
    & .shareButton {
        align-self: center;
        width: 24px;
        height: 24px;
        margin-left: 20px;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-right: 20px;
        margin-bottom: 0;
    }
    
    @media ${props => props.theme.device.xs} {
        margin-right: 10px;
        
        & .shareButton {
            margin-left: 10px;
        }
    }
`;

export const AssetsTopRight = styled.div`
    display: flex;
    width: 100%;
    height: 169px;
    padding: 26px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    @media ${props => props.theme.device.lg} {
        height: auto;
    }
    
    & .left {
        padding-right: 44px;
        border-right: 1px solid ${props => props.theme.colors.cadetBlue};
    }
    
    & .right {
        padding-left: 44px;
    }
    
    @media ${props => props.theme.device.lg} {
        & .left {
            padding-right: 16px;
        }
    
        & .right {
            padding-left: 16px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        padding: 16px;
        
        & .left {
            padding: 0 0 16px 0;
            border-right: 0;
            border-bottom: 1px solid ${props => props.theme.colors.cadetBlue};
        }
    
        & .right {
            padding: 16px 0 0 0;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 8px;
    }
`;

export const AssetsItem = styled.div`
    &:first-child {
        margin-bottom: 26px;
    }

    & .title {
        margin-bottom: 6px;
        color: ${props => props.theme.colors.secondary};
        font-weight: 500;
        line-height: 16px;
    }
    
    & .content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
        & .preContent {
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }
        
        & .rank {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 52px;
            margin-left: 6px;
            padding: 0 8px;
            color: ${props => props.theme.colors.white};
            font-size: ${props => props.theme.fontSize.f12};
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
    
            &.green {
                background-color: ${props => props.theme.colors.mediumGreen};
            }
    
            &.orange {
                background-color: ${props => props.theme.colors.sunshade};
            }
    
            & svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
        
        & a {
            display: flex;
            align-items: center;
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
            
            & .copy {
                width: 16px;
                height: 16px;
                margin-left: 6px;
                fill: ${props => props.theme.colors.clearBlue};
            }
        }
        
        & button {
            align-self: center;
            width: 24px;
            height: 24px;
            margin-left: 6px;
            border-radius: 6px;
            
            &.download {
                background: ${props => props.theme.colors.mediumGreen};
            }
            
            &.update {
                background: ${props => props.theme.colors.clearBlue};
            }
            
            &.reload {
                border: 1px solid ${props => props.theme.colors.cadetBlue};
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        & .content {
            font-size: ${props => props.theme.fontSize.f14};
            
            & a {
                font-size: ${props => props.theme.fontSize.f14};
            }
        }
    }
    
     @media ${props => props.theme.device.md} {
        &:first-child {
            margin-bottom: 8px;
        }
    }
`;

export const AssetsTopRightEmpty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 169px;
    padding: 26px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    & .description {
        text-align: center;
        max-width: 392px;
        width: 100%;
        margin-bottom: 16px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
    }
    
    & button {
        width: 260px;
        margin: 0 auto;
        font-weight: 500;
    }
    
    @media ${props => props.theme.device.lg} {
        height: auto;
    }
    
    @media ${props => props.theme.device.md} {
        padding: 16px;
        
        & .description {
            max-width: 249px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            width: 100%;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 8px;
    }
`;

export const AssetsScheduleContainer = styled.div`
    margin-bottom: 44px;
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 26px;
    }
`;

export const MyAssetsWrapper = styled.div`
    position: relative;
`;

export const Tabs = styled.div`
    display: flex;
    position: absolute;
    top: 26px;
    right: 0;
    
    @media ${props => props.theme.device.sm} {
        top: 4px;
    }
`;

export const RadioLabel = styled.label`
    display: block;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    cursor: pointer;
    
    input {
        display: none;
    }
`;

export const ListSwitcher = styled.div`
    width: 28px;
    height: 28px;
    background: url('${listSwitcherImage}') no-repeat center;
    
    &.checked {
         background: url('${listSwitcherActiveImage}') no-repeat center;
    }
`;

export const TableSwitcher = styled.div`
    width: 28px;
    height: 28px;
    background: url('${tableSwitcherImage}') no-repeat center;
    
    &.checked {
         background: url('${tableSwitcherActiveImage}') no-repeat center;
    }
`;
