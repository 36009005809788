export const baseTheme = {
  device: {
    xs: '(max-width: 360px)',
    sm: '(max-width: 480px)',
    md: '(max-width: 768px)',
    lg: '(max-width: 1220px)',
  },
  colors: {
    primary: '#23262F',
    secondary: '#777E91',
    white: '#fff',
    clearBlue: '#3772FF',
    mercury: '#E6E8EC',
    whiteSmoke: '#F4F5F6',
    coralRed: '#F43F3F',
    mediumGreen: '#18B04D',
    cadetBlue: '#B1B5C4',
    tuna: '#353945',
    sunshade: '#FD9821',
    scheduleGreen: '#C8E8D3',
    scheduleRed: '#FBC8D4',
    balticSea: '#23262F',
    milkWhite: '#FCFCFD',
    hawkesBlue: '#d7e3ff',
    pastelBlue: '#9bb9ff',
    jordyBlue: '#87ABFF',
    cosmos: '#fdd9d9',
    sweetPink: '#fa9f9f',
    geraldine: '#F98D8D',
    antiqueWhite: '#FFEBD8',
    chardonnay: '#fecc90',
    sand: '#FEC27C',
    edgeWater: '#bae7ca',
    vistaBlue: '#8cd8a6',
    hintOfGreen: '#DDFFE9',
    overlay: 'rgba(20, 20, 22, 0.7)',
    tagBackground: 'rgba(255, 255, 255, 0.2)',
    cardGradient: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0.49%, rgba(0, 0, 0, 0.71) 88.87%);',
    blueGradient: 'linear-gradient(157.55deg, #E9F0FF 0%, #F3F5FC 91.98%)',
    mainBannerGradient: 'linear-gradient(176.57deg, #E6E8EC 45.07%, rgba(243, 245, 252, 0.15) 85.46%)',
  },
  boxShadow: {
    mainShadow: '0 36px 64px -20px rgba(31, 47, 70, 0.16)',
    drop: '0 44px 64px -48px rgba(31, 47, 70, 0.18)',
    popup: '0 0 40px rgba(216, 216, 216, 0.8)',
    tooltip: '0 0 25px #D8D8D8;',
    hoverButton: '0 12px 26px rgba(0, 0, 0, 0.16)',
    servicesAdaptive: '0 36px 40px -20px rgba(31, 47, 70, 0.16)',
    filter: '0 6px 30px rgba(0, 0, 0, 0.1)',
  },
  fontSize: {
    f10: '10px',
    f12: '12px',
    f14: '14px',
    f16: '16px',
    f18: '18px',
    f20: '20px',
    f24: '24px',
    f32: '32px',
    f42: '42px',
    f48: '48px',
    f72: '72px',
  },
};
