import React, { useCallback, useState } from 'react';
import accountDefaultImage from '../../images/accountDefaultImage.png';
import ProfileMenu from '../ProfileMenu';
import { HeaderAvatarImage } from './styles';

const HeaderSettings = () => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const toggleOpenProfileMenu = useCallback(() => setOpenProfileMenu(prev => !prev), [setOpenProfileMenu]);

  return (
      <>
          <HeaderAvatarImage onClick={toggleOpenProfileMenu}>
              <img src={accountDefaultImage} alt={''} />
          </HeaderAvatarImage>
          <ProfileMenu openProfileMenu={openProfileMenu} setOpenProfileMenu={setOpenProfileMenu} />
      </>
  );
};

export default HeaderSettings;
