import styled from 'styled-components';

export const StyledSubMenu = styled.div`
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 16px;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        height: auto;
    }
`;

export const SubMenuTitle = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    font-size: ${props => props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 40px;
`;

export const SubMenuList = styled.ul`
     display: flex;
     list-style: none;
     border-bottom: 2px solid ${props => props.theme.colors.mercury};

     @media ${props => props.theme.device.lg} {
        order: 3;
        margin-top: 26px;
     }
     
     @media ${props => props.theme.device.md} {
        overflow-x: scroll;
        width: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
     }
`;

export const StyledSubMenuItem = styled.li`
    margin: 0 32px 0 0;
    
    &:last-child {
        margin-right: 0;
    }
    
    & .nav-link {
        display: block;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 40px;
    }
    
    & .nav-link.active {
        position: relative;
        color: ${props => props.theme.colors.primary};
        font-weight: 500;
    }
    
    & .nav-link.active:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.colors.clearBlue};
    }
    
    @media ${props => props.theme.device.lg} {
        & .nav-link {
            line-height: 32px;
        }
    }
`;

export const SubMenuButton = styled.div`
    & button {
        width: 120px;
        height: 40px;
        font-size: ${props => props.theme.fontSize.f14};
    }
    
    @media ${props => props.theme.device.lg} {
        order: 2;
        align-self: flex-end;
        margin-top: -40px;
        margin-left: 0;
    }
    
    @media ${props => props.theme.device.xs} {
        align-self: flex-start;
        margin-top: 16px;
    }
`;
