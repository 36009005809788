import React, { useState } from 'react';
import {
  DropDownHeader, DropDownList, ListItem, ResultBlock, SelectBlock, StyledSelect,
} from './styles';
import { Overlay } from '../Nav/Burger/styles';

const Select = ({
  options, resultBlock, validationCard, results,
}) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggling = () => {
    setIsOpen(prevState => !prevState);
    setOpen(prevState => !prevState);
  };

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    setOpen(false);
  };

  return (
      <>
          <Overlay open={open} onClick={toggling} />
          <StyledSelect>
              <SelectBlock className={validationCard && 'validationCard'}>
                  <DropDownHeader onClick={toggling} className={'validationHeader'}>
                      {selectedOption}
                  </DropDownHeader>
                  {isOpen && (
                      <DropDownList className={'validationList'}>
                          {options.map(option => (
                              <ListItem onClick={onOptionClicked(option)} key={Math.random()} className={selectedOption === option ? 'active' : ''}>
                                  {option}
                              </ListItem>
                          ))}
                      </DropDownList>
                  )}
              </SelectBlock>
              {resultBlock && <ResultBlock>Results: <span>{results}</span></ResultBlock>}
          </StyledSelect>
      </>
  );
};

export default Select;
