import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NewTokensLaunches from './components/NewTokensLaunches';
import Top from './components/Top';
import {
  MainAuthContent, MainContent, MainPageButtons, MainPageDescription, MainPageTitle, StyledHome,
} from './styles';
import TokensPopularity from './components/TokensPopularity';
import MainBanner from './components/MainBanner';
import Services from './components/Services';
import MembersSlider from './components/MembersSlider';
import { AuthContext } from '../../context';
import Button from '../../components/Button';
import { useModalContext } from '../../context/hooks';

const Home = () => {
  const { updateModalState } = useModalContext();
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const navigateCreate = () => {
    navigate('/create-token');
  };

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  return (
       <StyledHome>
           <MembersSlider />
           <MainAuthContent isAuth={isAuth}>
               <div>
                   <MainPageTitle>Welcome to Cript CCR</MainPageTitle>
                   <MainPageDescription>Create, buy and sell unique NFT tokens.</MainPageDescription>
               </div>
               <MainPageButtons>
                   <Button primary onClick={toggleBuyCCRModal}>Buy CCR</Button>
                   <Button outlined onClick={navigateCreate}>Create token</Button>
               </MainPageButtons>
           </MainAuthContent>
           <MainContent isAuth={isAuth}>
               <MainBanner />
               <Services />
           </MainContent>
           <NewTokensLaunches />
           <TokensPopularity />
           <Top />
       </StyledHome>
  );
};

export default Home;
