import styled from 'styled-components';

export const StyledCatalogSearch = styled.div`
    margin-bottom: 16px;
    
    h3 {
        margin-bottom: 8px;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-bottom: 32px;
    }
`;

export const SearchInputWrapper = styled.div`
    position: relative;
    height: 44px;
    
    & button {
        position: absolute;
        top: 0;
        left: 0;
        width: 44px;
        height: 44px;
        z-index: 1;
        
        & svg {
            width: 14px;
            height: 14px;
        }
    }
`;
