import React, { useState } from 'react';
import {
  ModalBody, ModalClose, ModalHeader, ModalTitle,
} from '../styles';
import Modal from '../index';
import ETH from './components/ETH';
import BTC from './components/BTC';
import BNB from './components/BNB';
import TRON from './components/TRON';
import USDT from './components/USDT';
import DOT from './components/DOT';
import SOLANA from './components/SOLANA';
import TelegramBot from './components/TelegramBot';
import CloseIcon from '../../../images/CloseIcon.svg';
import {
  PageWrapper, RadioLabel, Sidebar, SidebarTab, 
} from './styles';
import { useModalContext } from '../../../context/hooks';

const tabs = [
  { id: 1, title: 'ETH' },
  { id: 2, title: 'BTC' },
  { id: 3, title: 'BNB' },
  { id: 4, title: 'TRON' },
  { id: 5, title: 'USDT' },
  { id: 6, title: 'DOT' },
  { id: 7, title: 'SOLANA' },
  { id: 8, title: 'Telegram Bot' },
];

const BuyCCRModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: !modals.buyCCRModal.isOpen,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.buyCCRModal.isOpen}
              setIsOpen={toggleBuyCCRModal}
              modalName={'buyCCRModal'}
          >
              <ModalHeader>
                  <ModalTitle className={'buyCCRModal'}>Top-up your wallet</ModalTitle>
                  <ModalClose>
                      <CloseIcon onClick={toggleBuyCCRModal} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody>
                  <PageWrapper>
                      <Sidebar>
                          <h3>Buy CCR with:</h3>
                          <SidebarTab>
                              {tabs.map(tab => {
                                return (
                                      <RadioLabel
                                          key={tab.id}
                                          className={tab.id === selectedTab.id ? 'checked' : ''}
                                          onChange={() => {
                                            setSelectedTab(tab);
                                          }}
                                      >
                                          {tab.title}
                                          <input type='radio' name="tab" />
                                      </RadioLabel>
                                );
                              })}
                          </SidebarTab>
                      </Sidebar>
                      <>
                          {{
                            1: <ETH />,
                            2: <BTC />,
                            3: <BNB />,
                            4: <TRON />,
                            5: <USDT />,
                            6: <DOT />,
                            7: <SOLANA />,
                            8: <TelegramBot />,
                          }[selectedTab.id]}
                      </>
                  </PageWrapper>
              </ModalBody>
          </Modal>
      </>
  );
};

export default BuyCCRModal;
