import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationButtons, StyledMainSlider, SwiperWrapper } from './styles';
import 'swiper/css';
import { members } from '../../../../utils/MOCK';
import TokenCard from '../../../../components/Cards/TokenCard';

const membersTokensPopularity = members.slice(0, 5);

const MainSlider = () => {
  return (
       <StyledMainSlider>
           <SwiperWrapper>
               <Swiper
                   tag={'section'}
                   wrapperTag={'ul'}
                   modules={[Navigation, EffectCoverflow]}
                   effect={'coverflow'}
                   spaceBetween={38.5}
                   grabCursor={true}
                   centeredSlides={true}
                   slidesPerView={2}
                   coverflowEffect={{
                     rotate: 0,
                     stretch: 135,
                     depth: 80,
                     modifier: 1,
                     slideShadows: false,
                   }}
                   navigation={{
                     nextEl: '.swiper-button-next',
                     prevEl: '.swiper-button-prev',
                   }}
                   initialSlide={2}
                   breakpoints={{
                     768: {
                       effect: 'slide',
                       slidesPerView: 1,
                     },
                     1220: {
                       effect: 'coverflow',
                     },
                   }}
               >
                   {membersTokensPopularity.map(item => (
                       <SwiperSlide tag={'li'} key={item.id}>
                           <TokenCard {...item} />
                       </SwiperSlide>
                   ))}
                   <NavigationButtons>
                       <div className={'swiper-button-prev'} />
                       <div className={'swiper-button-next'} />
                   </NavigationButtons>
               </Swiper>
           </SwiperWrapper>
       </StyledMainSlider>
  );
};

export default MainSlider;
