import React from 'react';
import SubMenu from '../../components/SubMenu';
import { PageContainer } from '../../components/Container/styles';
import { ValidationEmpty } from './styles';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';

const navigationBreadcrumbs = [
  { title: 'Validation',  destination: '/token-validation-empty' },
];

const TokenValidationEmpty = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <SubMenu title='Validation' />
            <PageContainer>
                <ValidationEmpty>No cards left to validate</ValidationEmpty>
            </PageContainer>
        </Container>
  );
};

export default TokenValidationEmpty;
