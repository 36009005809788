import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import SubMenu from '../../components/SubMenu';
import Navigation from '../../components/Navigation';
import Container from '../../components/Container';
import { PageContainer } from '../../components/Container/styles';
import SortArrow from '../../images/SortArrow.svg';
import { HistoryTable } from './styles';
import HistoryPurchaseIcon from '../../images/historyIcons/HistoryPurchaseIcon.svg';
import HistorySaleIcon from '../../images/historyIcons/HistorySaleIcon.svg';
import HistoryCreateIcon from '../../images/historyIcons/HistoryCreateIcon.svg';
import HistoryValidateIcon from '../../images/historyIcons/HistoryValidateIcon.svg';
import HistoryTransferIcon from '../../images/historyIcons/HistoryTransferIcon.svg';
import HistoryConvertIcon from '../../images/historyIcons/HistoryConvertIcon.svg';
import HistoryConvertOrangeIcon from '../../images/historyIcons/HistoryConvertOrangeIcon.svg';
import HistoryStackingIcon from '../../images/historyIcons/HistoryStackingIcon.svg';
import HistoryReferralIcon from '../../images/historyIcons/HistoryReferralIcon.svg';
import historyUser1 from '../../images/historyUsers/user_1.png';
import historyUser2 from '../../images/historyUsers/user_2.png';
import historyUser3 from '../../images/historyUsers/user_3.png';
import historyUser4 from '../../images/historyUsers/user_4.png';
import historyUser5 from '../../images/historyUsers/user_5.png';
import historyUser6 from '../../images/historyUsers/user_6.png';
import historyUser7 from '../../images/historyUsers/user_7.png';
import historyUser8 from '../../images/historyUsers/user_8.png';
import historyUser9 from '../../images/historyUsers/user_9.png';
import TableSortArrow from '../../images/TableSortArrow.svg';
import { DefaultButton } from '../../components/Button/styles';
import TableTwoSortArrow from '../../images/TableTwoSortArrow.svg';

const createData = (date, operationType, from, to, deals, price) => {
  return {
    id : `${operationType}-${Math.random()}`, date,  operationType, from, to, deals, price,
  };
};

const rows = [
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryPurchaseIcon />, tokenName: 'Purchase of an NFT Token' },
    { image: historyUser1, name: '@Roland' },
    { image: historyUser8, name: 'Token Name' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistorySaleIcon />, tokenName: 'Sale of the NFT Token' },
    { image: historyUser2, name: 'Token Name' },
    { image: historyUser7, name: '@Joel' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryCreateIcon />, tokenName: 'Creating an NFT Token' },
    { image: historyUser3, name: 'Token Name' },
    { image: historyUser6, name: '@Ken' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryValidateIcon />, tokenName: 'Validator rights Purchase' },
    { image: historyUser4, name: 'Token Name Token Name' },
    { image: historyUser5, name: '@Marvin' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryTransferIcon />, tokenName: 'Transfer of CCR' },
    { image: historyUser5, name: '@Margo' },
    { image: historyUser1, name: '@John' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryConvertIcon />, tokenName: 'Convertation' },
    { image: historyUser6, name: 'ETH wallet' },
    { image: historyUser2, name: 'CCR' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryConvertOrangeIcon />, tokenName: 'Convertation' },
    { image: historyUser7, name: 'CCR' },
    { image: historyUser3, name: 'ETH wallet' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryStackingIcon />, tokenName: 'Staking' },
    { image: historyUser8, name: 'Token Name' },
    { image: historyUser4, name: '@Joel' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: <HistoryReferralIcon />, tokenName: 'Referral' },
    { image: historyUser9, name: '@Roland' },
    { image: historyUser5, name: '@Margo' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '999,000,999', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '999.999.999',
    }
  ),
];

const navigationBreadcrumbs = [
  { title: 'History',  destination: '/history' },
];

const History = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <SubMenu title='History' />
              <HistoryTable>
                  <thead>
                  <tr>
                      <th>
                          <DefaultButton className={'sort active'}>
                              Date
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              Operation type
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              From
                              <TableTwoSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort active'}>
                              To
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort active'}>
                              Deals
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort active'}>
                              Wallet balance
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {rows.map(row => (
                      <tr key={row.id}>
                          <td data-label='Date'>
                              <span>{row.date}</span>
                          </td>
                          <td>
                           <span className={'operationType'}>
                               {row.operationType.image}
                               <span className={'operationTypeText'}>
                                   {row.operationType.tokenName}
                               </span>
                           </span>
                          </td>
                          <td data-label='From'>
                              <span className={'user'}>
                                  <img src={row.from.image} alt={row.from.name} />
                                  <span className={'userName'}>{row.from.name}</span>
                              </span>
                          </td>
                          <td data-label='To'>
                              <span className={'user'}>
                                  <img src={row.to.image} alt={row.to.name} />
                                  <span className={'userName'}>{row.to.name}</span>
                              </span>
                          </td>
                          <td data-label='Deals'>
                           <span className={row.deals.status === 'outcome' ? 'dealsOutcome' : 'dealsIncome'}>
                               {row.deals.amount} {row.deals.currency}
                               <Link to={row.deals.linkDestination} className={'dealsLink'}>{row.deals.linkName}</Link>
                           </span>
                          </td>
                          <td data-label='Wallet balance'>
                              <div>
                                  <span className={row.price.status === 'outcome' ? 'priceOutcome' : 'priceIncome'}>
                                      {row.price.amount} {row.price.currency} <SortArrow />
                                  </span>
                                  <span className={'priceCurrency'}>
                                      {row.price.defaultCurrency}{row.price.defaultAmount}
                                  </span>
                              </div>
                          </td>
                      </tr>
                  ))}
                  </tbody>
              </HistoryTable>
          </PageContainer>
      </Container>
  );
};

export default History;
