import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    align-items: ${props => props.align || 'stretch'};
    justify-content: ${props => props.justify || 'stretch'};
    width: 1200px;
    margin: ${({ margin }) => margin || '0 auto'};
    
    @media ${props => props.theme.device.lg} {   
        width: 100%;
        padding: 0 45px;
        
        &.tokensPopularity {
            background: ${props => props.theme.colors.mercury};
        }
    }
    
    @media ${props => props.theme.device.md} {
        padding: 0 16px;
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 0 10px;
    }
`;

export const PageContainer = styled(StyledContainer)`
    flex-direction: column;
    margin: 16px 0 44px;
    width: 100%;
    padding: 0;
    
    @media ${props => props.theme.device.lg} {
        margin: 16px 0 26px;
    }
`;
