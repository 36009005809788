import styled from 'styled-components';

export const StyledBurger = styled.div`
    display: none;
    position: absolute;
    top: 24px;
    right: 45px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 2;
    -webkit-tap-highlight-color: transparent;
    
   @media ${props => props.theme.device.lg} {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
    
   @media ${props => props.theme.device.md} {
        right: 24px;
   }
    
   @media ${props => props.theme.device.xs} {
        right: 10px;
   }
`;

export const Overlay = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
`;
