import React, { useState } from 'react';
import {
  DropDownHeader, DropDownList, ListItem, ResultBlock, SelectBlock, StyledSelect,
} from './styles';

const FormSelect = ({
  onChange, options, resultBlock, selectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(prevState => !prevState);

  const onOptionClicked = (value) => () => {
    setIsOpen(false);
    if (onChange) {
      onChange(value);
    }
  };

  return (
      <StyledSelect>
          <SelectBlock>
              <DropDownHeader onClick={toggling}>
                  {selectedOption}
              </DropDownHeader>
              {isOpen && (
                  <DropDownList>
                      {options.map(option => (
                          <ListItem onClick={onOptionClicked(option)} key={Math.random()} className={selectedOption === option ? 'active' : ''}>
                              {option}
                          </ListItem>
                      ))}
                  </DropDownList>
              )}
          </SelectBlock>
          {resultBlock && <ResultBlock>Results: <span>11189</span></ResultBlock>}
      </StyledSelect>
  );
};

export default FormSelect;
