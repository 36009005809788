import React from 'react';
import SortArrow from '../../../../images/SortArrow.svg';
import MemberCardPhoto1 from '../../../../images/memberCards/member_card_1.png';
import {
  ContentBlock, InfoBlock, Name, Photo, Rank, StatusInfo, StyledFrameCard, Title,
  TopInfo, VoteAmount, VoteDown, VoteRange, VoteUp,
} from './styles';

const voteDown = '40';
const voteUp = '60';

const FrameCard = ({ id }) => {
  return (
      <StyledFrameCard className={id === 'horizontal' ? 'horizontal' : null}>
          <Photo background={`url(${MemberCardPhoto1})`} className={'photo'}>
              <TopInfo>
                  <Rank>#1</Rank>
              </TopInfo>
          </Photo>
          <ContentBlock className={'contentBlock'}>
              <Name className={'name'}>Aaron_Rodgers</Name>
              <InfoBlock className={'infoBlock'}>
                  <Title className={'title'}>Popularity</Title>
                  <VoteAmount className={'voteAmount'}>3,457,754 votes</VoteAmount>
                  <StatusInfo className={'statusInfoIncome'}>
                      <span>5,443 in 24h <SortArrow /></span>
                  </StatusInfo>
              </InfoBlock>
              <VoteRange className={'voteRange'}>
                  <VoteDown voteDown={voteDown}/>
                  <VoteUp voteUp={voteUp} />
              </VoteRange>
          </ContentBlock>
      </StyledFrameCard>
  );
};

export default FrameCard;
