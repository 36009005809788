import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { List, StyledMainMenu } from './styles';
import { AuthContext } from '../../../context';
import LaunchpadIcon from '../../../images/LaunchpadIcon.svg';
import TokenIcon from '../../../images/TokenIcon.svg';
import InvestorsIcon from '../../../images/InvestorsIcon.svg';
import DealsIcon from '../../../images/DealsIcon.svg';
import WhitePaperIcon from '../../../images/WhitePaperIcon.svg';

const MainMenu = (props) => {
  const { setOpen } = props;
  const { isAuth } = useContext(AuthContext);

  const onLinkClick = () => {
    setOpen(false);
  };

  return (
      <StyledMainMenu {...props} isAuth={isAuth}>
          <List className={'list'}>
              <li>
                  <NavLink
                      to="/launchpad"
                      onClick={onLinkClick}
                      className={
                          linkState => {
                            return `${linkState?.isActive ? ' active' : ''}`;
                          }
                      }
                  >
                      <LaunchpadIcon />Launchpad
                  </NavLink>
              </li>
              <li>
                  <NavLink
                      to="/tokens"
                      onClick={onLinkClick}
                      className={
                          linkState => {
                            return `${linkState?.isActive ? ' active' : ''}`;
                          }
                      }
                  >
                      <TokenIcon />Tokens
                  </NavLink>
              </li>
              <li>
                  <NavLink
                      to="/investors"
                      onClick={onLinkClick}
                      className={
                          linkState => {
                            return `${linkState?.isActive ? ' active' : ''}`;
                          }
                      }
                  >
                      <InvestorsIcon />Investors
                  </NavLink>
              </li>
              <li>
                  <NavLink
                      to="/deals"
                      onClick={onLinkClick}
                      className={
                          linkState => {
                            return `${linkState?.isActive ? ' active' : ''}`;
                          }
                      }
                  >
                      <DealsIcon />Deals
                  </NavLink>
              </li>
              <li>
                  <NavLink
                      to="/white-paper"
                      onClick={onLinkClick}
                      className={
                          linkState => {
                            return `${linkState?.isActive ? ' active' : ''}`;
                          }
                      }
                  >
                      <WhitePaperIcon />White paper
                  </NavLink>
              </li>
          </List>
      </StyledMainMenu>
  );
};

export default MainMenu;
