import React from 'react';
import { StyledFormGroup } from './styles';

const FormGroup = (props) => {
  return (
        <StyledFormGroup {...props} />
  );
};

export default FormGroup;
