import React from 'react';
import { Description, StyledSidebarItem } from './styles';
import CollectionValueIcon from '../../../../../images/CollectionValueIcon.svg';

const SidebarItem = ({
  image, title, currency, isBotView, 
}) => {
  if (isBotView) {
    return (
            <>
                <StyledSidebarItem>
                    <CollectionValueIcon />
                    <Description>
                        <span>442,256 CCR</span> $23,256
                    </Description>
                </StyledSidebarItem>
            </>
    );
  }

  return (
      <>
          <StyledSidebarItem>
              {image}
              <Description>
                  <span>1 {title}</span>
                  {currency}
              </Description>
          </StyledSidebarItem>
          <StyledSidebarItem>
              <CollectionValueIcon />
              <Description>
                  <span>442,256 CCR</span> $23,256
              </Description>
          </StyledSidebarItem>
      </>
  );
};

export default SidebarItem;
