import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalButtons, ModalDescription, ModalTitle } from '../styles';
import Button from '../../Button';
import Modal from '../index';
import { useModalContext } from '../../../context/hooks';

const SentForValidation = () => {
  const { modals, updateModalState } = useModalContext();
  const navigate = useNavigate();

  const toggleSentForValidation = () => {
    updateModalState('sentForValidation', {
      isOpen: !modals.sentForValidation.isOpen,
    });
  };

  const handleLaunchpad = () => {
    toggleSentForValidation();
    navigate('/launchpad');
  };

  return (
      <>
          <Modal
              isOpen={modals.sentForValidation.isOpen}
              setIsOpen={toggleSentForValidation}
          >
              <ModalTitle>NFT Token has been successfully sent for validation!</ModalTitle>
              <ModalDescription>
                  The token you created has been successfully sent for validation. Expect a notification of the result.
              </ModalDescription>
              <ModalButtons>
                  <Button
                      primary
                      onClick={handleLaunchpad}
                  >
                      Go to the launchpad
                  </Button>
                  <Button
                      outlined
                      onClick={toggleSentForValidation}
                  >
                      Cancel
                  </Button>
              </ModalButtons>
          </Modal>
      </>
  );
};

export default SentForValidation;
