import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CountDescription, CountImage, CountInfo, CountTitle, HeaderCountsList, StyledHeaderCounts,
} from './styles';
import SaleIcon from '../../images/SaleIcon.svg';
import WalletIcon from '../../images/WalletIcon.svg';
import PlusIcon from '../../images/PlusIcon.svg';
import { useModalContext } from '../../context/hooks';

const HeaderCounts = ({ propClass, changeCountDescription, setOpenProfileMenu }) => {
  const { updateModalState } = useModalContext();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handlerMobileWith = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handlerMobileWith);

    return () => window.removeEventListener('resize', handlerMobileWith);
  }, []);

  const navigateMyAssets = () => {
    if (isMobile) {
      setOpenProfileMenu(false);
      navigate('/my-assets');
    }
    navigate('/my-assets');
  };

  const toggleBuyCCRModal = () => {
    if (isMobile) {
      setOpenProfileMenu(false);
      updateModalState('buyCCRModal', {
        isOpen: true,
      });
    }
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  return (
      <StyledHeaderCounts className={propClass === 'profileMenu' ? 'profileMenu' : ''}>
          <HeaderCountsList className={'headerCountsList'}>
              <li onClick={navigateMyAssets}>
                  <CountImage className={'countImage'}>
                      <SaleIcon />
                  </CountImage>
                  <CountInfo className={'countInfo'}>
                      <CountTitle className={'countTitle'}>42,256 CCR</CountTitle>
                      <CountDescription>$58,987</CountDescription>
                  </CountInfo>
              </li>
              <li onClick={toggleBuyCCRModal}>
                  <CountImage className={'countImage'}>
                      <WalletIcon />
                      <span><PlusIcon /></span>
                  </CountImage>
                  <CountInfo className={'countInfo'}>
                      <CountTitle className={'countTitle'}>42,256 CCR</CountTitle>
                      {changeCountDescription
                        ? <CountDescription>$48,987</CountDescription>
                        : <CountDescription className={'blue'}>1 CCR = $0.22</CountDescription>
                      }
                  </CountInfo>
              </li>
              <li />
          </HeaderCountsList>
      </StyledHeaderCounts>
  );
};

export default HeaderCounts;
