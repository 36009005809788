import React, { useState } from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import {
  RadioLabel, InvestorsWrapper, Tabs, TabsResult,
} from './styles';
import ByAssetValue from './components/ByAssetValue';
import ByAssetGrowing from './components/ByAssetGrowing';
import InvestorsSearch from './components/InvestorsSearch';

const tokenPageTabs = [
  { id: 1, title: 'By asset value ' },
  { id: 2, title: 'By asset growing' },
];

const navigationBreadcrumbs = [
  { title: 'Investors',  destination: '/investors' },
];

const Investors = () => {
  const [selectedTab, setSelectedTab] = useState(tokenPageTabs[0]);

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <InvestorsWrapper>
              <h2>Top of Investors</h2>
              <InvestorsSearch />
              <Tabs>
                  {tokenPageTabs.map(tab => {
                    return (
                          <RadioLabel
                              key={tab.id}
                              className={tab.id === selectedTab.id ? 'checked' : ''}
                              onChange={() => {
                                setSelectedTab(tab);
                              }}
                          >{tab.title}
                              <input type='radio' name="tab" />
                          </RadioLabel>
                    );
                  })}
              </Tabs>
              <TabsResult>
                  {{
                    1: <ByAssetValue />,
                    2: <ByAssetGrowing />,
                  }[selectedTab.id]}
              </TabsResult>
          </InvestorsWrapper>
      </Container>
  );
};

export default Investors;
