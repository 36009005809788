import { gql } from '@apollo/client';

export const USERS = gql`
    query users($shift: Int) {
        users(shift: $shift) {
            edges {
                id
                address
                txHash
            }
            pageInfo {
                shift
            }
        }
    }
`;
