import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import SortArrow from '../../images/SortArrow.svg';
import RecentDealsIcon from '../../images/recent_deals_icon.png';
import tokenDefaultImage1 from '../../images/tableImages/table_img_1.png';
import tokenDefaultImage2 from '../../images/tableImages/table_img_2.png';
import tokenDefaultImage3 from '../../images/tableImages/table_img_3.png';
import { DealsTable } from './styles';
import TableSortArrow from '../../images/TableSortArrow.svg';
import { DefaultButton } from '../../components/Button/styles';
import TableTwoSortArrow from '../../images/TableTwoSortArrow.svg';
import { PageContainer } from '../../components/Container/styles';
import { smartTrim } from '../../utils/smartTrim';

const createData = (date, token, user, deals, popularity, hash) => {
  return {
    id : `${token}-${Math.random()}`, date, token, user, deals, popularity, hash,
  };
};

const rows = [
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenDefaultImage1, tokenName: 'Leonardo DiCaprio' },
    { image: RecentDealsIcon, userName: '@bryan' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '100,000,000', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '124,546,234',
    },
    { name: '0xd12222A8bE', link: '#' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenDefaultImage2, tokenName: 'Leonardo DiCaprio' },
    { image: RecentDealsIcon, userName: 'Bryan Hicks' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '100', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '124,546,234',
    },
    { name: '0xd12222A8bE', link: '#' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenDefaultImage3, tokenName: 'Leonardo DiCaprio' },
    { image: RecentDealsIcon, userName: '@bryan' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'outcome', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '100,000,000', currency: 'CCR', status: 'outcome', defaultCurrency: '$', defaultAmount: '124,546,234',
    },
    { name: '0xd12222A8bE', link: '#' }
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { image: tokenDefaultImage1, tokenName: 'Leonardo DiCaprio' },
    { image: RecentDealsIcon, userName: 'Bryan Hicks' },
    {
      amount: '1,300 ', currency: 'CCR', status: 'income', linkDestination: '#', linkName: '[TX]',
    },
    {
      amount: '100', currency: 'CCR', status: 'income', defaultCurrency: '$', defaultAmount: '124,546,234',
    },
    { name: '0xd12222A8bE', link: '#' }
  ),
];

const navigationBreadcrumbs = [
  { title: 'Deals',  destination: '/deals' },
];

const Deals = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <h2>Deals</h2>
              <DealsTable>
                  <thead>
                  <tr>
                      <th>
                          <DefaultButton className={'sort'}>
                              Date
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              NFT Token
                              <TableTwoSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              Investor
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              Deals
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort active'}>
                              Popularity
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                      <th>
                          <DefaultButton className={'sort'}>
                              TX hash
                              <TableSortArrow />
                          </DefaultButton>
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {rows.map(row => (
                      <tr key={row.id}>
                          <td><span>{row.date}</span></td>
                          <td>
                           <span className={'token'}>
                               <img src={row.token.image} alt={row.token.tokenName} />
                               {row.token.tokenName}
                           </span>
                          </td>
                          <td data-label='Investor'>
                           <span className={'user'}>
                               <img src={row.user.image} alt={row.user.userName} />
                               {row.user.userName}
                           </span>
                          </td>
                          <td data-label='Deals'>
                              <span className={row.deals.status === 'outcome' ? 'dealsOutcome' : 'dealsIncome'}>
                                  {row.deals.amount} {row.deals.currency}
                                  <Link to={row.deals.linkDestination} className={'dealsLink'}>{row.deals.linkName}</Link>
                              </span>
                          </td>
                          <td data-label='Popularity'>
                              <div>
                                  <span className={row.popularity.status === 'outcome' ? 'popularityOutcome' : 'popularityIncome'}>
                                      {row.popularity.amount} {row.popularity.currency} <SortArrow />
                                  </span>
                                  <span className={'popularityPrice'}>
                                      {row.popularity.defaultCurrency} {row.popularity.defaultAmount}
                                  </span>
                              </div>
                          </td>
                          <td data-label='TX hash'>
                              <Link to={row.hash.link} className={'hashLink'}>
                                  {smartTrim(row.hash.name, 8)}
                              </Link>
                          </td>
                      </tr>
                  ))}
                  </tbody>
              </DealsTable>
          </PageContainer>
      </Container>
  );
};

export default Deals;
