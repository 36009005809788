import React from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import { StyledValidation, ValidationDescription } from './styles';
import Button from '../../components/Button';
import WarningBlock from '../../components/WarningBlock';
import { useModalContext } from '../../context/hooks';

const navigationBreadcrumbs = [
  { title: 'Validation',  destination: '/validation' },
];

const Validation = () => {
  const { updateModalState } = useModalContext();

  const toggleConfirmValidatorModal = () => {
    updateModalState('confirmValidatorModal', {
      isOpen: true,
    });
  };

  const toggleInsufficientFundsModal = () => {
    updateModalState('insufficientFundsModal', {
      isOpen: true,
    });
  };

  const toggleCompletedValidatorModal = () => {
    updateModalState('completedValidatorModal', {
      isOpen: true,
    });
  };

  return (
      <StyledValidation>
          <Container direction={'column'}>
              <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
              <h2>Become a Popex NFT Validator</h2>
              <ValidationDescription>
                  Become a validator of the Popex NFT platform, check new tokens and start earning today!
                  The right to be our validator costs only <span>25 CCR</span>
              </ValidationDescription>
              <Button primary className={'becomeValidator'} onClick={toggleConfirmValidatorModal}>Become validator</Button>
              <Button primary className={'becomeValidator'} onClick={toggleInsufficientFundsModal}>Insufficient funds</Button>
              <Button primary className={'becomeValidator'} onClick={toggleCompletedValidatorModal}>Completed</Button>
              <WarningBlock
                  title='Validation process'
                  content='You will pass validation if 25 validators gave
                      correct answer. If after 50 attempts the validators
                      do not guess the name, then it is considered
                      that this is not a famous person and refuses to add him.
                      25.00 CCR that paid for validation is withheld in
                      benefit of the Cript NFT. If the validation is successful, then you will receive a notification that the name
                      validated and ready to run on Launchpad
                      and you can set a price within 10x within 24 hours or it is automatically assigned x4 of the initial investment.'
              />
          </Container>
      </StyledValidation>
  );
};

export default Validation;
