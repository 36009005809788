import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormButtons, Photo } from './styles';
import Button from '../../components/Button';
import Label from '../../components/Forms/Label';
import { FormError, FormGroupClear, FormInput } from '../../components/Forms/FormGroup/styles';
import FormGroup from '../../components/Forms/FormGroup';
import Input from '../../components/Forms/Input';
import PhotoIcon from '../../images/PhotoIcon.svg';
import EditIcon from '../../images/EditIcon.svg';
import { DefaultButton } from '../../components/Button/styles';
import Modal from '../../components/Modal';
import { ModalButtons, ModalTitle } from '../../components/Modal/styles';
import CropEditor from '../../components/CropEditor';
import DropzoneModal from '../../components/DropzoneModal';
import DropzoneButton from '../../components/DropzoneButton';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import { PageContainer } from '../../components/Container/styles';

const existingNicknames = ['Den', 'Viktor', 'Andrey'];
const navigationBreadcrumbs = [
  { title: 'Edit profile',  destination: '/edit-profile' },
];

const EditProfile = () => {
  const [nickname, setNickname] = useState('');
  const [nicknameDirty, setNicknameDirty] = useState(false);
  const [nicknameError, setNicknameError] = useState({
    message: 'Cannot be empty',
    status: 'error',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delaySubmit = () => {
    return new Promise((resolve => {
      setNicknameError(null);
      setTimeout(() => resolve('Success'), 3000);
    }));
  };

  const showNicknameError = useMemo(() => {
    return nicknameError && nicknameDirty;
  }, [nicknameError, nicknameDirty]);

  const nicknameHandler = (e) => {
    !nicknameDirty && setNicknameDirty(true);
    setNickname(e.target.value);

    if (!e.target.value) {
      setNicknameError({
        message: 'Cannot be empty',
        status: 'error',
      });
    } else {
      setNicknameError({
        message: 'Nickname is free',
        status: 'success',
      });
    }

    const isMatchNicknameHandler = existingNicknames.find((item) => item === e.target.value);

    if (isMatchNicknameHandler) {
      return setNicknameError({
        message: 'Error nickname',
        status: 'error',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNicknameDirty(true);

    if (nickname === '') {
      return setNicknameError({
        message: 'Cannot be empty',
        status: 'error',
      });
    }

    const isMatchHandleSubmit = existingNicknames.find((item) => item === nickname);

    if (isMatchHandleSubmit) {
      return setNicknameError({
        message: 'Error nickname',
        status: 'error',
      });
    }

    setLoading(true);
    delaySubmit().then(console.log).then(() => setLoading(false));
  };

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const toggleUploadModal = useCallback(() => setOpenUploadModal(prev => !prev), [setOpenUploadModal]);
  const toggleEditModal = useCallback(() => setOpenEditModal(prev => !prev), [setOpenEditModal]);
  const toggleCropModal = useCallback(() => setOpenCropModal(prev => !prev), [setOpenCropModal]);
  const [accountImage, setAccountImage] = useState('');
  const [files, setFiles] = useState([]);

  const handleUploadConfirm = useCallback(() => {
    toggleUploadModal();
    toggleCropModal();
  }, [toggleUploadModal, toggleCropModal]);

  const deleteEditModal = useCallback(() => {
    setAccountImage('');
    toggleEditModal();
  }, [setAccountImage, toggleEditModal]);

  const handleCancel = useCallback(() => {
    navigate('/my-assets');
  }, [navigate]);

  const handleEditImage = () => {
    if (accountImage) {
      toggleEditModal();
    } else toggleUploadModal();
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <h2>Edit profile</h2>
              <Form onSubmit={handleSubmit}>
                  <FormGroup>
                      <Photo>
                          {accountImage ? <img src={accountImage}/> : <PhotoIcon />}
                          <DefaultButton type={'button'} onClick={handleEditImage}>
                              <EditIcon />
                          </DefaultButton>
                      </Photo>
                      <FormGroupClear />
                  </FormGroup>
                  <FormGroup>
                      <Label htmlFor="nickname">Nickname</Label>
                      <FormInput>
                          <Input
                              value={nickname}
                              onChange={nicknameHandler}
                              name="nickname"
                              id="nickname"
                              type={'text'}
                              placeholder={'your_nickname'}
                              error={showNicknameError && nicknameError.status === 'error'}
                              disabled={loading ? 'disabled' : null}
                              mailIcon
                          />
                      </FormInput>
                      <FormGroupClear>
                          {showNicknameError && <FormError className={nicknameError.status === 'success' ? 'green' : null}>{nicknameError.message}</FormError>}
                      </FormGroupClear>
                  </FormGroup>
                  <FormButtons>
                      <Button
                          outlined
                          type={'button'}
                          onClick={handleCancel}
                      >
                          Cancel
                      </Button>
                      <Button
                          disabled={loading}
                          primary
                          type={'submit'}
                          align={'center'}
                      >
                          Save
                      </Button>
                  </FormButtons>
              </Form>

              {/* Upload Photo */}
              <Modal
                  isOpen={openUploadModal}
                  setIsOpen={setOpenUploadModal}
              >
                  <ModalTitle>Upload Photo</ModalTitle>
                  <DropzoneModal files={files} setFiles={setFiles} />
                  <ModalButtons>
                      <Button clasName={'disableConfirm'} primary disabled={!files.length} onClick={handleUploadConfirm}>Confirm</Button>
                      <Button outlined onClick={toggleUploadModal}>Cancel</Button>
                  </ModalButtons>
              </Modal>

              {/* Edit Photo */}
              <Modal
                  isOpen={openEditModal}
                  setIsOpen={setOpenEditModal}
              >
                  <ModalTitle>Edit Photo</ModalTitle>
                  <ModalButtons>
                      <DropzoneButton toggleEditModal={toggleEditModal} files={files} setFiles={setFiles} toggleCropModal={toggleCropModal} />
                      <Button delete onClick={deleteEditModal}>Delete photo</Button>
                      <Button outlined onClick={toggleEditModal}>Cancel</Button>
                  </ModalButtons>
              </Modal>

              {/* Cropp Photo */}
              <Modal
                  isOpen={openCropModal}
                  setIsOpen={setOpenCropModal}
                  modalName={'cropModal'}
              >
                  <ModalTitle>Edit Photo</ModalTitle>
                  <CropEditor toggleCropModal={toggleCropModal} setAccountImage={setAccountImage} imageToCrop={files[0]} />
              </Modal>
          </PageContainer>
      </Container>
  );
};

export default EditProfile;
