import styled from 'styled-components';

export const ControlBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    & button {
        width: 42px;
        height: 42px;
    }
    
    input {
        width: 100%;
        height: 3px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 90%;
        margin: 0 auto;
    }
`;

export const CropContainer = styled.div`
    margin-top: 24px;
    pointer-events: all;
    
    @media ${props => props.theme.device.md} {
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
`;
