import React from 'react';
import {
  ModalBody,
  ModalClose, ModalCongratulations, ModalDescription, ModalHeader, ModalTitle,
} from '../styles';
import Modal from '../index';
import ModalCongratulationsIcon from '../../../images/CongratulationsModalIcon.svg';
import CloseIcon from '../../../images/CloseIcon.svg';
import { useModalContext } from '../../../context/hooks';

const CongratulationsModal = () => {
  const { modals, updateModalState } = useModalContext();

  const toggleCongratulationsBuyCCRModal = () => {
    updateModalState('congratulationsBuyCCRModal', {
      ...modals.congratulationsBuyCCRModal,
      isOpen: false,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.congratulationsBuyCCRModal.isOpen}
              setIsOpen={toggleCongratulationsBuyCCRModal}
              modalName={'buyCCRModal congratulations'}
          >
              <ModalHeader>
                  <ModalTitle className={'buyCCRModal'}>Congratulations</ModalTitle>
                  <ModalClose>
                      <CloseIcon onClick={toggleCongratulationsBuyCCRModal} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody className={'congratulationsBuyCCR'}>
                  <ModalCongratulations><ModalCongratulationsIcon /></ModalCongratulations>
                  <ModalTitle className={'congratulations'}>Great!</ModalTitle>
                  <ModalDescription className={'congratulationsBuyCCR'}>
                      Your wallet was successfully filled with
                      <span>12.4342 CCR</span>
                  </ModalDescription>
              </ModalBody>
          </Modal>
      </>
  );
};

export default CongratulationsModal;
