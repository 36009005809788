import styled from 'styled-components';

export const ProfileInner = styled.a`
    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
    
    &:hover {
        & .name {
            color: ${props => props.theme.colors.primary};
        }
    }
    
    &.collectionItem {
        padding: 0;
        
        & .photo {
            margin-left: 0;
        }
        
        & .line {
            display: none;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        &.collectionItem {
            align-items: flex-start;
        
            & .description {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
`;

export const Photo = styled.div`
    width: 36px;
    height: 36px;
    margin: 0 8px 0 16px;
    
    & img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`;

export const Line = styled.div`
    width: 1px;
    height: 36px;
    background: ${props => props.theme.colors.cadetBlue} ;
    margin-left: 16px;
`;

export const Name = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
    
    &.new {
        & svg {
            margin-right: 4px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        font-size: ${props => props.theme.fontSize.f14};
        line-height: 20px;
    }
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 20px;
    white-space: nowrap;
    
    span {
        display: flex;
        align-items: center;
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 600;
        line-height: 20px;
        
        svg {
            width: 14px;
            height: 14px;
        }
    }
    
    span.income {
        color: ${props => props.theme.colors.mediumGreen};
        
        svg {
            fill: ${props => props.theme.colors.mediumGreen};
            transform: rotate(180deg);
        }
    }
    span.outcome {
        color: ${props => props.theme.colors.coralRed};
        
        svg {
            fill: ${props => props.theme.colors.coralRed};
        }
    }
`;
