import React, { useCallback } from 'react';
import { PopupMessageWindow } from './styles';

const PopupMessage = ({
  setIsOpen, children, modalName, timeoutId, isOpen,
}) => {
  const toggleModalWindowOpen = useCallback(() => {
    clearTimeout(timeoutId);
    return setIsOpen(prev => !prev);
  }, [timeoutId, setIsOpen]);

  return (
        <>
          <PopupMessageWindow className={modalName ? `${modalName}` : ''} isOpen={isOpen} onClick={toggleModalWindowOpen}>
            {children}
          </PopupMessageWindow>
        </>
  );
};

export default PopupMessage;
