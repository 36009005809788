import React, { useCallback, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  List, Menu, MenuDesc, MenuDescription, MenuImage, MenuName, MenuTop, Overlay,
} from './styles';
import LogoutIcon from '../../images/LogoutIcon.svg';
import HistoryIcon from '../../images/HistoryIcon.svg';
import ReferralsIcon from '../../images/ReferralsIcon.svg';
import StakingIcon from '../../images/StakingIcon.svg';
import ValidationIcon from '../../images/ValidationIcon.svg';
import CreateTokenIcon from '../../images/CreateTokenIcon.svg';
import SettingsIcon from '../../images/SettingsIcon.svg';
import NotificationsIcon from '../../images/NotificationsIcon.svg';
import MyAssetsIcon from '../../images/MyAssetsIcon.svg';
import { AuthContext } from '../../context';
import { DefaultButton } from '../Button/styles';
import accountDefaultImage from '../../images/accountDefaultImage.png';
import Button from '../Button';
import HeaderCounts from '../HeaderCounts';
import { useModalContext } from '../../context/hooks';

const ProfileMenu = ({ openProfileMenu, setOpenProfileMenu }) => {
  const { updateModalState } = useModalContext();
  const { setIsAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogOut = useCallback(() => {
    setIsAuth(false);
  }, [setIsAuth]);

  const onLinkClick = (callback) => () => {
    setOpenProfileMenu(false);
    callback?.();
  };

  const handleAccountSettingsLink = useCallback(() => {
    setOpenProfileMenu(false);
    navigate('/edit-profile');
  }, [setOpenProfileMenu, navigate]);

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  const handleBuyCCRLink = () => {
    setOpenProfileMenu(false);
    toggleBuyCCRModal();
  };

  return (
      <>
          <Overlay openProfileMenu={openProfileMenu} onClick={onLinkClick()} />
          <Menu openProfileMenu={openProfileMenu}>
              <MenuTop>
                  <MenuImage>
                      <img src={accountDefaultImage} alt={''} />
                      <DefaultButton onClick={handleAccountSettingsLink}><SettingsIcon /></DefaultButton>
                  </MenuImage>
                  <MenuDescription>
                      <MenuName>Nickname12345</MenuName>
                  </MenuDescription>
              </MenuTop>
              <MenuDesc>
                  <Button primary onClick={handleBuyCCRLink}>Buy CCR</Button>
                  <Button className={'currencyButton'} onClick={handleBuyCCRLink}>1 CCR = $0,22</Button>
              </MenuDesc>
              <HeaderCounts propClass={'profileMenu'} changeCountDescription setOpenProfileMenu={setOpenProfileMenu} />
              <List>
                  <li>
                      <NavLink className={'nav-link'} to="/my-assets" onClick={onLinkClick()}><MyAssetsIcon />My Assets</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/history" onClick={onLinkClick()}><HistoryIcon />History</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/referrals" onClick={onLinkClick()}><ReferralsIcon />Referrals</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/staking" onClick={onLinkClick()}><StakingIcon />Staking</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/token-validation" onClick={onLinkClick()}><ValidationIcon />Validation</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/create-token" onClick={onLinkClick()}><CreateTokenIcon />Create Token</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/" onClick={onLinkClick()}><NotificationsIcon />Notifications</NavLink>
                  </li>
                  <li>
                      <NavLink className={'nav-link'} to="/login" onClick={onLinkClick(handleLogOut)}><LogoutIcon />Log Out</NavLink>
                  </li>
              </List>
          </Menu>
      </>
  );
};

export default ProfileMenu;
