import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DropContainer, DropDescription, ThumbContainer,
} from './styles';
import Button from '../Button';
import ThumbImage from './ThumbImage';

const DropzoneModal = ({ files, setFiles, input }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      const reader = new FileReader();

      reader.onloadend = function () {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: reader.result,
        })));
        if (input?.onChange) {
          input.onChange(files);
        }
      };

      if (acceptedFiles[0]) {
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
        <>
            <DropContainer {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {files[0]
                  ? <ThumbContainer>
                        <ThumbImage file={files[0]} />
                    </ThumbContainer>
                  : <DropDescription>
                        Drag and drop image<br />
                        or
                    </DropDescription>
                }
                <Button primary type="button" onClick={open}>Choose photo</Button>
            </DropContainer>
        </>
  );
};

export default DropzoneModal;
