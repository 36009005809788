import React, { useState } from 'react';
import Modal from '../index';
import {
  ModalBody, ModalClose, ModalHeader, ModalTitle, ShareModalTabs, ShareRadioLabel,
} from '../styles';
import CloseIcon from '../../../images/CloseIcon.svg';
import EmbedIcon from '../../../images/shareIcons/EmbedIcon.svg';
import FacebookIcon from '../../../images/shareIcons/FacebookIcon.svg';
import InstagramIcon from '../../../images/shareIcons/InstagramIcon.svg';
import TwitterIcon from '../../../images/shareIcons/TwitterIcon.svg';
import MailIcon from '../../../images/shareIcons/MailIcon.svg';
import WhatsAppIcon from '../../../images/shareIcons/WhatsAppIcon.svg';
import TelegramIcon from '../../../images/shareIcons/TelegramIcon.svg';
import EmbedCopyBlock from './components/EmbedCopyBlock';
import InputCopyBlock from './components/InputCopyBlock';
import { useModalContext } from '../../../context/hooks';

const tabs = [
  {
    id: 1,
    image: <EmbedIcon />,
    title: 'Embed',
    embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/3OuPANsM6Zc" '
          + 'title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    id: 2, image: <FacebookIcon />, title: 'Facebook', link: 'https://criptccr.com/g3h34',
  },
  {
    id: 3, image: <InstagramIcon />, title: 'Instagram', link: 'https://criptccr.com/g3h34',
  },
  {
    id: 4, image: <TwitterIcon />, title: 'Twitter', link: 'https://criptccr.com/g3h34',
  },
  {
    id: 5, image: <MailIcon />, title: 'Mail', link: 'https://criptccr.com/g3h34',
  },
  {
    id: 6, image: <WhatsAppIcon />, title: 'WhatsApp', link: 'https://criptccr.com/g3h34',
  },
  {
    id: 7, image: <TelegramIcon />, title: 'Telegram', link: 'https://criptccr.com/g3h34',
  },
];

const ShareModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const toggleShareModal = () => {
    updateModalState('shareModal', {
      isOpen: !modals.shareModal.isOpen,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.shareModal.isOpen}
              setIsOpen={toggleShareModal}
              modalName={'share'}
          >
              <ModalHeader className={'share'}>
                  <ModalTitle className={'share'}>Share</ModalTitle>
                  <ModalClose className={'share'}>
                      <CloseIcon onClick={toggleShareModal} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody className={'share'}>
                  <ShareModalTabs>
                      {tabs.map(tab => {
                        return (
                              <ShareRadioLabel
                                  key={tab.id}
                                  className={tab.id === selectedTab.id ? 'checked' : ''}
                                  onChange={() => {
                                    setSelectedTab(tab);
                                  }}
                              >
                                  <div className={'image'}>{tab.image}</div>
                                  <div className={'title'}>{tab.title}</div>
                                  <input type='radio' name="tab" />
                              </ShareRadioLabel>
                        );
                      })}
                  </ShareModalTabs>
                  {{
                    1: <EmbedCopyBlock embed={tabs[0].embed} />,
                    2: <InputCopyBlock link={tabs[1].link} />,
                    3: <InputCopyBlock link={tabs[2].link} />,
                    4: <InputCopyBlock link={tabs[3].link} />,
                    5: <InputCopyBlock link={tabs[4].link} />,
                    6: <InputCopyBlock link={tabs[5].link} />,
                    7: <InputCopyBlock link={tabs[6].link} />,
                  }[selectedTab.id]}
              </ModalBody>
          </Modal>
      </>
  );
};

export default ShareModal;
