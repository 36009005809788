import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledNewTokensLaunches } from './styles';
import { members } from '../../../../utils/MOCK';
import {
  LeftSide, ListBottom, ListCard, ListTop, ListWrapper, MemberCardWrapper, RightSide,
} from '../../styles';
import Button from '../../../../components/Button';
import Container from '../../../../components/Container';
import TokenCardTimer from '../../../../components/Cards/TokenCardTimer';

const membersLaunches = members.slice(0, 5);

const NewTokensLaunches = () => {
  const navigate = useNavigate();

  const navigateToCatalogFilterPage = () => {
    navigate('/catalog-filter-page');
  };

  return (
      <>
          <StyledNewTokensLaunches>
              <Container direction={'column'}>
                  <ListWrapper>
                      <ListTop>
                          <LeftSide className={'newTokensLaunches'}>New Tokens Launches</LeftSide>
                          <RightSide>
                              <Button outlined className={'topSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                          </RightSide>
                      </ListTop>
                      <ListBottom>
                          <ListCard>
                              {membersLaunches.map(item => (
                                  <MemberCardWrapper key={item.id}>
                                      <TokenCardTimer {...item} />
                                  </MemberCardWrapper>
                              ))}
                          </ListCard>
                          <Button outlined className={'bottomSeeAllButton'} onClick={navigateToCatalogFilterPage}>See all</Button>
                      </ListBottom>
                  </ListWrapper>
              </Container>
          </StyledNewTokensLaunches>
      </>
  );
};

export default NewTokensLaunches;
