import styled, { css } from 'styled-components';

export const PopupMessageWindow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 100px;
    left: 50%;
    width: 260px;
    margin-left: -130px;
    padding: 16px;
    background: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;
    transform: translateY(-400%);
    transition: all 0.5s ease-out;
    z-index: 4;
    ${({ isOpen }) => {
    if (isOpen) {
      return css`
        transform: translateY(0);
      `;
    }
  }};
    
    @media ${props => props.theme.device.md} {
        width: 90%;
        padding: 40px 24px 24px;
    }
`;

export const PopupMessageTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    
    &.successMessage {
        color: ${props => props.theme.colors.mediumGreen};
    }
    
    &.errorMessage {
        color: ${props => props.theme.colors.coralRed};
    }
    
    & svg {
        margin-right: 8px;
    }
`;

