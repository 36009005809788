import styled from 'styled-components';

export const StyledHeader = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    background: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.lg} {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
`;

export const HeaderInfo = styled.div`
    display: ${({ isAuth }) => (isAuth ? 'flex' : 'none')};
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 80px;
    
    @media ${props => props.theme.device.lg} {
        flex-grow: 1;
    }
`;

export const HeaderButtons = styled.div`
    display: ${({ isAuth }) => (isAuth ? 'none' : 'flex')}; 
    flex-grow: 0;
    
    & button {
        height: 36px;
        font-size: ${props => props.theme.fontSize.f14};
        
        &.primary {
            width: 130px;
            margin-left: 20px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        margin-right: 55px;
        
        & button {
            &.primary {
                width: 100px;
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        & button {
            &.primary {
                width: 80px;
                margin-left: 10px;
            }
        }
    }
`;
