import styled from 'styled-components';

export const DropContainer = styled.div`
    text-align: center;
    width: 100%;
    margin: 24px auto 0;
    padding: 32px 0;
    border: 2px dashed ${props => props.theme.colors.mercury};
    border-radius: 16px;
    
    & button {
        width: 280px;
        height: 40px;
    }
    
    @media ${props => props.theme.device.md} {
        padding: 12px 0;
        
        & button {
            width: 240px;
        }
    }
    @media ${props => props.theme.device.xs} {
         & button {
            width: 180px;
        }
    }
`;

export const DropDescription = styled.div`
    margin-bottom: 12px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f24};
    line-height: 32px;
     
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;

export const ThumbContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
`;

export const Thumb = styled.div`
    display: inline-flex;
    width: 100px;
    height: 100px;
    
    & img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
