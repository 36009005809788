import React, { useCallback, useMemo, useState } from 'react';
import {
  CenterIcon, Content, FormBlock, FormButtonBlock, FormInfo, FormStep, LeftSide, RightSide,
  SecondStepInput, SidebarButtons, SidebarButtonsInner,
} from '../../styles';
import InfoIcon from '../../../../../images/TooltipIcon.svg';
import { FormError, FormGroupClear, FormInput } from '../../../../Forms/FormGroup/styles';
import Input from '../../../../Forms/Input';
import { CopyInputButton } from '../../../../Button/styles';
import CopyIcon from '../../../../../images/CopyIcon.svg';
import QRCodeIcon from '../../../../../images/QRCodeIcon.svg';
import TwoSideArrow from '../../../../../images/TwoSideArrow.svg';
import Button from '../../../../Button';
import Tooltip from '../../../../Tooltip';
import SidebarItem from '../SidebarItem';
import USDTIcon from '../../../../../images/buyCCRIcons/USDTIcon.svg';
import CollectionValueIcon from '../../../../../images/CollectionValueIcon.svg';
import { InputLabel } from '../../../../Forms/Label/styles';
import { InputBlock } from '../../../../Forms/Input/styles';
import { useModalContext } from '../../../../../context/hooks';

const DOT = () => {
  const { updateModalState } = useModalContext();
  const [address] = useState('1G2cm5Pgp5hxQyJgVt8PN4vN6AW2ardpQ2');

  const [swap, setSwap] = useState('');
  const [swapDirty, setSwapDirty] = useState(false);
  const [swapError, setSwapError] = useState('Cannot be empty');

  const showSwapError = useMemo(() => {
    return swapError && swapDirty;
  }, [swapError, swapDirty]);

  const nicknameHandler = (e) => {
    setSwap(e.target.value);
    if (!e.target.value) {
      setSwapError('Cannot be empty');
    } else {
      setSwapError('');
    }
  };

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case 'swap':
        setSwapDirty(true);
        break;
    }
  };

  const toggleConfirmationActionModal = () => {
    updateModalState('confirmationActionModal', {
      isOpen: true,
    });
  };

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSwapDirty(true);

    if (swap === '') {
      return setSwapError('Cannot be empty');
    } if (swap > 2000) {
      return setSwapError('More than balance');
    }
    toggleConfirmationActionModal();
    toggleBuyCCRModal();
  };

  const copyToClipboard = useCallback((pas) => () => {
    navigator.clipboard.writeText(pas).then();
  }, []);

  return (
        <>
            <SidebarButtons>
                <h3>Balance:</h3>
                <SidebarButtonsInner>
                    <SidebarItem image={<USDTIcon />} title='DOT' currency='$2,256' />
                </SidebarButtonsInner>
            </SidebarButtons>

            <Content>
                <form onSubmit={handleSubmit}>
                    <FormStep>
                        <h3>
                            1: Send DOT to this address
                            <Tooltip
                                tooltip='In the field above you will get your “12 word Password” in specific order.
                                        This information is private and must be kept secure.  This is the only way to access
                                        your wallet. If lost, recovery is not possible because we never store your Password on our servers.'
                            >
                                <InfoIcon />
                            </Tooltip>
                        </h3>
                        <FormBlock>
                            <LeftSide>
                                <InputLabel htmlFor="eth-address">
                                    Minimum deposit is currently 0.0022 DOT
                                    <Tooltip
                                        tooltip='In the field above you will get your “12 word Password” in specific order.
                                        This information is private and must be kept secure.  This is the only way to access
                                        your wallet. If lost, recovery is not possible because we never store your Password on our servers.'
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </InputLabel>
                                <FormInput>
                                    <InputBlock
                                        name="ethAddress"
                                        id="ethAddress"
                                        readOnly
                                    >
                                        {address}
                                    </InputBlock>
                                    <CopyInputButton type={'button'} onClick={copyToClipboard(address)}><CopyIcon /></CopyInputButton>
                                </FormInput>
                            </LeftSide>
                            <RightSide>
                                <QRCodeIcon />
                            </RightSide>
                        </FormBlock>
                    </FormStep>
                    <FormStep>
                        <h3>2: Swap DOT to CCR</h3>
                        <FormBlock>
                            <SecondStepInput >
                                <InputLabel htmlFor="swap">DOT to swap</InputLabel>
                                <FormInput>
                                    <Input
                                        name="swap"
                                        id="swap"
                                        type='number'
                                        preIcon={<USDTIcon />}
                                        placeholder={'3.63765 ETH'}
                                        maxInfo={'Max'}
                                        autoComplete='off'
                                        value={swap}
                                        onChange={nicknameHandler}
                                        onBlur={blurHandler}
                                        error={showSwapError}
                                    />
                                </FormInput>
                                <FormGroupClear>{showSwapError && <FormError>{swapError}</FormError>}</FormGroupClear>
                            </SecondStepInput>
                            <CenterIcon><TwoSideArrow /></CenterIcon>
                            <SecondStepInput >
                                <InputLabel htmlFor="buy">CCR to buy</InputLabel>
                                <FormInput>
                                    <Input
                                        name="buy"
                                        id="buy"
                                        type='number'
                                        preIcon={<CollectionValueIcon />}
                                        placeholder={'1543'}
                                        autoComplete='off'
                                    />
                                </FormInput>
                                <FormGroupClear />
                            </SecondStepInput>
                        </FormBlock>
                        <FormBlock>
                            <FormInfo>
                            <span>
                                DOT network fee
                                <Tooltip
                                    tooltip='In the field above you will get your “12 word Password” in specific order.
                                        This information is private and must be kept secure.  This is the only way to access
                                        your wallet. If lost, recovery is not possible because we never store your Password on our servers.'
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                                0.00000000 DOT ≈ $0.00 USD
                            </FormInfo>
                            <FormInfo>
                                <span>Total DOT  to swap</span>
                                0.00000000 DOT ≈ $0.00 USD
                            </FormInfo>
                            <FormButtonBlock>
                                <Button
                                    primary
                                    type={'submit'}
                                >
                                    Swap
                                </Button>
                            </FormButtonBlock>
                        </FormBlock>
                    </FormStep>
                </form>
            </Content>
        </>
  );
};

export default DOT;
