import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: flex;
    margin-bottom: 8px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    
    &.disabled {
        color: ${props => props.theme.colors.cadetBlue};
    }
`;

export const InputLabel = styled(StyledLabel)`
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: normal;
    line-height: 20px;
`;

export const ShareLabel = styled(StyledLabel)`
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const CheckboxLabel = styled(StyledLabel)`
    margin: 0 ;
    line-height: 20px;
    
    & a {
        font-size: ${props => props.theme.fontSize.f16};
        text-decoration: underline;
    }
`;

export const InvestLabel = styled(StyledLabel)`
    justify-content: space-between;
    align-items: center;
`;

export const SellLabel = styled(StyledLabel)`
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 500;
    line-height: 16px;
`;
