import React from 'react';
import { ModalCongratulations, ModalDescription, ModalTitle } from '../styles';
import Modal from '../index';
import ModalCongratulationsIcon from '../../../images/CongratulationsModalIcon.svg';
import { useModalContext } from '../../../context/hooks';

const CongratulationsModal = () => {
  const { modals, updateModalState } = useModalContext();

  const closeCongratulationsModal = () => {
    updateModalState('congratulationsModal', {
      ...modals.congratulationsModal,
      isOpen: false,
    });
  };

  return (
      <>
          <Modal
              isOpen={modals.congratulationsModal.isOpen}
              setIsOpen={closeCongratulationsModal}
              timeoutId={modals.congratulationsModal.timeoutId}
          >
              <ModalCongratulations><ModalCongratulationsIcon /></ModalCongratulations>
              <ModalTitle className={'congratulations'}>Congratulations</ModalTitle>
              <ModalDescription className={'congratulations'}>
                  {modals.congratulationsModal.congratulationsText}
              </ModalDescription>
          </Modal>
      </>
  );
};

export default CongratulationsModal;
