import styled from 'styled-components';

export const StyledMainMenu = styled.div`
    &.header {
        flex-grow: 1;
    }
    
    @media ${props => props.theme.device.lg} {
        &.header {
            overflow-y: auto;
            position: absolute;
            top: 90px;
            right: 45px;
            width: 327px;
            max-height: calc(100vh - 90px);
            z-index: 10;
            transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(200%)')};
            transition: transform 0.3s ease-in-out;
            
            & .list {
                flex-direction: column;
                flex-flow: column nowrap;
                padding: 10px 24px;
                background-color: ${props => props.theme.colors.white};
                border: 2px solid ${props => props.theme.colors.mercury};
                border-radius: 16px;
                box-shadow: ${props => props.theme.boxShadow.drop};
                
                li {
                    padding: 0;
                    line-height: 52px;
                    border-top: 1px solid ${props => props.theme.colors.mercury};
            
                    &:first-child {
                        border-top: none;
                    }
            
                    & a {
                        display: flex;
                        align-items: center;
                        color: ${props => props.theme.colors.primary};
            
                        & svg {
                            display: block;
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                        }   
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.header {
            right: 16px;
            
            & .list {
                display: block;
                margin-top: 0;
                column-count: auto;
            }
        }
        
        &.footer {
            width: 100%;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        &.header {
            width: 300px;
            right: 10px;
        }
    }
`;

export const List = styled.ul`
     display: flex;
     flex-flow: row nowrap;
     list-style: none;
        
     li {
         padding: 0 28px 0 0;
         line-height: 40px;
        
        &:last-child {
            padding-right: 0;
        }
        
        & a {
            display: block;
            color: ${props => props.theme.colors.secondary};
            font-weight: 500;
        
            & svg {
                display: none;
            }
        
            &:hover {
                color: ${props => props.theme.colors.clearBlue};
            }
        
            &.active {
                color: ${props => props.theme.colors.primary};
            }
        }
     }
     
     @media ${props => props.theme.device.md} {
        display: block;
        margin-top: 40px;
        column-count: 2;
     } 
`;
