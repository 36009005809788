import React from 'react';
import {
  InvestorsSelectContainer, InvestorsSelectTitle, InvestorsTable,
  InvestorsTableSelect, StyledInvestors,
} from './styles';
import { DefaultButton } from '../../../../components/Button/styles';
import TableSortArrow from '../../../../images/TableSortArrow.svg';
import TableTwoSortArrow from '../../../../images/TableTwoSortArrow.svg';
import accountDefaultImage from '../../../../images/accountDefaultImage.png';
import investorImage1 from '../../../../images/investors/investor_1.png';
import investorImage2 from '../../../../images/investors/investor_2.png';
import investorImage3 from '../../../../images/investors/investor_3.png';
import investorImage4 from '../../../../images/investors/investor_4.png';
import investorImage5 from '../../../../images/investors/investor_5.png';
import investorImage6 from '../../../../images/investors/investor_6.png';
import investorImage7 from '../../../../images/investors/investor_7.png';
import Select from '../../../../components/Select';
import RankIcon from '../../../../images/RankIcon.svg';
import LikeIcon from '../../../../images/LikeIcon.svg';
import RefreshIcon from '../../../../images/RefreshIcon.svg';
import Button from '../../../../components/Button';

const options = ['Name', 'Position', 'Invested', 'Shares', 'Asset Value'];

const createData = (investor, position, invested, vote, assetValue) => {
  return {
    id : `${investor}-${Math.random()}`, investor, position, invested, vote, assetValue,
  };
};

const rows = [
  createData(
    { image: accountDefaultImage, name: 'Bryan Hicks' },
    '1',
    { amount: '123,956', currency: 'CCR' },
    { status: 'up' },
    { amount: '123', currency: 'CCR' }
  ),
  createData(
    { image: investorImage1, name: 'Mary' },
    '2',
    { amount: '123,956', currency: 'CCR' },
    { status: 'down' },
    { amount: '12,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage2, name: 'Kery Lein' },
    '3',
    { amount: '123,956', currency: 'CCR' },
    { status: 'up' },
    { amount: '3,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage3, name: 'Mari Manson' },
    '4',
    { amount: '123,956', currency: 'CCR' },
    { status: 'up' },
    { amount: '23,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage4, name: 'Nikolas' },
    '5',
    { amount: '123,956', currency: 'CCR' },
    { status: 'up' },
    { amount: '1,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage5, name: 'Den Macklay' },
    '6',
    { amount: '123,956', currency: 'CCR' },
    { status: 'down' },
    { amount: '3,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage6, name: 'Kristin' },
    '7',
    { amount: '123,956', currency: 'CCR' },
    { status: 'down' },
    { amount: '12,956', currency: 'CCR' }
  ),
  createData(
    { image: investorImage7, name: 'Martin' },
    '8',
    { amount: '123,956', currency: 'CCR' },
    { status: 'down' },
    { amount: '2,956', currency: 'CCR' }
  ),
];

const Investors = () => {
  return (
       <StyledInvestors>
           <InvestorsSelectContainer>
               <InvestorsSelectTitle>Sort by</InvestorsSelectTitle>
               <InvestorsTableSelect>
                   <Select options={options} />
               </InvestorsTableSelect>
           </InvestorsSelectContainer>
           <InvestorsTable>
               <thead>
               <tr>
                   <th>Name</th>
                   <th>
                       <DefaultButton className={'sort'}>
                           Position
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
                   <th>
                       <DefaultButton className={'sort'}>
                           Invested
                           <TableTwoSortArrow />
                       </DefaultButton>
                   </th>
                   <th>
                       <DefaultButton className={'sort active'}>
                           Vote
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
                   <th>
                       <DefaultButton className={'sort'}>
                           Asset Value
                           <TableSortArrow />
                       </DefaultButton>
                   </th>
               </tr>
               </thead>
               <tbody>
               {rows.map(row => (
                   <tr key={row.id}>
                       <td className={'investor'}>
                           <img src={row.investor.image} alt={row.investor.name} />
                           {row.investor.name}
                       </td>
                       <td data-label='Position'>
                           <span className={`position ${row.position === '1' ? 'yellow' : row.position === '2' ? 'green' : row.position === '3' ? 'blue' : ''}`}>
                               <RankIcon />#{row.position}
                           </span>
                       </td>
                       <td data-label='Invested'>{row.invested.amount} {row.invested.currency}</td>
                       <td data-label='Vote'>{row.vote.status === 'up' ? <LikeIcon className={'up'} /> : <LikeIcon className={'down'} />}</td>
                       <td data-label='Asset Value'>
                           {row.assetValue.amount} {row.assetValue.currency}
                       </td>
                   </tr>
               ))}
               </tbody>
           </InvestorsTable>
           <Button outlined className={'more'}><RefreshIcon />Load More</Button>
       </StyledInvestors>
  );
};

export default Investors;
