import { gql } from '@apollo/client';

export const TOPS =  gql`
    query tops($tags: [String]!, $shift: Int, $limit: Int) {
        tops(tags: $tags, shift: $shift, limit: $limit) {
            pageInfo {
                total
                shift
            }
            edges {
                id
                uri
                tags
                txHash
            }
        }
    }
`;
