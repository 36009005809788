import styled from 'styled-components';

export const OnboardingSuccessInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: ${props => props.theme.colors.whiteSmoke};
    border-radius: 16px;
`;

export const OnboardingSuccessModalImage = styled.div`
    width: 100%;
    height: 350px;
    
    @media ${props => props.theme.device.lg} {
        height: 260px;
    }
    
    @media ${props => props.theme.device.md} {
         height: auto;
    }
`;

export const OnboardingSuccessModalContent = styled.div`
    text-align: center;
    max-width: 708px;
    padding: 12px 0 16px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    
    & h1 {
        margin-bottom: 12px;
        color: ${props => props.theme.colors.mediumGreen};
        letter-spacing: -0.02em;
    }
    
    @media ${props => props.theme.device.lg} {
        max-width: 538px;
    }
    
    @media ${props => props.theme.device.md} {
        max-width: 100%;
        padding: 0 16px 16px;
    }
`;

export const OnboardingSuccessModalButtons = styled.div`
    display: flex;
    margin-bottom: 44px;
    
    & button {
        width: 260px;
        
        &:first-child {
            margin-right: 26px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        margin-bottom: 16px;
        
        & button {
            width: 260px;
            margin: 0 auto;
        
            &:first-child {
                margin: 0 auto 16px;
            }
        }
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
        padding: 0 16px;
        
        & button {
            width: 100%;
        }
    }
`;

