import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalButtons, ModalDescription, ModalTitle } from '../styles';
import Button from '../../Button';
import Modal from '../index';
import { useModalContext } from '../../../context/hooks';

const BuySharesModal = () => {
  const { modals, updateModalState } = useModalContext();
  const navigate = useNavigate();

  const toggleBuySharesModal = () => {
    updateModalState('buySharesModal', {
      isOpen: !modals.buySharesModal.isOpen,
    });
  };

  const toggleOnboardingModal = () => {
    toggleBuySharesModal();
    updateModalState('onboardingModal', {
      isOpen: true,
    });
  };

  const handleLogIn = () => {
    toggleBuySharesModal();
    navigate('/login');
  };

  return (
      <>
          <Modal
              isOpen={modals.buySharesModal.isOpen}
              setIsOpen={toggleBuySharesModal}
          >
              <ModalTitle>Buy shares</ModalTitle>
              <ModalDescription>
                  In order to buy a share of tokens, please login or register
              </ModalDescription>
              <ModalButtons className={'flex'}>
                  <Button
                      outlined
                      onClick={handleLogIn}
                  >
                      Sign In
                  </Button>
                  <Button
                      primary
                      onClick={toggleOnboardingModal}
                  >
                      Sign Up
                  </Button>
              </ModalButtons>
              <Button
                  outlined
                  className={'closeThirdButton'}
                  onClick={toggleBuySharesModal}
              >
                  Close
              </Button>
          </Modal>
      </>
  );
};

export default BuySharesModal;
