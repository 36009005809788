import React from 'react';

const OnboardImageSuccess = ({ backgroundColor, circleColor }) => {
  return (
      <svg viewBox="0 0 854 350" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 16C0 7.16343 7.16344 0 16 0H838C846.837 0 854 7.16344 854 16V270.674C854 270.674 719.8 270.674 592.717 293.084C431.587 321.497 423.95 346.691 268.4 349.767C78.482 353.522 0 310.66 0 310.66V16Z" fill={backgroundColor} />
          <mask id="mask0_5223_149699" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="854" height="350">
              <path d="M0 16C0 7.16343 7.16344 0 16 0H838C846.837 0 854 7.16344 854 16V270.674C854 270.674 719.8 270.674 592.717 293.084C431.587 321.497 423.95 346.691 268.4 349.767C78.482 353.522 0 310.66 0 310.66V16Z" fill={backgroundColor} />
          </mask>
          <g mask="url(#mask0_5223_149699)">
              <rect x="57" y="22" width="402" height="402" rx="201" fill={circleColor} />
              <path d="M181.844 324.873C216.08 315.444 236.19 280.046 226.761 245.81C217.332 211.573 181.934 191.463 147.697 200.893C113.461 210.322 93.351 245.72 102.78 279.956C112.209 314.193 147.607 334.303 181.844 324.873Z" fill="white"/>
              <path d="M226.76 245.809C236.189 280.045 216.079 315.444 181.843 324.873C147.606 334.303 112.208 314.192 102.778 279.956C93.3491 245.719 113.46 210.321 147.696 200.892C181.932 191.462 217.331 211.573 226.76 245.809ZM107.613 278.624C116.307 310.191 148.944 328.733 180.511 320.039C212.078 311.345 230.62 278.707 221.926 247.141C213.232 215.574 180.594 197.032 149.027 205.726C117.46 214.42 98.9185 247.058 107.613 278.624Z" fill="#18B04D"/>
              <path d="M179.393 315.422C208.331 307.452 225.329 277.532 217.359 248.594C209.389 219.657 179.469 202.659 150.532 210.629C121.594 218.599 104.596 248.518 112.566 277.456C120.536 306.394 150.455 323.392 179.393 315.422Z" fill="url(#paint0_linear_5223_149699)"/>
              <path d="M160.623 275.272L181.172 239.096L186.74 242.256L163.027 283.997L137.983 269.769L141.144 264.205L160.623 275.272Z" fill="#18B04D"/>
              <path d="M342.492 233.281C342.576 233.102 342.681 232.938 342.804 232.788V232.787C342.896 232.676 342.995 232.574 343.105 232.481L343.562 232.019L343.683 232.107C346.016 230.965 350.837 232.175 355.71 235.358C361.601 239.205 365.386 244.503 364.294 247.316L364.459 247.439L363.805 248.093C363.774 248.125 363.74 248.159 363.709 248.19L363.69 248.209L363.685 248.211C363.517 248.363 363.328 248.494 363.118 248.605L363.113 248.607C360.808 249.815 355.938 248.613 351.01 245.395C345.008 241.476 341.195 236.053 342.491 233.281H342.492Z" fill="#FFA412"/>
              <path d="M356.411 234.659C350.409 230.74 344.491 229.811 343.193 232.582C341.896 235.354 345.709 240.778 351.711 244.696C357.712 248.614 363.632 249.546 364.929 246.772C366.228 244 362.415 238.578 356.411 234.659Z" fill="#FFB93E"/>
              <path d="M346.203 232.585C345.583 232.764 345.158 233.098 344.938 233.577C344.474 234.592 344.972 236.229 346.305 238.069C347.693 239.982 349.816 241.926 352.286 243.544C355.361 245.557 358.56 246.745 360.735 246.745C361.174 246.745 361.573 246.698 361.919 246.596C362.541 246.418 362.966 246.084 363.187 245.607C363.651 244.591 363.152 242.953 361.817 241.114C360.431 239.201 358.305 237.256 355.839 235.64C352.76 233.625 349.564 232.437 347.388 232.437C346.947 232.437 346.549 232.486 346.203 232.585H346.203ZM352.044 243.911C349.533 242.266 347.368 240.283 345.948 238.327C344.497 236.326 343.996 234.574 344.536 233.393C344.815 232.792 345.332 232.378 346.082 232.162C348.297 231.524 352.22 232.745 356.078 235.271C358.592 236.916 360.757 238.9 362.174 240.855C363.625 242.857 364.126 244.61 363.586 245.788C363.31 246.392 362.791 246.806 362.043 247.022C361.652 247.135 361.208 247.187 360.723 247.187C358.445 247.187 355.223 245.993 352.045 243.912L352.044 243.911Z" fill="#FFA412"/>
              <path d="M378.741 92.4595C378.805 92.331 378.875 92.2149 378.961 92.109V92.1079C379.031 92.0296 379.101 91.9572 379.181 91.8906L379.504 91.5623L379.589 91.6256C381.242 90.8143 384.666 91.674 388.128 93.9337C392.308 96.6648 394.997 100.425 394.219 102.423L394.337 102.511L393.875 102.974L393.805 103.042L393.789 103.056V103.058C393.671 103.166 393.537 103.26 393.381 103.338V103.34C391.744 104.197 388.288 103.343 384.789 101.059C380.529 98.2773 377.824 94.4269 378.741 92.4595H378.741Z" fill="#FFA412"/>
              <path d="M388.623 93.4354C384.368 90.6534 380.166 89.9938 379.243 91.9612C378.32 93.929 381.03 97.7797 385.291 100.561C389.552 103.343 393.754 104.004 394.677 102.035C395.595 100.068 392.89 96.2174 388.624 93.4354H388.623Z" fill="#FFB93E"/>
              <path d="M381.378 91.9642C380.938 92.0909 380.637 92.3282 380.482 92.6682C380.149 93.3878 380.503 94.5501 381.448 95.8568C382.435 97.2149 383.943 98.5955 385.698 99.7428C387.882 101.174 390.152 102.017 391.698 102.017C392.009 102.017 392.294 101.983 392.54 101.912C392.975 101.784 393.276 101.548 393.437 101.207C393.764 100.487 393.41 99.3246 392.465 98.0186C391.478 96.6605 389.969 95.2802 388.22 94.1326C386.036 92.7017 383.766 91.8583 382.22 91.8583C381.909 91.8583 381.625 91.8933 381.378 91.9639V91.9642ZM385.526 100.005C383.739 98.8365 382.204 97.4289 381.201 96.0406C380.165 94.6196 379.811 93.3755 380.197 92.5379C380.391 92.1113 380.761 91.8165 381.292 91.6636C382.865 91.2108 385.65 92.0767 388.387 93.8709C390.174 95.0386 391.709 96.4469 392.718 97.8351C393.748 99.2562 394.102 100.5 393.721 101.337C393.522 101.764 393.152 102.059 392.626 102.211C392.347 102.292 392.03 102.33 391.687 102.33C390.072 102.33 387.78 101.482 385.527 100.005L385.526 100.005Z" fill="#FFA412"/>
              <path d="M394.046 204.091C393.982 203.959 393.907 203.84 393.815 203.731L393.821 203.731L393.815 203.73C393.751 203.649 393.676 203.574 393.595 203.506L393.262 203.168L393.176 203.233C391.47 202.398 387.949 203.283 384.386 205.609C380.082 208.42 377.318 212.29 378.112 214.347L377.989 214.437L378.472 214.914C378.494 214.938 378.515 214.962 378.542 214.985L378.558 214.998V215.001C378.681 215.112 378.821 215.208 378.971 215.289L378.977 215.291C380.662 216.172 384.22 215.294 387.821 212.943C392.205 210.08 394.99 206.117 394.046 204.091L394.046 204.091Z" fill="#FFA412"/>
              <path d="M383.868 205.097C388.258 202.234 392.578 201.554 393.528 203.579C394.478 205.605 391.693 209.568 387.303 212.431C382.919 215.295 378.593 215.974 377.649 213.949C376.699 211.923 379.484 207.96 383.869 205.097L383.868 205.097Z" fill="#FFB93E"/>
              <path d="M390.466 203.474C388.878 203.474 386.543 204.342 384.295 205.814C382.491 206.996 380.935 208.417 379.921 209.815C378.949 211.16 378.584 212.355 378.923 213.097C379.084 213.447 379.395 213.691 379.846 213.822C380.103 213.894 380.393 213.93 380.715 213.93C382.303 213.93 384.638 213.062 386.887 211.59C388.695 210.408 390.246 208.988 391.255 207.59C392.231 206.245 392.597 205.049 392.258 204.308C392.097 203.957 391.786 203.714 391.335 203.584C391.078 203.51 390.788 203.475 390.466 203.475V203.474ZM380.726 214.253C380.372 214.253 380.044 214.212 379.76 214.13C379.212 213.973 378.832 213.67 378.633 213.231C378.236 212.369 378.601 211.088 379.663 209.626C380.699 208.196 382.282 206.747 384.118 205.546C386.935 203.699 389.806 202.808 391.422 203.273C391.969 203.431 392.35 203.734 392.554 204.173C392.946 205.036 392.581 206.316 391.518 207.779C390.482 209.208 388.899 210.656 387.064 211.859C384.74 213.379 382.389 214.253 380.726 214.253V214.253Z" fill="#FFA412"/>
              <path d="M344.355 125.458C344.392 125.378 344.44 125.305 344.492 125.238V125.238C344.533 125.188 344.578 125.143 344.628 125.102L344.83 124.896L344.882 124.935C345.919 124.428 348.063 124.966 350.228 126.38C352.85 128.09 354.529 130.444 354.043 131.696L354.12 131.75L353.828 132.041L353.784 132.084L353.776 132.092L353.773 132.093C353.699 132.161 353.616 132.219 353.522 132.269L353.519 132.27V132.27C352.495 132.806 350.33 132.272 348.139 130.842C345.473 129.1 343.778 126.69 344.355 125.457L344.355 125.458Z" fill="#FFA412"/>
              <path d="M350.539 126.069C347.873 124.327 345.241 123.914 344.664 125.146C344.086 126.378 345.781 128.789 348.45 130.531C351.119 132.273 353.749 132.686 354.326 131.454C354.903 130.222 353.208 127.811 350.539 126.069Z" fill="#FFB93E"/>
              <path d="M346.001 125.147C345.725 125.226 345.536 125.374 345.44 125.588C345.232 126.039 345.452 126.767 346.046 127.584C346.662 128.435 347.607 129.299 348.704 130.018C350.071 130.914 351.494 131.442 352.462 131.442C352.656 131.442 352.835 131.42 352.987 131.376C353.262 131.296 353.451 131.148 353.551 130.935C353.756 130.484 353.535 129.756 352.942 128.939C352.325 128.089 351.38 127.224 350.283 126.505C348.917 125.609 347.494 125.081 346.528 125.081C346.331 125.081 346.155 125.103 346.001 125.147V125.147ZM348.596 130.181C347.481 129.45 346.518 128.569 345.888 127.699C345.243 126.81 345.019 126.031 345.261 125.506C345.384 125.238 345.615 125.055 345.946 124.959C346.932 124.676 348.675 125.218 350.391 126.341C351.506 127.072 352.47 127.953 353.099 128.823C353.745 129.712 353.968 130.491 353.726 131.015C353.606 131.283 353.375 131.467 353.042 131.563C352.868 131.614 352.672 131.638 352.454 131.638C351.443 131.638 350.011 131.106 348.596 130.181Z" fill="#FFA412"/>
              <path d="M248.95 112.838C248.9 112.733 248.84 112.636 248.768 112.549V112.548C248.715 112.483 248.654 112.424 248.59 112.369L248.323 112.098L248.252 112.15C246.883 111.479 244.056 112.19 241.197 114.057C237.74 116.314 235.521 119.421 236.16 121.073L236.063 121.145L236.446 121.528C236.465 121.547 236.485 121.566 236.503 121.584L236.514 121.596L236.518 121.597C236.615 121.687 236.727 121.764 236.851 121.829L236.853 121.83V121.83C238.205 122.538 241.063 121.833 243.954 119.946C247.475 117.647 249.712 114.465 248.949 112.839L248.95 112.838Z" fill="#FFA412"/>
              <path d="M240.784 113.643C244.305 111.344 247.777 110.799 248.539 112.425C249.301 114.051 247.063 117.232 243.541 119.531C240.021 121.83 236.549 122.376 235.787 120.75C235.027 119.124 237.264 115.942 240.785 113.643H240.784Z" fill="#FFB93E"/>
              <path d="M246.079 112.341C244.802 112.341 242.926 113.038 241.122 114.22C239.672 115.168 238.427 116.309 237.613 117.431C236.831 118.511 236.538 119.471 236.811 120.067C236.939 120.348 237.19 120.544 237.553 120.649C237.756 120.707 237.991 120.736 238.249 120.736C239.526 120.736 241.4 120.039 243.206 118.857C244.653 117.908 245.899 116.768 246.714 115.645C247.496 114.566 247.789 113.605 247.517 113.01C247.386 112.729 247.138 112.533 246.773 112.428C246.57 112.37 246.337 112.341 246.078 112.341L246.079 112.341ZM238.256 120.995C237.97 120.995 237.711 120.962 237.481 120.897C237.042 120.77 236.738 120.527 236.576 120.175C236.258 119.482 236.553 118.454 237.404 117.28C238.236 116.132 239.506 114.969 240.98 114.004C243.243 112.522 245.545 111.806 246.844 112.18C247.284 112.307 247.588 112.55 247.751 112.903C248.068 113.595 247.775 114.623 246.923 115.797C246.091 116.944 244.82 118.108 243.347 119.073C241.482 120.294 239.591 120.995 238.256 120.995L238.256 120.995Z" fill="#FFA412"/>
              <path d="M211.631 68.7015C211.602 68.64 211.566 68.5843 211.525 68.5333L211.524 68.5325C211.493 68.4946 211.459 68.4598 211.421 68.4281L211.266 68.2703L211.224 68.3002C210.427 67.9099 208.781 68.3239 207.115 69.4109C205.103 70.7252 203.81 72.5344 204.183 73.4964L204.126 73.5381L204.35 73.7615L204.383 73.7945L204.389 73.8009L204.391 73.8019C204.448 73.854 204.513 73.8989 204.585 73.9368L204.586 73.9376C205.373 74.3497 207.037 73.9391 208.721 72.8398C210.771 71.5012 212.074 69.6488 211.63 68.7017L211.631 68.7015Z" fill="#FFA412"/>
              <path d="M206.875 69.1707C208.926 67.832 210.948 67.5144 211.391 68.4614C211.834 69.4082 210.531 71.2612 208.481 72.5998C206.431 73.9384 204.409 74.2561 203.966 73.3093C203.522 72.3625 204.825 70.5095 206.875 69.1707V69.1707Z" fill="#FFB93E"/>
              <path d="M209.955 68.4127C209.212 68.4127 208.12 68.8185 207.069 69.5066C206.225 70.0593 205.5 70.7235 205.026 71.3768C204.57 72.0054 204.4 72.5647 204.559 72.9113C204.634 73.075 204.779 73.1891 204.991 73.2501C205.11 73.2841 205.246 73.3006 205.396 73.3006C206.139 73.3009 207.231 72.8948 208.282 72.2067C209.126 71.6545 209.851 70.9903 210.325 70.337C210.781 69.7083 210.951 69.1491 210.792 68.8025C210.717 68.6383 210.572 68.5245 210.36 68.4635C210.242 68.4294 210.105 68.4129 209.955 68.4129L209.955 68.4127ZM205.401 73.4516C205.234 73.4516 205.083 73.4328 204.949 73.3946C204.694 73.321 204.516 73.1797 204.422 72.9738C204.237 72.5708 204.409 71.9722 204.904 71.2884C205.389 70.6203 206.128 69.9431 206.987 69.3808C208.305 68.5181 209.646 68.1011 210.402 68.3186C210.658 68.3923 210.835 68.5341 210.929 68.7394C211.114 69.1429 210.943 69.7411 210.447 70.4249C209.962 71.093 209.223 71.7707 208.365 72.3325C207.279 73.0435 206.178 73.4516 205.4 73.4516H205.401Z" fill="#FFA412"/>
              <path d="M245.317 81.5029C245.354 81.4241 245.4 81.3527 245.453 81.2879V81.2871C245.493 81.2389 245.537 81.1941 245.585 81.1535L245.785 80.952L245.836 80.9906C246.854 80.492 248.957 81.0206 251.084 82.4093C253.657 84.0876 255.307 86.3987 254.831 87.6268L254.902 87.6804L254.617 87.9653L254.574 88.0077L254.567 88.0156L254.563 88.0174C254.492 88.0837 254.409 88.1414 254.316 88.1894V88.1909L254.314 88.1907C253.31 88.7174 251.185 88.193 249.035 86.789C246.415 85.0792 244.751 82.7127 245.318 81.5034L245.317 81.5029Z" fill="#FFA412"/>
              <path d="M251.391 82.1045C248.773 80.3946 246.19 79.9893 245.624 81.1981C245.058 82.4079 246.721 84.7739 249.341 86.4842C251.959 88.1936 254.542 88.5999 255.108 87.3901C255.674 86.1808 254.011 83.8141 251.391 82.1045V82.1045Z" fill="#FFB93E"/>
              <path d="M246.936 81.2009C246.665 81.2789 246.479 81.4245 246.382 81.6336C246.179 82.0765 246.397 82.7908 246.98 83.5938C247.586 84.4288 248.511 85.277 249.588 85.9821C250.931 86.8613 252.327 87.38 253.276 87.38C253.468 87.38 253.643 87.3586 253.793 87.3152C254.064 87.2372 254.249 87.0916 254.346 86.8824C254.548 86.4395 254.331 85.7252 253.749 84.9223C253.142 84.0875 252.217 83.2393 251.138 82.5339C249.797 81.6547 248.401 81.1361 247.453 81.1361C247.26 81.1361 247.086 81.1574 246.936 81.2009H246.936ZM249.484 86.143C248.388 85.4249 247.443 84.5596 246.824 83.7064C246.19 82.833 245.972 82.0684 246.208 81.5538C246.329 81.2913 246.555 81.1107 246.883 81.0161C247.848 80.7382 249.561 81.2705 251.245 82.3728C252.34 83.0909 253.285 83.9564 253.905 84.8094C254.536 85.6828 254.757 86.4474 254.52 86.962C254.399 87.2244 254.174 87.4051 253.846 87.4996C253.676 87.5487 253.483 87.5723 253.271 87.5723C252.278 87.5723 250.871 87.0511 249.484 86.1432L249.484 86.143Z" fill="#FFA412"/>
              <path d="M514 423.619L138.928 425.56L53 426L91.1104 349.769L130.413 315.225L184.005 298.549L208.813 264.806H321.562L365.736 301.623L431.82 323.064L470.435 359.298L471.35 360.655L512.831 421.903L514 423.619Z" fill="#3772FF"/>
              <path d="M464.162 353.579H326.075L306.123 325.799L302.337 299.194L267.981 290.481L254.688 264.806H321.653L365.959 301.896L432.242 323.495L464.162 353.579Z" fill="#6392FF"/>
              <path d="M210.301 321.182L154.759 353.738L138.871 425.56L53 426L91.0849 349.769L130.361 315.225L183.918 298.549L208.708 264.806L210.301 321.182Z" fill="#2B58C2"/>
              <path d="M307.945 255.834L308.02 249.003L298.124 249.703L297.838 255.347C300.993 256.003 304.58 256.17 307.945 255.834H307.945Z" fill="#F0B288"/>
              <path d="M318.657 265.1C315.973 260.707 312.304 259.239 307.924 258.685L307.945 255.834C304.58 256.169 300.993 256.003 297.838 255.347L297.348 265.1H318.657V265.1Z" fill="#23262F"/>
              <path d="M258.821 256.499L257.916 258.567C253.536 259.124 249.616 261.482 246.018 265.1H266.783L268.992 256.524C265.447 257.127 262.065 257.083 258.821 256.499H258.821Z" fill="#23262F"/>
              <path d="M268.993 256.525L270.75 249.702L262.001 249.235L258.822 256.499C262.067 257.084 265.449 257.127 268.993 256.525V256.525Z" fill="#F0B288"/>
              <path d="M312.512 149.725C317.722 183.635 316.245 217.389 309.013 251.453H296.568C298.054 229.343 299.19 203.061 295.713 185.658L271.972 251.744H258.383L280.78 157.66L288.48 142.571L306.524 140.394L312.512 149.725H312.512Z" fill="#343945"/>
              <path d="M329.076 77.3981L332.421 67.3652L341.475 69.4796C342.157 72.8394 338.088 77.9363 335.065 81.7532L329.076 77.3981H329.076Z" fill="#F9B384"/>
              <path d="M298.55 113.208L288.125 113.329L287.09 99.6756L286.969 98.0425L297.44 97.9492L297.812 103.129L298.55 113.208Z" fill="#F0B288"/>
              <path d="M297.753 102.188C294.342 104.624 289.88 103.107 287.09 99.6756L286.969 98.0425L297.44 97.9492L297.753 102.188H297.753Z" fill="#E2915A"/>
              <path d="M295.321 101.044C290.03 102.253 284.124 98.8598 281.794 94.3532L281.479 86.2633L284.439 82.0645L297.036 83.149C296.977 83.1637 296.984 83.3393 297.036 83.6397C297.182 84.4238 297.666 86.0946 298.113 88.1099C299.212 93.0121 300.091 99.9514 295.321 101.043V101.044Z" fill="#F0B288"/>
              <path d="M286.457 108.039C286.457 108.039 294.235 114.261 301.001 105.395L313.834 148.013C305.976 153.566 296.044 155.484 284.125 153.925L286.457 108.039Z" fill="white"/>
              <path d="M293.156 114.863C291.355 114.863 289.567 114.325 288.125 113.327C289.202 113.716 290.303 113.952 291.403 114.072C291.82 114.114 292.238 114.137 292.654 114.137C293.335 114.137 294.013 114.076 294.678 113.94C295.743 113.732 296.798 113.397 297.765 112.862C298.274 112.637 298.717 112.297 299.201 112.01C299.679 111.71 300.095 111.328 300.579 111.008C300.222 111.451 299.85 111.897 299.441 112.307C299 112.676 298.586 113.099 298.073 113.386C297.111 114.062 295.973 114.468 294.819 114.706C294.269 114.812 293.713 114.863 293.156 114.863L293.156 114.863Z" fill="#BBC4DB"/>
              <path d="M328.727 75.5315C321.692 87.4135 314.828 97.8542 308.624 102.829C307.705 103.568 306.531 103.899 305.353 103.837C303.019 103.717 300.612 104.002 297.93 104.637C298.492 125.958 300.514 145.707 307.767 157.815C312.136 156.555 317.436 154.529 320.367 152.059C317.981 143.958 315.157 132.343 313.056 122.195L336.309 80.6644L328.726 75.5312L328.727 75.5315Z" fill="#2B58C2"/>
              <path d="M283.58 92.7942C284.561 91.5247 285.01 89.3915 284.126 85.0945C287.042 88.3943 291.378 89.165 297.735 86.3391C297.735 86.3391 301.87 84.7207 300.923 80.5061C300.067 76.6956 296.88 74.6734 294.39 77.7839C288.744 76.1363 283.191 75.84 280.703 80.9729C280.703 80.9729 272.771 85.0948 279.536 91.7833L283.579 92.7942H283.58Z" fill="#23262F"/>
              <path d="M347.432 74.4869L334.463 82.3487L333.883 77.7898L343.658 71.8633L347.432 74.4869Z" fill="#6F3428"/>
              <path d="M340.43 73.8198L337.105 75.8356L329.211 64.6146L334.134 61.6309L340.43 73.8198Z" fill="#FFA412"/>
              <path d="M334.802 61.2374L328.555 65.0243C327.156 65.872 325.347 65.5668 324.305 64.3062L313.852 51.6655L329.757 42.0234L336.131 57.1374C336.766 58.6445 336.2 60.3897 334.802 61.2374V61.2374Z" fill="#FFB93E"/>
              <path d="M334.034 53.2604L333.173 52.9019C335.573 47.1235 335.155 42.4289 333.393 41.3937C332.473 40.8552 331.203 41.4908 329.905 43.1361L329.172 42.5578C331.471 39.6447 333.219 40.2103 333.863 40.5887C336.012 41.8498 336.663 46.934 334.034 53.2604H334.034Z" fill="#FBC343"/>
              <path d="M321.589 60.8151C314.765 60.2189 310.558 57.2904 310.434 54.8032C310.398 54.0561 310.704 52.2451 314.349 51.5547L314.523 52.471C312.464 52.8618 311.313 53.6943 311.367 54.7567C311.467 56.7992 315.437 59.3402 321.67 59.8855L321.589 60.8151H321.589Z" fill="#FBC343"/>
              <path d="M329.022 51.421C328.476 51.421 327.816 50.9542 327.384 50.2069C326.836 49.2578 326.863 48.2168 327.446 47.8803C327.569 47.8087 327.708 47.7754 327.855 47.7754C328.401 47.7754 329.06 48.2422 329.492 48.9895C330.04 49.9381 330.013 50.9797 329.43 51.3158C329.306 51.387 329.168 51.4208 329.022 51.4208V51.421Z" fill="#FDE7B4"/>
              <path d="M341.468 69.2439C341.695 70.8608 340.673 72.3046 338.516 72.3046C337.17 72.3046 335.281 70.4565 334.886 68.2618C334.557 66.4521 335.252 65.2659 336.531 64.7386C337.842 64.1984 341.039 66.1929 341.468 69.2437V69.2439Z" fill="#F0B288"/>
              <path d="M311.126 44.8094L299.771 41.2313L300.211 39.834L311.568 43.4118L311.126 44.8094Z" fill="#FFB93E"/>
              <path d="M323.891 36.9395L322.449 36.6849L324.518 24.9609L325.959 25.2158L323.891 36.9395Z" fill="#FFB93E"/>
              <path d="M315.417 38.8634L308.998 28.8369L310.233 28.0469L316.65 38.0731L315.417 38.8634Z" fill="#FFB93E"/>
              <path d="M283.736 95.5948C280.559 96.7283 278.528 96.4717 278.359 93.4346C278.262 91.6917 280.057 90.3033 281.64 91.0397C282.327 91.3588 282.968 91.9644 283.58 92.7953L283.736 95.595L283.736 95.5948Z" fill="#F9B384"/>
              <path d="M334.756 69.7245C335.095 68.438 334.921 67.276 334.364 67.1291C333.809 66.9822 332.954 67.5839 332.615 68.8707C332.274 70.1575 331.884 72.011 332.759 72.4249C334.313 73.1585 334.416 71.0118 334.756 69.7245V69.7245Z" fill="#F9B384"/>
              <path d="M249.949 192.868L273.648 200.306C276.732 201.274 280.048 199.543 281.016 196.459L284.24 186.189C285.206 183.104 283.476 179.789 280.392 178.82L256.693 171.381C253.609 170.413 250.293 172.145 249.325 175.229L246.101 185.499C245.132 188.583 246.864 191.899 249.949 192.868V192.868Z" fill="#D13636"/>
              <path d="M262.908 173.302L271.374 175.961L272.07 173.743C272.188 173.362 271.975 172.954 271.594 172.833L269.468 172.165L265.568 170.941L264.516 170.611C264.443 170.589 264.371 170.579 264.3 170.579C263.99 170.578 263.701 170.777 263.604 171.086L262.908 173.302L262.908 173.302ZM281.119 185.202L277.857 195.591C277.225 197.601 275.6 199.041 273.691 199.533C273.058 199.699 272.393 199.76 271.721 199.7C271.721 199.7 271.721 199.7 271.72 199.7L271.718 199.7C271.3 199.666 270.877 199.584 270.46 199.453L246.804 192.026C243.709 191.054 241.971 187.728 242.944 184.631L246.202 174.241C247.176 171.146 250.504 169.409 253.598 170.381L261.745 172.938L262.441 170.721C262.761 169.697 263.857 169.126 264.879 169.446L265.773 169.727L270.729 171.282L271.959 171.67C272.454 171.823 272.86 172.165 273.102 172.626C273.342 173.089 273.389 173.614 273.234 174.109L272.537 176.326L277.257 177.806C277.444 177.864 277.624 177.931 277.799 178.007C277.801 178.009 277.803 178.009 277.803 178.009L277.805 178.009C279.597 178.773 280.848 180.361 281.249 182.179L281.249 182.181C281.463 183.152 281.436 184.189 281.119 185.203L281.119 185.202Z" fill="#F43F3F"/>
              <path d="M266.472 160.713C266.238 161.038 265.384 167.614 265.384 167.614C265.384 167.614 261.572 171.188 264.138 173.382C266.705 175.572 269.815 175.492 271.294 173.867C271.809 172.281 271.849 170.423 271.215 168.184L272.693 160.874L266.472 160.713L266.472 160.713Z" fill="#F0B288"/>
              <path d="M279.926 134.639L278.059 162.794C288.481 162.326 295.481 158.904 295.481 158.904C296.386 142.1 294.228 123.298 287.576 106.389C270.685 112.666 265.921 133.464 263.414 164.371L273.31 165.52L279.926 134.639H279.926Z" fill="#2B58C2"/>
              <path d="M279.925 134.639L278.059 162.794C288.481 162.326 295.481 158.904 295.481 158.904C296.385 142.1 294.228 123.298 287.575 106.389L279.925 134.639H279.925Z" fill="#3772FF"/>
              <path d="M306.469 103.774C306.103 103.834 305.728 103.854 305.353 103.835C303.019 103.714 300.612 104 297.93 104.635C298.492 125.956 300.514 145.705 307.767 157.813C312.136 156.553 317.436 154.527 320.367 152.057C318.107 144.384 315.455 133.561 313.396 123.816C308.187 118.441 309.42 115.796 307.374 109.567C306.821 107.886 306.531 105.911 306.469 103.773V103.774Z" fill="#3772FF"/>
              <path d="M313.388 155.899C317.523 187.734 315.808 219.457 309.014 251.452H296.57C298.056 229.342 299.191 203.061 295.715 185.657L295.608 185.954C296.233 176.289 295.586 169.127 295.586 156.602C295.614 155.838 295.635 155.07 295.651 154.299C298.997 154.033 302.14 153.412 305.077 152.432C305.883 154.359 306.777 156.159 307.769 157.815C309.561 157.298 311.51 156.652 313.388 155.899V155.899Z" fill="#777E90"/>
          </g>
          <defs>
              <linearGradient id="paint0_linear_5223_149699" x1="97.7561" y1="223.683" x2="183.632" y2="336.381" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#E9F0FF"/>
                  <stop offset="0.921569" stopColor="#F3F5FC"/>
                  <stop offset="1" stopColor="#F3F5FC"/>
              </linearGradient>
          </defs>
      </svg>
  );
};

export default OnboardImageSuccess;
