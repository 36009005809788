import styled from 'styled-components';
import sliderArrow from '../../images/SliderArrow.inline.svg';

export const FormMainFields = styled.div`
    display: flex;
    
    @media ${props => props.theme.device.lg} {
        justify-content: space-between;
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
    }
`;

export const FormInfoBlock = styled.div`
    width: 768px;
    margin-bottom: 40px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        margin-bottom: 16px;
    }
`;

export const FormOptionsBlock = styled.div`
     width: 332px;
     margin-left: 36px;
     
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin-left: 0;
    }
    
    @media ${props => props.theme.device.xs} {
        width: 100%;
    }
`;

export const EditTokenTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const RightEditToken = styled.div`
    display: flex;
    align-items: center;

    .button-prev,
    .button-next {
        display: flex;
        justify-content: center;
        align-items: center;
        position: inherit;
        width: 40px;
        height: 40px;
        border: 2px solid ${props => props.theme.colors.cadetBlue};
        border-radius: 40px;
            
        &:hover {
            background: ${props => props.theme.colors.white};
        }
    }
    
    .button-prev {
        margin-right: 10px;
        border: none;
    }
        
    .button-next {
        margin-left: 10px;
        transform: rotate(-180deg);
    }
    
    .button-next:after,
    .button-prev:after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('${sliderArrow}');
    }
`;

export const FormTabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;    
    
    @media ${props => props.theme.device.md} {
        overflow-x: scroll;
        flex-wrap: nowrap;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const RadioLabel = styled.label`
    margin: 0 12px 12px 0;
    padding: 6px 12px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
    }
`;

export const FormPhotoBlock = styled.div`
    width: 100%;
    height: 390px;
    padding: 26px 16px;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter}; 
    
    &.picture {
        height: 450px;
    }
    
    @media ${props => props.theme.device.md} {
        height: auto;
        padding: 26px 16px 16px 16px;
        
         &.picture {
            height: auto;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        width: 100%;
    }
`;

export const FormDateBlock = styled.div`
    width: 100%;
    margin-top: 16px;
    padding: 26px 16px 0;
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter}; 
    
    @media ${props => props.theme.device.xs} {
        width: 100%;
    }
`;

export const FormBottomFields = styled.div`
    width: 768px;
    
    &.editPage {
        display: flex;
        justify-content: space-between;
    }
    
    button.decline {
        width: 140px;
    }
    
    button.createToken {
        width: 260px;
        margin-top: 24px;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        padding-top: 26px;
        
        &.editPage {
            flex-direction: column;
        }
        
        button.decline {
            order: 2;
            width: 344px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        button.decline {
            width: 100%;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        button.createToken {
            width: 100%;
        }
    }
`;

export const FormBottomRight = styled.div`
    display: flex;
    
    & button {
        width: 180px;
        height: 48px;
        
        &:hover {
            color: ${props => props.theme.colors.white};
            background: ${props => props.theme.colors.clearBlue};
        }
        
        &:first-child {
            margin-right: 26px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        order: 1;
        margin-bottom: 16px;
        
        & button {
            width: 158px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & button {
            max-width: 228px;
            width: 100%;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        flex-direction: column;
    
        & button {
            max-width: 100%;
            width: 100%;
            
            &:first-child {
                margin: 0 0 16px 0;
            }
        }
    }
`;
