import styled from 'styled-components';

export const StyledValidationCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    padding: 16px;
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    box-sizing: border-box;
    border-radius: 14px;
    
    @media ${props => props.theme.device.lg} {
        width: 332px;
        flex-direction: column;
        justify-content: unset;
        margin: 0 3px 16px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin: 0 0 16px;
    }
`;

export const ValidationCardTitle = styled.div`
    margin-top: 16px;
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    line-height: 16px;

    &.black {
        color: ${props => props.theme.colors.primary};
    }
    
    &:first-child {
        margin-top: 0;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-top: 8px;
        font-size: ${props => props.theme.fontSize.f12};
        
        &:first-child {
            margin-top: 8px;
        }
        
        &.creationDate {
            margin-top: 0;
        }
    }
`;

export const ValidationCardLeft = styled.div`
    display: grid;
    grid-template-areas: 'validation-card-photo validation-card-info' 'validation-card-photo validation-card-tags';
    justify-content: start;
    
    @media ${props => props.theme.device.lg} {
        grid-template-areas: 'validation-card-photo validation-card-info' 'validation-card-tags validation-card-tags';
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
    }
`;

export const ValidationCardPhoto = styled.div`
    grid-area: validation-card-photo;
    width: 220px;
    height: 220px;
    margin-right: 16px;
    border-radius: 16px;

    & img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 144px;
        height: 144px;
        margin-right: 6px;
    }
`;

export const ValidationCardInfo = styled.div`
    max-width: 230px;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    word-break: break-all;
    
    & .name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        word-break: break-all;
    }
    
    & .link {
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
         & svg {
            width: 16px;
            height: 16px;
            margin-left: 6px;
            fill: ${props => props.theme.colors.clearBlue};
         }
    }
    
    @media ${props => props.theme.device.lg} {
        font-size: ${props => props.theme.fontSize.f14};
        
        & .name {
            font-size: ${props => props.theme.fontSize.f14};
        }
        
        & .link {
            -webkit-line-clamp: 2;
            font-size: ${props => props.theme.fontSize.f14};
            line-height: 16px;
            
            & svg {
                display: none;
            }
        }
    }
`;

export const ValidationCardTags = styled.div`
    grid-area: validation-card-tags;
    max-width: 230px;
    
    @media ${props => props.theme.device.lg} {
        max-width: 100%;
    }
`;

export const ValidationCardTagsWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    
    & button {
        margin-right: 8px;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        padding-bottom: 8px;
    
        & button {
            height: 26px;
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;

export const ValidationCardCenter = styled.div`
    max-width: 390px;
    
    @media ${props => props.theme.device.lg} {
        max-width: 100%;
    }
`;

export const ValidationCardCenterTop = styled.div`
    display: flex;
    justify-content: space-between;
    
    @media ${props => props.theme.device.lg} {
        flex-direction: column;
        justify-content: inherit;
    }
`;

export const ValidationCardAuthor = styled.div`
    display: flex;
    margin-top: 6px;
    align-items: center;
    font-weight: 500;
    
    & img {
        width: 32px;
        height: 32px;
        margin-right: 6px;
    }
    
    @media ${props => props.theme.device.lg} {
        padding-bottom: 8px;
    }
`;

export const ValidationCardSelect = styled.div`
    width: 140px;
    height: 30px;
    margin-top: 6px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        height: 36px;
    }
    
    @media ${props => props.theme.device.md} {
        width: 320px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
    }
`;

export const ValidationCardDescription = styled.div`
    line-height: 20px;
    
    @media ${props => props.theme.device.lg} {
        padding-bottom: 8px;
    }
`;

export const ValidationCardAmount = styled.div`
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const ValidationCardRight = styled.div`
    display: flex;
    flex-direction: column;
    
    & button {
        width: 230px;
        margin-top: 16px;
        
        &:first-child {
            margin-top: 0;
        }
        
        &:nth-child(2) {
            &:hover {
                box-shadow: ${props => props.theme.boxShadow.hoverButton};
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        margin-top: 16px;
    
        & button {
            width: 100%;
            
            &:first-child {
                margin-top: 0;
            }
        }
        
        @media ${props => props.theme.device.md} {
            & button {
                width: 310px;
            }
        }
        
        @media ${props => props.theme.device.sm} {
            & button {
                width: 100%;
            }
        }
    }
`;
