import styled from 'styled-components';

export const ModalOverlay = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.overlay};
    z-index: 3;
    
    & .modalWindow {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 50%;
        left: 50%;
        width: 520px;
        padding: 24px 40px;
        background: ${props => props.theme.colors.white};
        border: 2px solid ${props => props.theme.colors.mercury};
        border-radius: 16px;
        transform: translate(-50%, -50%);
        z-index: 4;
        
        &.tokenModal {
            padding: 0;
            border: none;
        }
        
        &.tokenModalLarge {
            top: 50px;
            margin-left: -260px;
            padding: 0;
            border: none;
            transform: none;
        }
        
        &.share {
            top: 50px;
            width: 678px;
            margin-left: -339px;
            padding: 0;
            border: 0;
            box-shadow: ${props => props.theme.boxShadow.tooltip};
            transform: none;
        }
        
        &.buyCCRModal {
            top: 50px;
            width: 1100px;
            height: 605px;
            margin-left: -550px;
            padding: 0;
            border: none;
            transform: none;
        }
        
        &.onboardingModalSmall {
            top: 50px;
            margin-left: -260px;
            padding: 0;
            border: none;
            transform: none;
        }
        
        &.onboardingLarge {
            top: 50px;
            width: 906px;
            margin-left: -453px;
            padding: 0;
            border: none;
            transform: none;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        & .modalWindow {
            &.tokenModalLarge {
                top: 20px;
            }
        
            &.buyCCRModal {
                top: 20px;
                width: 678px;
                height: 828px;
                margin-left: -339px;
            }
            
            &.onboardingModalSmall {
                top: 20px;
            }
            
            &.onboardingLarge {
                top: 20px;
                width: 678px;
                margin-left: -339px;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        & .modalWindow {
            width: 90%;
            padding: 26px 26px;
            
            &.tokenModalLarge {
                left: 5%;
                margin-left: 0;
            }
            
            &.share,
            &.buyCCRModal {
                left: 5%;
                width: 90%;
                height: auto;
                margin-left: 0;
                
                &.congratulations {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            
            &.onboardingModalSmall {
                left: 50%;
                width: 90%;
                margin-left: 0;
                transform: translate(-50%, 0);
            }
            
            &.onboardingLarge {
                left: 5%;
                width: 90%;
                margin-left: 0;
            }
            
            &.onboardingModalSmall {
                width: 327px;
            }
        }
        
        .cropModal {
            background: transparent;
            border: none;
            pointer-events: none;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & .modalWindow {
            padding: 26px 16px 16px;
        }
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    position: relative;
    padding: 26px;
    background: ${props => props.theme.colors.blueGradient};
    border-radius: 16px 16px 0px 0px;
    
    &.share {
        padding: 16px;
    }
    
    &.onboardingLarge {
        background: ${props => props.theme.colors.clearBlue};
    }
    
    @media ${props => props.theme.device.md} {
        padding: 16px;
    }
`;

export const ModalHeaderPhoto = styled.div`
    width: 62px;
    height: 62px;
    margin-right: 16px;
    
    & img {
        width: 62px;
        height: 62px;
    }
`;

export const ModalHeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    
    @media ${props => props.theme.device.xs} {
        justify-content: center;
    }
`;

export const ModalHeaderName = styled.div`
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 16px;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 26px 26px;
    
    &.share {
        padding: 0 16px 16px;
        
        & button.copyCode {
            width: 220px;
            margin: 26px auto 0 0;
        }
    }
    
    &.onboardingLarge {
        padding: 26px;
    }
    
     &.congratulationsBuyCCR {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
     }
    
    @media ${props => props.theme.device.md} {
        padding: 0 16px 16px;
        
        &.onboardingLarge {
            padding: 16px;
        }
        
        &.congratulationsBuyCCR {
            margin-bottom: 26px;
            padding: 0 16px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        &.share {
            & button.copyCode {
                width: 100%;
                margin: 26px 0 0;
            }
        }
    }
`;

/* Onboarding Modal */
export const OnboardingModalBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 44px 40px;
    
    & button {
        width: 132px;
        
        & .getStarted {
            width: 146px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin: 0 26px 26px;
    }
    
    @media ${props => props.theme.device.xs} {
        margin: 0 8px 26px;
    }
`;

export const OnboardingModalTabs = styled.div`
    display: flex;
`;

export const OnboardingModalRadioLabel = styled.label`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background: ${props => props.theme.colors.mercury};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
        margin-right: 0;
    }
    
    &.checked {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.clearBlue};
        
        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: ${props => props.theme.colors.clearBlue};
            border-radius: 100px;
        }
    }
`;

export const EnterForm = styled.form`
    padding: 26px 32px;
    background: ${props => props.theme.colors.whiteSmoke};
    border-radius: 16px;

    @media ${props => props.theme.device.md} {
        padding: 24px 16px 16px;
    }
`;

export const ModalCongratulations = styled.div`
    width: 90px;
    height: 90px;
    margin: 26px auto 16px;
    border-radius: 1000px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    & svg {
        width: 100%;
        height: 100%;
    }
`;

export const ModalTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 32px;
    
    & span.confirmation {
        font-size: ${props => props.theme.fontSize.f24};
        font-weight: 600;
        line-height: 32px;
    }
    
    &.congratulations {
        justify-content: center;
        color: ${props => props.theme.colors.mediumGreen};
    }
    
    &.onboardingLarge {
        color: ${props => props.theme.colors.white};
    }
    
     &.confirmCreationModalTitle {
        justify-content: space-between;
    }
    
    @media ${props => props.theme.device.sm} {
        & span.confirmation {
            display: none;
        }
    
        &.share,
        &.buyCCRModal,
        &.onboardingLarge {
            line-height: 24px;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f20};
        line-height: 24px;
    }
`;

export const ModalTag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 76px;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    line-height: 32px;
    background: ${props => props.theme.colors.tuna};
    border-radius: 100px;
    
    @media ${props => props.theme.device.md} {
        display: none;
    }
`;

export const ModalClose = styled.div`
    position: absolute;
    top: 26px;
    right: 26px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    
    &.share {
        top: 16px;
        right: 16px;
    }

    & svg {
        width: 100%;
        height: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        top: 16px;
        right: 16px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 24px;
        height: 24px;
    }
`;

export const ModalDescription = styled.div`
    width: 100%;
    margin-top: 6px;
    color: ${props => props.theme.colors.secondary};
    line-height: 20px;
    word-wrap: break-word;
    
    & span {
        color: ${props => props.theme.colors.primary};
    }
    
    &.congratulations {
        text-align: center;
        color: ${props => props.theme.colors.primary};
    }
    
    &.congratulationsBuyCCR {
        text-align: center;
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
        
         & span {
            display: block;
            color: ${props => props.theme.colors.mediumGreen};
            font-size: ${props => props.theme.fontSize.f20};
            font-weight: 600;
            line-height: 32px;
         }
    }
`;

export const EmotionBlock = styled.div`
    margin-top: 26px;
    
    &.gray {
        padding: 16px;
        background: ${props => props.theme.colors.whiteSmoke};
        border-radius: 8px;
        box-shadow: none;
    }
    
    &.warning {
        margin-top: 16px;
        padding: 16px;
        color: ${props => props.theme.colors.mediumGreen};
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 16px;
        border: 1px dashed ${props => props.theme.colors.mediumGreen};
        border-radius: 12px;
    }
    
    & label {
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: 500;
        line-height: 16px;
    }
`;

export const EmotionBlockTitle = styled.div`
    margin-bottom: 12px;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
`;

export const EmotionBlockTop = styled.div`
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.colors.cadetBlue};
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
    }
`;

export const EmotionBlockTopInfo = styled.div`
    padding-left: 44px;

    &:first-child {
        padding-right: 44px;
        padding-left: 0;
        border-right: 1px solid ${props => props.theme.colors.cadetBlue};
    }
    
    @media ${props => props.theme.device.md} {
        padding-left: 0;
    
        &:first-child {
            padding-right: 0;
            padding-bottom: 16px;
            border-right: none;
            border-bottom: 1px solid ${props => props.theme.colors.cadetBlue};
        }
        
        &:last-child {
            padding-top: 16px;
        }
    }
`;

export const EmotionAssetsBlock = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 16px 0;
    border: 1px solid ${props => props.theme.colors.mercury};
    border-radius: 8px;
    
    &.purchase {
        flex-direction: column;
        padding: 16px;
    }
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
        justify-content: inherit;
    }
`;

export const EmotionBlockPurchaseTop = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
    }
`;

export const EmotionBlockPurchaseItem = styled.div`
    &.line {
        width: 1px;
        margin: 0 32px;
        background: ${props => props.theme.colors.mercury};
    }

    & .title {
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 20px;
    }
    
    & .text {
        display: flex;
        align-items: center;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 600;
        line-height: 24px;
        
        & svg {
            width: 15px;
            height: 15px;
            margin-right: 6px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 45%;
        
        &.line {
            margin: 0 20px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
        
        &:first-child {
            padding-bottom: 16px;
            border-bottom: 1px solid ${props => props.theme.colors.mercury};
        }
        
         &:last-child {
            padding-top: 16px;
        }
        
        &.line {
            display: none;
        }
    }
`;

export const EmotionBlockPurchaseBottom = styled.div`
    padding-top: 16px;
    
    & .title {
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 20px;
    }
    
    & .text {
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 20px;
    }
`;

export const EmotionAssetsItem = styled.div`
    display: flex;
    padding: 0 8px;
    
    & svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        
        &.down {
            fill: ${props => props.theme.colors.coralRed};
            transform: rotate(180deg);
        }
        
        &.up {
            fill: ${props => props.theme.colors.mediumGreen};
        }
    }
    
    @media ${props => props.theme.device.sm} {
        margin-bottom: 16px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const EmotionAssetsLine = styled.div`
    width: 1px;
    background: ${props => props.theme.colors.mercury};
    
    @media ${props => props.theme.device.sm} {
        display: none;
    }
`;

export const EmotionAssetsItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    
    & .title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 2px;
        color: ${props => props.theme.colors.secondary};
        font-weight: 500;
        line-height: 16px;
        word-break: break-all;
    }
    
    & .value {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: 600;
        line-height: 16px;
        word-break: break-all;
    }
    
    & .currencyTitle {
        margin-top: 6px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f10};
        line-height: 14px;
        word-break: break-all;
    }
    
    & .currency {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 16px;
        word-break: break-all;
        
        &.black {
            color: ${props => props.theme.colors.primary};
        }
    }
`;

export const VoteBlock = styled.div`
    display: flex;
    margin-top: 16px;
    
    @media ${props => props.theme.device.md} {
        justify-content: space-between;
    }
`;

export const VoteBlockIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 40px;
    background: ${props => props.theme.colors.white};
    border-radius: 8px;
    cursor: pointer;
    
    & svg {
        width: 19px;
        height: 20px;
    }
    
    &.down {
        margin-right: 16px;
        
        &.active {
            border: 1px solid ${props => props.theme.colors.coralRed};
        }
        
        & svg {
            stroke: ${props => props.theme.colors.primary};
            transform: rotate(180deg);
            
            &.down {
                fill: ${props => props.theme.colors.coralRed};
                stroke: none;
            }
        }
    }
    
    &.up {
        &.active {
            border: 1px solid ${props => props.theme.colors.mediumGreen};
        }
    
        & svg {
            stroke: ${props => props.theme.colors.primary};
            
            &.up {
                fill: ${props => props.theme.colors.mediumGreen};
                stroke: none;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 120px;
    }
`;

export const EmotionTopDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmotionTopDesc = styled.div`
    margin-bottom: 6px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 16px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    & span {
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 500;
        line-height: 16px;
    }
`;

export const Shareholders = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 16px;
    margin-top: 10px;
`;

export const ShareholdersInner = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 16px;
    
    &.disabled {
        display: none;
    }
    
    & span {
        margin-left: 4px;
        font-size: ${props => props.theme.fontSize.f12};
        line-height: 16px;
    }
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f10};
    
        & span {
            font-size: ${props => props.theme.fontSize.f10};
        }
    }
`;

export const ModalForm = styled.form`
    overflow: hidden;
    position: relative;
    width: 100%;
    
    &.onboardingLarge {
        background: ${props => props.theme.colors.whiteSmoke};
        border-radius: 16px;
    }
`;

export const ModalFormYouHave = styled.div`
    float: left;
    margin-top: 2px;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    line-height: 1;
    
    &.disabled {
        display: none;
    }
    
    & span {
        margin-left: 2px;
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 500;
    }
`;

export const ModalFormAddFunds = styled.div`
    float: right;
    margin-top: 2px;
    line-height: 1;
    
    &.disabled {
        & button {
            color: ${props => props.theme.colors.cadetBlue};
        }
    }
    
    & button {
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f12};
        font-weight: 500;
        line-height: 1;
    }
`;

/* Range */
export const ModalRangeWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 16px;
    padding: 16px 0 26px;
`;

export const ModalRangeInner = styled.div`
    align-self: center;
    width: 95%;
    height: 2px;
    margin: 0 auto;
    border-radius: 16px;
`;

export const ModalRangeThumb = styled.div`
    display: flex;
    justifyContent: center;
    alignItems: center;
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.colors.clearBlue};
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 100%;
    outline: none;
    
    &.disabled {
        background-color: ${props => props.theme.colors.cadetBlue};
    }
`;

export const ModalRangeOutput = styled.div`
    margin-top: 12px;
    font-size: 12px;
    
    &.disabled {
        color: ${props => props.theme.colors.mercury};
    }
`;

/* Vote Modal Range */
export const ModalVote = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border: 1px solid ${props => props.theme.colors.whiteSmoke};
    border-radius: 8px;
`;

export const ModalVoteNumber = styled.div`
    overflow: hidden;
    width: 50px;
    font-weight: 500;
    color: ${props => props.theme.colors.coralRed};
    text-overflow: ellipsis;
    
    &.minus {
        text-align: right;
    }
    
    &.plus {
        color: ${props => props.theme.colors.mediumGreen};
    }
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f12};
    }
`;

export const ModalVoteRange = styled.div`
    display: flex;
    width: 330px;
    height: 10px;
    margin: 0 6px;
    border-radius: 100%;
    background: ${props => props.theme.colors.whiteSmoke};
    
    @media ${props => props.theme.device.md} {
        width: 250px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 150px;
    }
`;

export const ModalVoteDown = styled.div`
    width: ${({ width }) => `${width}%`};
    height: 10px;
    background: ${props => props.theme.colors.coralRed};
    border-right: ${({ voteDown, theme }) => (voteDown === '100' || voteDown === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteDown }) => (voteDown === '100' || voteDown === '0' ? '100px' : '100px 0 0 100px')};
`;

export const ModalVoteUp = styled.div`
    width: ${({ width }) => `${width}%`};
    height: 10px;
    background: ${props => props.theme.colors.mediumGreen};
    border-left: ${({ voteUp, theme }) => (voteUp === '100' || voteUp === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteUp }) => (voteUp === '100' || voteUp === '0' ? '100px' : '0 100px 100px 0')};
`;

/* Share Modal */
export const ShareModalTabs = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
    
    @media ${props => props.theme.device.md} {
        justify-content: flex-start;
        margin-top: 16px;
    }
`;

export const ShareRadioLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 85px;
    margin-bottom: 26px;
    cursor: pointer;
    
    & .image {
        width: 40px;
        height: 40px;
    
        & svg {
            width: 100%;
            height: 100%;
            fill: ${props => props.theme.colors.secondary};
        }
    }
    
    & .title {
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
    }
    
    input {
        display: none;
    }
    
    &.checked {
        & .image {
            & svg {
                fill: ${props => props.theme.colors.clearBlue};
            }
        }
        
        & .title {
            color: ${props => props.theme.colors.clearBlue};
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin: 0 5px 26px;
        
        & .title {
            font-size: ${props => props.theme.fontSize.f14};
        }
    }
    
    @media ${props => props.theme.device.sm} {
        min-width: 70px;
        margin-bottom: 16px;
    
        & .image {
            width: 32px;
            height: 32px;
        }
    }
`;

export const ShareOptions = styled.div`
    margin-top: 26px;
`;

export const ShareOptionsList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ShareOptionsItem = styled.div`
    margin-top: 16px;

    & .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        
        & input {
            display: none;
        }
        
        & .checkPointer {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border: 1px solid ${props => props.theme.colors.primary};
            border-radius: 100px;
        }
    }
    
    & .image {
        width: 208px;
        height: 94px;
        margin-top: 16px;
        border: 1px solid ${props => props.theme.colors.cadetBlue};
        border-radius: 8px;
    }
    
    &.checked {
        & .title {
            color: ${props => props.theme.colors.clearBlue};
            
            & .checkPointer {
                display: flex;
                justify-content: center;
                align-items: center;
                background: ${props => props.theme.colors.white};
                border: 1px solid ${props => props.theme.colors.clearBlue};
        
                &:after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: ${props => props.theme.colors.clearBlue};
                    border-radius: 100px;
                }
            }
        }
        
         & .image {
            border: 1px solid ${props => props.theme.colors.clearBlue};
        }
    }
    
    &:first-child {
        margin-right: 26px;
        
        & .image {
            width: 92px;
            height: 176px;
        }
    }
`;

export const ModalButtons = styled.div`
    width: 100%;
    margin-top: 26px;
    
    &.flex {
        display: flex;
        
        & button {
            max-width: 228px;
            
            &:nth-child(2) {
                margin-top: 0;
                margin-left: 12px;
            }
        }
    }

    button:nth-child(2),
    button:nth-child(3) {
        margin-top: 16px;
    }
    
    @media ${props => props.theme.device.md} {
        justify-content: center;
    }
    
    @media ${props => props.theme.device.xs} {
        &.flex {
            flex-direction: column;
            
            & button {
                max-width: 100%;
                
                &:first-child {
                    order: 1;
                }
                
                &:nth-child(2) {
                    order: 0;
                    margin-bottom: 16px;
                    margin-left: 0;
                }
            }
        }
    }
`;

export const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.overlay};
`;

