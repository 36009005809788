import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StyledMembersSlider } from './styles';
import { profiles } from '../../../../utils/MOCK';
import ProfileCard from '../../../../components/ProfileCard';

const MembersSlider = () => {
  return (
       <StyledMembersSlider>
         <Swiper
             tag={'section'}
             wrapperTag={'ul'}
             modules={[Autoplay]}
             slidesPerView={'auto'}
             loop={true}
             speed={1000}
             autoplay={{
               delay: 3000,
               reverseDirection: true,
             }}
         >
           {profiles.map(profile => (
               <SwiperSlide tag={'li'} key={profile.id}>
                   <ProfileCard {...profile} />
               </SwiperSlide>
           ))}
         </Swiper>
       </StyledMembersSlider>
  );
};

export default MembersSlider;
