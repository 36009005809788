import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 612px;
    width: 100%;
    margin-top: 40px;
    
    @media ${props => props.theme.device.lg} {
        max-width: 416px;
    }
`;

export const WalletAddress = styled.div`
    display: flex;
    align-items: center;
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
        align-items: inherit;
    }
`;

export const WalletAddressInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 16px;
    word-break: break-all;
    
    &.disabled {
        color: ${props => props.theme.colors.cadetBlue};
        
        & .walletAddressLink {
            color: ${props => props.theme.colors.cadetBlue};
            pointer-events: none;
            
            & svg {
                fill: ${props => props.theme.colors.cadetBlue};
            }
        }
    }
    
    & .walletAddressLink {
        margin-top: 6px;
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 16px;
        word-break: break-all;
        
        & svg {
            width: 16px;
            height: 16px;
            margin-left: 6px;
            fill: ${props => props.theme.colors.clearBlue};
        }
    }
    
    @media ${props => props.theme.device.sm} {
        margin-top: 16px;
        margin-left: 0;
    }
`;

export const Photo = styled.div`
    position: relative;
    width: 98px;
    height: 98px;
    border-radius: 100%;
    
    & img {
        width: 100%;
        height: auto;
        border-radius: 100%;
    }
    
    & button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 32px;
        height: 32px;
        
        & svg {
            width: 32px;
            height: 32px;
            fill: ${props => props.theme.colors.clearBlue};
        }
    }
    
    &.disabled {
        & button {
            pointer-events: none;
        
            & svg {
                fill: ${props => props.theme.colors.mercury};
            }
        }
    }
`;

export const FormButtons = styled.div`
    display: flex;
    
    & button {
        width: 200px;
        
        &:first-child {
            margin-right: 16px;
        }
    }
    
    @media ${props => props.theme.device.sm} {
         & button {
            width: 150px;
         }
    }
    
    @media ${props => props.theme.device.xs} {
        flex-direction: column;
        
        & button {
            width: 100%;
                
            &:first-child {
                order: 1;
                margin-top: 16px;
                margin-right: 0;
            }
                
            &:last-child {
                order: 0;
            }
        }
    }
`;
