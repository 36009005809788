import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomInfo,
  ContentBlock,
  ContentBlockInner,
  Name,
  Photo,
  StyledTokenCard,
  Timer,
  TopInfo,
} from '../styles';
import { DefaultButton } from '../../Button/styles';
import BookmarkIcon from '../../../images/BookmarkIcon.svg';
import BookmarkWhiteFillIcon from '../../../images/BookmarkWhiteFillIcon.svg';

const TokenCardTimer = ({
  image, isTopTag, tags, name, timer, horizontalProps, timerProps,
}) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);

  const navigateToTokenPage = () => {
    navigate('/token-page');
  };

  const handleBookmarkClick = (event) => {
    event.stopPropagation();
    setShow(prev => !prev);
  };

  return (
      <StyledTokenCard onClick={navigateToTokenPage} className={timerProps ? 'horizontal timer' : horizontalProps ? 'horizontal' : ''}>
          <Photo background={`url(${image})`} className={'photo timer'}>
              <TopInfo className={'simple'}>
                  <DefaultButton onClick={handleBookmarkClick}>{show ? <BookmarkIcon /> : <BookmarkWhiteFillIcon />}</DefaultButton>
              </TopInfo>
              <BottomInfo className={'bottomInfo'}>
                  {isTopTag && <DefaultButton className={'topButton'}>Top</DefaultButton>}
                  {tags.map(tag => {
                    return (
                        <DefaultButton key={tag} className={'categoryButton'}>{tag}</DefaultButton>
                    );
                  })}
              </BottomInfo>
          </Photo>
          <ContentBlock className={'contentBlock timer'}>
              <ContentBlockInner className={'contentBlockInner horizontalTimer'}>
                  <Name className={'name'}>{name}</Name>
                  <Timer className={horizontalProps ? 'horizontalTimer' : ''}>
                      {timer?.hours} <span>Hrs</span>
                      {timer?.minutes} <span>Min</span>
                      {timer?.seconds} <span>Sec</span>
                  </Timer>
              </ContentBlockInner>
          </ContentBlock>
      </StyledTokenCard>
  );
};

export default TokenCardTimer;
