import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import Header from './Header';
import Footer from './Footer';
import Deals from '../pages/Deals';
import MyAssets from '../pages/MyAssets';
import { Content, Main, StyledLayout } from './styles';
import { AuthContext } from '../context';
import PrivateRoute from '../components/PrivateRoute';
import Referrals from '../pages/Referrals';
import Staking from '../pages/Staking';
import TokenValidation from '../pages/TokenValidation';
import Home from '../pages/Home';
import TokenPage from '../pages/TokenPage';
import ScrollToTop from '../hooks/ScrollToTop';
import CreateToken from '../pages/CreateToken';
import Modals from '../pages/Modals';
import MyAssetsEmpty from '../pages/MyAssetsEmpty';
import Tokens from '../pages/Tokens';
import Investors from '../pages/Investors';
import Validation from '../pages/Validation';
import TokenValidationEmpty from '../pages/TokenValidationEmpty';
import History from '../pages/History';
import EditToken from '../pages/EditToken';
import UserProfile from '../pages/UserProfile';
import Launchpad from '../pages/Launchpad';
import WhitePaper from '../pages/WhitePaper';
import EditProfile from '../pages/EditProfile';
import CatalogFilterPage from '../pages/CatalogFilterPage';
import Login from '../pages/Login';
import { client } from '../graphql/client';
import { ModalProvider } from '../context/modal';
import Frame from '../pages/Frame';
import InvestModal from '../components/Modal/InvestModal';
import SellModal from '../components/Modal/SellModal';
import ConfirmInvestModal from '../components/Modal/ConfirmInvestModal';
import ConfirmSellModal from '../components/Modal/ConfirmSellModal';
import BuyCCRModal from '../components/Modal/BuyCCRModal';
import ConfirmationActionModal from '../components/Modal/ConfirmationActionModal';
import ShareModal from '../components/Modal/ShareModal';
import SentForValidation from '../components/Modal/SentForValidation';
import AlmostThereModal from '../components/Modal/AlmostThereModal';
import ConfirmValidatorModal from '../components/Modal/ConfirmValidatorModal';
import InsufficientFundsModal from '../components/Modal/InsufficientFundsModal';
import CompletedValidatorModal from '../components/Modal/CompletedValidatorModal';
import BuySharesModal from '../components/Modal/BuySharesModal';
import ConfirmCreationModal from '../components/Modal/ConfirmCreationModal';
import OnboardingModal from '../components/Modal/OnboardingModal';
import CreateNewWalletModal from '../components/Modal/CreateNewWalletModal';
import ProfileModal from '../components/Modal/ProfileModal';
import OnboardingSuccessModal from '../components/Modal/OnboardingSuccessModal';
import CongratulationsModal from '../components/Modal/CongratulationsModal';
import CongratulationsBuyCCRModal from '../components/Modal/CongratulationsBuyCCRModal';

const Layout = () => {
  const [isAuth, setIsAuth] = useState(true);
  const location = useLocation();

  const locationContent = location.pathname === '/for-frame';

  return (
        <StyledLayout>
            <Content>
                <ApolloProvider client={client}>
                    <AuthContext.Provider value={{
                      isAuth,
                      setIsAuth,
                    }}>
                        <ModalProvider>
                            <Header />
                            <ScrollToTop />
                            <Main locationContent={locationContent}>
                                <Routes>
                                    <Route exact path='/login' element={<Login />}/>
                                    <Route exact path='/' element={<Home />}/>
                                    <Route path='/token-page/:id' element={<PrivateRoute><TokenPage /></PrivateRoute>}/>
                                    <Route path='/token-page' element={<PrivateRoute><TokenPage /></PrivateRoute>}/>
                                    <Route path='/edit-profile' element={<PrivateRoute><EditProfile /></PrivateRoute>}/>
                                    <Route path='/launchpad' element={<Launchpad />}/>
                                    <Route path='/tokens' element={<Tokens />}/>
                                    <Route path='/investors' element={<Investors />}/>
                                    <Route path='/deals' element={<Deals />}/>
                                    <Route path='/white-paper' element={<WhitePaper />}/>
                                    <Route path='/my-assets' element={<PrivateRoute><MyAssets /></PrivateRoute>}/>
                                    <Route path='/history' element={<PrivateRoute><History /></PrivateRoute>}/>
                                    <Route path='/referrals' element={<PrivateRoute><Referrals /></PrivateRoute>}/>
                                    <Route path='/staking' element={<PrivateRoute><Staking /></PrivateRoute>}/>
                                    <Route path='/validation' element={<PrivateRoute><Validation /></PrivateRoute>}/>
                                    <Route path='/token-validation' element={<PrivateRoute><TokenValidation /></PrivateRoute>}/>
                                    <Route path='/create-token' element={<PrivateRoute><CreateToken /></PrivateRoute>}/>
                                    <Route path='/edit-token' element={<PrivateRoute><EditToken /></PrivateRoute>}/>
                                    <Route path='/catalog-filter-page' element={<PrivateRoute><CatalogFilterPage /></PrivateRoute>}/>

                                    <Route path='/modals' element={<PrivateRoute><Modals /></PrivateRoute>}/>
                                    <Route path='/my-assets-empty' element={<PrivateRoute><MyAssetsEmpty /></PrivateRoute>}/>
                                    <Route path='/token-validation-empty' element={<PrivateRoute><TokenValidationEmpty /></PrivateRoute>}/>

                                    <Route path='/user-profile' element={<PrivateRoute><UserProfile /></PrivateRoute>}/>

                                    <Route path='/for-frame' element={<PrivateRoute><Frame /></PrivateRoute>}/>
                                </Routes>
                            </Main>
                            {/* Modals */}
                            <InvestModal />
                            <SellModal />
                            <ConfirmInvestModal />
                            <ConfirmSellModal />
                            <BuyCCRModal />
                            <ConfirmationActionModal />
                            <ShareModal />
                            <SentForValidation />
                            <AlmostThereModal />
                            <ConfirmValidatorModal />
                            <InsufficientFundsModal />
                            <CompletedValidatorModal />
                            <BuySharesModal />
                            <ConfirmCreationModal />
                            <OnboardingModal />
                            <CreateNewWalletModal />
                            <ProfileModal />
                            <OnboardingSuccessModal />
                            <CongratulationsModal />
                            <CongratulationsBuyCCRModal />
                        </ModalProvider>
                    </AuthContext.Provider>
                </ApolloProvider>
            </Content>
            <Footer />
        </StyledLayout>
  );
};

export default Layout;

