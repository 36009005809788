import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { StyledReferralsTable } from './styles';
import referralsTableImage1 from '../../../../images/referralsTable/referrals_table_image_1.png';
import referralsTableImage2 from '../../../../images/referralsTable/referrals_table_image_2.png';
import referralsTableImage3 from '../../../../images/referralsTable/referrals_table_image_3.png';
import referralsTableImage4 from '../../../../images/referralsTable/referrals_table_image_4.png';
import referralsTableImage5 from '../../../../images/referralsTable/referrals_table_image_5.png';
import referralsTableImage6 from '../../../../images/referralsTable/referrals_table_image_6.png';
import referralsTableImage7 from '../../../../images/referralsTable/referrals_table_image_7.png';
import { smartTrim } from '../../../../utils/smartTrim';

const createData = (date, user, walletAddress, referralLevel, action, amount, commission) => {
  return {
    id : `${user}-${Math.random()}`, date, user, walletAddress, referralLevel, action, amount, commission,
  };
};

const rows = [
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage1, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    3,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage2, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    2,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage3, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    4,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage4, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    5,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage5, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    1,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage6, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    2,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
  createData(
    format(new Date(), 'dd MMM HH:MM'),
    { userLink: '#', image: referralsTableImage7, userName: 'Bryan Hicks' },
    { walletLink: '#', walletName: '0xd154RH45A8b5E' },
    4,
    ' Buy CCR',
    '0.1234 ETH',
    '534.0034 CCR - 10%'
  ),
];

const ReferralsTable = () => {
  return (
      <StyledReferralsTable>
          <thead>
          <tr>
              <th>Date/Time</th>
              <th>User</th>
              <th>Wallet address </th>
              <th>Referral level</th>
              <th>Action</th>
              <th>Amount</th>
              <th>Commission</th>
          </tr>
          </thead>
          <tbody>
          {rows.map(row => (
              <tr key={row.id}>
                  <td data-label='Date/Time' className={'date'}>{row.date}</td>
                  <td>
                      <Link to={row.user.userLink} className={'link'}>
                          <img src={row.user.image} alt={row.user.userName} />
                          {row.user.userName}
                      </Link>
                  </td>
                  <td data-label='Wallet address'>
                      <Link to={row.walletAddress.walletLink} className={'link'}>
                          {smartTrim(row.walletAddress.walletName, 11)}
                      </Link>
                  </td>
                  <td data-label='Referral level'>{row.referralLevel}</td>
                  <td data-label='Action'>{row.action}</td>
                  <td data-label='Amount'>{row.amount}</td>
                  <td data-label='Commission'>{row.commission}</td>
              </tr>
          ))}
          </tbody>
      </StyledReferralsTable>
  );
};

export default ReferralsTable;
