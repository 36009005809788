import styled from 'styled-components';

export const HeaderAvatarImage = styled.div`
    display: flex;
    cursor: pointer;
    
    & img {
        width: 40px;
        height: 40px;
        border: 2px solid ${props => props.theme.colors.mercury};
        border-radius: 100px;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-right: 55px;
    }
`;
