import React, { useState } from 'react';
import {
  EmotionAssetsBlock, EmotionAssetsItem, EmotionAssetsItemInfo, EmotionAssetsLine, EmotionBlock,
  EmotionBlockTitle,
  ModalBody, ModalButtons, ModalHeader, ModalHeaderInfo, ModalHeaderName, ModalHeaderPhoto, ModalTitle,
} from '../styles';
import Button from '../../Button';
import Modal from '../index';
import modalHeaderPhoto from '../../../images/modalHeaderPhoto.png';
import BusinessIcon from '../../../images/BusinessIcon.svg';
import CollectionValueIcon from '../../../images/CollectionValueIcon.svg';
import VoteFillIcon from '../../../images/LikeIcon.svg';
import { useModalContext } from '../../../context/hooks';
import SaveProfileIcon from '../../../images/SaveProfileIcon.svg';

const ConfirmInvestModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [loading, setLoading] = useState(false);

  const toggleCongratulationsModal = (data) => {
    const second = {
      ...modals.congratulationsModal,
      isOpen: !modals.congratulationsModal.isOpen,
      ...data,
    };
    updateModalState('congratulationsModal', second);
  };

  const toggleConfirmInvestModal = () => {
    updateModalState('confirmInvestModal', {
      isOpen: !modals.confirmInvestModal.isOpen,
    });
  };

  const delayCongratulationsSubmit = () => {
    const id = setTimeout(() => toggleCongratulationsModal({ isOpen: false }), 3000);

    toggleCongratulationsModal({ timeoutId: id, congratulationsText: 'You have invested in a token' }); // because of useReducer is async, we must do this
    toggleConfirmInvestModal();
  };

  const delaySubmit = () => {
    return new Promise((resolve => {
      setTimeout(() => resolve('Success'), 3000);
    }));
  };

  const handleInvestConfirm = () => {
    setLoading(true);
    delaySubmit().then(console.log).then(() => {
      setLoading(false);
      delayCongratulationsSubmit();
    });
  };

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: !modals.investModal.isOpen,
    });
  };

  const handleInvestModalBack = (e) => {
    e.preventDefault();
    toggleConfirmInvestModal();
    toggleInvestModal();
  };

  return (
      <>
          <Modal
              isOpen={modals.confirmInvestModal.isOpen}
              setIsOpen={toggleConfirmInvestModal}
              modalName={'tokenModal'}
          >
              <ModalHeader>
                  <ModalHeaderPhoto>
                      <img src={modalHeaderPhoto} alt={''} />
                  </ModalHeaderPhoto>
                  <ModalHeaderInfo>
                      <ModalHeaderName>Token name</ModalHeaderName>
                      <ModalTitle>Confirm the Deal</ModalTitle>
                  </ModalHeaderInfo>
              </ModalHeader>
              <ModalBody>
                  <EmotionBlock>
                      <EmotionBlockTitle>Purchase details</EmotionBlockTitle>
                      <EmotionAssetsBlock>
                          <EmotionAssetsItem>
                              <VoteFillIcon className={'up'} />
                              <EmotionAssetsItemInfo>
                                  <div className={'title'}>Votes:</div>
                                  <div className={'value'}>980.345</div>
                              </EmotionAssetsItemInfo>
                          </EmotionAssetsItem>
                          <EmotionAssetsLine />
                          <EmotionAssetsItem>
                              <BusinessIcon />
                              <EmotionAssetsItemInfo>
                                  <div className={'title'}>Shares:</div>
                                  <div className={'value'}>980.345</div>
                              </EmotionAssetsItemInfo>
                          </EmotionAssetsItem>
                          <EmotionAssetsLine />
                          <EmotionAssetsItem>
                              <CollectionValueIcon />
                              <EmotionAssetsItemInfo>
                                  <div className={'title'}>Shares value:</div>
                                  <div className={'value'}>119.6547565 CCR</div>
                                  <div className={'currencyTitle'}>Shareholders fee 18.3%</div>
                                  <div className={'currency black'}>$2.17</div>
                              </EmotionAssetsItemInfo>
                          </EmotionAssetsItem>
                      </EmotionAssetsBlock>
                  </EmotionBlock>
                  <ModalButtons className={'flex'}>
                      <Button
                          outlined
                          onClick={handleInvestModalBack}
                      >
                          Back
                      </Button>
                      <Button
                          primary
                          className={'loading'}
                          onClick={handleInvestConfirm}
                      >
                          Confirm
                          {loading ? <SaveProfileIcon /> : null}
                      </Button>
                  </ModalButtons>
              </ModalBody>
          </Modal>
      </>
  );
};

export default ConfirmInvestModal;
