import React, { useState } from 'react';
import Container from '../../components/Container';
import {
  CatalogFilterBlock,
  CatalogFilters, CatalogList, CatalogWrapper, FilterCloseButton, OverlayFilter, StyledTokens,
} from './styles';
import MostPopular from './components/MostPopular';
import New from './components/New';
import Button from '../../components/Button';
import FilterIcon from '../../images/FilterIcon.svg';
import CloseIcon from '../../images/CloseIcon.svg';
import Navigation from '../../components/Navigation';
import Catalog from '../../components/CardLists/Catalog';
import CatalogSearch from '../../components/CatalogSearch';
import CatalogFilter from '../../components/CatalogFilter';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'Tokens',  destination: '/tokens' },
];

const Tokens = () => {
  const [open, setOpen] = useState(false);

  const overflowScroll = () => {
    if (open) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const openFilters = () => {
    setOpen(prev => !prev);
    overflowScroll();
  };

  return (
      <StyledTokens>
          <Container direction={'column'}>
              <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
              <PageContainer>
                  <CatalogWrapper>
                      <CatalogFilters justify={'space-between'}>
                          <CatalogSearch />
                          <Button primary className={'filterButton'} onClick={openFilters}><FilterIcon />Filter</Button>
                          <OverlayFilter open={open} onClick={openFilters} />
                          <CatalogFilterBlock open={open} setOpen={setOpen}>
                              <FilterCloseButton onClick={openFilters}>
                                  <CloseIcon />
                              </FilterCloseButton>
                              <CatalogFilter />
                          </CatalogFilterBlock>
                      </CatalogFilters>
                      <CatalogList>
                          <MostPopular />
                          <New />
                          <Catalog isLoading />
                      </CatalogList>
                  </CatalogWrapper>
              </PageContainer>
          </Container>
      </StyledTokens>
  );
};

export default Tokens;
