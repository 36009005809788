import styled from 'styled-components';

export const StyledFrameCard = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 240px;
    border-radius: 14px;
    cursor: pointer;
    
    &:hover {
        box-shadow: ${props => props.theme.boxShadow.mainShadow};
        
        & button.buyShares {
            color: ${props => props.theme.colors.white};
            background: ${props => props.theme.colors.clearBlue};
        }
    }
    
    &.horizontal {
        flex-direction: row;
        width: 300px;
        height: 100px;
            
        & .photo {
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            background-position: center;
            border-radius: 14px 0 0 14px;
                
            &:before {
                border-radius: 0 0 14px 0;
            }
        }
            
        & .contentBlock {
            height: 100%;
            padding: 0 12px 10px;
            border: 1px solid ${props => props.theme.colors.cadetBlue};
            border-radius: 0 14px 14px 0;
                
            & .name {
                font-size: ${props => props.theme.fontSize.f14};
                font-weight: 500;
                line-height: 18px;
            }
            
            & .infoBlock {
                height: 60px;
                margin-bottom: 4px;
                padding: 5px 8px;
                
                & .title {
                    font-size: ${props => props.theme.fontSize.f10};
                    font-weight: 400;
                    line-height: 14px;
                }
                
                & .voteAmount {
                    font-size: ${props => props.theme.fontSize.f14};
                    line-height: 24px;
                }
                
                & .statusInfoOutcome,
                & .statusInfoIncome {
                    span {
                        font-size: ${props => props.theme.fontSize.f12};
                    }
                }
            }
        }
    }
`;

export const Photo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 240px;
    padding: 10px;
    border-radius: 14px 14px 0 0;
    background: ${({ background }) => (background || 'initial')} no-repeat 100%;
    background-size: cover;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 53px;
        background: ${props => props.theme.colors.cardGradient};
        border-radius: 0 0 14px 14px;
        transform: rotate(-180deg);
        opacity: 0.7;
    }
`;

export const TopInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
`;

export const Rank = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 24px;
    padding: 0 6px;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.f12};
    background: ${props => props.theme.colors.tagBackground};
    border: 1px solid ${props => props.theme.colors.white};
    border-radius: 8px;
`;

export const ContentBlock = styled.div`
    width: 100%;
    padding: 0 16px 16px;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    border-top: none;
    border-radius: 0px 0px 14px 14px;
`;

export const Name = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 2px 0;
    font-size: ${props => props.theme.fontSize.f20};
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    height: 85px;
    padding: 8px 16px;
    background: ${props => props.theme.colors.blueGradient};
    border-radius: 6px;
`;

export const Title = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
`;

export const VoteAmount = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: ${props => props.theme.fontSize.f20};
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
`;

export const StatusInfo = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    
    & span {
        display: flex;
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: 500;
        line-height: 16px;
    }
    
    & svg {
        display: none;
    }
    
    &.statusInfoIncome {
        & svg {
            display: flex;
            width: 16px;
            height: 16px;
            fill: ${props => props.theme.colors.mediumGreen};
            transform: rotate(180deg);
        }
    }
    
    &.statusInfoOutcome {
         & svg {
            display: flex;
            width: 16px;
            height: 16px;
            fill: ${props => props.theme.colors.coralRed};
         }
    }
`;

export const VoteRange = styled.div`
    display: flex;
    width: 100%;
    height: 6px;
`;

export const VoteDown = styled.div`
    width: ${({ voteDown }) => `${voteDown}%`};
    height: 6px;
    background: ${props => props.theme.colors.coralRed};
    border-right: ${({ voteDown, theme }) => (voteDown === '100' || voteDown === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteDown }) => (voteDown === '100' || voteDown === '0' ? '100px' : '100px 0 0 100px')};
`;

export const VoteUp = styled.div`
    width: ${({ voteUp }) => `${voteUp}%`};
    height: 6px;
    background: ${props => props.theme.colors.mediumGreen};
    border-radius: 0 100px 100px 0;
    border-left: ${({ voteUp, theme }) => (voteUp === '100' || voteUp === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteUp }) => (voteUp === '100' || voteUp === '0' ? '100px' : '0 100px 100px 0')};
`;
