import React, { useCallback, useEffect } from 'react';
import {
  ModalOverlay, ModalBackdrop,
} from './styles';

const Modal = ({
  isOpen, setIsOpen, children, modalName, timeoutId, loading,
}) => {
  const toggleModalWindowOpen = useCallback(() => {
    clearTimeout(timeoutId);
    return setIsOpen(prev => !prev);
  }, [timeoutId, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
        <>
            { isOpen
            && <div>
                <ModalOverlay>
                    <div className={modalName ? `modalWindow ${modalName}` : 'modalWindow'}>
                        {children}
                    </div>
                    <ModalBackdrop onClick={loading ? undefined : toggleModalWindowOpen} />
                </ModalOverlay>
            </div>
            }
        </>
  );
};

export default Modal;
