import styled from 'styled-components';

export const StyledReferralsTable = styled.table`
    width: 100%;
    margin-top: 26px;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th, td {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
        
        & .link {
            display: flex;
            align-items: center;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            text-decoration: underline;
                
            img {
                display: block;
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
            
            &:hover {
                color: ${props => props.theme.colors.clearBlue};
            }
        }
        
        &.date {
            color: ${props => props.theme.colors.primary};
        }
    }
    
    th {
        color: ${props => props.theme.colors.secondary};
    }
    
    @media ${props => props.theme.device.lg} {
        th, td {
            height: 60px;
            
            & .link {
                img {
                    display: none;
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
    
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            order: 2;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            border-radius: 12px;
            
            &:nth-child(even) {
                background: ${props => props.theme.colors.white};
            }
            
             &:first-child {
                order: 1;
                background: ${props => props.theme.colors.white};
                z-index: 1;
            }
            
            &:nth-child(2) {
                order: 0;
                margin-bottom: 12px;
                padding: 0;
                background: transparent;
                
                &:before {
                    display: none;
                }
            }
        
            &:before {
                content: attr(data-label);
                float: left;
                padding-right: 15px;
                color: ${props => props.theme.colors.secondary};
                font-weight: 600;
            }
            
            & .link {
                img {
                    display: block;
                }
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px; 
        }
        
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12};
            
            & .link {
               font-size: ${props => props.theme.fontSize.f12};
            }
            
            &:nth-child(2) {
                margin-bottom: 6px;
                
                & .link {
                    font-size: ${props => props.theme.fontSize.f14};
                }
            }
        }
    }
`;
