import React from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import { Typography1, Typography2, Typography3 } from './styles';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'White Paper',  destination: '/white-paper' },
];

const WhitePaper = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <Typography1>
                  <h3>Heading 1</h3>
                  <p>
                      The clarity of our position is obvious: the modern development methodology requires the definition and refinement of standard approaches.
                      Only some features of domestic politics call us to new achievements, which, in turn, should be limited exclusively to the way of thinking.
                      Being just a part of the overall picture, many famous personalities form a global economic network and at the same time are turned into a
                      laughing stock, although their very existence brings undoubted benefits to society!
                  </p>
                  <p>
                      Diverse and rich experience tells us that the economic agenda of today gives us no choice but to prioritize reason over emotions. Being
                      only part of the overall picture, the clear signs of the victory of institutionalization call us to new achievements, which, in turn,
                      should be indicated as candidates for the role of key factors. Banal, but irrefutable conclusions, as well as clear signs of the victory
                      of institutionalization, call us to new achievements, which, in turn, should be anathema to the social-democratic anathema.
                  </p>
                  <p>
                      In particular, the strengthening and development of the internal structure requires us to analyze the withdrawal of current assets. Ideological
                      considerations of the highest order, as well as the modern methodology of development, create prerequisites for the directions of progressive development.
                      There is a controversial point of view, which reads something like the following: thorough research of competitors forms a global economic network and at
                      the same time is made public. We have to start from the fact that a high-quality prototype of a future project requires an analysis of the priority requirements.
                  </p>
              </Typography1>
              <Typography2>
                  <h3>Heading 2</h3>
                  <p>
                      The clarity of our position is obvious: the modern development methodology requires the definition and refinement of standard approaches.
                      Only some features of domestic politics call us to new achievements, which, in turn, should be limited exclusively to the way of thinking.
                      Being just a part of the overall picture, many famous personalities form a global economic network and at the same time are turned into a
                      laughing stock, although their very existence brings undoubted benefits to society!
                  </p>
                  <p>
                      Diverse and rich experience tells us that the economic agenda of today gives us no choice but to prioritize reason over emotions. Being
                      only part of the overall picture, the clear signs of the victory of institutionalization call us to new achievements, which, in turn,
                      should be indicated as candidates for the role of key factors. Banal, but irrefutable conclusions, as well as clear signs of the victory
                      of institutionalization, call us to new achievements, which, in turn, should be anathema to the social-democratic anathema.
                  </p>
                  <p>
                      In particular, the strengthening and development of the internal structure requires us to analyze the withdrawal of current assets. Ideological
                      considerations of the highest order, as well as the modern methodology of development, create prerequisites for the directions of progressive development.
                      There is a controversial point of view, which reads something like the following: thorough research of competitors forms a global economic network and at
                      the same time is made public. We have to start from the fact that a high-quality prototype of a future project requires an analysis of the priority requirements.
                  </p>
              </Typography2>
              <Typography3>
                  <h3>Heading 3</h3>
                  <p>
                      The clarity of our position is obvious: the modern development methodology requires the definition and refinement of standard approaches.
                      Only some features of domestic politics call us to new achievements, which, in turn, should be limited exclusively to the way of thinking.
                      Being just a part of the overall picture, many famous personalities form a global economic network and at the same time are turned into a
                      laughing stock, although their very existence brings undoubted benefits to society!
                  </p>
                  <p>
                      Diverse and rich experience tells us that the economic agenda of today gives us no choice but to prioritize reason over emotions. Being
                      only part of the overall picture, the clear signs of the victory of institutionalization call us to new achievements, which, in turn,
                      should be indicated as candidates for the role of key factors. Banal, but irrefutable conclusions, as well as clear signs of the victory
                      of institutionalization, call us to new achievements, which, in turn, should be anathema to the social-democratic anathema.
                  </p>
                  <p>
                      In particular, the strengthening and development of the internal structure requires us to analyze the withdrawal of current assets. Ideological
                      considerations of the highest order, as well as the modern methodology of development, create prerequisites for the directions of progressive development.
                      There is a controversial point of view, which reads something like the following: thorough research of competitors forms a global economic network and at
                      the same time is made public. We have to start from the fact that a high-quality prototype of a future project requires an analysis of the priority requirements.
                  </p>
              </Typography3>
          </PageContainer>
      </Container>
  );
};

export default WhitePaper;
