import React from 'react';
import {
  OnboardingModalContent, OnboardingModalImage, OnboardingModalText, OnboardingModalTitle, StyledOnboardingModalItem,
} from './styles';
import { SkipButton } from '../../../Button/styles';

const OnboardingModalIcon = ({
  toggleModalAndReset, toggleCreateNewWalletModal, image: Image, backgroundColor, buttonColor, circleColor, title, text,
}) => {
  const handleSkipButtonClick = () => {
    toggleModalAndReset();
    toggleCreateNewWalletModal();
  };

  return (
        <StyledOnboardingModalItem>
            <SkipButton onClick={handleSkipButtonClick} background={buttonColor}>Skip</SkipButton>
            <OnboardingModalImage>
                <Image backgroundColor={backgroundColor} circleColor={circleColor} />
            </OnboardingModalImage>
            <OnboardingModalContent>
                <OnboardingModalTitle>
                    {title}
                </OnboardingModalTitle>
                <OnboardingModalText>
                    {text}
                </OnboardingModalText>
            </OnboardingModalContent>
        </StyledOnboardingModalItem>
  );
};

export default OnboardingModalIcon;
