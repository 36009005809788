import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropContainer, DropDescription } from './styles';
import Button from '../Button';

const DropzoneCreate = ({ files, setFiles, input }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
      if (input?.onChange) {
        input.onChange(acceptedFiles);
      }
    },
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
        <>
            <DropContainer {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <DropDescription>
                    Drag and drop image or
                </DropDescription>
                <Button primary type="button" onClick={open}>Choose photo</Button>
            </DropContainer>
        </>
  );
};

export default DropzoneCreate;
