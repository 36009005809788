import styled from 'styled-components';

export const StyledInvestors = styled.div`
    & .more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 40px;
        margin: 26px auto 0;
        font-size: ${props => props.theme.fontSize.f14};
        
        & svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }
`;

export const InvestorsSelectContainer = styled.div`
    display: none;
    
    @media ${props => props.theme.device.md} {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }
    
    @media ${props => props.theme.device.sm} {
        height: auto;
        margin-bottom: 16px;
    }
`;

export const InvestorsSelectTitle = styled.div`
    margin-bottom: 8px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 500;
    line-height: 16px;
`;

export const InvestorsTableSelect = styled.div`  
    @media ${props => props.theme.device.md} {
        width: 260px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
    }
`;

export const InvestorsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        color: ${props => props.theme.colors.secondary};
        font-weight: 500;
        
        & button.sort {
            display: flex;
            align-items: center;
            color: ${props => props.theme.colors.secondary};
            font-size: ${props => props.theme.fontSize.f14}; 
            font-weight: 500;
            line-height: 24px;
                
            & svg {
                width: 24px;
                height: 24px;
                fill: ${props => props.theme.colors.secondary};
            }
        }
        
        & button.sort.active {
            & svg {
                transform: rotate(180deg);
            }
        }
        
    }
    
    td {
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
        
        & svg {
            &.up {
                width: 15px;
                height: 16px;
                fill: ${props => props.theme.colors.mediumGreen};
            }  
            &.down {
                width: 15px;
                height: 16px;
                fill: ${props => props.theme.colors.coralRed};
                transform: rotate(180deg);
            }  
        }
        
        a img {
            display: block;
        }
        
        &.investor {
            display: flex;
            align-items: center;
        
            img {
                display: block;
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
        }
        
        & span.position {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 52px;
            max-width: 72px;
            padding: 0 8px;
            color: ${props => props.theme.colors.white};
            font-size: ${props => props.theme.fontSize.f12}; 
            line-height: 24px;
            background: ${props => props.theme.colors.secondary};
            border-radius: 8px;
            
            & svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
            
            &.orange {
                background: ${props => props.theme.colors.sunshade};
            }
            
            &.green {
                background: ${props => props.theme.colors.mediumGreen};
            }
            
            &.blue {
                background: ${props => props.theme.colors.clearBlue};
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        th, td {
            a img {
                width: 120px;
                height: 80px;
            }
            
            & button.sort {
                text-align: left;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
    
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
            
            &:last-child {
                 margin-bottom: 0;
            }
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            line-height: 44px;
            border-radius: 12px;
            
            & svg {
                &.up {
                    width: 19px;
                    height: 20px;
                }  
                
                &.down {
                    width: 19px;
                    height: 20px;
                }  
            }
            
            a img {
                height: 52px;
            }
            
            &:nth-child(even) {
                background: ${props => props.theme.colors.white};
            }
            
            &:last-child {
                background: transparent;
            }
        
            &:before {
                content: attr(data-label);
                float: left;
                padding-right: 15px;
                color: ${props => props.theme.colors.secondary};
                font-weight: 600;
            }
            
            &.investor {
                overflow: inherit;
                justify-content: stretch;
                margin-bottom: 12px;
                padding: 0;
                
                &:before {
                    padding-right: 0;
                }
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px;
        }
    
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12}; 
            
            &.investor {
                margin-bottom: 6px;
            }
        }
    }
`;
