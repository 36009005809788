import React, { useState } from 'react';
import RankIcon from '../../../../images/RankIcon.svg';
import { DefaultButton } from '../../../../components/Button/styles';
import ButtonStarIcon from '../../../../images/ButtonStarIcon.svg';
import ButtonStarIconFollow from '../../../../images/ButtonStarIconFollow.svg';

const Table = ({
  position, investor, invested, tokens,
}) => {
  const [follow, setFollow] = useState(false);

  const handleFollowClick = () => {
    setFollow(prev => !prev);
  };

  return (
      <tr>
          <td>
              <span className={`position ${position === 1 ? 'orange' : position === 2 ? 'green' : position === 3 ? 'blue' : ''}`}>
                <RankIcon/>#{position}
              </span>
          </td>
          <td className={'name'}>
              <img src={investor?.image} alt={investor?.name}/>
              {investor?.name}
          </td>
          <td className={'invested'} data-label='Total invest'>
              <div>
                  {invested?.amount} {invested?.currency}
                  <span>({invested?.currencyDesc}{invested?.amountDesc})</span>
              </div>
          </td>
          <td data-label='Tokens #'>{tokens}</td>
          <td className={'follow'}>
              <DefaultButton onClick={handleFollowClick} className={!follow ? '' : 'followed'}>
                  {!follow ? <><ButtonStarIcon /><span>Follow</span> </> : <><ButtonStarIconFollow /><span>Followed</span> </>}
              </DefaultButton>
          </td>
      </tr>
  );
};

export default Table;
