import styled from 'styled-components';

export const StyledLogo = styled.div`
    & .logo {
        display: flex;
        flex-grow: 0;
        align-items: center;
        margin-right: 28px;
        padding-right: 28px;
        border-right: 2px solid ${props => props.theme.colors.mercury};

        & .logoImage {
            width: 32px;
            height: 32px;
        }
        
        & .logoText {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            font-size: ${props => props.theme.fontSize.f24};
            font-weight: 600;
            line-height: 32px;
        }
        
        &.footerLogo {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
        }
    }
    
    @media ${props => props.theme.device.md} {
        & .logo {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
    
            & .logoImage {
                width: 26px;
                height: 26px;
            }
            
            & .logoText {
                margin-left: 6px;
                font-size: ${props => props.theme.fontSize.f20};
                line-height: 20px;
            }
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & .logo {
            &.headerLogo {
                & .logoText {
                    display: none;
                }
            }
        }
    }
`;
