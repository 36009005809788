import React, { useState } from 'react';
import {
  CatalogFilterTop, FilterClear, FilterTabs, FilterTopTabs,
  FilterWrapper, FilterWrapperFavourite, RadioLabelFilter, StyledCatalogFilter,
} from './styles';
import BasketIcon from '../../images/BasketIcon.svg';
import BookmarkBlackIcon from '../../images/BookmarkBlackIcon.svg';
import BookmarkBlueFillIcon from '../../images/BookmarkBlueFillIcon.svg';
import LikeIcon from '../../images/LikeIcon.svg';
import { DefaultButton } from '../Button/styles';

const tops = [
  { id: 1, title: 'Most liked' },
  { id: 2, title: 'Most popular' },
  { id: 3, image: <LikeIcon />, title: 'Like' },
  { id: 4, image: <LikeIcon />, title: 'Dislike' },
];

const tags = [
  { id: 1, title: 'All' },
  { id: 2, title: 'Actors' },
  { id: 3, title: 'Bloggers' },
  { id: 4, title: 'Businessmens' },
  { id: 5, title: 'Rappers' },
  { id: 6, title: 'Singers' },
  { id: 7, title: 'Football players' },
  { id: 8, title: 'Basketball players' },
  { id: 9, title: 'Models' },
  { id: 10, title: 'Actors' },
  { id: 11, title: 'Businessmens' },
  { id: 12, title: 'Football players' },
  { id: 13, title: 'Bloggers' },
  { id: 14, title: 'Singers' },
  { id: 15, title: 'Rappers' },
  { id: 16, title: 'Basketball players' },
  { id: 17, title: 'Models' },
];

const times = [
  { id: 1, title: 'All' },
  { id: 2, title: '1H' },
  { id: 3, title: '24H' },
  { id: 4, title: '1W' },
  { id: 5, title: '1M' },
  { id: 6, title: '1Y' },
];

const CatalogFilter = () => {
  const [selectedTop, setSelectedTop] = useState();
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedTime, setSelectedTime] = useState();

  const [show, setShow] = useState(true);

  const removeAllTop = () => {
    setSelectedTop(undefined);
  };

  const handleToggleTag = (tagId) => {
    setSelectedTag(prevState => {
      if (prevState.includes(tagId)) {
        return prevState.filter((item) => {
          return item !== tagId;
        });
      }
      return [...prevState, tagId];
    });
  };

  const removeAllTags = () => {
    setSelectedTag([]);
  };

  const removeAllTime = () => {
    setSelectedTime(undefined);
  };

  const handleBookmarkClick = (event) => {
    event.stopPropagation();
    setShow(prev => !prev);
  };

  return (
      <StyledCatalogFilter>
          <CatalogFilterTop>
              <h3>Filter</h3>
          </CatalogFilterTop>
          <FilterWrapperFavourite>
              <h4>My favorite</h4>
              <DefaultButton onClick={handleBookmarkClick} className={'favourite'}>{show ? <BookmarkBlackIcon /> : <BookmarkBlueFillIcon />}</DefaultButton>
          </FilterWrapperFavourite>
          <FilterWrapper>
              <FilterTopTabs>
                  <h4>Tops</h4>
                  {selectedTop && <FilterClear onClick={removeAllTop}><BasketIcon /></FilterClear>}
              </FilterTopTabs>
              <FilterTabs>
                  {tops.map(top => {
                    return (
                          <RadioLabelFilter
                              key={top.id}
                              className={top.id === selectedTop ? 'checked' : ''}
                              onClick={() => {
                                setSelectedTop(top.id);
                              }}
                          >
                              <span className={top.title === 'Dislike' ? 'dislike' : ''}>
                                  {top.image}
                              </span>
                              {top.title}
                              <input type='radio' />
                          </RadioLabelFilter>
                    );
                  })}
              </FilterTabs>
          </FilterWrapper>
          <FilterWrapper>
              <FilterTopTabs>
                  <h4>By time</h4>
                  {selectedTime && <FilterClear onClick={removeAllTime}><BasketIcon /></FilterClear>}
              </FilterTopTabs>
              <FilterTabs>
                  {times.map(time => {
                    return (
                          <RadioLabelFilter
                              key={time.id}
                              className={time.id === selectedTime ? 'checked' : ''}
                              onClick={() => {
                                setSelectedTime(time.id);
                              }}
                          >
                              {time.title}
                              <input type='radio' />
                          </RadioLabelFilter>
                    );
                  })}
              </FilterTabs>
          </FilterWrapper>
          <FilterWrapper>
              <FilterTopTabs>
                  <h4>Tags</h4>
                  {selectedTag.length > 0 && <FilterClear onClick={removeAllTags}><BasketIcon /></FilterClear>}
              </FilterTopTabs>
              <FilterTabs>
                  {tags.map(tag => {
                    return (
                          <RadioLabelFilter
                              key={tag.id}
                              className={selectedTag.includes(tag.id) ? 'checked' : ''}
                              onChange={() => {
                                handleToggleTag(tag.id);
                              }}
                          >
                              {tag.title}
                              <input type='checkbox' />
                          </RadioLabelFilter>
                    );
                  })}
              </FilterTabs>
          </FilterWrapper>
      </StyledCatalogFilter>
  );
};

export default CatalogFilter;
