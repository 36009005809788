import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubMenu from '../../components/SubMenu';
import Button from '../../components/Button';
import {
  Description, ReferralBottom, ReferralInformationItem, ReferralInfoWrapper,
  ReferralProgram, ReferralTop,
} from './styles';
import CopyButtonIcon from '../../images/CopyButtonIcon.svg';
import ReferralsTable from './components/ReferralsTable';
import Navigation from '../../components/Navigation';
import Container from '../../components/Container';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'Referrals',  destination: '/referrals' },
];

const Referrals = () => {
  const navigate = useNavigate();

  const handleAssetsPage = () => {
    navigate('/my-assets');
  };

  const handleHomePage = () => {
    navigate('/');
  };

  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <SubMenu title='Referrals' />
              <ReferralInfoWrapper>
                  <ReferralTop>
                      <ReferralProgram>
                          <h3>Referral program</h3>
                          <Description>
                              In the field above you will get your “12 word Password” in specific order. This information is private and must be kept secure.
                              This is the only way to access your wallet. If lost, recovery is not possible because we never store your Password on our
                              servers. If your Password had been stolen you must move your funds to a new account immediately with a newly generated
                              Password. Save your “12 word Password” on a piece of paper and put it in a secure place or saving it to an encrypted
                              file on a USB stick.
                          </Description>
                      </ReferralProgram>
                      <div>
                          <h3>Information</h3>
                          <ReferralInformationItem>
                              <div className={'title'}>Total commission</div>
                              <div className={'text'}>12.346544</div>
                          </ReferralInformationItem>
                          <ReferralInformationItem>
                              <div className={'title'}>Referral number:</div>
                              <div className={'text'}>56</div>
                          </ReferralInformationItem>
                      </div>
                  </ReferralTop>
                  <ReferralBottom>
                      <Button primary onClick={handleAssetsPage}><CopyButtonIcon />Link to Assets page</Button>
                      <Button primary onClick={handleHomePage}><CopyButtonIcon />Link to Homepage</Button>
                  </ReferralBottom>
              </ReferralInfoWrapper>
              <ReferralsTable />
          </PageContainer>
      </Container>
  );
};

export default Referrals;
