import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomInfo,
  ButtonWrapper,
  ContentBlock,
  ContentBlockInner,
  CurrencyFirst,
  CurrencyInfoBlock,
  CurrencySecond,
  Name,
  Photo,
  Rank,
  StatusInfo,
  StyledTokenCard,
  TopInfo,
  VoteDown,
  VoteRange,
  VoteUp,
} from '../styles';
import BookmarkIcon from '../../../images/BookmarkIcon.svg';
import BookmarkWhiteFillIcon from '../../../images/BookmarkWhiteFillIcon.svg';
import { CardButton, DefaultButton } from '../../Button/styles';
import SortArrow from '../../../images/SortArrow.svg';
import { useModalContext } from '../../../context/hooks';
import { AuthContext } from '../../../context';

const TokenCard = ({
  image, rank, isTopTag, tags, vote, name, currencyFirst, currencySecond, statusInfo, horizontalProps,
}) => {
  const { updateModalState } = useModalContext();
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(true);

  const toggleBuySharesModal = (event) => {
    event.stopPropagation();
    updateModalState('buySharesModal', {
      isOpen: true,
    });
  };

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: true,
    });
  };

  const navigateToTokenPage = () => {
    navigate('/token-page');
  };

  const handleBookmarkClick = (event) => {
    event.stopPropagation();
    setShow(prev => !prev);
  };

  return (
      <StyledTokenCard onClick={navigateToTokenPage} className={horizontalProps ? 'horizontal' : ''}>
          <Photo background={`url(${image})`} className={'photo'}>
              <TopInfo>
                  <Rank>#{rank}</Rank>
                  <DefaultButton onClick={handleBookmarkClick}>{show ? <BookmarkIcon /> : <BookmarkWhiteFillIcon />}</DefaultButton>
              </TopInfo>
              <BottomInfo className={'bottomInfo'}>
                  {isTopTag && <DefaultButton className={'topButton'}>Top</DefaultButton>}
                  {tags.map(tag => {
                    return (
                        <DefaultButton key={tag} className={'categoryButton'}>{tag}</DefaultButton>
                    );
                  })}
              </BottomInfo>
          </Photo>
          <ContentBlock className={'contentBlock'}>
              <VoteRange className={'voteRange'}>
                  <VoteDown voteDown={vote.down} />
                  <VoteUp voteUp={vote.up} />
              </VoteRange>
              <ContentBlockInner className={'contentBlockInner'}>
                  <Name className={'name'}>{name}</Name>
                  <CurrencyInfoBlock>
                      <CurrencyFirst>{currencyFirst?.currency} {currencyFirst?.amount}</CurrencyFirst>
                      <CurrencySecond>{currencySecond?.amount} {currencySecond?.currency}</CurrencySecond>
                  </CurrencyInfoBlock>
                  <StatusInfo className={statusInfo?.status === 'outcome' ? 'statusInfoOutcome' : statusInfo?.status === 'income' ? 'statusInfoIncome' : ''}>
                      {statusInfo?.amount} {statusInfo?.currency} in {statusInfo?.date} <SortArrow />
                  </StatusInfo>
              </ContentBlockInner>
              <ButtonWrapper className={'buttonWrapper'}>
                  {isAuth
                    ? <CardButton outlined className={'buyShares'} onClick={toggleInvestModal}>Buy shares</CardButton>
                    : <CardButton outlined className={'buyShares'} onClick={toggleBuySharesModal}>Buy shares</CardButton>
                  }
              </ButtonWrapper>
          </ContentBlock>
      </StyledTokenCard>
  );
};

export default TokenCard;
