import React from 'react';
import { Thumb } from '../styles';

const ThumbImage = ({ file }) => {
  return (
      <Thumb>
          <img src={file.preview} />
      </Thumb>
  );
};

export default ThumbImage;
