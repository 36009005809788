import React, { useCallback, useState } from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import Button from '../../components/Button';
import FilterIcon from '../../images/FilterIcon.svg';
import CloseIcon from '../../images/CloseIcon.svg';
import Catalog from '../../components/CardLists/Catalog';
import CatalogSearch from '../../components/CatalogSearch';
import CatalogFilter from '../../components/CatalogFilter';
import {
  CatalogFilterBlock, CatalogFilters, CatalogList, CatalogWrapper,
  FilterCloseButton, OverlayFilter, StyledCatalogFilterPage,
} from './styles';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'Tokens of Popularity',  destination: '/tokens' },
];

const CatalogFilterPage = () => {
  const [open, setOpen] = useState(false);
  const openFilters = useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);

  return (
      <StyledCatalogFilterPage>
          <Container direction={'column'}>
              <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
              <PageContainer>
                  <CatalogWrapper>
                      <CatalogFilters justify={'space-between'}>
                          <CatalogSearch />
                          <Button primary className={'filterButton'} onClick={openFilters}><FilterIcon />Filter</Button>
                          <OverlayFilter open={open} onClick={openFilters} />
                          <CatalogFilterBlock open={open} setOpen={setOpen}>
                              <FilterCloseButton onClick={openFilters}>
                                  <CloseIcon />
                              </FilterCloseButton>
                              <CatalogFilter />
                          </CatalogFilterBlock>
                      </CatalogFilters>
                      <CatalogList>
                          <Catalog />
                      </CatalogList>
                  </CatalogWrapper>
              </PageContainer>
          </Container>
      </StyledCatalogFilterPage>
  );
};

export default CatalogFilterPage;
