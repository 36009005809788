import React from 'react';
import Container from '../../components/Container';
import Navigation from '../../components/Navigation';
import CatalogFullSizeList from '../../components/CardLists/CatalogFullSizeListCard';
import RecentlyLaunched from '../../components/CardLists/RecentlyLaunched';
import { PageContainer } from '../../components/Container/styles';

const navigationBreadcrumbs = [
  { title: 'Launchpad',  destination: '/launchpad' },
];

const Launchpad = () => {
  return (
      <Container direction={'column'}>
          <Navigation navigationBreadcrumbs={navigationBreadcrumbs} />
          <PageContainer>
              <CatalogFullSizeList catalogTitle='Launchpad' tokenCardTimer />
              <RecentlyLaunched />
          </PageContainer>
      </Container>
  );
};

export default Launchpad;
