import styled from 'styled-components';

export const StyledTooltip = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    
    .tooltipText {
        visibility: hidden;
        position: absolute;
        top: 25px;
        left: -20px;
        width: 275px;
        padding: 16px;
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: normal;
        line-height: 20px;
        background-color: ${props => props.theme.colors.white};
        border-radius: 16px;
        box-shadow: ${props => props.theme.boxShadow.tooltip};
        z-index: 2;
    }
    
    &:hover .tooltipText {
        visibility: visible;
    }
    
    
    @media ${props => props.theme.device.lg} {   
        display: none;
    }
`;
