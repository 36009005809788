import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from './Logo.svg';
import { StyledLogo } from './styles';

const Logo = ({ headerLogo }) => {
  return (
        <StyledLogo>
            <Link to="/" className={`logo ${headerLogo ? 'headerLogo' : 'footerLogo'}`}>
                <LogoImage className={'logoImage'} />
                <div className={'logoText'}>
                    CriptCCR
                </div>
            </Link>
        </StyledLogo>
  );
};

export default Logo;
