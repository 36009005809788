import styled from 'styled-components';

export const StyledOnboardingModalItem = styled.div`
    position: relative;
`;

export const OnboardingModalImage = styled.div`
    width: 520px;
    height: 290px;
    
    @media ${props => props.theme.device.md} {
        width: 327px;
        height: 185px;
    }
    
    @media ${props => props.theme.device.xs} {
         width: 100%;
         height: auto;
    }
`;

export const OnboardingModalContent = styled.div`
    height: 204px;
    margin: 36px 44px;
    
    @media ${props => props.theme.device.md} {
        height: 316px;
        margin: 26px 26px;
    }
    
    @media ${props => props.theme.device.xs} {
        margin: 26px 8px;
    }
`;

export const OnboardingModalTitle = styled.div`
    font-size: ${props => props.theme.fontSize.f24};
    line-height: 32px;
    letter-spacing: -0.3px;
    
    & strong {
        font-size: ${props => props.theme.fontSize.f24};
        font-weight: 600;
        line-height: 32px;
    }
`;

export const OnboardingModalText = styled.div`
    margin-top: 12px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
`;

