import styled from 'styled-components';

export const StyledTokenProfileCard = styled.div`
    display: grid;
    grid-template-areas: 'token-photo token-information' 'token-photo token-vote' 'token-photo token-buttons';
    justify-content: start;
    width: 100%;
    padding: 16px;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.filter};
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        grid-template-areas: 'token-photo token-information' 'token-vote token-vote' 'token-buttons token-buttons';
        display: flex;
        flex-wrap: wrap;
    }
`;

export const TokenPhoto = styled.div`
    grid-area: token-photo;
    width: 200px;
    height: 200px;
    margin-right: 16px;
    border-radius: 16px;

    & img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    
    @media ${props => props.theme.device.md} {
        flex-grow: 0;
        width: 110px;
        height: 110px;
        margin-bottom: 16px;
    }
`;

export const TokenInformation = styled.div`
    grid-area: token-information;
    position: relative;
    
    @media ${props => props.theme.device.md} {
        flex-grow: 1;
        margin-bottom: 16px;
    }
`;

export const TokenFavorites = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border: 1px solid ${props => props.theme.colors.whiteSmoke};
    border-radius: 8px;
    cursor: pointer;
        
    & svg {
        width: 12px;
        height: 16px;
    }
`;

export const TokenName = styled.div`
    margin-bottom: 4px;
    padding-right: 38px;
    
    & h3 {
        color: ${props => props.theme.colors.secondary};
    }
    
    @media ${props => props.theme.device.sm} {
        margin-bottom: 8px;
    }
`;

export const TokenCurrency = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 32px;
    word-break: break-all;
    
    & .currencyIcon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
    
    @media ${props => props.theme.device.md} {
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
        
        & .currencyIcon {
            width: 16px;
            height: 16px;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f14};
        line-height: 20px;
    }
`;

export const AfterTokenCurrency = styled.div`
    display: block;
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 25px;
    word-break: break-all;
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f14};
        line-height: 20px;
    }
`;

export const TokenVote = styled.div`
    grid-area: token-vote;
    width: 100%;
`;

export const TokenVoteInner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border: 1px solid ${props => props.theme.colors.whiteSmoke};
    border-radius: 8px;
    
    @media ${props => props.theme.device.md} {
        width: 340px;
    }
    
    @media ${props => props.theme.device.sm} {
        width: 100%;
    }
`;

export const TokenVoteNumberMinus = styled.div`
    color: ${props => props.theme.colors.coralRed};
    font-weight: 500;
`;

export const TokenVoteNumberPlus = styled.div`
    color: ${props => props.theme.colors.mediumGreen};
    font-weight: 500;
`;

export const TokenVoteDown = styled.div`
    width: ${({ voteDown }) => `${voteDown}%`};
    height: 10px;
    background: ${props => props.theme.colors.coralRed};
    border-right: ${({ voteDown, theme }) => (voteDown === '100' || voteDown === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteDown }) => (voteDown === '100' || voteDown === '0' ? '100px' : '100px 0 0 100px')};
`;

export const TokenVoteUp = styled.div`
    width: ${({ voteUp }) => `${voteUp}%`};
    height: 10px;
    background: ${props => props.theme.colors.mediumGreen};
    border-left: ${({ voteUp, theme }) => (voteUp === '100' || voteUp === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
    border-radius: ${({ voteUp }) => (voteUp === '100' || voteUp === '0' ? '100px' : '0 100px 100px 0')};
`;

export const TokenVoteRange = styled.div`
    display: flex;
    width: 277px;
    height: 10px;
    margin: 0 6px;
    border-radius: 100%;
    background: ${props => props.theme.colors.whiteSmoke};
    
    @media ${props => props.theme.device.md} {
        width: 160px;
    }
    
    @media ${props => props.theme.device.xs} {
        width: 120px;
    }
`;

export const TokenButtons = styled.div`
    overflow-x: scroll;
    grid-area: token-buttons;
    display: flex;
    align-self: flex-end;
    flex-wrap: nowrap;
    width: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
        
    &::-webkit-scrollbar {
       display: none;
    }
    
    & button {
        margin-right: 8px;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 16px;
    }
`;
