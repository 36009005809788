import styled from 'styled-components';

export const StakingTable = styled.table`
    width: 100%;
    position: relative;
    border-collapse: collapse;
    
    tr {
        border-bottom: 1px solid ${props => props.theme.colors.mercury};
    }
    
    th, td {
        text-align: left;
        height: 80px;
        padding-right: 20px;
        font-weight: 500;
    }
    
    th {
        color: ${props => props.theme.colors.secondary};
    }
    
    td {
        & span {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-weight: 500;
            
            &.token {
                flex-wrap: nowrap;
            
                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                }
                
                & .tokenText {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-width: 120px;
                }
            }
            
            &.bonusCryptoOutcome {
                color: ${props => props.theme.colors.coralRed};
                
                &:before {
                    content: '-';
                }
            }
            
            &.bonusCryptoIncome {
                color: ${props => props.theme.colors.mediumGreen};
                
                &:before {
                    content: '+';
                }
            }
        }
    }
    
    @media ${props => props.theme.device.lg} {
        td {
            & span {
                &.token {
                    img {
                        display: none;
                    }
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        thead tr {
            display: none;
        }
        
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 16px;
            border-bottom: none;
            border-radius: 8px;
            background: ${props => props.theme.colors.blueGradient};
            
             &:last-child {
                margin-bottom: 0;
            }
        }
        
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding: 0 8px;
            color: ${props => props.theme.colors.primary};
            font-weight: 500;
            border-radius: 12px;
            
            &:nth-child(odd) {
                background: ${props => props.theme.colors.white};
            }
            
            &:first-child {
                height: 44px;
                margin-bottom: -44px;
                padding: 0;
                background: transparent;
                z-index: 1;
            }
            
            &:nth-child(2) {
                height: 44px;
                margin-bottom: 12px;
                padding: 0;
                background: ${props => props.theme.colors.blueGradient};
                
                &:before {
                    display: none;
                }
            }
            
            & span {
                &.token {
                    img {
                        display: block;
                    }
                }
            }
        }
        
        td:before {
            content: attr(data-label);
            flex-grow: 1;
            color: ${props => props.theme.colors.secondary};
            font-weight: 600;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        td {
            & span {
                &.token {
                    max-width: 160px;
                }
            }
        }
    }
    
    @media ${props => props.theme.device.xs} {
        tr {
            padding: 6px; 
        }
        
        td {
            padding: 0 6px;
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;

