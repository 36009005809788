import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '../../../images/CloseIcon.svg';
import {
  ModalBody, ModalClose, ModalHeader, ModalTitle,
} from '../styles';
import Modal from '../index';
import {
  ConfirmationBottomInner,
  ConfirmationBottomItem, ConfirmationButtons,
  ConfirmationTopItem, ConfirmationWrapper,
} from './styles';
import ETHIcon from '../../../images/buyCCRIcons/ETHIcon.svg';
import CollectionValueIcon from '../../../images/CollectionValueIcon.svg';
import TwoSideArrow from '../../../images/TwoSideArrow.svg';
import Button from '../../Button';
import { smartTrim } from '../../../utils/smartTrim';
import { useModalContext } from '../../../context/hooks';

const ConfirmationActionModal = () => {
  const { modals, updateModalState } = useModalContext();
  const link = '1G2cm5Pgp5hxQyJgVt8PN4vN6AW2ardpQ2';
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const toggleConfirmationActionModal = () => {
    updateModalState('confirmationActionModal', {
      isOpen: !modals.confirmationActionModal.isOpen,
    });
  };

  const toggleCongratulationsBuyCCRModal = () => {
    toggleConfirmationActionModal();
    updateModalState('congratulationsBuyCCRModal', {
      isOpen: true,
    });
  };

  const handlerMobileWith = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handlerMobileWith);

    return () => window.removeEventListener('resize', handlerMobileWith);
  }, []);

  return (
      <>
          <Modal
              isOpen={modals.confirmationActionModal.isOpen}
              setIsOpen={toggleConfirmationActionModal}
              modalName={'buyCCRModal'}
          >
              <ModalHeader>
                  <ModalTitle className={'buyCCRModal'}>Confirmation <span className={'confirmation'}>of the action</span></ModalTitle>
                  <ModalClose>
                      <CloseIcon onClick={toggleConfirmationActionModal} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody>
                  <ConfirmationWrapper>
                      <ConfirmationTopItem>
                          <div className={'title'}>From:</div>
                          <div className={'text'}><span>Your self</span></div>
                      </ConfirmationTopItem>
                      <ConfirmationTopItem>
                          <div className={'title'}>To:</div>
                          <div className={'text'}>
                              <span>Your self</span>
                              <Link to={'#'}>
                                  [
                                  {isMobile
                                    ? smartTrim(link, 16)
                                    : link
                                  }
                              ]
                              </Link>
                          </div>
                      </ConfirmationTopItem>
                  </ConfirmationWrapper>
                  <ConfirmationWrapper className={'bottom'}>
                      <ConfirmationBottomInner>
                          <ConfirmationBottomItem>
                              <div className={'title'}>ETH to swap</div>
                              <div className={'text'}><ETHIcon />3.63765 ETH</div>
                          </ConfirmationBottomItem>
                          <ConfirmationBottomItem className={'center'}>
                              <div className={'title'}>Exchange</div>
                              <div className={'text'}><TwoSideArrow /></div>
                          </ConfirmationBottomItem>
                          <ConfirmationBottomItem>
                              <div className={'title'}>CCR to buy</div>
                              <div className={'text'}><CollectionValueIcon />1543</div>
                          </ConfirmationBottomItem>
                      </ConfirmationBottomInner>
                      <ConfirmationBottomInner>
                          <ConfirmationBottomItem className={'right rightMargin'}>
                              <div className={'title'}>ETH network fee</div>
                              <div className={'text'}>0.00000000 ETH ≈ $0.00 USD</div>
                          </ConfirmationBottomItem>
                          <ConfirmationBottomItem className={'right'}>
                              <div className={'title'}>Total ETH  to swap</div>
                              <div className={'text'}>0.00000000 ETH ≈ $0.00 USD</div>
                          </ConfirmationBottomItem>
                      </ConfirmationBottomInner>
                  </ConfirmationWrapper>
                  <ConfirmationButtons>
                      <Button outlined onClick={toggleConfirmationActionModal}>Cancel</Button>
                      <Button primary onClick={toggleCongratulationsBuyCCRModal}>Confirm</Button>
                  </ConfirmationButtons>
              </ModalBody>
          </Modal>
      </>
  );
};

export default ConfirmationActionModal;
