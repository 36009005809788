import styled from 'styled-components';

export const StyleTelegramBot = styled.div`
    h3 svg {
        margin-right: 16px;
    }
    
    @media ${props => props.theme.device.lg} {
        margin-top: 24px;
    }
    
    @media ${props => props.theme.device.md} {
        margin-top: 0;
    
        h3 svg {
            margin-right: 8px;
        }
    }
`;

export const TelegramBotList = styled.ul`
    list-style-position: inside;

    li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 32px;
        font-size: ${props => props.theme.fontSize.f16};
        line-height: 24px;
        word-break: break-all;
        
        & svg {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            fill: ${props => props.theme.colors.clearBlue};
        }
        
        & button {
            width: 20px;
            height: 20px;
            & svg {
                fill: ${props => props.theme.colors.clearBlue};
            }
        }
        
        a {
            font-size: ${props => props.theme.fontSize.f16};
            line-height: 24px;
            text-decoration: underline;
        }
        
        span {
            font-size: ${props => props.theme.fontSize.f16};
            font-weight: 500;
            line-height: 24px;
            word-break: break-all;
        }
    }
    
    @media ${props => props.theme.device.md} {
        li {
            margin-top: 16px;
        }
    }
    
    @media ${props => props.theme.device.xs} {
        li {
            display: block;
            vertical-align: middle;
            
            & svg {
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 3px;
            }
        }
    }
`;
