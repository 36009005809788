import styled from 'styled-components';

export const StyledHeaderCounts = styled.div`
    &.profileMenu {
        display: none;
        margin-top: 16px;
        
        & .headerCountsList {
            justify-content: space-between;
            align-items: center;
            height: 54px;
            border-top: 1px solid ${props => props.theme.colors.mercury};
        
            li {
                margin-right: 0;
                
                &:nth-child(2) {
                    order: 1;
                }
                
                &:last-child {
                    display: flex;
                    order: 0;
                    width: 1px;
                    height: 22px;
                    border: 1px solid ${props => props.theme.colors.mercury};
                }
            }
        }
        
        & .countImage {
            width: 22px;
            height: 22px;
            
            & svg {
                width: 14px;
                height: 14px;
            }
            
            span {
                top: -8px;
                right: -2px;
            
                & svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        
        & .countInfo {
            margin-left: 6px;
        
            & .countTitle {
                font-size: ${props => props.theme.fontSize.f12};
                font-weight: 500;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.profileMenu {
            display: block;
            
            & .headerCountsList {
                li {
                    display: flex;
                }
            }
        }
    }
`;

export const HeaderCountsList = styled.ul`
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    list-style: none;
    
    li {
        display: flex;
        align-items: center;
        margin-right: 24px;
        cursor: pointer;
        
        &:last-child {
            display: none;
        }
    }
    
    @media ${props => props.theme.device.md} {
        li {
            display: none;
        }
    }
`;

export const CountInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
`;

export const CountImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 32px;
    height: 32px;
    background-color: ${props => props.theme.colors.mercury};
    border-radius: 8px;
    
    & svg {
        width: 20px;
        height: 20px;
        fill: ${props => props.theme.colors.clearBlue};
    }
    
    span {
        position: absolute;
        top: -6px;
        right: -6px;
        
        & svg {
            width: 12px;
            height: 12px;
            
        }
    }
`;

export const CountTitle = styled.div`
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 700;
    line-height: 16px;
`;

export const CountDescription = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f10};
    line-height: 14px;
    
    &.blue {
        color: ${props => props.theme.colors.clearBlue};
        font-weight: 500;
    }
`;

