import React, { useState } from 'react';
import {
  EmotionAssetsBlock, EmotionAssetsItem, EmotionAssetsItemInfo, EmotionAssetsLine, EmotionBlock,
  ModalBody, ModalButtons, ModalHeader, ModalHeaderInfo, ModalHeaderName, ModalHeaderPhoto, ModalTitle,
} from '../styles';
import Button from '../../Button';
import Modal from '../index';
import modalHeaderPhoto from '../../../images/modalHeaderPhoto.png';
import BusinessIcon from '../../../images/BusinessIcon.svg';
import CollectionValueIcon from '../../../images/CollectionValueIcon.svg';
import { useModalContext } from '../../../context/hooks';
import SaveProfileIcon from '../../../images/SaveProfileIcon.svg';

const ConfirmSellModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [loading, setLoading] = useState(false);

  const toggleCongratulationsModal = (data) => {
    const second = {
      ...modals.congratulationsModal,
      isOpen: !modals.congratulationsModal.isOpen,
      ...data,
    };
    updateModalState('congratulationsModal', second);
  };

  const toggleConfirmSellModal = () => {
    updateModalState('confirmSellModal', {
      isOpen: !modals.confirmSellModal.isOpen,
    });
  };

  const delayCongratulationsSubmit = () => {
    const id = setTimeout(() => toggleCongratulationsModal({ isOpen: false }), 3000);

    toggleCongratulationsModal({ timeoutId: id, congratulationsText: 'The sale was successful' }); // because of useReducer is async, we must do this
    toggleConfirmSellModal();
  };

  const delaySubmit = () => {
    return new Promise((resolve => {
      setTimeout(() => resolve('Success'), 3000);
    }));
  };

  const handleSellConfirm = () => {
    setLoading(true);
    delaySubmit().then(console.log).then(() => {
      setLoading(false);
      delayCongratulationsSubmit();
    });
  };

  const toggleSellModal = () => {
    updateModalState('sellModal', {
      isOpen: !modals.sellModal.isOpen,
    });
  };

  const handleSellModalBack = (e) => {
    e.preventDefault();
    toggleConfirmSellModal();
    toggleSellModal();
  };

  return (
      <>
          <Modal
              isOpen={modals.confirmSellModal.isOpen}
              setIsOpen={toggleConfirmSellModal}
              modalName={'tokenModal'}
          >
              <ModalHeader>
                  <ModalHeaderPhoto>
                      <img src={modalHeaderPhoto} alt={''} />
                  </ModalHeaderPhoto>
                  <ModalHeaderInfo>
                      <ModalHeaderName>Token name</ModalHeaderName>
                      <ModalTitle>Confirm the Deal</ModalTitle>
                  </ModalHeaderInfo>
              </ModalHeader>
              <ModalBody>
                  <EmotionBlock>
                      <EmotionAssetsBlock>
                          <EmotionAssetsItem>
                              <BusinessIcon />
                              <EmotionAssetsItemInfo>
                                  <div className={'title'}>You sell shares:</div>
                                  <div className={'value'}>999.999999</div>
                                  <div className={'currency'}>$2.17</div>
                              </EmotionAssetsItemInfo>
                          </EmotionAssetsItem>
                          <EmotionAssetsLine />
                          <EmotionAssetsItem>
                              <CollectionValueIcon />
                              <EmotionAssetsItemInfo>
                                  <div className={'title'}>You get:</div>
                                  <div className={'value'}>999.999999 CCR</div>
                              </EmotionAssetsItemInfo>
                          </EmotionAssetsItem>
                      </EmotionAssetsBlock>
                  </EmotionBlock>
                  <ModalButtons className={'flex'}>
                      <Button
                          outlined
                          onClick={handleSellModalBack}
                      >
                          Back
                      </Button>
                      <Button
                          primary
                          className={'loading'}
                          onClick={handleSellConfirm}
                      >
                          Sell
                          {loading ? <SaveProfileIcon /> : null}
                      </Button>
                  </ModalButtons>
              </ModalBody>
          </Modal>
      </>
  );
};

export default ConfirmSellModal;
