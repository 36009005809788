import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { DefaultButton } from '../../Button/styles';
import { baseTheme as theme } from '../../../styles/theme';
import {
  SkeletonBottomInfo,
  SkeletonButtonWrapper, SkeletonContentBlock, SkeletonContentBlockInner, SkeletonCurrencyInfoBlock,
  SkeletonPhoto,
  SkeletonStatusInfo,
  SkeletonTopInfo, SkeletonVoteDown, SkeletonVoteRange, SkeletonVoteUp,
  StyledTokenCardSkeleton,
} from './styles';

const voteDown = '30';
const voteUp = '70';

const TokenCardSkeleton = ({ horizontalProps }) => {
  return (
      <SkeletonTheme baseColor={theme.colors.cadetBlue}>
          <StyledTokenCardSkeleton className={horizontalProps ? 'horizontal' : ''}>
              <SkeletonPhoto className={'photo'}>
                  <SkeletonTopInfo>
                      <Skeleton count={1} width={40} height={24} />
                      <Skeleton count={1} width={24} height={24} borderRadius={90} />
                  </SkeletonTopInfo>
                  <SkeletonBottomInfo className={'bottomInfo'}>
                      <DefaultButton className={'categoryButton'}>
                          <Skeleton count={1} width={32} height={22} borderRadius={90} />
                      </DefaultButton>
                      <DefaultButton className={'categoryButton'}>
                          <Skeleton count={1} width={54} height={22} borderRadius={90} />
                      </DefaultButton>
                      <DefaultButton className={'categoryButton'}>
                          <Skeleton count={1} width={24} height={22} borderRadius={90} />
                      </DefaultButton>
                  </SkeletonBottomInfo>
              </SkeletonPhoto>
              <SkeletonContentBlock className={'contentBlock'}>
                  <SkeletonVoteRange className={'voteRange'}>
                      <SkeletonVoteDown voteDown={voteDown}>
                          <Skeleton count={1} height={6} borderRadius={'0'} />
                      </SkeletonVoteDown>
                      <SkeletonVoteUp voteUp={voteUp}>
                          <Skeleton count={1} height={6} borderRadius={'0'} />
                      </SkeletonVoteUp>
                  </SkeletonVoteRange>
                  <SkeletonContentBlockInner>
                      <Skeleton count={1} height={16} />
                      <SkeletonCurrencyInfoBlock>
                          <Skeleton count={1} height={48} />
                      </SkeletonCurrencyInfoBlock>
                      <SkeletonStatusInfo>
                          <Skeleton count={1} height={16} />
                      </SkeletonStatusInfo>
                  </SkeletonContentBlockInner>
                  <SkeletonButtonWrapper className={'buttonWrapper'}>
                      <Skeleton count={1} height={32} />
                  </SkeletonButtonWrapper>
              </SkeletonContentBlock>
          </StyledTokenCardSkeleton>
      </SkeletonTheme>
  );
};

export default TokenCardSkeleton;
