import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import CopyIcon from '../../../../../images/CopyIcon.svg';
import TelegramIcon from '../../../../../images/TelegramIcon.svg';
import RateIcon from '../../../../../images/RateIcon.svg';
import CreditCardIcon from '../../../../../images/CreditCardIcon.svg';
import CheckIcon from '../../../../../images/CheckIcon.svg';
import { StyleTelegramBot, TelegramBotList } from './styles';
import { DefaultButton } from '../../../../Button/styles';
import SidebarItem from '../SidebarItem';
import { Content, SidebarButtons, SidebarButtonsInner } from '../../styles';
import { smartTrim } from '../../../../../utils/smartTrim';

const textareaArray = ['0xa35322488855645654323367544455'];

const TelegramBot = () => {
  const [walletAddressLink] = useState(textareaArray.join(' '));

  const copyToClipboard = useCallback((wal) => () => {
    navigator.clipboard.writeText(wal).then();
  }, []);

  return (
        <>
            <SidebarButtons>
                <h3>Balance:</h3>
                <SidebarButtonsInner>
                    <SidebarItem isBotView />
                </SidebarButtonsInner>
            </SidebarButtons>
            <Content>
                <StyleTelegramBot>
                    <h3><TelegramIcon />Telegram Bot</h3>
                    <TelegramBotList>
                        <li>1. Go to our bot using the link: &nbsp; <Link to='/'>www.link.com</Link></li>
                        <li>2. Then push <span>/start</span> </li>
                        <li>
                            3. When prompted for your wallet address, enter &nbsp;
                            <span>{smartTrim(walletAddressLink, 16)}</span>
                            <DefaultButton type={'button'} onClick={copyToClipboard(walletAddressLink)}>
                                <CopyIcon />
                            </DefaultButton>
                        </li>
                        <li>4. Select the top-up amount <RateIcon /></li>
                        <li>5. Pay by credit card or Apple Pay <CreditCardIcon /></li>
                        <li>6. The funds will be credited to your balance within a few seconds <CheckIcon /></li>
                    </TelegramBotList>
                </StyleTelegramBot>
            </Content>
        </>
  );
};

export default TelegramBot;
