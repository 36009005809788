import styled from 'styled-components';

export const StyledTokenCardSkeleton = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    border-radius: 14px;
    cursor: pointer;
    
    & span {
        line-height: normal !important;
    }
    
    &:hover {
        box-shadow: ${props => props.theme.boxShadow.mainShadow};
    }
    
    @media ${props => props.theme.device.sm} {    
        &.horizontal {
            flex-direction: row;
            
            & .photo {
                flex-shrink: 0;
                width: 160px;
                border-radius: 14px 0 0 14px;
                
                & .bottomInfo {
                    overflow: hidden;
                }
            }
            
            & .contentBlock {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 200px;
                border-radius: 0 14px 14px 0;
                
                & .voteRange {
                    order: 1;
                    margin-top: 8px;
                    padding: 0 10px;
                }
                
                & .contentBlockInner {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    order: 0;
                    height: 100%;
                    margin-top: 12px;
                    
                    & .name {
                        font-size: ${props => props.theme.fontSize.f16};
                        line-height: 24px;
                    }
                }
                
                & .buttonWrapper {
                    order: 2;
                    margin-top: 8px;
                }
            }
        }
    }

    @media ${props => props.theme.device.xs} {
        &.horizontal {
            & .photo {
                width: 130px;
                padding: 12px 4px;
            }
        }
    }
`;

export const SkeletonPhoto = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    padding: 12px;
    border-radius: 14px 14px 0 0;
    background: ${props => props.theme.colors.blueGradient};
`;

export const SkeletonTopInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SkeletonBottomInfo = styled.div`
    display: flex;
    align-items: center;
    
    & button.categoryButton {
        margin-right: 8px;
        
        &:last-child {
            margin-right: 0;
        }
    }
`;

export const SkeletonContentBlock = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.white};
    border-radius: 0px 0px 14px 14px;
`;

export const SkeletonVoteRange = styled.div`
    display: flex;
    width: 100%;
    height: 6px;
`;

export const SkeletonVoteDown = styled.div`
    width: ${({ voteDown }) => `${voteDown}%`};
    height: 6px;
    border-right: ${({ voteDown, theme }) => (voteDown === '100' || voteDown === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
`;

export const SkeletonVoteUp = styled.div`
    width: ${({ voteUp }) => `${voteUp}%`};
    height: 6px;
    border-left: ${({ voteUp, theme }) => (voteUp === '100' || voteUp === '0' ? 'none' : `1px solid ${theme.colors.white}`)};
`;

export const SkeletonContentBlockInner = styled.div`
    margin: 8px 0;
    padding: 0 10px;
`;

export const SkeletonCurrencyInfoBlock = styled.div`
    margin-top: 8px;
`;

export const SkeletonStatusInfo = styled.div`
    margin-top: 8px;
`;

export const SkeletonButtonWrapper = styled.div`
    padding: 0 10px 8px;
`;
