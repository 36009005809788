import styled from 'styled-components';

export const TablePopupButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const TablePopupWrapper = styled.div`
    display: ${({ openTablePopup }) => (openTablePopup ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 24px;
    right: 0;
    width: 200px;
    padding: 12px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.popup};
    z-index: 6;
    
    button {
        width: 100%;
        height: 36px;
    }
    button:last-child {
        margin-top: 12px;            
    }
`;

export const TablePopupOverlay = styled.div`
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 5;
    transform: ${({ openTablePopup }) => (openTablePopup ? 'translateX(0)' : 'translateX(100%)')};
`;

