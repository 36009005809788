import styled from 'styled-components';

export const StyledTokensPopularity = styled.div`  
    position: relative;
    padding-bottom: 80px;

    &:before {
        content: '';
        position: absolute;
        top: -178px;
        left: 0;
        width: 100%;
        height: 525px;
        background: ${props => props.theme.colors.mercury};
    }
    
    @media ${props => props.theme.device.lg} {
        &:before {
            display: none;
        }
    }
    
    @media ${props => props.theme.device.md} {
        padding-bottom: 44px;
    }
`;

export const TopFilters = styled.div`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 24px;
    
    @media ${props => props.theme.device.lg} {
         overflow-x: scroll;
         flex-wrap: nowrap;
         width: auto;
         white-space: nowrap;
         -ms-overflow-style: none;
         scrollbar-width: none;
        
         &::-webkit-scrollbar {
            display: none;
         }
    }
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    height: 36px;
    margin-right: 12px;
    padding: 0 18px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f14};
    font-weight: 600;
    border: 1px solid ${props => props.theme.colors.cadetBlue};
    border-radius: 100px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
       margin-right: 0;
    }
    
    &.checked {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tuna};
        border: 1px solid ${props => props.theme.colors.tuna};
    }
`;
