import { gql } from '@apollo/client';

export const DEALS = gql`
    query deals($tokenId: ID, $shift: Int) {
        deals(tokenId: $tokenId, shift: $shift) {
            pageInfo {
                shift
            }
            edges {
                id
                type
                value
                shares
                createdAt
                top {
                    id
                    uri
                    tags
                    txHash
                }
                user {
                    id
                    address
                    txHash
                }
                txHash
            }
        }
    }
`;
