import styled from 'styled-components';

export const StyledHome = styled.div`
    h2 {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
`;

export const MainAuthContent = styled.div`
    display: ${({ isAuth }) => (isAuth ? 'flex' : 'none')};
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding: 44px;
    
    @media ${props => props.theme.device.lg} {
        width: 100%;
        padding: 44px 45px;
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        padding: 26px 16px 44px;
    }
    
    @media ${props => props.theme.device.xs} {
        padding: 26px 10px 44px;
    }
`;

export const MainPageTitle = styled.div`
    font-size: ${props => props.theme.fontSize.f32};
    font-weight: 600;
    line-height: 42px;
    
    @media ${props => props.theme.device.md} {
        text-align: center;
    }
`;

export const MainPageDescription = styled.div`
    margin-top: 8px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f16};
    font-weight: 500;
    line-height: 24px;
    
    @media ${props => props.theme.device.md} {
        text-align: center;
        margin-top: 16px;
    }
`;

export const MainPageButtons = styled.div`
    display: flex;
    padding: 16px;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow.mainShadow};
    
    & button {
        width: 180px;
        
        &:first-child {
            margin-right: 16px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        & button {
            width: 120px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        flex-direction: column;
    
        & button {
            width: 320px;
            margin: 0 auto;
            
            &:first-child {
                margin: 0 auto 16px;
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
         & button {
            width: 280px;
         }
    }
    
    @media ${props => props.theme.device.xs} {
        & button {
            width: 100%;
         }
    }
`;

export const MainContent = styled.div`
    display: ${({ isAuth }) => (isAuth ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    background: ${props => props.theme.colors.mainBannerGradient};
    
    @media ${props => props.theme.device.md} {
        padding-bottom: 44px;
    }
`;

export const ListWrapper = styled.div`
    padding: 0 44px;
    
    &.services {
        padding: 44px 44px 4px;
        background: ${props => props.theme.colors.white};
        border-radius: 32px;
        box-shadow: ${props => props.theme.boxShadow.mainShadow};
    }
    
    &.tokensPopularity {
        padding: 44px 44px 0;
        background: ${props => props.theme.colors.white};
        border-radius: 32px;
        z-index: 1;
    }
    
    &.catalog {
        padding: 0 0 44px 0;
        
        &:last-child {
            padding-bottom: 0;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        padding: 0;
    
        &.tokensPopularity {
            padding: 44px 0;
            background: ${props => props.theme.colors.mercury};
            border-radius: 0;
        }
        
        &.catalog {
            padding: 0 0 26px 0;
        }
    }
    
    @media ${props => props.theme.device.md} {
        &.services {
            padding: 26px 0 0;
            border-radius: 0;
            box-shadow: none;
        }
        
        &.tokensPopularity {
            padding: 26px 0 36px;
            border-radius: 0;
            box-shadow: none;
        }
    }
`;

export const ListTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    
    &.catalog {
        margin-bottom: 20px;
    }
    
    @media ${props => props.theme.device.sm} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ListBottom = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.fontSize || props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 32px;
    
    @media ${props => props.theme.device.lg} {
        &.newTokensLaunches {
            flex-direction: column;
            align-items: baseline;
        }
    }
    
    @media ${props => props.theme.device.md} {
        justify-content: space-between;
    }
`;

export const RadioLabel = styled.label`
    display: block;
    margin: 0 28px 0 0;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.fontSize || props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 40px;
    cursor: pointer;
    
    input {
        display: none;
    }
    
    &:last-child {
       margin-right: 0;
    }
    
    &.checked {
        position: relative;
        color: ${props => props.theme.colors.primary};
        
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: ${props => props.theme.colors.clearBlue};
        }
    }
    
    @media ${props => props.theme.device.md} {
        font-size: ${props => props.fontSize || props.theme.fontSize.f24};
    }
`;

export const RightSide = styled.div`
    @media ${props => props.theme.device.md} {
        margin-left: 20px;
    }
    
    @media ${props => props.theme.device.sm} {
        margin-left: 0;
    }
`;

export const SelectContainer = styled.div`
    width: 225px;
    
    @media ${props => props.theme.device.lg} {
        width: 200px;
    }
    
    @media ${props => props.theme.device.sm} {
        margin-top: 16px;
    }
`;

export const SelectContainerTitle = styled.div`
    display: none;
    
    @media ${props => props.theme.device.sm} {
        display: flex;
        margin-bottom: 8px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f14};
        font-weight: 500;
        line-height: 16px;    
    }
`;

export const ListCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    
    @media ${props => props.theme.device.lg} {
        overflow-x: scroll;
        width: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const MemberCardWrapper = styled.div`
    flex-shrink: 0;
    width: 200px;
    margin-right: 28px;
    
    &:last-child {
        margin-right: 0;
    }
    
    &.catalogWrapper {
        margin-right: 16px;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    &.catalogListWrapper {
        margin-right: 0;
    }
    
    @media ${props => props.theme.device.sm} {
        &.catalogListWrapper {
            width: 100%;
            margin-bottom: 16px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
