import React from 'react';
import { StyledInput } from './styles';

const Input = ({
  onMaxHandler, maxInfo, preIcon, mailIcon, ...props
}) => {
  return (
        <>
            <StyledInput {...props} className={`${mailIcon ? 'mailIcon' : null} ${preIcon ? 'preIcon' : null} ${maxInfo ? 'maxInfo' : null}`} />
            {mailIcon && <span className={'mailIconSpan'}>@</span>}
            {preIcon && <span className={'preIconSpan'}>{preIcon}</span>}
            {maxInfo && <span className={'maxInfoSpan'} onClick={onMaxHandler}>{maxInfo}</span>}
        </>
  );
};

export default Input;
