import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  StyledValidationCard, ValidationCardAmount, ValidationCardAuthor, ValidationCardCenter, ValidationCardCenterTop,
  ValidationCardDescription, ValidationCardInfo, ValidationCardLeft, ValidationCardPhoto,
  ValidationCardRight, ValidationCardSelect, ValidationCardTags, ValidationCardTagsWrapper, ValidationCardTitle,
} from './styles';
import Button from '../../../../components/Button';
import { TagButton } from '../../../../components/Button/styles';
import accountDefaultImage from '../../../../images/accountDefaultImage.png';
import Select from '../../../../components/Select';
import { smartTrim } from '../../../../utils/smartTrim';
import CopyButtonIcon from '../../../../images/CopyButtonIcon.svg';

const options = ['4 Jan 01:00', '5 Jan 01:00', '6 Jan 01:00'];

const ValidationCard = ({
  image, creationDate, nameToken, url, tags, author, description, investmentAmount,
}) => {
  const navigate = useNavigate();

  const navigateEdit = useCallback(() => {
    navigate('/create-token');
  }, [navigate]);

  return (
      <StyledValidationCard>
          <ValidationCardLeft>
              <ValidationCardPhoto><img src={image} alt={nameToken} /></ValidationCardPhoto>
              <ValidationCardInfo>
                  <ValidationCardTitle className={'creationDate'}>Creation date</ValidationCardTitle>
                  {creationDate}
                  <ValidationCardTitle>Name Token</ValidationCardTitle>
                  <div className={'name'}>{nameToken}</div>
                  <ValidationCardTitle>URL</ValidationCardTitle>
                  <Link to={'#'} className={'link'}>
                      {smartTrim(url, 18)}
                      <CopyButtonIcon />
                  </Link>
              </ValidationCardInfo>
              <ValidationCardTags>
                  <ValidationCardTitle>Tags</ValidationCardTitle>
                  <ValidationCardTagsWrapper>
                      {tags?.map(tag => {
                        return (
                            <TagButton key={tag}>{tag}</TagButton>
                        );
                      })}
                  </ValidationCardTagsWrapper>
              </ValidationCardTags>
          </ValidationCardLeft>
          <ValidationCardCenter>
              <ValidationCardCenterTop>
                  <div>
                      <ValidationCardTitle>Author</ValidationCardTitle>
                      <ValidationCardAuthor>
                          {author?.photo
                            ? <img src={author?.photo} alt={author?.name} />
                            : <img src={accountDefaultImage} alt={author?.name} />
                          }
                          {author?.name}
                      </ValidationCardAuthor>
                  </div>
                  <div>
                      <ValidationCardTitle>Start date</ValidationCardTitle>
                      <ValidationCardSelect>
                          <Select options={options} validationCard />
                      </ValidationCardSelect>
                  </div>
              </ValidationCardCenterTop>
              <ValidationCardTitle>Description</ValidationCardTitle>
              <ValidationCardDescription>{description}</ValidationCardDescription>
              <ValidationCardTitle className={'black'}>Investment amount</ValidationCardTitle>
              <ValidationCardAmount>{investmentAmount?.amount}{investmentAmount?.currency}</ValidationCardAmount>
          </ValidationCardCenter>
          <ValidationCardRight>
              <Button primary>Approve</Button>
              <Button outlined onClick={navigateEdit}>Edit</Button>
              <Button decline>Decline</Button>
          </ValidationCardRight>
      </StyledValidationCard>
  );
};

export default ValidationCard;
