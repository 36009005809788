import React, { useCallback, useState } from 'react';
import Button from '../Button';
import { TablePopupButton, TablePopupOverlay, TablePopupWrapper } from './styles';
import DotsImage from '../../images/Dots.svg';
import { useModalContext } from '../../context/hooks';

const TablePopup = () => {
  const { updateModalState } = useModalContext();
  const [openTablePopup, setOpenTablePopup] = useState(false);
  const toggleOpenTablePopup = useCallback(() => setOpenTablePopup(prev => !prev), [setOpenTablePopup]);

  const onLinkClick = (callback) => () => {
    setOpenTablePopup(false);
    callback?.();
  };

  const toggleInvestModal = () => {
    updateModalState('investModal', {
      isOpen: true,
    });
  };

  const toggleSellModal = () => {
    updateModalState('sellModal', {
      isOpen: true,
    });
  };

  return (
        <>
            <TablePopupOverlay openTablePopup={openTablePopup} onClick={onLinkClick()} />
            <TablePopupButton onClick={toggleOpenTablePopup}>
                <DotsImage />
                <TablePopupWrapper openTablePopup={openTablePopup}>
                    <Button primary onClick={toggleInvestModal}>Buy</Button>
                    <Button outlined onClick={toggleSellModal}>Sell</Button>
                </TablePopupWrapper>
            </TablePopupButton>
        </>
  );
};

export default TablePopup;
