import styled from 'styled-components';

export const StyledNavigation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    button {
        display: flex;
        align-items: center;
        margin-left: 15px;
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f14};
        
        & svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }
`;

export const NavigationList = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 14px 0;
    list-style: none;
`;

export const NavigationItem = styled.li`
    padding-right: 6px;
    line-height: 20px;
    
    & a {
        color: ${props => props.theme.colors.cadetBlue};
        word-break: break-all;
        
        & svg {
            margin-left: 6px;
        }
    }
    
    & span {
        color: ${props => props.theme.colors.primary};
        font-weight: 500;
        word-break: break-all;
    }
    
    &.home {
        margin-right: 6px;
        border-right: 1px solid ${props => props.theme.colors.cadetBlue};
        
        & a {
            display: block;
            width: 20px;
            height: 20px;
            
            & svg {
                margin-left: 0;
            }
        }
    }
`;
