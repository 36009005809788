import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalBody } from '../styles';
import Modal from '../index';
import {
  OnboardingSuccessInner,
  OnboardingSuccessModalButtons,
  OnboardingSuccessModalContent,
  OnboardingSuccessModalImage,
} from './styles';
import OnboardImageSuccess from '../../../images/icons/OnboardImageSuccess';
import { baseTheme as theme } from '../../../styles/theme';
import Button from '../../Button';
import { AuthContext } from '../../../context';
import { useModalContext } from '../../../context/hooks';

const colors = {
  backgroundColor: theme.colors.mediumGreen,
  circleColor: theme.colors.hintOfGreen,
};

const OnboardingSuccessModal = () => {
  const { modals, updateModalState } = useModalContext();
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleOnboardingSuccessModal = () => {
    updateModalState('onboardingSuccessModal', {
      isOpen: !modals.onboardingSuccessModal.isOpen,
    });
  };

  const toggleBuyCCRModal = () => {
    updateModalState('buyCCRModal', {
      isOpen: true,
    });
  };

  const handleSignTokens = () => {
    toggleOnboardingSuccessModal();
    setIsAuth(true);
    navigate('/tokens');
  };

  const handleSignBuyCCR = () => {
    toggleOnboardingSuccessModal();
    toggleBuyCCRModal();
    setIsAuth(true);
    navigate('/');
  };

  return (
      <>
          <Modal
              isOpen={modals.onboardingSuccessModal.isOpen}
              setIsOpen={toggleOnboardingSuccessModal}
              modalName={'onboardingLarge'}
          >
              <ModalBody className={'onboardingLarge'}>
                  <OnboardingSuccessInner>
                      <OnboardingSuccessModalImage>
                          <OnboardImageSuccess backgroundColor={colors.backgroundColor} circleColor={colors.circleColor} />
                      </OnboardingSuccessModalImage>
                      <OnboardingSuccessModalContent>
                          <h1>Success</h1>
                          In the field above you will get your “12 word Password” in specific order.
                          This information is private and must be kept secure.
                          This is the only way to access your wallet.
                      </OnboardingSuccessModalContent>
                      <OnboardingSuccessModalButtons isAuth={isAuth}>
                          <Button outlined onClick={handleSignTokens}>Go to view tokens</Button>
                          <Button primary onClick={handleSignBuyCCR}>Top-up wallet</Button>
                      </OnboardingSuccessModalButtons>
                  </OnboardingSuccessInner>
              </ModalBody>
          </Modal>
      </>
  );
};

export default OnboardingSuccessModal;
