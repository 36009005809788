import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../index';
import {
  EnterForm, ModalBody, ModalButtons, ModalClose, ModalHeader, ModalTitle, 
} from '../styles';
import CloseButtonIcon from '../../../images/CloseButtonIcon.svg';
import FormGroup from '../../Forms/FormGroup';
import Label from '../../Forms/Label';
import {
  FormError, FormGroupClear, FormInput,
} from '../../Forms/FormGroup/styles';
import { DefaultButton, FormButton } from '../../Button/styles';
import { Photo, WalletAddress, WalletAddressInfo } from '../../../pages/EditProfile/styles';
import PhotoIcon from '../../../images/PhotoIcon.svg';
import EditIcon from '../../../images/EditIcon.svg';
import SaveProfileIcon from '../../../images/SaveProfileIcon.svg';
import Input from '../../Forms/Input';
import Button from '../../Button';
import DropzoneModal from '../../DropzoneModal';
import DropzoneButton from '../../DropzoneButton';
import CropEditor from '../../CropEditor';
import WarningBlock from '../../WarningBlock';
import CopyButtonIcon from '../../../images/CopyButtonIcon.svg';
import { useModalContext } from '../../../context/hooks';

const existingNicknames = ['Den', 'Viktor', 'Andrey'];
const textareaArray = ['0xd1540f88E3995FaC39DD3310e8E1A43A156dA8bE'];

const ProfileModal = () => {
  const { modals, updateModalState } = useModalContext();
  const [walletAddressLink] = useState(textareaArray.join(' '));
  const [nickname, setNickname] = useState('');
  const [nicknameDirty, setNicknameDirty] = useState(false);
  const [nicknameError, setNicknameError] = useState({
    message: 'Cannot be empty',
    status: 'error',
  });
  const [loading, setLoading] = useState(false);

  const toggleOnboardingSuccessModal = () => {
    updateModalState('onboardingSuccessModal', {
      isOpen: true,
    });
  };

  const toggleProfileModal = () => {
    updateModalState('profileModal', {
      isOpen: !modals.profileModal.isOpen,
    });
  };

  const delaySubmit = () => {
    return new Promise((resolve => {
      setNicknameError(null);
      setTimeout(() => resolve('Success'), 3000);
    }));
  };

  const showNicknameError = useMemo(() => {
    return nicknameError && nicknameDirty;
  }, [nicknameError, nicknameDirty]);

  const nicknameHandler = (e) => {
    !nicknameDirty && setNicknameDirty(true);
    setNickname(e.target.value);

    if (!e.target.value) {
      setNicknameError({
        message: 'Cannot be empty',
        status: 'error', 
      });
    } else {
      setNicknameError({
        message: 'Nickname is free',
        status: 'success',
      });
    }

    const isMatchNicknameHandler = existingNicknames.find((item) => item === e.target.value);

    if (isMatchNicknameHandler) {
      return setNicknameError({
        message: 'Error nickname',
        status: 'error',
      });
    }
  };

  const toggleModalAndReset = () => {
    toggleProfileModal();
    setNickname('');
    setNicknameError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNicknameDirty(true);

    if (nickname === '') {
      return setNicknameError({
        message: 'Cannot be empty',
        status: 'error',
      });
    }

    const isMatchHandleSubmit = existingNicknames.find((item) => item === nickname);

    if (isMatchHandleSubmit) {
      return setNicknameError({
        message: 'Error nickname',
        status: 'error',
      });
    }

    setLoading(true);
    delaySubmit().then(console.log).then(() => {
      setLoading(false);
      toggleModalAndReset();
      toggleOnboardingSuccessModal();
    });
  };

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const toggleUploadModal = useCallback(() => setOpenUploadModal(prev => !prev), [setOpenUploadModal]);
  const toggleEditModal = useCallback(() => setOpenEditModal(prev => !prev), [setOpenEditModal]);
  const toggleCropModal = useCallback(() => setOpenCropModal(prev => !prev), [setOpenCropModal]);
  const [accountImage, setAccountImage] = useState('');
  const [files, setFiles] = useState([]);

  const handleUploadConfirm = useCallback(() => {
    toggleUploadModal();
    toggleCropModal();
  }, [toggleUploadModal, toggleCropModal]);

  const deleteEditModal = useCallback(() => {
    setAccountImage('');
    toggleEditModal();
  }, [setAccountImage, toggleEditModal]);

  const handleEditImage = () => {
    if (accountImage) {
      toggleEditModal();
    } else toggleUploadModal();
  };

  const copyToClipboard = useCallback((wal) => () => {
    navigator.clipboard.writeText(wal).then();
  }, []);

  return (
      <>
          <Modal
              isOpen={modals.profileModal.isOpen}
              setIsOpen={toggleModalAndReset}
              modalName={'onboardingLarge'}
              loading={loading}
          >
              <ModalHeader className={'onboardingLarge'}>
                  <ModalTitle className={'onboardingLarge'}>Profile</ModalTitle>
                  <ModalClose className={'onboardingLarge'}>
                      <CloseButtonIcon onClick={loading ? undefined : toggleModalAndReset} />
                  </ModalClose>
              </ModalHeader>
              <ModalBody className={'onboardingLarge'}>
                  <EnterForm onSubmit={handleSubmit}>
                      <FormGroup>
                          <WalletAddress>
                              <Photo className={loading ? 'disabled' : null}>
                                  {accountImage ? <img src={accountImage}/> : <PhotoIcon />}
                                  <DefaultButton type={'button'} onClick={handleEditImage}>
                                      <EditIcon />
                                  </DefaultButton>
                              </Photo>
                              <WalletAddressInfo className={loading ? 'disabled' : null}>
                                  Wallet address:
                                  <Link className={'walletAddressLink'} to={'#'} onClick={copyToClipboard(walletAddressLink)}>
                                      0xd1540f88E3995FaC39DD3310e8E1A43A156dA8bE<CopyButtonIcon />
                                  </Link>
                              </WalletAddressInfo>
                          </WalletAddress>
                          <FormGroupClear />
                      </FormGroup>
                      <FormGroup>
                          <Label htmlFor="nickname" className={loading ? 'disabled' : null}>Choose Nickname</Label>
                          <FormInput>
                              <Input
                                  value={nickname}
                                  onChange={nicknameHandler}
                                  name="nickname"
                                  id="nickname"
                                  type={'text'}
                                  placeholder={'your_nickname'}
                                  error={showNicknameError && nicknameError.status === 'error'}
                                  disabled={loading ? 'disabled' : null}
                                  mailIcon
                              />
                          </FormInput>
                          <FormGroupClear>
                              {showNicknameError && <FormError className={nicknameError.status === 'success' ? 'green' : null}>{nicknameError.message}</FormError>}
                          </FormGroupClear>
                      </FormGroup>
                      <FormGroup className={'warning'}>
                          <WarningBlock
                              className={loading ? 'disabled' : null}
                              title='You must save your Password'
                              content='In the field above you will get your “12 word Password” in specific order.
                              This information is private and must be kept secure.  This is the only way to access your wallet.
                              If lost, recovery is not possible because we never store your Password on our servers. '
                          />
                      </FormGroup>
                      <FormButton
                          primary
                          type={'submit'}
                          className={'loading'}
                      >
                          Save profile
                          {loading ? <SaveProfileIcon /> : null}
                      </FormButton>
                  </EnterForm>
              </ModalBody>
          </Modal>

          {/* Upload Photo */}
          <Modal
              isOpen={openUploadModal}
              setIsOpen={setOpenUploadModal}
          >
              <ModalTitle>Upload Photo</ModalTitle>
              <DropzoneModal files={files} setFiles={setFiles} />
              <ModalButtons>
                  <Button clasName={'disableConfirm'} primary disabled={!files.length} onClick={handleUploadConfirm}>Confirm</Button>
                  <Button outlined onClick={toggleUploadModal}>Cancel</Button>
              </ModalButtons>
          </Modal>

          {/* Edit Photo */}
          <Modal
              isOpen={openEditModal}
              setIsOpen={setOpenEditModal}
          >
              <ModalTitle>Edit Photo</ModalTitle>
              <ModalButtons>
                  <DropzoneButton toggleEditModal={toggleEditModal} files={files} setFiles={setFiles} toggleCropModal={toggleCropModal} />
                  <Button delete onClick={deleteEditModal}>Delete photo</Button>
                  <Button outlined onClick={toggleEditModal}>Cancel</Button>
              </ModalButtons>
          </Modal>

          {/* Cropp Photo */}
          <Modal
              isOpen={openCropModal}
              setIsOpen={setOpenCropModal}
              modalName={'cropModal'}
          >
              <ModalTitle>Edit Photo</ModalTitle>
              <CropEditor toggleCropModal={toggleCropModal} setAccountImage={setAccountImage} imageToCrop={files[0]} />
          </Modal>
      </>
  );
};

export default ProfileModal;
