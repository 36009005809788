import styled from 'styled-components';

export const StyledInput = styled.input`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
    border-radius: 8px;
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
    
    &:before, &:after {
      -webkit-user-select: initial;
      -khtml-user-select: initial;
      -moz-user-select: initial;
      -ms-user-select: initial;
      user-select: initial;
    }
    
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type='number'],
    &[type="number"]:hover,
    &[type="number"]:focus {
        appearance: none;
        -moz-appearance: textfield;
    }
    
    &:disabled {
        color: ${props => props.theme.colors.cadetBlue};
        background: ${props => props.theme.colors.milkWhite};
        
        &::placeholder {
            color: ${props => props.theme.colors.cadetBlue};
        }
        
        &.mailIcon + span.mailIconSpan {
            color: ${props => props.theme.colors.cadetBlue};
        }
    }
    
    &.mailIcon {
        padding-left: 32px;
    }
    
    &.mailIcon + span.mailIconSpan {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 16px;
        color: ${props => props.theme.colors.clearBlue};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 56px;
        z-index: 1;
    }
    
    &.preIcon {
        padding-left: 44px;
    }
    
    &.preIcon + span.preIconSpan {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 16px;
        height: 56px;
        z-index: 1;
    
        & svg {
            width: 24px;
            height: 24px;
        }
    }
    
    &.maxInfo {
        padding-right: 70px;
    }
    
    &.maxInfo + span.preIconSpan + span.maxInfoSpan {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2px;
        right: 0;
        width: 65px;
        height: 52px;
        color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: normal;
        text-decoration: underline;
        border-left: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
        cursor: pointer;
    }
    
    &:focus {
        border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.clearBlue)};
        outline: none;
    }
    &::placeholder {
        color: ${props => props.theme.colors.secondary};
    }
`;

export const InputBlock = styled.div`
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    background: ${props => props.theme.colors.white};
    border: 2px solid ${props => (props.error ? props.theme.colors.coralRed : props.theme.colors.mercury)};
    border-radius: 8px;
    
    &.shareInput {
        color: ${props => props.theme.colors.clearBlue};
        font-weight: 500;
        text-decoration: underline;
    }
`;

export const InputBlockInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: ${props => props.theme.fontSize.f16};
    line-height: 24px;
    background: ${props => (props.theme.colors.mercury)};
    border-radius: 8px;
`;

export const InputBlockInnerLeft = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    
    & img {
        width: 32px;
        height: 32px;
        margin-right: 6px;
    }
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f12};
    }
`;

export const InputBlockInnerRight = styled.div`
    display: flex;
    align-items: center;
    
    & .invested {
        margin-right: 8px;
        color: ${props => (props.theme.colors.secondary)};
        font-size: ${props => props.theme.fontSize.f16};
    }
    
    & .currency {
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
    }
    
    @media ${props => props.theme.device.lg} {
        & .invested {
            display: none;
        }
    }
    
    @media ${props => props.theme.device.sm} {
        & .currency {
            font-size: ${props => props.theme.fontSize.f14};
        }
    }
    
    @media ${props => props.theme.device.xs} {
        & .currency {
            font-size: ${props => props.theme.fontSize.f12};
        }
    }
`;

export const CheckboxInput = styled(StyledInput)`
    width: 20px;
    height: 20px;
    margin-right: 12px;
    padding: 0;
`;

export const SearchInput = styled(StyledInput)`
    padding: 10px 16px 10px 42px;
    
    @media ${props => props.theme.device.xs} {
        font-size: ${props => props.theme.fontSize.f14};
    }
`;

