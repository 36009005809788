import styled from 'styled-components';

export const StyledServices = styled.div`
    @media ${props => props.theme.device.md} {
        background: ${props => props.theme.colors.white};
        box-shadow: ${props => props.theme.boxShadow.servicesAdaptive};
    }
`;

export const ListServices = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media ${props => props.theme.device.lg} {
        flex-wrap: wrap;
    }
`;

export const ServiceItem = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 220px;
    width: 100%;
    margin: 16px 0 40px;
    
    & .readMore {
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 12px 0;
        font-weight: 500;
        
        & svg {
            margin-left: 8px;
        }
    }
    
    @media ${props => props.theme.device.lg} {
        max-width: 45%;
    }
    
    @media ${props => props.theme.device.md} {
        max-width: 100%;
        margin: 0 0 16px;
        
        &:last-child {
            margin-bottom: 26px;
        }
    }
`;

export const ServiceItemTop = styled.div`
    @media ${props => props.theme.device.md} {
        display: flex;
        align-items: center;
    }
`;

export const ServiceItemImage = styled.div`
    width: 50px;
    height: 50px;

    & svg {
        width: 100%;
        height: 100%;
    }
    
    @media ${props => props.theme.device.md} {
        width: 25px;
        height: 25px;
        margin-right: 8px;
    }
`;

export const ServiceItemTitle = styled.div`
    margin: 26px 0 8px;
    font-size: ${props => props.theme.fontSize.f20};
    font-weight: 600;
    line-height: 32px;
    
    @media ${props => props.theme.device.md} {
        margin: 0;
    }
`;
