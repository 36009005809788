import styled from 'styled-components';

export const StyledWarningBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px;
    border: 2px dashed ${props => props.theme.colors.clearBlue};
    border-radius: 16px;
    
    &.alert {
        border: 2px dashed ${props => props.theme.colors.coralRed};
        
        & .infoBlock {
            color: ${props => props.theme.colors.coralRed};
            
            & .infoBlockTitle {
                color: ${props => props.theme.colors.coralRed};
                
                & svg {
                    fill: ${props => props.theme.colors.coralRed};
                }
            }
        }
    }
    
    &.disabled {
        border: 2px dashed ${props => props.theme.colors.cadetBlue};
        
         & .infoBlock {
            color: ${props => props.theme.colors.cadetBlue};
            
            & .infoBlockTitle {
                color: ${props => props.theme.colors.cadetBlue};
                
                & svg {
                    fill: ${props => props.theme.colors.cadetBlue};
                }
            }
        }
    }
    
    @media ${props => props.theme.device.md} {
        flex-direction: column;
        padding: 16px;
    }
`;

export const InfoBlock = styled.div`
    color: ${props => props.theme.colors.clearBlue};
    line-height: 20px;
`;

export const InfoBlockTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${props => props.theme.colors.clearBlue};
    font-size: ${props => props.theme.fontSize.f24};
    font-weight: 600;
    line-height: 32px;
    
    & svg {
        width: 32px;
        height: 32px;
        margin-right: 6px;
        fill: ${props => props.theme.colors.clearBlue};
    }
    
    @media ${props => props.theme.device.md} {
        font-size: ${props => props.theme.fontSize.f16};
        font-weight: 500;
        line-height: 24px;
    }
`;
