import styled from 'styled-components';
import sliderArrow from '../../../../images/SliderArrow.inline.svg';

export const StyledMainSlider = styled.div`
    display: flex;
    justify-content: center;
    width: 438px;

    @media ${props => props.theme.device.lg} {
        width: 48%;
    }
    
     @media ${props => props.theme.device.md} {
        display: none;
     }
`;

export const SwiperWrapper = styled.div`
    width: 100%;
    
    li {
        list-style: none;
    }
    
    @media ${props => props.theme.device.lg} {
        width: 200px;
    }
`;

export const NavigationButtons = styled.div`
    display: flex;
    justify-content: center;
    
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        justify-content: center;
        align-items: center;
        position: inherit;
        width: 40px;
        height: 40px;
        margin-top: 24px;
        border: 2px solid ${props => props.theme.colors.cadetBlue};
        border-radius: 40px;
            
        &:hover {
            background: ${props => props.theme.colors.white};
        }
    }
        
    .swiper-button-next {
        margin-left: 10px;
        transform: rotate(-180deg);
    }
    
    .swiper-button-next:after,
    .swiper-button-prev:after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('${sliderArrow}');
    }
`;
