import styled from 'styled-components';

export const StyledSidebarItem = styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    margin-bottom: 16px;
    padding: 0 16px;
    border: 2px solid ${props => props.theme.colors.mercury};
    border-radius: 16px;
        
    & svg {
        width: 24px;
        height: 24px;        
    }
    
    @media ${props => props.theme.device.lg} {
        width: 50%;
        margin-bottom: 0;
        
        &:first-child {
            margin-right: 16px;
        }
    }
    
    @media ${props => props.theme.device.md} {
        width: 100%;
        margin: 0 auto;
        
        &:first-child {
            margin: 0 auto 24px;
        }
    }
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.f12};
    line-height: 16px;
    
    & span {
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fontSize.f16};
        font-family: "DM Sans", sans-serif;
        font-weight: bold;
        line-height: 24px;
    }
`;
